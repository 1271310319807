
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
// import GbaHeader from "../header";

// Style
import useStyle from "./index.styles";
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import constants from '../../../../constants';

const StartScreen = ({
    width,
    height,
    bg,
    gradientBg,
    gameLogo,
    setIsGamePage,
    setIsHowToPlayScreen,
    logoWidth,
    isComingSoon,
    getchallengeAsync,
    gameStatus,
    limitedEdition = false,
    isColorSplash = false,
    handleGbaState,
    gameBranding = null,
}) => {
    const classes = useStyle();
    const location = useLocation();
    const navigate = useNavigate();
    const isDiwaliDash = bg.includes('diwaliDash')
    const isbuildingBlocks = bg.includes('building_blocks');
    const isCrazyCars = bg.includes('crazycar')
    const isPopcornMatch = bg.includes('popcorn')

    const customBannerImg = "/images/gba/limited-edition-banner.svg"

    const { t } = useTranslation()

    const handleStartGameClick = () => {
        setIsGamePage(true);
    }

    useEffect(() => {

        console.log("bg on start screen : ", bg);
        console.log("gradientBg on start screen : ", gradientBg);
        console.log("gameLogo on start screen : ", gameLogo);
        function loadBackgroundImage(url) {
            const img = new Image();
            img.src = url;

            img.onload = () => {
                console.log(bg, " bg image loaded")
            };

            img.onerror = () => {
                console.error("Failed to load background image.");
                // Optionally handle the error
            };
        }
        loadBackgroundImage(bg)
    }, [bg])

    // useEffect(() => {
    //     handleGbaState(constants.GBA_SCREEN_STATE[0])
    // }, [])

    return (
        <div className={classes.gbaStartScreenRoot} style={isPopcornMatch ? {} : { backgroundImage: `${gradientBg}` }}>
            <div className={classes.gbaStartScreenContainer} style={{ backgroundImage: `url(${bg})`, backgroundPositionY: isbuildingBlocks ? 'bottom' : 'center', backgroundSize: isbuildingBlocks || isCrazyCars ? 'contain' : 'cover' }}>

                <div className={`${classes.centerFlex} ${classes.bgImgBox}`} style={
                    isColorSplash ? {
                        backgroundImage: 'url(/images/gba/bucket_splash.png)',
                        backgroundSize: '130%',
                        backgroundPosition: 'center',
                    } : {
                        alignItems: isDiwaliDash ? 'flex-end' : 'center',
                    }
                }>
                    {
                        isDiwaliDash ? (
                            <img src="/diwaliDash/banner.png" alt="banner" className={classes.bannerImg} onLoad={() => {
                                console.log("banner image loaded successfully")
                            }} />
                        ) : (null)
                    }

                    {
                        limitedEdition && <div className={classes.customBannerContainer}>
                            {/* <h6 className={classes.customBannerText}>limited edition</h6> */}
                            <img src={customBannerImg} className={classes.customBannerImg} width="100%" alt="banner"
                                onLoad={() => {
                                    console.log("limitedEdition image loaded successfully")
                                }} />

                        </div>
                    }

                    <div className={`${classes.gameLogoAnimation}`}>
                        <img src={gameLogo} className={classes.titleImage} style={{ width: logoWidth }} alt="reveal" onLoad={() => {
                            console.log("game logo image loaded successfully")
                        }}
                            onError={() => {
                                console.log("error occured in loading game logo")
                            }}
                        />
                    </div>
                    {gameBranding && <div className={classes.gameSubLogoAnimation}>
                        <img src={gameBranding} className={classes.titleImage} style={{ width: logoWidth }} alt="reveal" onLoad={() => {
                            console.log("game logo image loaded successfully")
                        }}
                            onError={() => {
                                console.log("error occured in loading game logo")
                            }}
                        />
                    </div>
                    }

                </div>

                {
                    !isComingSoon ? (
                        <div className={`${classes.centerFlex} ${classes.btnBox}`}>
                            <div className={classes.btnContainer}>
                                <Button
                                    variant="contained"
                                    color="gbabtn"
                                    fullWidth
                                    size="small"
                                    className={classes.startBtn}
                                    onClick={handleStartGameClick}>
                                    {t("START GAME")}
                                </Button>
                                {/* <button className={classes.startBtn} onClick={handleStartGameClick}>START GAME</button> */}
                                {/* <button className={classes.howToPlay} onClick={setIsHowToPlayScreen}>HOW TO PLAY</button> */}
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', width: '90%', margin: 'auto', height: '90%', justifyContent: 'center' }}>
                            <div className={`${classes.centerFlex} ${classes.comingSoonText}`}>{t("Coming soon")}...</div>
                            <LoadingButton
                                variant='contained'
                                color='gbabtn'
                                fullWidth
                                size='small'
                                className={classes.startBtn}
                                style={{ width: '100%' }}
                                onClick={getchallengeAsync}
                            >
                                {t("NEXT CHALLENGE")}
                            </LoadingButton>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default StartScreen