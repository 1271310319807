const speechToText = async (sentencesSentimentAnalyses, spotlightDetails) => {
    const { sentencesAnalyses, transcript } = sentencesSentimentAnalyses;
    const { criteria, keywords, timespent } = spotlightDetails;

    // convert check in criteria to number
    criteria.forEach((item) => {
        item.check = parseInt(item.check);
    });

    // console.log("timespent:", timespent);

    //duration calculation
    let responseData = { timespent };
    let totalEnabledCriteria = criteria.reduce((a, b) => a + parseInt(b.check), 0);

    const scoreFactor = totalEnabledCriteria > 0 ? 120 / totalEnabledCriteria : 0;
    let totalUserPoint = 0;
    const durationTaken = timespent;

    const actualDurationCriteria = criteria.find((item) => item.name === 'Duration');
    if (actualDurationCriteria && actualDurationCriteria.check === 1) {
        const actualDuration = actualDurationCriteria.rate;
        const { weightage = 1 } = actualDurationCriteria;
        let durationTakenPercent = 0;
        if (actualDuration > 0) { durationTakenPercent = (durationTaken / actualDuration) * 100; }

        let durationPoint = 0;
        let durationFeedBack = 'Too Short';
        if (durationTakenPercent <= 50) {
            durationPoint = Math.floor(10 * (durationTakenPercent / 100));
            durationFeedBack = 'Too Short';
        } else if (durationTakenPercent > 50 && durationTakenPercent <= 80) {
            durationPoint = 10 + Math.round((7 / 29) * (durationTakenPercent - 51));
            durationFeedBack = 'Short';
        } else if (durationTakenPercent > 80 && durationTakenPercent <= 110) {
            durationPoint = 18 + Math.round((2 / 29) * (durationTakenPercent - 81));
            durationFeedBack = 'Good';
        } else if (durationTakenPercent > 110 && durationTakenPercent <= 125) {
            durationPoint = 17 + Math.round((7 / 14) * (111 - durationTakenPercent));
            durationFeedBack = 'Long';
        } else if (durationTakenPercent > 125) {
            let point = 10 + Math.floor(0.1 * (126 - durationTakenPercent));
            durationPoint = point < 0 ? 0 : point;
            durationFeedBack = 'Too Long';
        }

        durationPoint = (durationPoint / 20) * scoreFactor * weightage;
        const scorePercent = Math.round((durationPoint * 100) / scoreFactor);
        // const scorePercent = Math.floor((durationPoint * 100) / scoreFactor);
        totalUserPoint += Math.round(durationPoint);
        const duration = {
            actualDuration: actualDurationCriteria.rate,
            durationTaken: durationTaken,
            score: Math.round(durationPoint),
            scorePercent: scorePercent,
            max_score: scoreFactor * weightage,
            qualitativeFeedback: durationFeedBack,
            labels: [
                {
                    id: 1,
                    name: 'TOO SHORT',
                    count: 1,
                    backgroundColor: '#FF0000',
                    value: 'too-short',
                },
                {
                    id: 2,
                    name: 'SHORT',
                    count: 2,
                    backgroundColor: '#FFB300',
                    value: 'short',
                },
                {
                    id: 3,
                    name: 'GOOD',
                    count: 2,
                    backgroundColor: '#2A9A1A',
                    value: 'good',
                },
                {
                    id: 4,
                    name: 'LONG',
                    count: 2,
                    backgroundColor: '#FFB300',
                    value: 'long',
                },
                {
                    id: 5,
                    name: 'TOO LONG',
                    count: 2,
                    backgroundColor: '#FF0000',
                    value: 'too-long',
                },
            ],
        };
        responseData = { ...responseData, duration: duration };
    }

    // speech rate

    const speechRateCriteria = criteria.find((item) => item.name === 'SpeechRate');
    if (speechRateCriteria && speechRateCriteria.check === 1) {
        const { weightage = 1 } = speechRateCriteria;

        const speechRate = Math.round(transcript.split(' ').length / (timespent / 60));

        let dataPoint = sentencesAnalyses.map((item) => {
            if (item.words !== undefined && item.words.length > 0) {
                return Math.round(item.words.length / (item.duration / 600000000));
            } else {
                return 0;
            }
        });

        let minSpeechRate = speechRateCriteria.rate;

        let currentSpeechRatePercent = speechRate > 0 ? Math.round((speechRate / minSpeechRate) * 100) : 0;
        let speechRatePoint = 0;
        let speechFeedback = 'Too Slow';
        if (currentSpeechRatePercent <= 50 && speechRate > 0) {
            speechRatePoint = 10;
            speechFeedback = 'Too Slow';
        } else if (currentSpeechRatePercent <= 75) {
            speechRatePoint = 17;
            speechFeedback = 'Slow';
        } else if (currentSpeechRatePercent <= 110) {
            speechRatePoint = 18;
            speechFeedback = 'Good Pace';
        } else if (currentSpeechRatePercent <= 125) {
            speechRatePoint = 17;
            speechFeedback = 'Fast';
        } else if (currentSpeechRatePercent > 125) {
            speechRatePoint = 10;
            speechFeedback = 'Too Fast';
        }
        speechRatePoint = (speechRatePoint / 20) * scoreFactor * weightage;

        const scorePercent = Math.round((speechRatePoint * 100) / scoreFactor);
        // const scorePercent = Math.floor((speechRatePoint * 100) / scoreFactor);
        totalUserPoint += Math.round(speechRatePoint);

        const speechRateData = {
            value: speechRate,
            qualitativeFeedback: speechFeedback,
            data: dataPoint,
            score: Math.round(speechRatePoint),
            scorePercent: scorePercent,
            max_score: scoreFactor * weightage,
            labels: [
                {
                    id: 1,
                    name: 'TOO SLOW',
                    count: 1,
                    backgroundColor: '#FF0000',
                    value: 'too-slow',
                },
                {
                    id: 2,
                    name: 'SLOW',
                    count: 1,
                    backgroundColor: '#FFB300',
                    value: 'slow',
                },
                {
                    id: 3,
                    name: 'GOOD PACE',
                    count: 1,
                    backgroundColor: '#2A9A1A',
                    value: 'good-pace',
                },
                {
                    id: 4,
                    name: 'FAST',
                    count: 1,
                    backgroundColor: '#FFB300',
                    value: 'fast',
                },
                {
                    id: 5,
                    name: 'TOO FAST',
                    count: 1,
                    backgroundColor: '#FF0000',
                    value: 'too-fast',
                },
            ],
        };
        responseData = { ...responseData, speechRate: speechRateData };
    }

    //keywordMatch
    const keywordMatchCriteria = criteria.find((item) => item.name === 'KeyMatch');
    if (keywordMatchCriteria && keywordMatchCriteria.check === 1) {
        const { weightage = 1 } = keywordMatchCriteria;
        let learnerTranscript = transcript.toLowerCase();
        let key_criteria = keywords && keywords.length > 0 ? keywords : [];
        const keywordsArray = [...key_criteria.flatMap((item) => item.keywords)];
        let data = [];
        keywordsArray.forEach((keyword, index) => {
            let count = 0;
            if (keyword.name) {
                const name = keyword.name.toLowerCase();
                let regExp = new RegExp(`\\b${name}\\b`, 'gi');
                count = (learnerTranscript.match(regExp) || []).length;
            }

            if (keyword.matchType !== 'similar') {
                let obj = {
                    id: index + 1,
                    name: keyword.name,
                    count: count,
                    product: keyword.product,
                    type: count === 0 ? 'no-match' : 'exact-match',
                };
                data.push(obj);
            } else {
                if (count > 0) {
                    let obj = {
                        id: index + 1,
                        name: keyword.name,
                        count: count,
                        product: keyword.product,
                        type: 'exact-match',
                    };
                    data.push(obj);
                } else {
                    let keywordMatchesCount = 0;
                    keyword.similarKeywords.forEach((similar) => {
                        let similarCount = 0;
                        if (similar.name) {
                            const name = similar.name.toLowerCase();
                            let regExp = new RegExp(name, 'gi');
                            similarCount = (learnerTranscript.match(regExp) || []).length;
                        }

                        keywordMatchesCount += similarCount;
                    });
                    let obj = {
                        id: index + 1,
                        name: keyword.name,
                        count: keywordMatchesCount,
                        product: keyword.product,
                        type: keywordMatchesCount === 0 ? 'no-match' : 'similar-match',
                    };
                    data.push(obj);
                }
            }
        });

        let keywordPoint = 0;
        const minKeywordMatch = parseInt(keywordMatchCriteria.rate);
        const totalMatchPercent = data.length > 0 ? Math.round((data.filter((item) => item.type !== 'no-match').length / data.length) * 100) : 0;
        let rangeGap = (100 - minKeywordMatch) / 4;
        let keywordFeedback = 'Poor';

        if (totalMatchPercent < minKeywordMatch) {
            keywordPoint = 0;
            keywordFeedback = 'Poor';
        } else if (totalMatchPercent === minKeywordMatch) {
            keywordPoint = 10;
            keywordFeedback = 'Average';
        } else if (totalMatchPercent > minKeywordMatch && totalMatchPercent <= minKeywordMatch + rangeGap) {
            keywordPoint = 14;
            keywordFeedback = 'Good';
        } else if (totalMatchPercent > minKeywordMatch + rangeGap && totalMatchPercent <= minKeywordMatch + 2 * rangeGap) {
            keywordPoint = 16;
            keywordFeedback = 'Very Good';
        } else if (totalMatchPercent > minKeywordMatch + 2 * rangeGap && totalMatchPercent <= minKeywordMatch + 3 * rangeGap) {
            keywordPoint = 18;
            keywordFeedback = 'Excellent';
        } else if (totalMatchPercent > minKeywordMatch + 3 * rangeGap && totalMatchPercent <= minKeywordMatch + 4 * rangeGap) {
            keywordPoint = totalMatchPercent === 100 ? 20 : 19;
            keywordFeedback = 'Perfect Match';
        }
        keywordPoint = (keywordPoint / 20) * scoreFactor * weightage;
        const scorePercent = Math.round((keywordPoint * 100) / scoreFactor);
        // const scorePercent = Math.floor((keywordPoint * 100) / scoreFactor);
        totalUserPoint += Math.round(keywordPoint);
        const keywordMatchData = {
            value: totalMatchPercent,
            qualitativeFeedback: keywordFeedback,
            labels: [
                {
                    id: 1,
                    name: 'EXACT MATCH',
                    count: 1,
                    backgroundColor: '#2A9A1A',
                    value: 'exact-match',
                },
                {
                    id: 2,
                    name: 'SIMILAR MATCH',
                    count: 1,
                    backgroundColor: '#93E600',
                    value: 'similar-match',
                },
                {
                    id: 3,
                    name: 'NO MATCH',
                    count: 1,
                    backgroundColor: '#FF0000',
                    value: 'no-match',
                },
                {
                    id: 4,
                    name: 'POOR',
                    count: 1,
                    backgroundColor: '#FF0000',
                    value: 'poor',
                },
                {
                    id: 5,
                    name: 'AVERAGE',
                    count: 1,
                    backgroundColor: '#FF0000',
                    value: 'average',
                },
                {
                    id: 6,
                    name: 'GOOD',
                    count: 1,
                    backgroundColor: '#FFB300',
                    value: 'good',
                },
                {
                    id: 7,
                    name: 'VERY GOOD',
                    count: 1,
                    backgroundColor: '#FFB300',
                    value: 'very-good',
                },
                {
                    id: 8,
                    name: 'EXCELLENT',
                    count: 1,
                    backgroundColor: '#2A9A1A',
                    value: 'excellent',
                },
                {
                    id: 9,
                    name: 'PERFECT MATCH',
                    count: 1,
                    backgroundColor: '#2A9A1A',
                    value: 'perfect-match',
                },
            ],
            data: data,
            score: Math.round(keywordPoint),
            scorePercent: scorePercent,
            max_score: scoreFactor * weightage,
        };
        responseData = { ...responseData, keywordMatch: keywordMatchData };
    }


    //clarity
    // const clarityCriteria = criteria.find((item) => item.name === 'Clarity');
    // if (clarityCriteria && clarityCriteria.check === 1) {
    //     let avgClarityPercent = 0;
    //     const { weightage = 1 } = clarityCriteria;
    //     if (wordsArray.length > 0) { avgClarityPercent = Math.round((wordsArray.reduce((a, b) => a + b.Confidence, 0) / wordsArray.length) * 100); }

    //     let clarityPoint = 0;
    //     let clarityFeedback = 'Very Unclear';
    //     if (avgClarityPercent <= 50 && avgClarityPercent > 0) {
    //         clarityPoint = 10;
    //         clarityFeedback = 'Very Unclear';
    //     } else if (avgClarityPercent > 50 && avgClarityPercent <= 60) {
    //         clarityPoint = 12;
    //         clarityFeedback = 'Unclear';
    //     } else if (avgClarityPercent > 60 && avgClarityPercent <= 75) {
    //         clarityPoint = 14;
    //         clarityFeedback = 'Unclear';
    //     } else if (avgClarityPercent > 75 && avgClarityPercent <= 90) {
    //         clarityPoint = 18;
    //         clarityFeedback = 'Clear';
    //     } else if (avgClarityPercent > 90 && avgClarityPercent <= 100) {
    //         clarityPoint = 20;
    //         clarityFeedback = 'Very Clear';
    //     }
    //     clarityPoint = (clarityPoint / 20) * scoreFactor * weightage;
    //     const scorePercent = Math.round((clarityPoint * 100) / scoreFactor);
    // const scorePercent = Math.floor((clarityPoint * 100) / scoreFactor);
    //     totalUserPoint += clarityPoint;

    //     let groupClarityBasedOnTimeChunk = sentencesAnalyses.map((item) => {
    //         if (!item.words || item.words.length === 0) return 0;
    //         return Math.round((item.words.reduce((a, b) => a + b.Confidence, 0) / item.words.length) * 100);
    //     });

    //     const clarityData = {
    //         value: avgClarityPercent,
    //         qualitativeFeedback: clarityFeedback,
    //         data: groupClarityBasedOnTimeChunk,
    //         score: clarityPoint,
    //         scorePercent: scorePercent,
    //         max_score: scoreFactor * weightage,
    //         labels: [
    //             {
    //                 id: 1,
    //                 name: 'VERY UNCLEAR',
    //                 count: 1,
    //                 backgroundColor: '#FF0000',
    //                 value: 'very-unclear',
    //             },
    //             {
    //                 id: 2,
    //                 name: 'UNCLEAR',
    //                 count: 1,
    //                 backgroundColor: '#FFB300',
    //                 value: 'unclear',
    //             },
    //             {
    //                 id: 3,
    //                 name: 'CLEAR',
    //                 count: 1,
    //                 backgroundColor: '#2A9A1A',
    //                 value: 'clear',
    //             },
    //             {
    //                 id: 4,
    //                 name: 'VERY CLEAR',
    //                 count: 1,
    //                 backgroundColor: '#2A9A1A',
    //                 value: 'very-clear',
    //             },
    //         ],
    //     };
    //     responseData = { ...responseData, clarity: clarityData };
    // }

    //tone
    // const toneCriteria = criteria.find((item) => item.name === 'Tone');
    // if (toneCriteria && toneCriteria.check === 1) {
    //     const { weightage = 1 } = toneCriteria;

    //     let toneGroupingBasedOnTimeChunk = [];
    //     let dataPointArray = [];

    //     dataPointArray = sentiments[0].sentences.map((item) => item);

    //     let totalValue = 0;
    //     toneGroupingBasedOnTimeChunk = dataPointArray.map((item) => {
    //         let value = 20;
    //         const confidenceScores = item.confidenceScores[item.sentiment];
    //         switch (item.sentiment) {
    //             case 'positive':
    //                 value = Math.round(10 + 10 * confidenceScores);
    //                 totalValue += value;
    //                 break;
    //             case 'netural':
    //                 value = Math.round(5 + 5 * confidenceScores);
    //                 totalValue += value;
    //                 break;
    //             case 'negative':
    //                 value = 5;
    //                 totalValue += value;
    //                 value = -5;
    //                 break;
    //             default:
    //                 totalValue += value;
    //                 break;
    //         }
    //         return item.confidenceScores;
    //     });

    //     let toneScore = Math.round(totalValue / dataPointArray.length) * weightage;

    //     function getToneType(tone) {
    //         if (tone < 10) return 'Negative';
    //         if (tone >= 10 && tone <= 15) return 'Neutral';
    //         if (tone > 15) return 'Positive';
    //     }

    //     const toneType = getToneType(toneScore);

    //     const scorePercent = Math.round((toneScore * 100) / scoreFactor);
    // const scorePercent = Math.floor((toneScore * 100) / scoreFactor);
    //     totalUserPoint += toneScore;
    //     const toneData = {
    //         value: toneType,
    //         qualitativeFeedback: toneType,
    //         tone: toneScore,
    //         data: toneGroupingBasedOnTimeChunk,
    //         score: toneScore,
    //         scorePercent: scorePercent,
    //         max_score: scoreFactor * weightage,
    //         labels: [
    //             {
    //                 id: 1,
    //                 name: 'NEGATIVE',
    //                 count: 1,
    //                 backgroundColor: '#FF0000',
    //                 value: 'negative',
    //             },
    //             {
    //                 id: 2,
    //                 name: 'NEUTRAL',
    //                 count: 1,
    //                 backgroundColor: '#FFB300',
    //                 value: 'neutral',
    //             },
    //             {
    //                 id: 3,
    //                 name: 'POSITIVE',
    //                 count: 1,
    //                 backgroundColor: '#2A9A1A',
    //                 value: 'positive',
    //             },
    //         ],
    //     };
    //     responseData = { ...responseData, tone: toneData };
    // }


    //transcript match
    // const transcriptMatchCriteria = criteria.find((item) => item.name === 'TranscriptMatch');
    // if (transcriptMatchCriteria && transcriptMatchCriteria.check === 1) {
    //     const { weightage = 1 } = transcriptMatchCriteria;
    //     let creatorText = creatorTranscript;
    //     let filteredCreatorText = creatorText ? creatorText.replace(/[.,]/g, '').toLowerCase() : '';
    //     let filteredLearnerText = transcript ? transcript.replace(/[.,]/g, '').toLowerCase() : '';
    //     const jsonParams = JSON.stringify({ creatorTranscript: filteredCreatorText, learnerTranscript: filteredLearnerText });

    //     //create transcript compare
    //     let transcriptCompareOptions = { mode: 'text', pythonOptions: ['-u'], args: [jsonParams] };
    //     let transcriptCompareResults = await PythonShell.run('scripts/transcriptCompare.py', transcriptCompareOptions);

    //     let formattedLearnerTranscript = '';
    //     if (transcriptCompareResults && transcriptCompareResults.length === 2) {
    //         const creatorTranscript = transcriptCompareResults[0].split(' ');
    //         const learnerTranscript = transcriptCompareResults[1].split(' ');
    //         const lTranscriptErrInd = creatorTranscript.map((word, index) => {
    //             //create word string by replacing letter with _
    //             const wrongString = word.split('').reduce((a, b) => a + '_', '');
    //             //compare it with the word if matches then add the bold tag
    //             if (word === wrongString) {
    //                 const text = '<b>' + learnerTranscript[index] + '</b>';
    //                 return text;
    //             } else {
    //                 //return original word
    //                 return learnerTranscript[index];
    //             }
    //         });
    //         //remove word with all letters as _
    //         const remove_extraLTranscript = lTranscriptErrInd.filter((word) => {
    //             const wrongString = word.split('').reduce((a, b) => a + '_', '');
    //             if (word !== wrongString) return true;
    //             return false;
    //         });
    //         //create string
    //         formattedLearnerTranscript = remove_extraLTranscript.join(' ');
    //     }

    //     let transcriptMatchPoint = 0;
    //     const minTranscriptMatch = parseInt(transcriptMatchCriteria.rate);

    //     const shouldMatch = () => {
    //         if ((creatorText || transcript) && (creatorText.length > 0 || transcript.length > 0)) return true;
    //         return false;
    //     };

    //     let transcriptMatchOptions = { mode: 'text', pythonOptions: ['-u'], args: [jsonParams] };
    //     let transcriptMatchResults = shouldMatch ? await PythonShell.run('scripts/transcriptMatch.py', transcriptMatchOptions) : null;

    //     let totalComp = transcriptMatchResults && transcriptMatchResults.length > 0 ? transcriptMatchResults[0].split(' ') : 0;
    //     let totalMatchPercent = Math.round(parseFloat(totalComp[totalComp.length - 1].split(']]')[0]) * 100);

    //     let rangeGap = (100 - minTranscriptMatch) / 4;
    //     let transcriptFeedback = 'Poor';

    //     if (totalMatchPercent < minTranscriptMatch) {
    //         transcriptMatchPoint = 0;
    //         transcriptFeedback = 'Poor';
    //     } else if (totalMatchPercent === minTranscriptMatch) {
    //         transcriptMatchPoint = 10;
    //         transcriptFeedback = 'Average';
    //     } else if (totalMatchPercent > minTranscriptMatch && totalMatchPercent <= minTranscriptMatch + rangeGap) {
    //         transcriptMatchPoint = 12;
    //         transcriptFeedback = 'Good';
    //     } else if (totalMatchPercent > minTranscriptMatch + rangeGap && totalMatchPercent <= minTranscriptMatch + 2 * rangeGap) {
    //         transcriptMatchPoint = 14;
    //         transcriptFeedback = 'Very Good';
    //     } else if (totalMatchPercent > minTranscriptMatch + 2 * rangeGap && totalMatchPercent <= minTranscriptMatch + 3 * rangeGap) {
    //         transcriptMatchPoint = 16;
    //         transcriptFeedback = 'Excellent';
    //     } else if (totalMatchPercent > minTranscriptMatch + 3 * rangeGap && totalMatchPercent <= minTranscriptMatch + 4 * rangeGap) {
    //         transcriptMatchPoint = totalMatchPercent === 100 ? 20 : 20 - (100 - totalMatchPercent) / (rangeGap / 4);
    //         transcriptFeedback = 'Perfect Match';
    //     }
    //     transcriptMatchPoint = (transcriptMatchPoint / 20) * scoreFactor * weightage;
    //     const scorePercent = Math.round((transcriptMatchPoint * 100) / scoreFactor);
    // const scorePercent = Math.floor((transcriptMatchPoint * 100) / scoreFactor);
    //     totalUserPoint += transcriptMatchPoint;

    //     let transcriptMatch = {
    //         value: totalMatchPercent,
    //         qualitativeFeedback: transcriptFeedback,
    //         labels: [
    //             {
    //                 id: 1,
    //                 name: 'POOR',
    //                 count: 1,
    //                 backgroundColor: '#FF0000',
    //                 value: 'poor',
    //             },
    //             {
    //                 id: 2,
    //                 name: 'AVERAGE',
    //                 count: 1,
    //                 backgroundColor: '#FF0000',
    //                 value: 'average',
    //             },
    //             {
    //                 id: 3,
    //                 name: 'GOOD',
    //                 count: 1,
    //                 backgroundColor: '#FFB300',
    //                 value: 'good',
    //             },
    //             {
    //                 id: 4,
    //                 name: 'VERY GOOD',
    //                 count: 1,
    //                 backgroundColor: '#FFB300',
    //                 value: 'very-good',
    //             },
    //             {
    //                 id: 5,
    //                 name: 'EXCELLENT',
    //                 count: 1,
    //                 backgroundColor: '#2A9A1A',
    //                 value: 'excellent',
    //             },
    //             {
    //                 id: 6,
    //                 name: 'PERFECT MATCH',
    //                 count: 1,
    //                 backgroundColor: '#2A9A1A',
    //                 value: 'perfect-match',
    //             },
    //         ],
    //         transcript: filteredLearnerText,
    //         formattedLearnerTranscript,
    //         creatorTranscript: filteredCreatorText,
    //         score: transcriptMatchPoint,
    //         scorePercent: scorePercent,
    //         max_score: scoreFactor * weightage,
    //     };

    //     responseData = { ...responseData, transcriptMatch: transcriptMatch };
    // }

    //calculate user score

    let userScore = Math.round(totalUserPoint);
    const userScorePercent = Math.round((totalUserPoint / 120) * 100);
    let rating = 0;
    if (userScorePercent <= 40) {
        rating = 1;
    } else if (userScorePercent <= 74) {
        rating = 2;
    } else if (userScorePercent >= 75) {
        rating = 3;
    }
    responseData = { ...responseData, rating, userScore };

    return responseData;
};

export default speechToText;