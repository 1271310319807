import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie-player';

// mui
import { useTheme } from '@mui/styles'
import { Button, Card, CardActionArea, CardContent, CardMedia, Dialog, Grid, Rating, Typography } from '@mui/material';

// mui-icons
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';

// styles
import useStyles from './index.styles';
import { submitMicroskillFeedback } from '../../redux/microskill/microskill.api';
import { useTranslation } from 'react-i18next';
import palette, { lightPalette } from '../../theme/palette';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

// lottie 
import FORM_SUBMISSION_LOTTIE_DATA from "../../lottie-assets/Form_Submission_lottie.json";
import SMOKE_FEEDBACK from '../../lottie-assets/ms-feedback/smoke-feedback.json';
import STAR_FEEDBACK from '../../lottie-assets/ms-feedback/star-feedback.json';
import AWESOME_HEARTS_2 from '../../lottie-assets/ms-feedback/awesome-hearts-2.json';
import AWESOME_HEARTS from '../../lottie-assets/ms-feedback/awesome-hearts.json';
import isEmpty from '../../utils/isEmpty';

const emojisArray = [
    {
        id: 1,
        emoji: '&#x1F62D;',
        name: 'Not Worth It',
        lottieFile: SMOKE_FEEDBACK
    },
    {
        id: 2,
        emoji: '&#x1F615;',
        name: 'Not Good',
        lottieFile: SMOKE_FEEDBACK
    },

    {
        id: 3,
        emoji: '&#x1F611;',
        name: 'Average',
        lottieFile: STAR_FEEDBACK
    },

    {
        id: 4,
        emoji: '&#x1F60A;',
        name: 'Good',
        lottieFile: STAR_FEEDBACK
    },

    {
        id: 5,
        emoji: '&#x1F60D;',
        name: 'Awesome',
        lottieFile: AWESOME_HEARTS_2
    },
]

const MicroskillFeedBack = (props) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { openFeedback, setOpenFeedback, feedbackMicroskillId, name, imageUrl, ratingValue, setRatingValue } = props;

    const [isSelected, setIsSelected] = useState([false, false, false, false, false, false]);
    const [addtionalComment, setAdditionalComment] = useState('')
    const [showCongratesScreen, setShowCongratesScreen] = useState(false);
    const optionRef = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()]);
    const { isDarkMode } = useSelector(state => state.commonPersist);
    const dialogCloseTimeoutRef = useRef(null);
    const addCommentRef = useRef(null);

    const { t, i18n } = useTranslation()
    const styles = useStyles(i18n);

    const FeedbackOptions = [
        {
            imgPath: "/images/microskill-feedback/chat_org.svg",
            onSelectedImgPath: "/images/microskill-feedback/chat.svg",
            message: "I can apply what I learnt & practiced",
            onSelected: "#FF2596",
            width: "18%",
        },
        {
            imgPath: "/images/microskill-feedback/goal_org.svg",
            onSelectedImgPath: "/images/microskill-feedback/goal.svg",
            message: "Extremely relevant content",
            onSelected: "#0043B5",
        },
        {
            imgPath: "/images/microskill-feedback/conference_orange.svg",
            onSelectedImgPath: "/images/microskill-feedback/conference_white.svg",
            message: "Easy to learn, practice and apply",
            onSelected: "#1E88E5",
            width: "17%",
        },
        {
            imgPath: "/images/microskill-feedback/idea_orange.svg",
            onSelectedImgPath: "/images/microskill-feedback/idea_white.svg",
            message: "My results were insightful",
            onSelected: "#D93731",
        },
        {
            imgPath: "/images/microskill-feedback/touch_org.svg",
            onSelectedImgPath: "/images/microskill-feedback/touch.svg",
            message: "Learning experience was highly interactive",
            onSelected: "#43A047",
            width: "16%",
        },
        {
            imgPath: "/images/microskill-feedback/surface_org.svg",
            onSelectedImgPath: "/images/microskill-feedback/surface1.svg",
            message: "This Microskill will enhance my capability",
            onSelected: "#FFB300",
            width: "19%",
        }
    ];

    const handleCloseBtn = () => {
        setRatingValue(null);
        setOpenFeedback(false);
        if (showCongratesScreen) {
            clearTimeout(dialogCloseTimeoutRef.current);
            navigate("/learn");
        }
    }

    const handleOptionSelect = (e, idx) => {
        const el = optionRef.current[idx];
        isSelected[idx] = !isSelected[idx];
        setIsSelected(isSelected);
        if (isSelected[idx] && FeedbackOptions[idx].onSelected) {
            el.current.style.background = FeedbackOptions[idx].onSelected;
            el.current.style.color = isDarkMode ? "" : "#FFF";
            el.current.children[0].children[0].style.filter = "brightness(0) invert(1)" // targetting image element to invert color while selection
        }
        else {
            el.current.style.background = null;
            el.current.style.color = "";
            el.current.children[0].children[0].style.filter = ""
        }

        // el.current.style.background = isSelected[idx] ? FeedbackOptions[idx].onSelected : '#690380';
        el.current.children[0].src = isSelected[idx] ? FeedbackOptions[idx].onSelectedImgPath : FeedbackOptions[idx].imgPath;
    }

    const handleAdditionalComment = (e) => {
        setAdditionalComment(e.target.value);
    }

    const handleSuccessReponse = () => {
        setShowCongratesScreen(true);
        dialogCloseTimeoutRef.current = setTimeout(() => {
            setOpenFeedback(false);
            navigate("/learn");
        }, 6000);
    }

    const handleSubmitFeedback = async () => {
        const data = {
            rating: ratingValue,
        }

        // for (let i = 0; i < isSelected.length; i++) {
        //     const value = isSelected[i];
        //     data[FeedbackOptions[i].message] = value ? 'Y' : 'N';
        // }

        data['additionalComment'] = addtionalComment;

        const payload = {
            type: 'USER_MICROSKILL_FEEDBACK',
            data: data
        }

        if (ratingValue !== 0) {
            const respData = await submitMicroskillFeedback(feedbackMicroskillId, payload);
            if (respData.status === 200 || respData.status === 201) {
                handleSuccessReponse();
            }
        }
    }


    return (
        <Dialog
            open={openFeedback}
            sx={{
                backdropFilter: "blur(16px)",
            }}
            PaperProps={{
                sx: {
                    width: '50%',
                    borderRadius: '10px',
                    [theme.breakpoints.down('sm')]: {
                        width: '96%',
                        margin: '0',
                    }
                }
            }}
        >
            <div className={`${styles.innerContainer}`}>

                <div className={`${styles.banner}`} >

                    <div className={`${styles.backContainer} ${styles.centerFlex}`}>
                        <div className={`${styles.centerFlex} ${styles.backButton}`} onClick={handleCloseBtn}>
                            <CloseIcon style={{ color: '#FFF' }} />
                        </div>
                    </div>
                    {
                        showCongratesScreen && <div className={`${styles.centerFlex} ${styles.winScreenLottieContainer}`}>
                            <Lottie
                                animationData={FORM_SUBMISSION_LOTTIE_DATA}
                                loop={false}
                                play={true}
                                style={{ width: '50%' }}
                            />
                        </div>
                    }

                    <div className={`${styles.centerFlex} ${styles.titleContainer}`}>
                        <div className={styles.staticTitle}>{t('Feedback For Microskill')}</div>
                        <h4 className={`${styles.microskillName}`}>{name}</h4>
                    </div>
                </div>

                {
                    showCongratesScreen ? (
                        <>
                            <div className={styles.congratsText}>
                                Submitted Successfully
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.mainContainer}
                                style={{
                                    paddingBottom: isEmpty(ratingValue) ? '3rem' : '1rem',
                                }}
                            >

                                <div className={styles.rateExperience}>

                                    <div className={`${styles.rateExperienceText} ${styles.centerFlex}`}>{'Rate Your Experience'}</div>

                                    <div className={styles.gridContainer}>

                                        {emojisArray?.map((item, index) => (

                                            <div className={styles.cardWrapper}
                                                onClick={() => {
                                                    setRatingValue(item?.id)
                                                    if (!isEmpty(addCommentRef.current)) {
                                                        addCommentRef.current.focus();
                                                    }
                                                }}
                                                style={ratingValue === item?.id ? {
                                                    transform: 'scale(1.2)',
                                                    zIndex: 10,
                                                    borderColor: palette.orange,
                                                } : {}}
                                            >

                                                <div className={`${styles.lottieContainer} `} style={{
                                                    visibility: ratingValue === item?.id ? 'visible' : 'hidden',
                                                    opacity: ratingValue === item?.id ? 1 : 0,
                                                    bottom: [0, 1].includes(index) ? '75%' : [2, 3].includes(index) ? '50%' : '80%',

                                                }}>
                                                    <Lottie
                                                        animationData={item?.lottieFile}
                                                        loop={true}
                                                        play={ratingValue === item?.id}
                                                        // play={true}
                                                        rendererSettings={index === 4 ? {
                                                            preserveAspectRatio: 'xMidYMid slice'
                                                        } : {}}
                                                        style={index === 4 ? {
                                                            width: '100%',
                                                            height: '100%',
                                                        } : {}}
                                                    />
                                                </div>

                                                <div className={`${styles.cardContainer} ${styles.centerFlex}`} key={item?.id} >

                                                    <p className={`${styles.emojiContainer} ${styles.centerFlex}`} dangerouslySetInnerHTML={{ __html: item?.emoji }}></p>

                                                    <div className={`${styles.emojiName} ${styles.centerFlex}`}>
                                                        {item?.name}
                                                    </div>
                                                </div>
                                            </div>

                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className={`${styles.submitFeedbackContainer}`} style={{
                                height: isEmpty(ratingValue) ? '0rem' : '',
                                padding: isEmpty(ratingValue) ? '0rem' : '1rem 1.5rem',
                            }}>

                                <div className={`${styles.additionalCommentContainer}`}>
                                    <input type='text' className={styles.additionalComment} placeholder={t("Add comments here")} onChange={(e) => handleAdditionalComment(e)} ref={addCommentRef} />
                                </div>


                                <div className={styles.loadingBtn}>
                                    <LoadingButton
                                        type="submit"
                                        style={{ width: '100%', borderRadius: '5px', padding: "8px 22px", backgroundColor: '#F4511E', fontWeight: 'bold', fontSize: '1rem', }}
                                        fullWidth
                                        onClick={handleSubmitFeedback}
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        {t("Submit")}
                                    </LoadingButton>
                                </div>




                                {/* <button
                        style={{ backgroundColor: (ratingValue === 0 ? '#707070' : '#F4511E'), color: ('#FFF') }}
                        className={styles.submitFeedbackButton} onClick={handleSubmitFeedback}>
                        {t("Submit")}
                    </button> */}
                            </div>
                        </>
                    )
                }
            </div>
        </Dialog>
    )
}

export default MicroskillFeedBack