import { microskillActionTypes } from "./microskill.types";

export const getAllModules = (data) => ({
    type: microskillActionTypes.GET_ALL_MODULES,
    payload: data
});


export const handleRecentlyLearned = (data) => ({
    type: microskillActionTypes.RECENTLY_LEARNED,
    payload: data
});
