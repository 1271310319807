import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import LoadingMicroskill from "../../components/loading-microskill";

// redux
import { logout, signIn, validateThirdPartyUser } from "../../redux/auth/auth.api";
import { openDialogue } from "../../redux/common/common.actions";
import isEmpty from "../../utils/isEmpty";

const CompanyIntegartionMondelaz = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const isMobile = useSelector(state => state?.common?.isMobile);

    const queryParams = new URLSearchParams(window.location.search);
    const encryptedUserId = queryParams.get('uname');
    const client = queryParams.get('client');
    const microskillId = queryParams.get('microskillId');
    const dialogue = {
        isDialogueOpen: true,
        dialogueTitle: 'Login',
        dialogueContent: 'User ID does not exist, please contact your company administrator.'
    }

    useEffect(() => {
        async function signInUser() {
            const resp = await validateThirdPartyUser({ encryptedText: encryptedUserId, companyCode: client });
            if (resp.status === 401) return dispatch(openDialogue(dialogue));
            const req = {
                username: resp?.data?.data?.username,
                password: ''
            };
            const signInResp = await signIn(req);
            if (signInResp.status === 200 || signInResp.status === 201) {
                if (!isEmpty(microskillId)) {
                    navigate(`/challenge-list/${microskillId}`);
                } else {
                    // navigate('/learn');
                    window.location.href = '/learn';
                }
            } else {
                return dispatch(openDialogue(dialogue));
            }
        }
        signInUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <LoadingMicroskill loadingText={false} />
        </div>
    )
}

export default CompanyIntegartionMondelaz;