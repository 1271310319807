import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// components
import StartScreen from "../common-screens/start-screen";
import HowToPlay from "../how-to-play/mrr-how-to-play";
import GbaGameOverScreen from "../common-screens/game-over-screen";
import GbaWinScreen from "../common-screens/win-screen";

// utils
import commonUtil from "../../../utils/commonUtil";
import isEmpty from "../../../utils/isEmpty";

// redux
import { submitChallengeAttemptData, submitEndChallengeAttempt } from "../../../redux/gba/gba.api";
import { startChallengeAttempt } from "../../../redux/microskill/microskill.api";

// constants
import constants from "../../../constants";

// theme
import palette from "../../../theme/palette";

// Style
import useStyle from "./index.styles";

const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;
const IMG_BUCKET_OUTLINE = '/images/gba/Popcorn_Match_Bucket_Outline.png';
const IMG_BUCKET_EMPTY = '/images/gba/Popcorn_Match_Bucket_Empty.png';
const IMG_BUCKET_FULL = '/images/gba/Popcorn_Match_Bucket_Full.png';

const topCardCount = 3;
const bottomCardCount = 2;
const maxTime = 180;
const maxLife = 5;
const mxmScore = 120;

const GalacticWordSearch = (props) => {
    const { gameData, handleGbaState, isPauseOverlay, defaultGradient, challengeData, setAddPause } = props;
    const questionsLength = gameData[0]?.length;
    const navigate = useNavigate();
    const { microskillId, challengeId } = useParams();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyle(isMobile);
    const dispatch = useDispatch();
    const [isGamePage, setIsGamePage] = useState(false);
    const [isHowToPlayScreen, setIsHowToPlayScreen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isGameComplete, setIsGameComplete] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [gameEndType, setGameEndType] = useState('');
    const [attemptData, setAttemptData] = useState('');
    // const [topCardArray, setTopCardArray] = useState(commonUtil.getSlicedArray(topCardCount, 0, gameData[activeIndex].options));
    // const [bottomCardArray, setBottomCardArray] = useState(commonUtil.getSlicedArray(bottomCardCount, 0, commonUtil.shuffleArray(topCardArray)));
    // const [bottomCardArray, setBottomCardArray] = useState(commonUtil.getSlicedArray(bottomCardCount, 0, gameData[activeIndex].options));
    const progressBarRef = useRef();
    const popcornMatchRootRef = useRef();
    const questionRef = useRef();
    const qstnBoxRef = useRef();
    const containerRef = useRef();
    const totalScoreRef = useRef(0);
    const isDragActive = useRef(true);
    const dragItemElementRef = useRef(null);
    const dragItemRef = useRef(null);
    const topCardRef = useRef(null);
    const attemptCountRef = useRef(0);
    const topCardArrayRef = useRef();
    const bottomCardArrayRef = useRef();
    const remainingTopCardArrayRef = useRef();
    const timeRefText = useRef();
    const lifeRef = useRef();
    const lifeRefText = useRef(maxLife);
    const lifeRefImg = useRef();
    const intervalRef = useRef();
    const isGamePausedRef = useRef(false);
    const firstUpdate = useRef(true);
    const prevAnsTimeRef = useRef(maxTime);
    const finalChallengeDataRef = useRef();
    const allAttempCountRef = useRef(0);
    const attemptDataRef = useRef();
    const gradientRef = useRef();
    const lifeBoxRef = useRef();

    let marksPerAnswer = 8;
    let bgGradientDefault = defaultGradient;
    let currentX;
    let currentY;
    let initialX;
    let initialY;
    let xOffset = 0;
    let yOffset = 0;
    let transitionTimeForCorrectAnswer = 500;
    let bufferTime = 300;

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        }
    }, []);

    useEffect(() => {
        if (isPauseOverlay) {
            isGamePausedRef.current = true;
            if (!firstUpdate.current) {
                handleBackButtonClick();
            }
        } else {
            if (firstUpdate.current) {
                firstUpdate.current = false;
                return
            }
            handleResumeClick();
        }
    }, [isPauseOverlay]);

    const handleBackButtonClick = () => {
        clearInterval(intervalRef.current);
    }

    const handleResumeClick = () => {
        setAddPause(true);
        startTimer();
    }

    useEffect(() => {
        if (isGamePage) {
            handleGbaState(GBA_SCREEN_STATE[2]);
            getChallengeDataAsync();
            startTimer();
        }
    }, [isGamePage]);

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    const getChallengeDataAsync = async () => {
        const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
        if (attemptResp.status === 200 || attemptResp.status === 201) {
            setAttemptData(attemptResp.data.data);
            attemptDataRef.current = attemptResp.data.data;
            let challengeCompleted = challengeData.completion;
            // Remove after successful developed
            const endAttempt = await submitEndChallengeAttempt({ microskillId, challengeId, attemptId: attemptResp.data.data.id, status: 'COMPLETED', scored: challengeCompleted ? challengeData.scored : 120, completion: true, timespent: 0 });
            if (endAttempt.status === 200 || endAttempt.status === 201) {
                navigate(-1);
            }
        }
    };

    const startTimer = async () => {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(async () => {
            if (isEmpty(timeRefText.current)) {
                clearInterval(intervalRef.current);
                return
            }
            let time = parseInt(timeRefText.current.innerHTML) - 1;
            timeRefText.current.innerHTML = time;
            if (time === 0) {
                isDragActive.current = false;
                clearInterval(intervalRef.current);
                console.log(attemptDataRef.microskillId);
                // setTimeout(() => {
                submitEndChallengeAttempt({
                    ...finalChallengeDataRef.current,
                    microskillId: attemptDataRef.current.microskillId,
                    attemptId: attemptDataRef.current.id,
                    challengeId: attemptDataRef.current.challengeId,
                    scored: totalScoreRef.current,
                    completion: false,
                    status: "TIMERLOSE",
                    timespent: 0
                });
                await sleep(2 * bufferTime);
                setShowCard(false);
                await sleep(bufferTime);
                setIsGameComplete(true);
                setGameEndType('timeup');
                handleGbaState(GBA_SCREEN_STATE[4]);
                // }, 1000);
                return
            }
        }, 1000);
    }

    const handleProgressBar = () => {
        let width = (parseInt(allAttempCountRef.current) / questionsLength) * 100;
        progressBarRef.current.style.width = `${width}%`
    }

    const restartGame = async () => {
        isDragActive.current = true;
        attemptCountRef.current = 0;
        allAttempCountRef.current = 0;
        totalScoreRef.current = 0;
        dragItemElementRef.current = null;
        startTimer();
        setShowCard(true);
        setIsGameComplete(false);
        setActiveIndex(0);
        setIsGamePage(false);
        await sleep(10);
        setIsGamePage(true);
    }

    const handleChallengeAttemptData = (params) => {
        let { qId, aId, isCorrect, t, skillId, contextId } = params;
        let questionObj = {
            questionId: qId,
            answers: [{
                answerId: aId,
                correct: isCorrect
            }],
            timespent: t
        };
        let req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptData.id,
            questions: [questionObj]
        }
        submitChallengeAttemptData(req);
        handleEndChallengeAttempt(questionObj, req, skillId, contextId)
    }

    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
        let tempQuestionObj = {
            ...questionObj,
            skillId: skillId,
            contextId: contextId,
        }
        let totalTimeSpent = (isEmpty(finalChallengeDataRef.current) ? 0 : finalChallengeDataRef.current.timespent) + tempQuestionObj.timespent;
        if (isEmpty(finalChallengeDataRef.current)) {
            finalChallengeDataRef.current = {
                ...req,
                questions: [tempQuestionObj],
                completion: true,
                status: "COMPLETED",
                timespent: totalTimeSpent,
                scored: totalScoreRef.current,
                fallbackSave: true,
            }
        } else {
            finalChallengeDataRef.current.questions.push(tempQuestionObj)
            finalChallengeDataRef.current = {
                ...finalChallengeDataRef.current,
                timespent: totalTimeSpent,
            }
        }
    }

    return (
        <div style={{ background: !isGamePage ? bgGradientDefault : bgGradientDefault }}
            className={classes.popcornMatchRoot} ref={popcornMatchRootRef}>
            <div className={classes.gradientBox} ref={gradientRef} />
            {
                !isGamePage ? (
                    !isHowToPlayScreen ? (
                        <div className={classes.startPage}>
                            <StartScreen
                                setIsGamePage={setIsGamePage}
                                setIsHowToPlayScreen={setIsHowToPlayScreen}
                                gradientBg={bgGradientDefault}
                                isComingSoon={true}
                                bg={"/images/gba/galactic-wordsearch-bg.png"}
                                gameLogo="/images/gba/galactic-wordsearch-logo.png"
                                getchallengeAsync={getChallengeDataAsync}
                                gameStatus={gameData.status === 'COMPLETED'}
                                handleGbaState={handleGbaState}
                            />
                        </div>
                    ) : (
                        <HowToPlay
                            // headerBg={headerBg}
                            qstnBoxBg={'#000000'}
                            qstnImg={'/images/gba/popcorn_perfo_rd.png'}
                            answerImg={IMG_BUCKET_EMPTY}
                            animationImg={IMG_BUCKET_FULL}
                            headerColor='#a34735'
                            setIsGamePage={setIsGamePage}
                            gameType='popcornMatch' />
                    )
                ) : (
                    !isGameComplete ? (
                        <div className={classes.container} ref={containerRef}>
                            <div>
                                <div className={classes.progressBarContainer}>
                                    <div className={classes.progressBar} ref={progressBarRef} />
                                </div>
                                {/* QUESTION BOX */}
                                <div className={classes.qstnContainer}>
                                    <div className={classes.qstnBox} ref={qstnBoxRef}>
                                        <div className={classes.assetBox}>
                                            <div className={classes.lifeText} ref={lifeBoxRef}>
                                                <div className={classes.life} ref={lifeRef}>
                                                    <span ref={lifeRefText}>{maxLife}</span> x
                                                </div>
                                                <img src='/images/icons/heart.png' className={classes.icon} alt="heart" ref={lifeRefImg} />
                                            </div>
                                            <div className={classes.timerText}>
                                                <img src='/images/icons/stopwatch.png' className={classes.timerIcon} alt="timer" />
                                                <div className={classes.time}><span ref={timeRefText}>{maxTime}</span>s</div>
                                            </div>
                                        </div>
                                        <div className={classes.qstn} ref={questionRef}>{gameData[activeIndex].context}</div>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.dragCardContainer} >

                            </div>
                        </div>
                    ) : (
                        <>
                            {
                                gameEndType === 'win' && (
                                    <GbaWinScreen
                                        maxScore={mxmScore}
                                        bg={""}
                                        obtainedScore={totalScoreRef.current} />
                                )
                            }
                            {
                                gameEndType === 'loose' && (
                                    <GbaGameOverScreen
                                        type={"gameover"}
                                        gameType={gameData?.gameType}
                                        gameData={gameData}
                                        bg={""}
                                        restartGame={restartGame}
                                    />
                                )
                            }
                            {
                                gameEndType === 'timeup' && (
                                    <GbaGameOverScreen
                                        type={"time"}
                                        bg={""}
                                        restartGame={restartGame}
                                    />
                                )
                            }
                        </>
                    )
                )
            }
        </div >
    )
};

export default GalacticWordSearch;
