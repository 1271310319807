import React from 'react';

// styles
import useStyles from './index.styles';

// mui
import { Button, Divider } from '@mui/material';

// utils
import commonUtil from '../../../../utils/commonUtil';
import { useTranslation } from 'react-i18next';


const FormLooseScreen = (props) => {

    const { formName, setOpenForm, showScore, score, maxScore, formTryAgainClick } = props;

    const formCloseOrExit = () => {
        window.location.href = '/learn';
        setOpenForm(false);
    }

    const styles = useStyles();

    const { t } = useTranslation()
    return (
        <div className={styles.formWinContainer}>
            <div className={styles.formInfoContainer}>
                <div className={`${styles.centerFlex} ${styles.closeContainer}`}>
                    <img src='/images/icons/form-close-icon.svg' alt="form-close-icon" className={styles.closeIcon} onClick={() => formCloseOrExit()} />

                </div>
                <div className={`${styles.centerFlex} ${styles.info}`}>
                    <div className={styles.formName}>{formName}</div>
                </div>
            </div>

            <div className={styles.userInfoContainer}>
                <div className={`${styles.tickContainer}`}>
                    <img src='/images/icons/form-cross.png' className={styles.tickIcon} alt="loose-form-assesment" />
                </div>
                <div className={styles.userInfo}>
                    <div className={styles.thankTimeupText} style={{
                        color: 'red'
                    }}>
                        {t('TRY AGAIN')}
                    </div>
                    <div className={styles.userName}>
                        {commonUtil.getUserName() === null || commonUtil.getUserName() === undefined || commonUtil.getUserName() === '' ? commonUtil.getLoginUserName() : `${commonUtil.getUserName()} (${commonUtil.getLoginUserName()})`}
                    </div>

                    <Divider className={styles.divideLine} sx={{
                        borderBottomColor: "red !important",
                    }} />

                    <div className={styles.additionalText}>
                        {t("You did not pass the assessment.")}
                    </div>

                    {/* <div className={styles.dateDisplay}>
                        {moment().format('DD MMMM YYYY')} at {moment().format('hh:mm A')}
                    </div> */}

                    {showScore && (
                        <>
                            <div className={styles.totalScoreText}>
                                {t("TOTAL SCORE")}:
                            </div>

                            <div className={styles.scoreText}>
                                {score} / {maxScore}
                            </div>
                        </>
                    )}
                </div>

            </div>

            <div className={`${styles.centerFlex} ${styles.exitButtonContainer}`}>
                <Button className={styles.exitButton} onClick={() => formTryAgainClick()}>
                    {t("Try Again")}
                </Button>
            </div>
        </div>
    )
}

export default FormLooseScreen;