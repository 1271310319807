import { spotlightTypes } from "./spotlight.types";
import constants from "../../constants";

const INITIAL_STATE = {
    // MODULE_LIST: null,
    screenState: constants.SPOTLIGHT_SCREEN_STATE[0]
}
const spotlightReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case spotlightTypes.SPOTLIGHT_SCREENSTATE:
            return {
                ...state,
                screenState: action.payload
            }
        default:
            return state;
    }
}

export default spotlightReducer;