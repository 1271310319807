/* eslint-disable no-dupe-keys */
import { makeStyles } from "@mui/styles";
import commonUtil, { isNativeApp } from "../../../utils/commonUtil";
import constants from "../../../constants";
const isHDScreen = commonUtil.getScreenResolution() === 'HD';

const GBA_HEADER_HEIGHT = isNativeApp() ? constants.HEADER_HEIGHT_MOBILE : constants.HEADER_HEIGHT

const useStyle = makeStyles((theme) => ({

    CrazyCarsRoot: {
        height: '100%',
        width: '100%',
        color: "#fff",
        boxSizing: "border-box",
        //background: "linear-gradient(#f7591f, #fcb340)",
        position: "relative",
        transition: "0.7s",
        userSelect: 'none',
        transition: 'background 1s'
    },

    startPage: {
        height: "100%",
        // backgroundImage: "url(/popcornMatch/popcorn_match_overlay.png)"
    },
    centerFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    bgImgBox: {
        textAlign: "center",
        height: "40%"
    },
    mainText: {
        animation: "$zoomInOut 1s ease 0s infinite normal forwards running",
    },
    titleImage: {
        width: "85%"
    },
    btnBox: {
        textAlign: "center",
        height: "60%"
    },
    btnContainer: {
        width: "100%",
    },
    howToPlay: {
        width: "85%",
        fontFamily: "MotionControl",
        fontSize: "1.8em",
        border: "none",
        padding: "0.6rem",
        cursor: "pointer",
        borderRadius: "5px",
        background: "none",
        marginBottom: "30px",
        color: "#fff",
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '2.5em',
        },
    },
    startBtn: {
        width: "85%",
        fontFamily: "MotionControl",
        fontSize: "2em",
        background: "#ffe66e",
        border: "none",
        padding: "0.6rem",
        cursor: "pointer",
        borderRadius: "5px",
        boxShadow: '0 2px 7px -1px #000',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '3em',
        },
    },

    "@keyframes animateOpacity": {
        "0%": { opacity: "0" },
        "100%": { opacity: "1" },
    },
    "@keyframes shakeObj": {
        "0%": { transform: "rotate(3deg)" },
        "10%": { transform: "rotate(-3deg)" },
        "20%": { transform: "rotate(3deg)" },
        "30%": { transform: "rotate(-3deg)" },
        "40%": { transform: "rotate(3deg)" },
        "50%": { transform: "rotate(-3deg)" },
        "60%": { transform: "rotate(3deg)" },
        "70%": { transform: "rotate(-3deg)" },
        "80%": { transform: "rotate(3deg)" },
        "90%": { transform: "rotate(-3deg)" },
        "95%": { transform: "rotate(3deg)" },
        "100%": { transform: "rotate(0deg)" },
    },
    "@keyframes slideFromBottom": {
        "0%": { transform: "translateY(250%)" },
        "100%": { transform: "translateY(0)" },
    },
    "@keyframes zoomInOut": {
        "0%": { transform: "scale(1)" },
        "50%": { transform: "scale(0.95)" },
        "100%": { transform: "scale(1)" },
    },
    // questionBox container
    qstnContainer: {
        height: "100%",
        transform: "translateY(-200px)",
        animation: "$translateQstnContainer 1s ease 0s 1 normal forwards running",
        zIndex: '3',
        position: 'relative',
        opacity: '0.9',
    },
    "@keyframes translateQstnContainer": {
        "0%": { transform: "translateY(-200px)" },
        "100%": { transform: "translateY(0)" }
    },
    qstnBox: {
        backgroundColor: "#000000",
        height: "100%",
        fontSize: "16px",
        // boxShadow: "0px 10px 20px #0000004D",
        display: 'grid',
        gridTemplateRows: '35% 65%'
    },
    qstnBoxBoxShadow: {
        transform: "translateY(0px)",
        animation: '$wrongAnsAnimation 1.5s ease-in-out 0s infinite normal forwards running'
    },
    "@keyframes wrongAnsAnimation": {
        "0%": { boxShadow: "0px 10px 20px #0000004D" },
        "50%": { boxShadow: "0px 0px 20px 5px #F63B34E8" },
        "100%": { boxShadow: "0px 10px 20px #0000004D" }
    },
    assetBox: {
        display: "grid",
        // gridTemplateColumns: "1fr  1fr",
        gridTemplateColumns: "1fr  1fr 1fr",
        padding: "12px",
        paddingBottom: '0px',
        alignItems: 'center'
    },
    lifeText: {
        display: "flex",
        alignItems: "center",
        padding: isHDScreen ? '0' : "0"
    },
    timerText: {
        display: "flex",
        alignItems: "center",
        padding: "0 15px",
        //justifyContent: "right",
        justifySelf: "end"
    },
    icon: {
        width: isHDScreen ? '1em' : '1em',
        height: isHDScreen ? '1em' : '1em',
        margin: "0 5px"
    },
    life: {
        fontWeight: '600',
        fontSize: isHDScreen ? '1em' : '1em',
    },
    timerIcon: {
        width: "13px",
        height: "12px",
        margin: "0 6px"
    },
    qstn: {
        textAlign: "center",
        transition: '.5s',
        fontFamily: 'Open Sans',
        fontSize: isHDScreen ? '0.6em' : '1em',
        opacity: '1',
        fontSize: '1em',
        padding: '2%',
        height: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        [theme.breakpoints.down("sm")]: {
            fontSize: isHDScreen ? '0.75em' : '1em',
            // fontSize: 'clamp(1.2rem, 1.2vw, 1.5rem)',
        },
    },

    //game play
    gameContainer: {
        display: "grid",
        height: "100%",
        width: '100%',
        gridTemplateRows: "100%",
        overflow: 'hidden',
        touchAction: 'none',
        opacity: '0',
        animation: '$animateGameLandingPage 2s ease 0s 1 normal forwards running',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    "@keyframes animateGameLandingPage": {
        "0%": { opacity: '0' },
        "100%": { opacity: '1' },
    },
    questionBox: {
        height: "60%",
        padding: "0.7rem"
    },
    qBox: {
        backgroundColor: "#000",
        height: "100%",
        padding: "1rem",
        borderRadius: "5px",
    },
    dragCardContainer: {
        display: "grid",
        height: "100%",
        gridAutoRows: "50% 50%",
        transition: '.5s'
    },
    answerContainer: {
        display: "flex",
        padding: "3rem 0",
        flexWrap: "wrap",
    },
    // answer: {
    //     flex: "1 1 33%",
    //     display: "flex",
    //     //overflow: 'hidden',
    //     padding: '0 1.2rem',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     transition: '.4s'
    // },
    anwserImgBox: {
        position: 'relative',
        height: '100px',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        transition: '0.4s'
    },
    // answerText: {
    //     position: 'absolute',
    //     top: '100%',
    //     left: '50%',
    //     transform: 'translateX(-50%)',
    //     fontSize: '0.7rem',
    //     textAlign: 'center',
    //     width: '14ch',
    //     // whiteSpace: 'normal',
    //     // wordBreak: 'break-word',
    //     fontFamily: 'Montserrat',
    //     [theme.breakpoints.down("sm")]: {
    //         // mobile
    //         fontSize: '1rem',
    //     },
    // },
    answerImg: {
        height: '60px',
    },

    option: {
        flex: "1 1 33%",
        display: "flex",
        padding: '0 1.2rem',
        alignItems: 'center',
        justifyContent: 'center',
    },
    qstnImgBox: {
        position: 'relative',
        height: '90px',
        width: '90px',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    },
    qstnText: {
        position: 'absolute',
        top: '108%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '0.7em',
        textAlign: 'center',
        width: '14ch',
        // whiteSpace: 'normal',
        // wordBreak: 'break-word',
        fontFamily: 'Montserrat',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1em',
        },
    },
    qstnImg: {
        height: '60px',
    },
    // optionContainer: {
    //     display: "flex",
    //     padding: "3rem 0",
    //     flexWrap: "wrap",
    //     justifyContent: "center",
    //     transition: "transform 1s",
    // },
    optionCard: {
        flex: "0 1 33%",
        display: "flex",
        justifyContent: "center",
    },
    progressBarContainer: {
        position: "relative",
        height: "6px",
        zIndex: "99999999999999999999",

        width: (isMobile) => {
            return `calc(100% - ${isMobile ? '0px' : '6px'})`
        },
        borderRadius: (isMobile) => {
            return `${isMobile ? '0px' : '8px'}`
        },
        marginTop: (isMobile) => {
            return `${isMobile ? '0px' : '4px'}`
        },
        marginBottom: (isMobile) => {
            return `${isMobile ? '0px' : '4px'}`
        },
        margin: 'auto',
        overflow: 'hidden',
        backgroundColor: theme.palette.white,
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.progressBarBg,
        },
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    },
    progressBar: {
        position: "absolute",
        top: "0",
        left: "0",
        height: "10px",
        backgroundColor: "#ccab36",
        width: '0',
        transition: '.4s',
    },
    boxShadowBreathingAnimation: {
        transition: '2s',
        animation: '$boxShadowBreathingAnimation 1s ease 0s infinite normal forwards running '
    },
    dragItemAction: {
        fontFamily: 'MotionControl',
        fontSize: '1.6em',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.8em',
        },
    },
    '@keyframes boxShadowBreathingAnimation': {
        '0%': { boxShadow: '0 0 15px -2px red' },
        '10%': { boxShadow: '0 0 16px -2px red' },
        '20%': { boxShadow: '0 0 17px -1px red' },
        '30%': { boxShadow: '0 0 18px -1px red' },
        '40%': { boxShadow: '0 0 19px 0px red' },
        '50%': { boxShadow: '0 0 20px 0px red' },
        '60%': { boxShadow: '0 0 19px -1px red' },
        '70%': { boxShadow: '0 0 18px -1px red' },
        '80%': { boxShadow: '0 0 17px -2px red' },
        '90%': { boxShadow: '0 0 16px -2px red' },
        '100%': { boxShadow: '0 0 15px -2px red' },
    },


    gameplayContainer: {
        width: '100%',
        display: 'grid',
        gridAutoRows: '90% 10%',
        alignItems: 'center',
        // justifyContent: 'center'
    },
    scrollBox: {
        height: '100%',
        position: 'relative',
        background: '#0C8C1A',
        display: 'grid',
        gridTemplateColumns: '20% 60% 20%',
        [theme.breakpoints.down("sm")]: {
            // mobile
            width: '100vw',
        },
        // overflow: 'auto'
    },
    gameplayBox: {
        // overflow: 'auto',
        // transform: 'translateY(-700px)',
        transistion: '0.3s',

        // transition: 'transform 330ms ease-in-out',
        // transition: '.2s',
        // position: 'absolute',
        //animationName: '$assetsAnimation',
        //animationDuration: '2s',
        //animationIterationCount: 'infinite',
        // animationTimingFunction: 'linear',
        // transform: 'translateY(-50vh)',
        width: '100%',
        left: '0',
        right: '0',
        top: '100%',
        height: '100%',
        '& > *': {
            display: 'flex',
            alignItems: 'center',
        },
        '& > img': {

        },
        '& div:nth-child(odd)': {
            justifyContent: 'flex-end',
        },
        '& div:nth-child(even)': {
            justifyContent: 'left',
        }
    },

    '@keyframes assetsAnimation': {
        '100%': {
            transform: 'translateY(0)',
        }
    },
    answerTxt: {
        position: 'absolute',
        cursor: 'pointer',
        zIndex: '3',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
        width: '90%',
        padding: '0.6rem',
        height: '80%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center !important',
        textAlign: 'center',
        fontSize: isHDScreen ? '0.6em' : '0.9em',
        fontWeight: isHDScreen ? '700' : 'bold',
        [theme.breakpoints.up("sm")]: {
            // mobile
            fontSize: isHDScreen ? '0.6em' : '0.9em',
        }
    },
    rockImageBox: {

    },
    rockBox: {
        position: 'relative',
        width: '50%',
        margin: '0 1rem'
    },
    rockImage: {
        position: 'relative',
        zIndex: '2',
        width: '100%'
    },
    correctLottie: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%',
    },
    wrongLottie: {
        position: 'absolute',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -100%)',
        height: '30%',
        aspectRatio: '1 / 1',
        zIndex: '2'
    },
    incorrectGradientOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        backgroundImage: 'linear-gradient(#800E31, #F63B34)',
        opacity: '0',
        transition: '.5s ease'
    },
    speedBarBox: {
        height: '100%',
        // background: '#0C8C1A',
        background: theme.palette.black,
        zIndex: '2',
        display: 'grid',
        gridTemplateColumns: '20% 60% 20%',
        alignItems: "center",
        justifyContent: "center",
        boxShadow: '0 0 15px 2px #000'
    },
    speedBarRectBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: '100%',
        '& > *': {
            width: '0.5em',
            borderRadius: '5px',
            [theme.breakpoints.down('sm')]: {
                // width: '5px',
                width: '7px',
                margin: '0 0.15rem'
            },
            height: '40%',
            margin: isHDScreen ? '0 .20rem' : '0 .25rem',
            background: '#fff'
        }
    },
    speedDecrement: {
        fontSize: '2em',
        marginRight: isHDScreen ? '0.5rem' : '1rem',
        cursor: 'pointer',
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        height: '100%',
        '& > *': {
            // '& > img': {
            width: '30%',
            [theme.breakpoints.down('sm')]: {
                width: '50%'
            }
        }
    },
    speedIncrement: {
        fontSize: '2em',
        marginLeft: isHDScreen ? '0.5rem' : '1rem',
        cursor: 'pointer',
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        height: '100%',
        '& > img': {
            // '& > img': {
            width: '30%',
            [theme.breakpoints.down('sm')]: {
                width: '50%'
            }
        }
    },
    incorrectGradientOverlayAnimationInfinite: {
        animation: '$incorrectGradientOverlayAnimationInfinite 1.5s ease-in-out 0s infinite normal forwards running'
    },
    '@keyframes incorrectGradientOverlayAnimationInfinite': {
        '0%': { background: "0C8C1A" },
        "50%": { background: "#FF5757" },
        "100%": { background: "0C8C1A" }
    },
    wrongAnsAnimation: {
        animation: '$incorrectGradientOverlayAnimationInfinite 1.5s ease-in-out 0s 2 normal forwards running'
    },
    BlankOption: {
        display: 'grid',
        gridTemplateColumns: '20% 60% 20%'
    },
    road: {
        borderLeft: '2px solid #ddd',
        borderRight: '2px solid #ddd',
        display: 'grid',
        gridTemplateColumns: '47.5% 5% 47.5%',
        position: 'relative',
    },
    roadStrips: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        backgroundSize: 'contain',

        '& > img': {
            width: '77%',
        }
    },
    '@keyframes roadAnimation': {
        to: {
            transform: 'translateY(0)',
        }
    },
    optionContainer: {
        display: 'flex',
        // width: '6em',
        // height: '6em',
        margin: 'auto',
        justifyContent: 'center',
        transition: '0s',
        // transition: 'transform 0.0s',
        position: 'absolute',
        left: '50%',
        zIndex: 2,
    },
    // optionContainerLeft: {
    //     transform: 'translate(-140%, -200px)',
    // },
    // optionContainerRight: {
    //     transform: 'translate(40%, -200px)',
    // },
    optionWrapper: {
        width: '3.5em',
        aspectRatio: '1/1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#F4511E',
        borderRadius: '50%',
        fontSize: '1.7em',
        fontWeight: '800',
    },
    options: {
        width: '100%',
        height: '100%',
        fontSize: '0.9em',
        fontWeight: '900',
        borderRadius: '50%',
        textAlign: 'center',
        // background: '#FFB300',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.9rem'
        }
    },
    carContainer: {
        position: 'absolute',
        top: '90%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '3em',
        height: '7em',
        zIndex: 2,
    },
    blinkAnimation: {
        animation: '$carBlinkAnimation 1s ease-in-out 0s 3 normal forwards running',
    },
    '@keyframes rotateAnimationLeft': {
        '0%': {
            transform: 'translate(0, -50%) rotate(45deg)',
            opacity: 0,
        },
        '12.5%': {
            transform: 'translate(-50%, -50%) rotate(-45deg)',
            opacity: 1,
        },
        '25%': {
            transform: 'translate(0, -50%) rotate(0deg)',
            opacity: 1,
        },
        '37.5%': {
            transform: 'translate(50%, -50%) rotate(45deg)',
            opacity: 1,
        },
        '50%': {
            transform: 'translate(50, -50%) rotate(0deg)',
            opacity: 1,
        },
        '62.5%': {
            transform: 'translate(-50%, -50%) rotate(-45deg)',
            opacity: 1,
        },
        '75%': {
            transform: 'translate(0, -50%) rotate(0deg)',
            opacity: 1,
        },
        '87.5%': {
            transform: 'translate(50%, -50%) rotate(45deg)',
            opacity: 1,
        },
        '100%': {
            transform: 'translate(0, -50%) rotate(45deg)',
            opacity: 1,
        },
    },

    '@keyframes rotateAnimationRight': {
        '0%': {
            transform: 'translate(-100%, -50%) rotate(-45deg)',
            opacity: 1,
        },
        '12.5%': {
            transform: 'translate(-50%, -50%) rotate(45deg)',
            opacity: 1,
        },
        '25%': {
            transform: 'translate(-100%, -50%) rotate(0deg)',
            opacity: 1,
        },
        '37.5%': {
            transform: 'translate(-50%, -50%) rotate(-45deg)',
            opacity: 1,
        },
        '50%': {
            transform: 'translate(-100%, -50%) rotate(0deg)',
            opacity: 1,
        },
        '62.5%': {
            transform: 'translate(-50%, -50%) rotate(45deg)',
            opacity: 1,
        },
        '75%': {
            transform: 'translate(-100%, -50%) rotate(0deg)',
            opacity: 1,
        },
        '87.5%': {
            transform: 'translate(-50%, -50%) rotate(-45deg)',
            opacity: 1,
        },
        '100%': {
            transform: 'translate(-100%, -50%) rotate(-45deg)',
            opacity: 1,
        },
    },
    carRotationLeft: {
        animationName: '$rotateAnimationLeft',
        animationDuration: '1.5s',
        animationIterationCount: 'infinite',
        transformOrigin: 'center center',
        position: 'absolute',
    },
    carRotationRight: {
        animationName: '$rotateAnimationRight',
        animationDuration: '1.5s',
        animationIterationCount: 'infinite',
        transformOrigin: 'center center',
        position: 'absolute',
    },
    carImg: {
        width: '100%',
        height: '100%',
        position: 'relative',
        zIndex: '10',
    },
    carNitro: {
        display: 'none',
        width: '116%',
        height: '100%',
        position: 'absolute',
        top: '143%',
        left: '50%',
        padding: 0,
        margin: 0,
        transform: 'translate(-50%, -50%)',
        zIndex: '1',
        animation: '$carBlinkAnimation 1s ease-in-out 0s 3 normal forwards running',
    },
    carSmoke: {
        position: 'absolute',
        // display: 'none',
        width: '100%',
        height: '50%',
        animation: '$carBlinkAnimation 1s ease-in-out 0s 3 normal forwards running',
    },
    carSmoke1: {
        top: '0%',
        left: '75%',
        display: 'none',
    },
    carSmoke2: {
        top: '60%',
        left: '75%',
        display: 'none',
    },
    carSmoke3: {
        top: '75%',
        left: '22%',
        display: 'none',
        transformOrigin: 'center center',
        transform: 'translate(-100%, -50%) rotate(180deg)'
    },
    carSmoke4: {
        top: '15%',
        left: '22%',
        display: 'none',
        transformOrigin: 'center center',
        transform: 'translate(-100%, -50%) rotate(180deg)'
    },
    '@keyframes carBlinkAnimation': {
        '0%, 100%': { opacity: '1' },
        '50%': { opacity: '0' },
    },
    missedContainer: {
        position: 'absolute',
        top: '0%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontWeight: '900',
        fontSize: '2em',
        opacity: '0',
        fontFamily: 'MotionControl'
    },
    optionAnimation: {
        animation: '$optionEncounter 2s ease-in-out 0s 1 normal forwards running',
    },
    "@keyframes optionEncounter": {
        "0%": { top: '79%', opacity: '1' },
        "50%": { top: '76%', opacity: '0.5' },
        "100%": { top: '73%', opacity: '0' }
    },
    answer: {
        // position: 'absolute',
        top: '79%',
        // transform: 'translate(10px, -50%)',
        fontSize: '2em',
        opacity: '0',
        fontWeight: '900',
        height: 'max-content',
        textAlign: 'center',
        fontFamily: 'MotionControl'
    },
    leftSideImg: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden'
    },
    rightSideImg: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden'
    },
    assetImg: {
        display: 'grid',
        alignItems: 'flex-end',
        padding: '15px 15px',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            padding: '15px 15px',
            width: '100%',
        }
    },
    context: {
        textAlign: 'center',
        color: '#FFBB00',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50%',
        padding: '2% 2% 4%',
        transition: 'all 0.2s',
        fontSize: '1em',
        [theme.breakpoints.down("sm")]: {
            fontSize: '1em',
            // fontSize: 'clamp(1.2rem, 1.2vw, 1.5rem)',
        },
    },
    blinkAnimationEffect: {
        animation: '$blinkAnimation 1.5s ease-in-out 0s infinite normal forwards running',
    },
    '@keyframes blinkAnimation': {
        '0%': { opacity: 0 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0 }
    },
    roadSideImg: {
        width: '100%',
        userSelect: 'none',
    },
    imgBox: {
        position: 'absolute',
        bottom: 0,
        left: 0,
    },
    rightRoadSideImg: {
        transform: 'rotate(180deg)'
    },
    answerBox: {
        position: 'absolute',
        height: '25%',
        bottom: '5%',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '50% 50%'
    },
    optionContainerBox: {
        position: 'relative',
        zIndex: 1,
    },
    questionNo: {
        textAlign: 'center',
        fontWeight: 600,
        color: '#F5CF38',
        letterSpacing: '0.2rem'
    },

    // how to play
    howToPlayContainer: {
        position: 'fixed',
        // width: '100%',
        height: `calc(100dvh)`,
        top: 0,
        left: 0,
        paddingTop: GBA_HEADER_HEIGHT,
        zIndex: 9999,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        [theme.breakpoints.up("sm")]: {
            height: `calc(100dvh - ${GBA_HEADER_HEIGHT})`,
            top: GBA_HEADER_HEIGHT,
            paddingTop: 0,
            aspectRatio: '9/16',
            left: '50%',
            transform: 'translateX(-50%)',
        },
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        }
    },
    skipBtnContainer: {
        position: 'fixed',
        top: '75%',
        left: '75%'
    },
    skipBtn: {
        border: '2px solid #fff !important',
        borderRight: 'none !important',
        borderRadius: '2rem 0 0 2rem !important',
        fontWeight: '700 !important',
        fontSize: '1.2rem !important',
        fontFamily: 'Open sans !important',
        paddingLeft: '1.6rem !important',
        backgroundColor: `#fff !important`
    },
}));

export default useStyle;