import { commonActionTypes } from "./common.types";

export const setIsMobile = (data) => ({
    type: commonActionTypes.SET_IS_MOBILE,
    payload: data
});

export const setIsTablet = (data) => ({
    type: commonActionTypes.SET_IS_TABLET,
    payload: data
});

export const openToast = (data) => ({
    type: commonActionTypes.OPEN_TOAST,
    payload: data
});

export const openDialogue = (data) => ({
    type: commonActionTypes.OPEN_DIALOGUE,
    payload: data
});

export const toggleSideDrawer = (data) => ({
    type: commonActionTypes.TOGGLE_SIDE_DRAWER,
    payload: data
});

export const setIsNativeApp = (data) => ({
    type: commonActionTypes.SET_IS_NATIVE_APP,
    payload: data
});

export const setHeaderSearchText = (data) => ({
    type: commonActionTypes.SET_HEADER_SEARCH_TEXT,
    payload: data
});

export const setViewMicroskillByCategory = (data) => ({
    type: commonActionTypes.VIEW_MICROSKILL_BY_CATEGORY,
    payload: data
});

export const setBrowserTabFocus = (data) => ({
    type: commonActionTypes.SET_BROWSER_TAB_FOCUS,
    payload: data
});

export const setRotateMobile = (data) => ({
    type: commonActionTypes.ROTATE_MOBILE,
    payload: data
});

export const setIsDocViewing = (data) => ({
    type: commonActionTypes.IS_DOC_VIEWING,
    payload: data
});

export const setSelectedLanguage = (data) => ({
    type: commonActionTypes.SET_SELECTED_LANGUAGE,
    payload: data
});

export const setMicroskillCategories = (data) => ({
    type: commonActionTypes.SET_MICROSKILL_CATEGORIES,
    payload: data
});

export const setSelectedMicroskillCategories = (data) => ({
    type: commonActionTypes.SET_SELECTED_MICROSKILL_CATEGORIES,
    payload: data
});

export const setLaOnboardingScreen = (data) => ({
    type: commonActionTypes.VIEW_LA_ONBOARDING_SCREEN,
    payload: data
});

export const setLearnerJourneyEnded = (data) => ({
    type: commonActionTypes.SET_LEARNER_JOURNEY_ENDED,
    payload: data
});


