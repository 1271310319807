import { makeStyles } from "@mui/styles";
import commonUtil from "../../../utils/commonUtil";
// import palette from "../../theme/palette";
import { GAME_ASSETS } from "./index";
import { randomAsset } from "./index";

// const COLORS = constants.COLORS;
const isHDScreen = commonUtil.getScreenResolution() === 'FHD';
const screen = commonUtil.getScreenResolution();
const zoom = commonUtil.browserZoom();

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    tikiMonkeyRoot: {
        height: '100%',
    },
    //game play
    gameContainer: {
        display: "grid",
        height: "100%",
        overflow: 'hidden',
        touchAction: 'none',
        opacity: '1',
        animation: '$animateGameLandingPage 2s ease 0s 1 normal forwards running, $animateOverlay 3s ease 1s 1 normal forwards running',
        backgroundImage: `linear-gradient(#00000000, #00000000), ${GAME_ASSETS[randomAsset].gameBgImage}`,
        backgroundRepeat: 'repeat-x',
        backgroundSize: 'cover',
        transition: '1s',
        gridTemplateRows: !isHDScreen ? "1.5% 20% 78.5%" : "1.5% 23% 75.5%",
        // animation: '$animateOverlay 1s ease 0s 1 normal forwards running'
    },
    "@keyframes animateGameLandingPage": {
        "0%": { opacity: '0' },
        "100%": { opacity: '1' }
    },
    "@keyframes animateOverlay": {
        "to": { backgroundImage: `linear-gradient(#0000007a, #00000069), ${GAME_ASSETS[randomAsset].gameBgImage}` },
    },
    progressBarContainer: {
        position: "relative",
        height: "9px",
        zIndex: "2",
        width: (isMobile) => {
            return `calc(100% - ${isMobile ? '0px' : '0px'})`
        },
        borderRadius: (isMobile) => {
            return `${isMobile ? '0px' : '0px'}`
        },
        margin: (isMobile) => {
            return isMobile ? '' : "0em auto"
        },
        overflow: 'hidden',
        backgroundColor: theme.palette.white,
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.progressBarBg,
        },
    },
    progressBar: {
        position: "absolute",
        top: "0",
        left: "0",
        height: "9px",
        backgroundColor: "#ffd042",
        width: '0',
        transition: '0.4s',
    },
    // questionBox container
    qstnContainer: {
        height: "100%",
        width: "100%",
        transform: "translateY(-200px)",
        animation: "$translateQstnContainer 1s ease 0s 1 normal forwards running",
        zIndex: '2',
        position: 'relative',
        opacity: '0.8',
        // padding: !isHDScreen ? "0.5rem 0.5rem 0 0.5rem" : "0.5rem 0.5rem 0 0.5rem",
    },
    "@keyframes translateQstnContainer": {
        "0%": { transform: "translateY(-200px)" },
        "100%": { transform: "translateY(0)" }
    },
    qstnBox: {
        display: 'grid',
        gridTemplateRows: '35% 65%',
        backgroundColor: "#000000",
        // borderRadius: "10px",
        // height: "100%",
        fontSize: "1.2em",
        // boxShadow: "0px 10px 20px #0000004D",
        height: '90%'
    },
    qstnBoxBoxShadow: {
        animation: '$wrongAnsAnimation 1.5s ease 0s 1 normal forwards running'
    },
    "@keyframes wrongAnsAnimation": {
        "0%": { boxShadow: "0px 10px 20px #0000004D" },
        "100%": { boxShadow: "0px 0px 20px -1px #F63B34E8" },
        // "100%": { boxShadow: "0px 10px 20px #0000004D"}
    },
    assetBox: {
        display: "grid",
        // gridTemplateColumns: "1fr 1fr",
        gridTemplateColumns: "1fr 1fr 1fr",
        padding: "12px",
        alignItems: 'center'
    },
    lifeText: {
        display: "flex",
        alignItems: "center",
    },
    timerText: {
        display: "flex",
        alignItems: "center",
        // padding: "0 15px",
        width: '100%',
        justifyContent: "end",
    },
    time: {
        fontWeight: '600',
        fontSize: '1em',
        width: '25%',
        textAlign: 'center'
    },
    icon: {
        width: "1em",
        height: "1em",
        margin: "0 5px"
    },
    life: {
        fontWeight: '600',
        fontSize: '1em',
    },
    timerIcon: {
        width: "1em",
        height: "1em",
        margin: "0 5px",
    },
    qstn: {
        textAlign: "center",
        transition: '.5s',
        fontFamily: 'Open Sans',
        fontSize: '1em',
        padding: '0rem 0.9rem',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '0.9rem ',
        },
    },
    gamePlayWrapper: {
        position: 'relative',
    },
    gameplayContainer: {
        height: '100%',
        display: 'grid',
        gridAutoRows: '24% 24% 24% 24%',
        alignItems: 'flex-end',
        // justifyContent: 'center',
        '& .tikiMonkeyOptionSetBox:nth-child(even)': {
            '& .tikiMonkeyMovingAssetBox': {
                transform: 'rotateY(180deg)'
            }
        }
    },
    optionSetBox: {
        position: 'relative',
        '&:nth-child(odd)': {
            '& .tikiMonkeyPathtBox': {
                transform: 'translateX(-100%)'
            }
        },
        '&:nth-child(even)': {
            '& .tikiMonkeyPathtBox': {
                transform: 'translateX(100%)'
            }
        }
    },
    pathBoxAnimation1: {
        animation: '$pathBoxAnimation1 1.5s ease 1s 1 normal forwards running'
    },
    '@keyframes pathBoxAnimation1': {
        '0%': {
            transform: 'translateX(100%)',
        },
        '100%': {
            transform: 'translateX(0%)'
        },
    },
    pathBoxAnimation2: {
        animation: '$pathBoxAnimation2 1.5s ease 1s 1 normal forwards running'
    },
    '@keyframes pathBoxAnimation2': {
        '0%': {
            transform: 'translateX(-100%)'
        },
        '100%': {
            transform: 'translateX(0%)'
        },
    },
    movingAssetBox: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        padding: '0',
        opacity: '0',
        transition: 'all 1s 3s',
        marginBottom: '-1rem',
        zIndex: 1,
        position: 'relative',
    },
    movingAssetBoxAnimation: {
        animation: '$movingAssetBoxAnimation 1s ease 2.5s 1 normal forwards running'
    },
    '@keyframes movingAssetBoxAnimation': {
        '0%': {
            opacity: '0'
        },
        '100%': {
            opacity: '1'
        },
    },
    monkeyImg: {
        width: '100px'
    },
    bananaImgWrapper: {
        position: 'absolute',
        right: '0',
    },
    bananaImg: {
        width: '25px'
    },
    pathBox: {
        position: 'relative',
        // height: '4em',
        height: '4.5em',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer'
    },
    option: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        width: '85%',
        fontSize: '1.2em',
        fontFamily: 'Roboto, sans-serif',
    },
    monkeyAssetBox: {
        transform: 'translateX(0px)',
    },
    incorrectGradientOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        backgroundImage: 'linear-gradient(#800e315e, #f63b348c)',
        opacity: '0',
        transition: '.5s ease',
    },
    incorrectGradientOverlayAnimation: {
        animation: '$incorrectGradientOverlayAnimation 2s ease-in-out 0.1s 1 normal forwards running'
    },
    '@keyframes incorrectGradientOverlayAnimation': {
        '0%': { opacity: '0' },
        '10%': { opacity: '1' },
        '80%': { opacity: '1' },
        '100%': { opacity: '0' },
    },
    incorrectGradientOverlayAnimationInfinite: {
        animation: '$incorrectGradientOverlayAnimationInfinite 1.2s ease-in-out 0s infinite normal forwards running'
    },
    '@keyframes incorrectGradientOverlayAnimationInfinite': {
        from: { opacity: '1' },
        to: { opacity: '0' }
    },
    answerLottieBox: {
        position: 'absolute',
        left: '50%',
        top: 0,
        bottom: 0,
        position: 'absolute',
        width: '50%',
        left: '50%',
        height: '50%',
        transform: 'translateX(-50%)',
        zIndex: '2'
        // '&:nth-child(even)': {
        //     transform: 'translateX(-50%) rotateY(180deg)',
        // }
    },
    questionNo: {
        textAlign: 'center',
        fontWeight: 600,
        color: '#F5CF38',
        letterSpacing: '0.2rem'
    },
    muteSwitch: {
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: 2,
        cursor: 'pointer',
        padding: '0.4rem'
    },
    timeoutLottieBox: {
        fontFamily: 'MotionControl !important',
        position: 'absolute',
        // fontSize: '2.5rem',
        textAlign: 'center',
        width: '100%',
        zIndex: 2,
        transition: '0.5s',
        opacity: '0',
        width: '90%',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '0rem',
        display: 'flex',
        justifyContent: 'center',
    },
}))

export default useStyles