import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie-player';

// mui
import CircularProgress from '@mui/material/CircularProgress';

// components
import StartScreen from '../common-screens/start-screen';
import GbaWinScreen from '../common-screens/win-screen';
import GbaGameOverScreen from '../common-screens/game-over-screen';
import RevealThePictureHTP from "../how-to-play/reveal-the-picture";

// redux
import { startChallengeAttempt } from '../../../redux/microskill/microskill.api';
import { submitChallengeAttemptData, submitEndChallengeAttempt } from '../../../redux/gba/gba.api';
import { handleGbaGameState } from '../../../redux/gba/gba.actions';

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from '../../../utils/commonUtil';

//constants
import constants from '../../../constants';

//  styles
import useStyles from "./index.styles"

import HOW_TO_PLAY_LOTTIE from "../../../lottie-assets/how-to-play-reveal-the-picture.json"

let questionsPerContext = 4;
const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;

const RevealPicture = (props) => {
    const dispatch = useDispatch();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const { gameData, handleGbaState, defaultGradient, isPauseOverlay, setAddPause } = props;
    const { microskillId, challengeId } = useParams();
    const classes = useStyles(isMobile);

    const optionsData = gameData.questions;
    const contextData = gameData.contexts;

    let maxLifeGba = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxLife;
    let maxQuestion = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxQuestion;
    let maxLife = commonUtil.getMaxLifeForGba({
        gbaType: gameData.designType,
        questionsInGba: optionsData.length * questionsPerContext,
        maxQuestion: maxQuestion,
        maxLife: maxLifeGba,
    });

    let mxmScore = 120;
    let socrePerQuestion = mxmScore / (optionsData.length * questionsPerContext);
    let maxTime = 120;
    let tempTimer = maxTime;
    let containerId = 'Reveal-Picture-Container';

    const bgGradientDefault = defaultGradient;
    const [attemptData, setAttemptData] = useState(null);
    const [isGamePage, setIsGamePage] = useState(false);
    const [componentKey, setComponentKey] = useState(new Date().getTime());
    const [isHowToPlayScreen, setIsHowToPlayScreen] = useState(false);
    const [isGameComplete, setIsGameComplete] = useState(false);
    const [gameEndType, setGameEndType] = useState();
    const [totalScore, setTotalScore] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [optionAnswers, setOptionAnswers] = useState();
    const [correctAnsPerQues, setCorrectAnsPerQues] = useState(0);
    const [lifeRemaining, setLifeRemaining] = useState(maxLife);
    const [correctAns, setCorrectAns] = useState(0);
    const [context, setContext] = useState([]);
    const [isGuideLottiePlaying, setIsGuideLottiePlaying] = useState(true);
    const [incorrectData, setIncorrectData] = useState();

    let gradientBg = "linear-gradient(#353146, #F63B34)";
    let inCorrectAnsGradient = 'linear-gradient(rgb(114, 104, 104), rgb(246, 59, 52))';

    const intervalRef = useRef();
    const attemptRef = useRef();
    const finalChallengeDataRef = useRef();
    const previousTimeRef = useRef(maxTime);
    const lifeTextRef = useRef();
    const timerRef = useRef();
    const questionRef = useRef();
    const progressBarRef = useRef();
    const heartRef = useRef();
    const gradientOverlayRef = useRef();
    const qstnBoxRef = useRef();
    const lifeRef = useRef();
    const containerRef = useRef();
    const dropContainerRef = useRef();
    const RevealPictureRef = useRef();
    const totalScoreRef = useRef();
    const optionRef = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()]);
    const timerImgRef = useRef();
    const timeRef = useRef();
    const correctAnswerQuestionRef = useRef(0);
    const questionNoRef = useRef(0);
    const answerClickedRef = useRef(0);

    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const totalGBATimespentIntervalRef = useRef();
    const totalGBATimespentRef = useRef(0);

    const getChallengeDataAsync = async () => {
        const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
        setAttemptData(attemptResp.data.data);
        attemptRef.current = attemptResp.data.data;
    };

    useEffect(() => {
        if (isEmpty(attemptData)) return
        // set initial state
        let tempData = {
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            status: constants.GBA_ILA_STATUS.exited,
        }
        dispatch(handleGbaGameState(tempData));
    }, [attemptData])

    const startTimer = (time) => {
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
        totalGBATimespentIntervalRef.current = setInterval(() => {
            setAddPause(true);
            totalGBATimespentRef.current += 1;
        }, 1000);

        intervalRef.current = setInterval(() => {
            if (timerRef.current) {
                timerRef.current.innerHTML = time--;
            }
            if (time < 10) {
                timeRef.current.style.color = "#FF5757";
                timerImgRef.current.src = "/images/gba/timer-red.svg"
                qstnBoxRef.current.style.boxShadow = '0px 0px 7px 4px #c51414bd';
                gradientOverlayRef.current.style.backgroundImage = `${inCorrectAnsGradient}`
                gradientOverlayRef.current.classList.add(classes.incorrectGradientOverlayAnimationInfinite);
                timeRef.current.classList.add(classes.incorrectGradientOverlayAnimationInfinite);
            }
            if (time === 0) {
                dropContainerRef.current.style.pointerEvents = "none";
            }
            if (time === -1) {
                RevealPictureRef.current.style.backgroundImage = `${inCorrectAnsGradient}`
                clearInterval(totalGBATimespentIntervalRef.current);
                submitEndChallengeAttempt({
                    ...finalChallengeDataRef.current,
                    microskillId: microskillId, challengeId: challengeId,
                    attemptId: attemptRef.current.id, scored: totalScore,
                    completion: false, status: "TIMERLOSE", timespent: totalGBATimespentRef.current
                })
                setTimeout(() => {
                    setIsGameComplete(true);
                    setGameEndType('timeup')
                    handleGbaState(GBA_SCREEN_STATE[5]);
                }, 1000);
                setTimeout(() => {
                    clearInterval(intervalRef.current);
                })
            }
        }, 1000)
    }

    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
        let tempQuestionObj = {
            ...questionObj,
            skillId: skillId,
            contextId: contextId
        }

        let totalTimeSpent = maxTime - parseInt(timerRef.current.innerHTML);
        if (isEmpty(finalChallengeDataRef.current)) {
            finalChallengeDataRef.current = {
                ...req,
                questions: [tempQuestionObj],
                completion: true,
                status: "INPROGRESS",
                timespent: totalTimeSpent,
                scored: totalScore,
                fallbackSave: true,
            }
        } else {
            finalChallengeDataRef.current.questions.push(tempQuestionObj)
            finalChallengeDataRef.current = {
                ...finalChallengeDataRef.current,
                scored: totalScore,
                timespent: totalTimeSpent,
            }
        }
    }

    const handleAttemptedChallenge = (params) => {
        let { qId, aId, isCorrect, t, skillId, contextId } = params;

        let questionObj = {
            questionId: qId,
            skillId: skillId,
            contextId: contextId,
            answers: [{
                answerId: aId,
                correct: isCorrect,
            }],
            timespent: t,
        };

        let req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptData.id,
            questions: [questionObj]
        }

        submitChallengeAttemptData(req);
        handleEndChallengeAttempt(questionObj, req, skillId, contextId);
    }

    useEffect(() => {
        if (isEmpty(attemptData)) getChallengeDataAsync();
    }, []);

    useEffect(() => {
        if (isGamePage) {
            totalScoreRef.current = 0;
            startTimer(tempTimer);
            RevealPictureRef.current.style.backgroundImage = `${bgGradientDefault}`
            handleGbaState(GBA_SCREEN_STATE[2])
        }
    }, [isGamePage]);

    useEffect(() => {
        if (activeIndex < optionsData.length) {
            const tempContext = contextData.filter((temp) => temp.id === optionsData[activeIndex].contextId);
            setContext(tempContext);
            setOptionAnswers(optionsData[activeIndex].answers);
            if (activeIndex > 0) {
                dropContainerRef.current.style.pointerEvents = "auto";
            }
        }
        else {
            clearInterval(totalGBATimespentIntervalRef.current);
            submitEndChallengeAttempt({ ...finalChallengeDataRef.current, scored: Math.ceil(totalScore), completion: true, status: "COMPLETED", timespent: totalGBATimespentRef.current });
            handleGbaState(GBA_SCREEN_STATE[3])
            setIsGameComplete(true);
            setGameEndType('win');
        }
    }, [activeIndex]);

    useEffect(() => {
        if (correctAnsPerQues === 4) {
            setCorrectAns(correctAns + 1);
            clearAll();
            setTimeout(() => {
                setCorrectAnsPerQues(0);
                questionRef.current.style.scale = '1';
                setActiveIndex(activeIndex + 1);
            }, [5000])
        }
        if (correctAnswerQuestionRef.current > 0) {
            var progressBarWidth = ((correctAnswerQuestionRef.current) / (optionsData.length * 4)) * 100;
            progressBarRef.current.style.width = progressBarWidth + '%';
        }
    }, [correctAnsPerQues]);

    useEffect(() => {
        if (lifeRemaining === 1) {
            gradientOverlayRef.current.classList.add(classes.incorrectGradientOverlayAnimationInfinite);
            lifeTextRef.current.style.color = "#FF5757";
            qstnBoxRef.current.style.boxShadow = '0px 0px 7px 4px #c51414bd';
            heartRef.current.src = "/images/icons/heart-red.png";
            lifeTextRef.current.classList.add(classes.incorrectGradientOverlayAnimationInfinite);
        }
        if (lifeRemaining === 0) {
            dropContainerRef.current.style.pointerEvents = "none";
            RevealPictureRef.current.style.backgroundImage = `${inCorrectAnsGradient}`
            setTimeout(async () => {
                clearInterval(totalGBATimespentIntervalRef.current);
                const submitEndChallengeAttemptData = await submitEndChallengeAttempt({ ...finalChallengeDataRef.current, scored: totalScore, completion: false, status: "LIFELOSE", timespent: totalGBATimespentRef.current })
                setIncorrectData(submitEndChallengeAttemptData?.data?.data?.consecutiveIncorrectAttempt);

                handleGbaState(GBA_SCREEN_STATE[4])
                setIsGameComplete(true);
                setGameEndType('loose');
            }, [2000])
        }
        else {
            setTimeout(() => {
                if (isGamePage) {
                    gradientOverlayRef.current.style.opacity = '0'
                }
            }, [500])
        }
    }, [lifeRemaining])

    useEffect(() => {
        if (isGuideLottiePlaying) return
        if (isPauseOverlay) {
            handleBackButtonClick();
        } else {
            if (isGamePage) {
                handleResumeClick();
            }
        }
    }, [isPauseOverlay]);

    const handleLifeLoss = () => {
        setLifeRemaining(lifeRemaining - 1);
    }

    const clearAll = () => {
        dropContainerRef.current.style.pointerEvents = "none"
        setTimeout(() => {
            questionRef.current.style.scale = '0'
            optionRef.current.map((item) => {
                item.style.transition = "0.3s ease-in-out"
                item.style.background = "transparent";
                item.style.color = "transparent"
                item.innerHTML = '';
                item.style.backdropFilter = 'blur(0px)'
                item.style.border = "transparent";
                // dropContainerRef.current.style.pointerEvents = "auto"
            })
        }, [2000])
    }

    const handleCorrectAns = (id, option) => {
        optionRef.current[id].style.pointerEvents = "none";
        const currentTime = parseInt(timerRef.current.innerHTML);
        correctAnswerQuestionRef.current += 1;

        setTimeout(() => {
            optionRef.current[id].style.transition = "0.3s ease-in-out";
            optionRef.current[id].innerHTML = `<img style="width:50%; height:auto; margin-top:0.5rem;" src="/images/gba/reveal-the-picture/correct-ans.png"/>`
            optionRef.current[id].color = "transparent"
            optionRef.current[id].style.background = "green"
        }, [900])

        setTimeout(() => {
            optionRef.current[id].style.transition = "background 0.3s ease-in-out"
            optionRef.current[id].style.background = "transparent"
            optionRef.current[id].innerHTML = `<img style="width:50%; height:auto; margin-top:0.5rem; opacity:0.5;" src="/images/gba/reveal-the-picture/correct-ans.png"/>`
        }, [1500])

        const question = optionsData[activeIndex];
        const t = previousTimeRef.current - currentTime;
        handleAttemptedChallenge({
            qId: question.id,
            aId: option.id,
            isCorrect: option.isCorrect,
            t: t,
            skillId: optionsData[activeIndex].skillId,
            contextId: optionsData[activeIndex].contextId
        });

        setCorrectAnsPerQues(correctAnsPerQues + 1);
        setTotalScore(totalScore + socrePerQuestion)
        totalScoreRef.current += socrePerQuestion;
        dropContainerRef.current.style.pointerEvents = "auto"
        previousTimeRef.current = currentTime;
    }

    const handleIncorrectAns = (id, option) => {
        optionRef.current[id].style.pointerEvents = "none";
        const currentTime = parseInt(timerRef.current.innerHTML);
        gradientOverlayRef.current.style.backgroundImage = `${inCorrectAnsGradient}`;
        gradientOverlayRef.current.style.opacity = '1'
        setTimeout(() => {
            optionRef.current[id].style.transition = "0.2s ease-in-out";
            optionRef.current[id].style.background = "rgb(246, 59, 52, 0.5)"
            optionRef.current[id].color = "transparent"
            optionRef.current[id].style.backdropFilter = "blur(4px)"
            optionRef.current[id].innerHTML = `<img style="width:50%; height:auto; margin-top:0.5rem;" src="/images/gba/reveal-the-picture/wrong.svg"/>`
        }, [500])


        const question = optionsData[activeIndex];
        const t = previousTimeRef.current - currentTime;
        handleAttemptedChallenge({
            qId: question.id,
            aId: option.id,
            isCorrect: option.isCorrect,
            t: t,
            skillId: optionsData[activeIndex].skillId,
            contextId: optionsData[activeIndex].contextId
        });

        // setCorrectAnsPerQues(correctAnsPerQues + 1);
        handleLifeLoss();
        // setTotalScore(totalScore - socrePerQuestion)
        // totalScoreRef.current -= socrePerQuestion;
        setTimeout(() => {
            if (lifeRemaining > 1) {
                dropContainerRef.current.style.pointerEvents = "auto";
            }
        }, 1300);
        previousTimeRef.current = currentTime;
    }

    const handleOptionClick = (event, optionid) => {
        const id = event.target.id;
        dropContainerRef.current.style.pointerEvents = "none"
        optionRef.current[id].classList.remove(`${classes.optionUnreveal}`);
        optionRef.current[id].style.transition = "background 0.3s ease-in-out";
        optionRef.current[id].style.background = "#F5D03C"

        const temp = optionAnswers.filter((option) => (option.id === optionid));

        if (temp[0].isCorrect) {
            handleCorrectAns(id, temp[0]);
            answerClickedRef.current = answerClickedRef.current + 1;
            // questionNoRef.current.innerHTML = answerClickedRef.current;
        }
        else {
            answerClickedRef.current = answerClickedRef.current + 1;
            // questionNoRef.current.innerHTML = answerClickedRef.current;
            handleIncorrectAns(id, temp[0]);
        }
    }

    const restartGame = () => {
        clearInterval(intervalRef.current);
        setIsGamePage(true);
        setIsGameComplete(false);
        setActiveIndex(0);
        setLifeRemaining(maxLife);
        setIsHowToPlayScreen(false);
        setGameEndType('');
        setCorrectAnsPerQues(0);
        setTotalScore(0);
        startTimer(tempTimer);
        getChallengeDataAsync();
        totalScoreRef.current = 0;
        correctAnswerQuestionRef.current = 0;
        previousTimeRef.current = maxTime; // to avoid negative time sending in payload
        finalChallengeDataRef.current = '';
        handleGbaState(GBA_SCREEN_STATE[2])
        RevealPictureRef.current.style.backgroundImage = `${bgGradientDefault}`;
        answerClickedRef.current = 0;
    }

    //  Pause Screen
    const handleBackButtonClick = () => {
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
        dispatch(handleGbaGameState({
            ...finalChallengeDataRef.current,
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptRef.current.id,
            scored: Math.ceil(totalScoreRef.current),
            timespent: totalGBATimespentRef.current,
        }))
    }

    const handleResumeClick = () => {
        setAddPause(true);
        startTimer(parseInt(timerRef.current.innerHTML))
    }

    const [fSize, setFSize] = useState(1);
    useEffect(() => {
        setFSize(window.innerHeight / 961);
    }, [])
    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }
    window.addEventListener("resize", resFont);

    const handleGuideLottieComplete = async () => {
        await sleep(1000 * 1);
        console.log('complete');
        setIsGuideLottiePlaying(false);
        // startGame();
    }

    return (
        <div style={{ backgroundImage: !isGamePage ? bgGradientDefault : bgGradientDefault, fontSize: `${fSize}rem` }} className={classes.RevealPicture} key={componentKey} ref={RevealPictureRef}>
            {
                isEmpty(attemptData) ? (
                    <div style={constants.FULL_HEIGHT_CENTER}>
                        <CircularProgress />
                    </div>
                ) : (
                    !isGamePage ? (
                        !isHowToPlayScreen ? (
                            <StartScreen setIsGamePage={setIsGamePage}
                                setIsHowToPlayScreen={setIsHowToPlayScreen}
                                bg={"/images/gba/reveal-the-picture/reveal_picture_startscreen.svg"}
                                logoWidth={"85%"}
                                gameLogo={"/images/gba/reveal-the-picture/reveal-the-picture-logo.svg"}
                                handleGbaState={handleGbaState}
                            />
                        ) : <RevealThePictureHTP width={"100%"} height={"100%"} title={"Reveal the Picture"} setIsGamePage={setIsGamePage} />
                    ) : (
                        !isGameComplete ? (

                            isGuideLottiePlaying ? <div className={classes.howToPlayContainer}>
                                <Lottie
                                    animationData={HOW_TO_PLAY_LOTTIE}
                                    style={{ width: '100%' }}
                                    // ref={correctLottieRefsArray.current[i]}
                                    loop={false}
                                    play={true}
                                    onComplete={handleGuideLottieComplete}
                                />
                            </div>
                                :
                                <>
                                    <div className={classes.gradientOverlay} ref={gradientOverlayRef} />
                                    <div className={classes.container}>
                                        <div className={classes.containerBox} style={{ background: { bgGradientDefault } }}>
                                            <div className={classes.progressBarContainer}>
                                                <div className={classes.progressBar} ref={progressBarRef} />
                                            </div>

                                            {/* QUESTION BOX */}
                                            <div className={classes.qstnContainer}>
                                                <div className={classes.qstnBox} ref={qstnBoxRef}>
                                                    <div className={classes.assetBox}>
                                                        <div className={classes.lifeText} ref={lifeTextRef}>
                                                            <div className={classes.life} ref={lifeRef}><b style={{ fontSize: '1em', fontWeight: '600' }}>{lifeRemaining} x</b></div>
                                                            <img src='/images/icons/heart.png' className={classes.icon} ref={heartRef} alt="heart" />
                                                        </div>

                                                        <div className={classes.questionNo}>Q<span ref={questionNoRef}>{activeIndex + 1}</span>/{gameData?.questions?.length}</div>

                                                        <div className={classes.timerText} ref={timeRef}>
                                                            <img src='/images/icons/stopwatch.png' className={classes.timerIcon} alt="timer" ref={timerImgRef} />
                                                            <div style={{ width: '16%' }}><b><span ref={timerRef}>{tempTimer}</span>s</b></div>
                                                        </div>
                                                    </div>
                                                    <div className={classes.qstn} ref={questionRef}>

                                                        {/* ADD CONTEXT */}
                                                        {/* {activeIndex < optionData.length ?
                                                            (optionData[activeIndex].context) : null} */}
                                                        {/* <br></br> */}

                                                        {activeIndex < optionsData.length && optionsData[activeIndex].question}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={classes.gamePlayBox} ref={containerRef} id={containerId}>
                                                <div className={classes.dropContainer} ref={dropContainerRef} style={{ backgroundImage: `url(${context.length > 0 ? context[0].context : null})`, borderRadius: "7px" }}>
                                                    {/* Option Container */}
                                                    {activeIndex < optionsData.length && optionsData[activeIndex].answers.map((option, i) => (
                                                        <div key={`${option.id}`} id={i}
                                                            ref={(el) => optionRef.current[i] = el} className={`${classes.optionContainer} ${classes.optionUnreveal}`}
                                                            onClick={(event) => handleOptionClick(event, option.id)}>
                                                            {option.answer}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        ) : (
                            <>
                                {
                                    gameEndType === 'win' && (
                                        <GbaWinScreen
                                            maxScore={mxmScore}
                                            bg={"/images/gba/building_blocks.svg"}
                                            obtainedScore={Math.ceil(totalScore)} />
                                    )
                                }
                                {
                                    gameEndType === 'loose' && (
                                        <GbaGameOverScreen
                                            type={"gameover"}
                                            gameType={gameData?.gameType}
                                            gameData={gameData}
                                            incorrectAttempt={incorrectData}
                                            bg={"/images/gba/building_blocks.svg"}
                                            bgGradient={gradientBg}
                                            restartGame={restartGame} />
                                    )
                                }
                                {
                                    gameEndType === 'timeup' && (
                                        <GbaGameOverScreen
                                            type={"time"}
                                            bg={"/images/gba/building_blocks.svg"}
                                            bgGradient={defaultGradient}
                                            restartGame={restartGame} />
                                    )
                                }
                            </>
                        )
                    )
                )
            }
        </div>
    )
}

export default RevealPicture;