import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import webViewJavaScriptBridge from 'webview-javascript-bridge';

//mui
import { Grid, Box, CircularProgress, Button } from "@mui/material";
import { styled } from "@mui/styles";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

//utils
import isEmpty from '../../utils/isEmpty';
import commonUtil from '../../utils/commonUtil';

//components
import LearningReports from './components/learning-reports';
import SkillAnalysis from './components/skill-analysis';
import HeaderBackButton from '../layout-elements/header-back-button';
// import FeaturedSpotlight from './components/featured-spotlight';

//services
import { getLearningReport, getSkillAnalysis, getMicroskillList, getScenarioList, getCompletionAndScore } from '../../redux/manager-dashboard/manager-dashboard.api'

// styles
import useStyles from "./index.styles";

//multilingual
import { useTranslation } from "react-i18next";

//constants
import constants from '../../constants';

//theme
import theme from '../../theme';
import MicroskillFilter from './components/microskill-filter';


const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.darkVoilet,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.fontColor,
    borderRadius: '20px',
    [theme.breakpoints.up('md')]: {
        // height: (leaderboardList.length == 10 && leaderboardRef.current ? `${~~((leaderboardRef.current.clientHeight-16)/2)}px` : '355px')
        height: '100%',
        // marginBottom: theme.spacing(2)
        padding: theme.spacing(4)
    }
}));

const ItemStyle = (theme) => ({
    backgroundColor: theme.palette.darkVoilet,
    borderRadius: '10px',
    padding: 0,
    [theme.breakpoints.down('md')]: {
        backgroundColor: theme.palette.mobilePerfPageCardsBg
    }

})

const defaultCompletionAndScore = {
    my_completion_rate: 0,
    team_completion_rate: 0,
    company_completion_rate: 0,
    my_percentage: 0,
    team_percentage: 0,
    company_percentage: 0
}

const HIGH_LOW_TOGGLE_OPTIONS = {
    HIGH: 'HIGH',
    LOW: 'LOW'
}

const msDefaultPagination = {
    "count": 0,
    "pageCount": 0,
    "startIndex": 0,
    "limit": 0
}


const ManagerDashboard = ({ userType, isOnlyManager }) => {
    const [loadingLR, setLoadingLR] = useState(false);
    const [loadingSa, setLoadingSa] = useState(false);
    const [loadingScore, setLoadingScore] = useState(false);
    const { t, i18n } = useTranslation()
    const classes = useStyles(i18n);
    const firstRender = useRef(true);
    let [searchParams, setSearchParams] = useSearchParams();
    const isDarkMode = useSelector(state => state?.commonPersist?.isDarkMode);
    const [highOrLow, setHighOrLow] = useState(HIGH_LOW_TOGGLE_OPTIONS.LOW);
    const [completionAndScore, setCompletionAndScore] = useState(defaultCompletionAndScore);
    const [microskills, setMicroskills] = useState([]);
    const [msPagination, setMsPagination] = useState(msDefaultPagination);
    const [limit, setLimit] = useState(50);
    const [bottomLoader, setBottomLoader] = useState(false);
    const [selectedMicroskills, setSelectedMicroskills] = useState([]);
    // const microskillsObject = commonUtil.objectify(microskills, 'id')
    // const [scenarios, setScenarios] = useState([]);
    const [learningReportList, setLearningReportList] = useState([]);
    const [skillData, setSkillData] = useState([]);
    const user = useSelector(state => state?.auth?.user?.user);
    const userProfileData = useSelector(state => state?.auth?.userProfileData);
    const defaultManager = user?.username;
    const defaultFullName = userProfileData?.fullname ?? user?.fullname;
    let manager = searchParams.get('manager') ?? defaultManager;
    let fullName = searchParams.get('fullName') ?? defaultFullName;
    let reportingManager = searchParams.get('reportingManager');

    let isNative = localStorage.getItem("isNative");

    useEffect(() => {
        fetchCompletionAndScore();
        fetchLearningReport();
        // fetchSkillAnalysis();

    }, [highOrLow, manager, selectedMicroskills])

    useEffect(() => {
        fetchMicroskills()
    }, [limit])


    const fetchCompletionAndScore = async () => {
        const payload = {
            managerName: manager,
            microskillId: selectedMicroskills
        }
        setLoadingScore(true);
        const response = await getCompletionAndScore(payload);
        setLoadingScore(false);
        if (response && response.status === 200) {
            const temp = {
                my_completion_rate: 0,
                team_completion_rate: 0,
                company_completion_rate: 0,
                my_percentage: 0,
                team_percentage: 0,
                company_percentage: 0
            }
            response.data.data.forEach(ele => {
                temp[ele.type] = ele?.value ? parseInt(ele.value) : 0
            })

            setCompletionAndScore(temp)
        }
    }

    const fetchMicroskills = async () => {
        const payload = {
            managerUsername: manager,
            searchText: "",
            limit,
            startIndex: 0
        }
        setBottomLoader(true)
        const response = await getMicroskillList(payload);
        setBottomLoader(false)
        if (response && response.status === 200) {
            // console.log('ms count from api = ',response.data.data.length);
            setMicroskills(response.data.data);
            setMsPagination(response.data.pagination);
        }
    }

    const fetchScenarios = async () => {
        // const response = await getScenarioList();
        // if (response && response.status === 200) {
        //     const data = response.data.data.map(element => ({
        //         id: element.challengeId,
        //         name: element.scenario,
        //         ...element
        //     }))
        //     setScenarios(data);
        // }
    }

    const getToggleBtnStyle = (name) => {
        return {
            color: '#ffffff',
            backgroundColor: highOrLow === HIGH_LOW_TOGGLE_OPTIONS[name] ? theme.palette.orange : 'transparent',
            border: highOrLow === HIGH_LOW_TOGGLE_OPTIONS[name] ? `2px solid ${theme.palette.orange}` : '2px solid transparent',
            borderRadius: '20px',//'5px 0px 0px 5px',
            fontWeight: 600,
            fontSize: '0.8rem',
            textTransform: 'math-auto',
            padding: '0 1rem',
            transition: 'all 0.3s ease-in-out 0s'
        }
    }


    const fetchLearningReport = async () => {
        const payload = {
            reportingManager,
            managerName: manager,
            microskillId: selectedMicroskills,
            limit: 100,
            startIndex: 0,
            sort: highOrLow === HIGH_LOW_TOGGLE_OPTIONS.LOW ? "ASC" : "DESC"

        }
        setLoadingLR(true);
        const response = await getLearningReport(payload);
        setLoadingLR(false);
        if (response.status === 200) {
            const tempLearningReport = [];
            response.data.data.forEach(ele => {

                tempLearningReport.push({
                    manager: ele.manager,
                    fullName: ele.fullName ?? '',
                    averageCompletion: isEmpty(ele.average_completion) ? 'N/A' : `${parseInt(ele.average_completion)}%`,
                    averageEffectiveness: isEmpty(ele.average_effectiveness) ? 'N/A' : `${parseInt(ele.average_effectiveness)}%`,
                    averageTimeSpent: isEmpty(ele.average_timespent) ? 'N/A' : commonUtil.fancyTimeFormat(ele.average_timespent),
                    averageSpotlightDuration: isEmpty(ele.timespent) ? null : commonUtil.fancyTimeFormat(ele.timespent),
                    averageKeywordMatch: isEmpty(ele.avg_keywordScore) ? null : `${parseInt(ele.avg_keywordScore)}%`,
                    averageTranscriptMatch: isEmpty(ele.avg_transcriptMatchScore) ? null : `${parseInt(ele.avg_transcriptMatchScore)}%`,
                    designation: ele.designation ?? '-',
                })

            })
            setLearningReportList(tempLearningReport);

        }
    }

    const fetchSkillAnalysis = async () => {
        const payload = {
            reportingManager,
            managerName: manager,
            microskillId: selectedMicroskills,
            limit: 100,
            startIndex: 0,
            sort: highOrLow === HIGH_LOW_TOGGLE_OPTIONS.LOW ? "ASC" : "DESC"

        }
        setLoadingSa(true);
        const response = await getSkillAnalysis(payload);
        setLoadingSa(false);
        if (response.status === 200) {
            const tempSkillData = [];
            response.data.data.forEach(ele => {
                tempSkillData.push({
                    id: ele.userId,
                    username: ele.name,
                    strong: ele.strongest_skill,
                    weak: (ele.strongest_skill === ele.weakest_skill) ? 'N/A' : ele.weakest_skill,
                })

            })
            setSkillData(tempSkillData);

        }
    }

    const handleManagerClick = (item, row) => {
        if (item.key !== 'fullName') return
        if (row?.designation === 'end') return
        const newParams = {
            manager: row["manager"],
            fullName: row["fullName"] ?? '',
            reportingManager: manager,
        }
        updateSearchParams(newParams);
    }

    const updateSearchParams = (object = {}) => {
        // Create a new set of search params
        const newParams = new URLSearchParams(searchParams);
        // Set new search params
        for (let key in object) newParams.set(key, object[key]);
        setSearchParams(newParams);
    };

    const loaderStyle = { height: '20px !important', width: '20px !important' }

    useEffect(() => {
        // callback function to call when event triggers
        const onPageLoad = () => {
          console.log('Manager Performance Page loaded');
          if (isNative) {
            webViewJavaScriptBridge.sendMessage({ action: 'TurnOffNativeSplashScreenChannel', params: true })
          }
          // do something else
        };
    
        // Check if the page has already loaded
        if (document.readyState === 'complete') {
          onPageLoad();
        } else {
          window.addEventListener('load', onPageLoad, false);
          // Remove the event listener when component unmounts
          return () => window.removeEventListener('load', onPageLoad);
        }
      }, []);

    return (
        <>
            {searchParams.get('manager') ? <Header title={fullName ?? searchParams.get('manager')} isDarkMode={isDarkMode} /> : null}

            <div className={classes.btnWrapper} style={{ paddingTop: searchParams.get('manager') ? '1rem' : 0 }}>
                <MicroskillFilter
                    microskills={microskills}
                    selectedMicroskills={selectedMicroskills}
                    setSelectedMicroskills={setSelectedMicroskills}
                    handleResetFilter={() => { }}
                    isDarkMode={isDarkMode}
                    manager={manager}
                    t={t}
                    msPagination={msPagination}
                    setLimit={setLimit}
                    bottomLoader={bottomLoader}
                />

                {/* <Button
                        variant="contained"
                        // color={"#fff"}
                        size="small"
                        className={`${classes.btn}`}
                    // onClick={handleTryBtnClick}
                    >
                        {t("Send Nudge")}
                    </Button> */}

            </div>
            <Grid container justifyContent='center'
                sx={{
                    marginTop: '1rem',
                    // paddingBottom: '1rem',
                    rowGap: "1.2rem",
                    columnGap: "1rem",
                    "& .MuiGrid-root": {
                        justifyContent: "center",
                        alignItems: 'center',
                        flexBasis: '44%',
                        maxWidth: '44%',
                    },
                    '@media (min-width: 900px)': {
                        width: '50%',
                        marginTop: '0',
                        marginLeft: '0',
                        rowGap: "3rem",
                        columnGap: "3rem",
                        height: '100%',//85%
                        // paddingLeft: (theme) => theme.spacing(2)
                    }
                }} >
                <Grid item xs={5} md={5}>
                    <Item sx={ItemStyle}>
                        <div className={classes.cardSpacing}>
                            <p className={classes.cardHeading}>{t("Completion")} </p>
                            {
                                !isOnlyManager ?
                                    <>
                                        {loadingScore ? <CircularProgress sx={loaderStyle} /> : <p className={classes.cardValue} style={{ color: '#f4511e' }}>{completionAndScore.my_completion_rate}%</p>}
                                        <p className={classes.cardSubHeading}>{t("Your Completion")} </p>
                                    </> : null
                            }
                            {/* <p className={classes.cardBar} style={{ backgroundColor: `${scoreColor} ` }}></p> */}
                            {loadingScore ? <CircularProgress sx={loaderStyle} /> : <p className={classes.cardValue}>{completionAndScore.team_completion_rate}%</p>}
                            <p className={classes.cardSubHeading}>{t("Team Average")}</p>
                            {loadingScore ? <CircularProgress sx={loaderStyle} /> : <p className={classes.cardValue}>{completionAndScore.company_completion_rate}%</p>}
                            <p className={classes.cardSubHeading}>{t("Company Average")}</p>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={5} md={5}>
                    <Item sx={ItemStyle}>
                        <div className={classes.cardSpacing}>
                            <p className={classes.cardHeading}>{t("Score")} </p>
                            {
                                !isOnlyManager ?
                                    <>
                                        {loadingScore ? <CircularProgress sx={loaderStyle} /> : <p className={classes.cardValue} style={{ color: '#f4511e' }}>{completionAndScore.my_percentage}%</p>}
                                        <p className={classes.cardSubHeading}>{t("Your Effectiveness")} </p>
                                    </> : null
                            }
                            {/* <p className={classes.cardBar} style={{ backgroundColor: `${scoreColor} ` }}></p> */}
                            {loadingScore ? <CircularProgress sx={loaderStyle} /> : <p className={classes.cardValue}>{completionAndScore.team_percentage}%</p>}
                            <p className={classes.cardSubHeading}>{t("Team Average")}</p>
                            {loadingScore ? <CircularProgress sx={loaderStyle} /> : <p className={classes.cardValue}>{completionAndScore.company_percentage}%</p>}
                            <p className={classes.cardSubHeading}>{t("Company Average")}</p>
                        </div>
                    </Item>
                </Grid>
            </Grid>

            {/*Toggle High/Low Performers */}
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '0.8rem 0' }}>
                <ToggleButtonGroup
                    value={highOrLow}
                    exclusive
                    onChange={(e) => {
                        const newValue = e.currentTarget.value;
                        setHighOrLow(newValue);
                    }}
                    sx={{
                        borderRadius: '20px', overflow: 'hidden', height: '2.2rem', gap: '0.3rem',
                        backgroundColor: '#00000080',
                        padding: '0.2rem'
                    }}
                >
                    <ToggleButton value={HIGH_LOW_TOGGLE_OPTIONS.LOW} style={getToggleBtnStyle('LOW')} >Lowest Performers</ToggleButton>
                    <ToggleButton value={HIGH_LOW_TOGGLE_OPTIONS.HIGH} style={getToggleBtnStyle('HIGH')}>Highest Performers</ToggleButton>
                </ToggleButtonGroup>
            </Box>

            {/* Learning report */}
            <LearningReports isDarkMode={isDarkMode} loading={loadingLR} learningReportList={learningReportList} fullName={fullName} handleManagerClick={handleManagerClick} />

            {/* Skill analysis */}
            {false && <SkillAnalysis isDarkMode={isDarkMode} loading={loadingSa} skillData={skillData} />}

            {/* Featured Spotlight */}
            {/* <FeaturedSpotlight isDarkMode={isDarkMode} scenarios={scenarios} /> */}


        </>
    )
}

export default ManagerDashboard

const Header = ({ title = "", isDarkMode }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleBackButtonClick = () => {
        navigate(-1);
    }
    return (
        <AppBar component="nav" className={classes.appBarRoot}>
            <Toolbar className={classes.toolbar}>
                <Box onClick={handleBackButtonClick}>
                    <HeaderBackButton background={isDarkMode ? '#ffffff' : '#f4511e29'} />


                </Box>

                <div className={classes.headerText}>
                    {title}'s Dashboard
                </div>




            </Toolbar>
        </AppBar>
    )
}