
import { makeStyles } from "@mui/styles";
import commonUtil from "../../../../utils/commonUtil";
import palette from "../../../../theme/palette";

const zoom = commonUtil.browserZoom();

const useStyle = makeStyles((theme) => ({

    centerFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
    },
    gbaStartScreenRoot: {
        height: "100%",
        // background: 'linear-gradient(#621ff7, #ed40fc)',
    },
    gbaStartScreenContainer: {
        height: "100%",
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'grid',
        gridTemplateRows: '55% 45%'
    },

    bgImgBox: {
        textAlign: "center",
        // height: "45%",
        position: 'relative',
    },
    mainText: {
        animation: "$zoomInOut 1s ease 0s infinite normal forwards running",
    },
    titleImage: {
        width: "80%"
    },
    btnBox: {
        textAlign: "center",
        // height: "55%"
    },
    btnContainer: {
        width: "90%",
        margin: 'auto'
        // display: 'grid',
        // alignItems: 'center',
        // justifyContent: 'center'
    },
    howToPlay: {
        fontFamily: "MotionControl !important",
        fontSize: "2.5em !important",
        background: "none",
        border: "none",
        padding: "1rem 0.6rem",
        cursor: "pointer",
        borderRadius: "5px",
        color: '#fff',
        margin: '2rem 0',
        [theme.breakpoints.down("sm")]: {
            fontSize: "2.5em !important",
        }
    },
    startBtn: {
        fontFamily: "MotionControl",
        fontSize: "2.5em !important",
        lineHeight: (i18n) => i18n.language === 'en' ? '1.2 !important' : '',
        borderRadius: "5px",
        color: "#000000",
        margin: '2rem 0',
        padding: '0 !important',
    },

    "@keyframes zoomInOut": {
        "0%": { transform: "scale(1)" },
        "50%": { transform: "scale(0.95)" },
        "100%": { transform: "scale(1)" },
    },
    bannerImg: {
        position: 'absolute',
        width: '40%',
        left: '-1%',
        top: '6%'
    },
    comingSoonText: {
        fontSize: '2rem',
        fontWeight: 700,
        marginBottom: '1rem'
    },
    customBannerContainer: {
        width: '40%',
        position: 'absolute',
        top: '5%',
        left: '-5px',
        zIndex: '10',
        borderRadius: '0 6px 6px 0'
    },
    customBannerImg: {
        color: palette.white,
    },
    gameLogoAnimation: {
        animation: "$transitionAnim 1s ease 0s 1 normal forwards running",
    },
    gameSubLogoAnimation: {
        animation: "$scaleUpAnim 1s ease 0s 1 normal forwards running",
        display: 'flex',
        justifyContent: 'center',
    },
    "@keyframes transitionAnim": {
        "0%": {
            transform: "translateY(-100%)",
            opacity: 0
        },
        "100%": {
            transform: "translateY(0%)",
            opacity: 1
        }
    },
    "@keyframes scaleUpAnim": {
        "0%": { transform: "scale(0)", opacity: 0 },
        "100%": { transform: "scale(1)", opacity: 1 },
    },

}));

export default useStyle;
