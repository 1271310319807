import axios from "axios";
import { openToast, openDialogue } from "../redux/common/common.actions";
import isEmpty from "./isEmpty";
import { store } from '../redux/store';
import { logout } from "../redux/auth/auth.api";

const baseConfig = {
    baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
    // mode: 'no-cors',
    // withCredentials: false,
    // credentials: 'same-origin',
}
const axiosInstance = axios.create(baseConfig);
const accessToken = localStorage.getItem("accessToken");
// const refreshToken = store?.getState()?.auth?.tokens?.refreshToken;
// const accessToken = store?.getState()?.auth?.tokens?.accessToken;
// console.log("accessToken:", accessToken)
// console.log("persist:", JSON.parse(localStorage.getItem('persist:root')));

const PUBLIC_ENDPOINT = [
    `${process.env.REACT_APP_API_BASE_URL}/access/logout`
];

const MULTIPART_FORM_DATA = [
    '/spotlight-attempt',
    '/upload-spotlight-video',
    '/upload-microskill-certificate',
    '/upload-media-file'
]

axiosInstance.interceptors.request.use(
    (config) => {
        if (!PUBLIC_ENDPOINT.includes(config.url) && !isEmpty(accessToken)) {
            // config.headers["Authorization"] = accessToken && `Bearer ${accessToken}`;
        }
        config.headers["x-api-key"] = process.env.REACT_APP_X_API_KEY;
        config.headers["x-access-token"] = localStorage.getItem('accessToken');
        config.headers["x-refresh-token"] = localStorage.getItem('refreshToken');

        // config.headers["x-user-id"] = userId;
        // config.headers["x-user-name"] = userName;
        config.headers["access-control-allow-origin"] = "*";
        // config.headers["Content-Type"] = "application/json";
        
        if (MULTIPART_FORM_DATA.includes(config.url)) {
        // if (MULTIPART_FORM_DATA.some(item => config.url.includes(item))) {

            // console.log("config.url:", config.url)
            config.headers["Content-Type"] = "multipart/form-data";
            // config.headers["accept"] = "multipart/form-data";

            return config
        }
        // console.log("else")
        config.headers["Content-Type"] = "application/json";
        return config
    },
    error => {
        Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    response => {
        return response
    },

    function (error) {

        // console.log("error:", error.response);
        if (!navigator.onLine) {
            store.dispatch(openDialogue({
                isDialogueOpen: true,
                dialogueTitle: 'Network Error',
                dialogueContent: 'You seem to be offline, please check your internet and try again.'
            }));
            return error
        }

        if (error.response.status === 500) {
            store.dispatch(openToast({ isToastOpen: true, toastMessage: 'Internal server error!', toastSeverity: 'error' }));
        }
        if (error.response.status === 403) {
            store.dispatch(logout);
        }
        if (error.response.status === 401 || error.response.status === 404) {
            return error.response;
        }
        return Promise.reject(error)
    }
)

export default axiosInstance;