import { eventActionTypes } from "./event.types";

export const LoginPage = (data) => ({
    type: eventActionTypes.LOGIN_PAGE,
    payload: data
})

export const LoginButtonClick = (data) => ({
    type: eventActionTypes.LOGIN_BUTTON_CLICK,
    payload: data
});

export const LoginOtp = (data) => ({
    type: eventActionTypes.LOGIN_OTP,
    payload: data
});

export const SubmitOtp = (data) => ({
    type: eventActionTypes.SUBMIT_OTP,
    payload: data
});

export const ScreenChange = (data) => ({
    type: eventActionTypes.SCREEN_CHANGE,
    payload: data
})

export const LearnPage = (data) => ({
    type: eventActionTypes.LEARN_PAGE,
    payload: data
})

export const LoggedIn = (data) => ({
    type: eventActionTypes.LOGGED_IN,
    payload: data
});

export const MicroskillsLoaded = (data) => ({
    type: eventActionTypes.MICROSKILLS_LOADED,
    payload: data
})

export const DocumentPage = (data) => ({
    type: eventActionTypes.DOCUMENTS_PAGE,
    payload: data
})

export const DocumentsLoaded = (data) => ({
    type: eventActionTypes.DOCUMENTS_LOADED,
    payload: data
})

export const PerformancePage = (data) => ({
    type: eventActionTypes.PERFORMANCE_PAGE,
    payload: data
})
export const SalesEnablementPage = (data) => ({
    type: eventActionTypes.SALES_ENABLEMENT_PAGE,
    payload: data
})