import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    laWinScreenRoot: {
        height: '100%',
        width: '100%',
        userSelect: 'none',
        position: 'relative',
        '@media(max-width: 300px)': {
            fontSize: '12px'
        },
    },
    laWinScreenBox: {
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.ilaCorrect1,
        color: theme.palette.white,
        borderRadius: '0.5rem',
        display: 'grid',
        padding: '2rem',
        justifyContent: 'center',
        textAlign: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        [theme.breakpoints.down("md")]: {
            padding: '1rem',
            borderRadius: '0rem',
        }
    },
    completeTxt: {
        fontWeight: 700,
        letterSpacing: '8px',
        textDecoration: 'underline',
        fontSize: '1.2rem',
        marginTop: '2.5rem',
        [theme.breakpoints.down("md")]: {
            fontSize: '1em',
            fontWeight: 600,
            letterSpacing: '3px',
        }
    },
    congratsText: {
        fontWeight: 900,
        fontSize: '2.6rem',
        [theme.breakpoints.down("md")]: {
            fontSize: '1.8em',
        }
    },
    subText: {
        fontFamily: 'Open Sans',
        fontSize: '1.35rem',
        [theme.breakpoints.down("md")]: {
            fontSize: '0.9em',
        }
    },
    detailsBox: {
        gap: '3rem',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down("md")]: {
            gap: '2rem',
        }
    },
    detailText: {
        fontWeight: 900,
        fontSize: '2.4rem',
        [theme.breakpoints.down("md")]: {
            fontSize: '1.6em',
        }
    },
    iconBox: {
        gap: '1rem'
    },
    actionButton: {
        fontSize: '2rem',
        fontWeight: 900,
        textTransform: 'capitalize !important',
        fontFamily: 'MotionControl',
        [theme.breakpoints.down("md")]: {
            fontSize: '1.6em',
            fontWeight: 700,
        }
    },

    // audioPlayer
    audioPlayerContainer: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 1
    },

    // confetti lottie animation 
    lottieContainer: {
        inset: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',

    },

    ilaWinScreenBtnContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
    },

    ilaInnerBtnContainer: {
        // width: '100%',
        // display: 'grid',
        // gap: '1em',
    }

    // // snow fall animation
    // giftAnimeWrapperOverlay: {
    //     inset: 0,
    //     // filter: 'blur(50px)',
    //     position: 'absolute',
    //     borderRadius: '1rem',
    //     backgroundSize: 'cover',
    //     backgroundPosition: 'center',
    // },

    // // snow fall animation
    // snowContainer: {
    //     width: '100%',
    //     height: 'calc(100% + 25px)',
    //     position: 'absolute',
    //     // top: -25,
    //     inset: 0,
    //     backgroundColor: 'transparent',
    //     overflow: 'hidden',
    //     listStyle: 'none',
    //     "& li": {
    //         opacity: 0,
    //         position: 'absolute',
    //         top: 0,
    //         borderRadius: '100%',
    //         // backgroundColor: '#FFFFFF',
    //         backgroundRepeat: 'no-repeat',
    //         backgroundSize: '100% auto',
    //         webkitAnimationName: '$snowDrop',
    //         animationName: '$snowDrop',
    //         webkitAnimationIterationCount: 3,
    //         animationIterationCount: 3,
    //         webkitAnimationIterationCount: 'linear',
    //         animationTimingFunction: 'linear',
    //         webkitAnimationFillMode: 'forwards',
    //         animationFillMode: 'forwards',
    //     },
    // },

    // "@keyframes snowDrop": {
    //     "0%": {
    //         transform: 'translate(0, 0)',
    //         opacity: 0.5,
    //         marginLeft: 0,
    //     },
    //     "10%": {
    //         marginLeft: '15px',
    //     },
    //     "20%": {
    //         marginLeft: '20px',
    //     },
    //     "30%": {
    //         // transform: 'translate(0, 166.6666666667px)',
    //         opacity: 0.75,
    //         marginLeft: '15px',
    //     },
    //     "40%": {
    //         marginLeft: '0',
    //     },
    //     "50%": {
    //         // transform: 'translate(0, 333.3333333333px)',
    //         opacity: 1,
    //         marginLeft: '-15px',
    //     },
    //     "60%": {
    //         marginLeft: '-20px',
    //     },
    //     "70%": {
    //         marginLeft: '-15px',
    //     },
    //     "80%": {
    //         transform: 'translate(0, 500px)',
    //         opacity: 0.5,
    //     },
    //     "90%": {
    //         marginLeft: '0',
    //     },
    //     "100%": {
    //         transform: 'translate(0, 999px)',
    //         opacity: 0,
    //     }
    // }
}))

export default useStyles