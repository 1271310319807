// import jwtDecode from "jwt-decode";
import { jwtDecode } from "jwt-decode";


// redux
import { setToken, setCurrentUser, setUser } from "./auth.actions";
// utils
import axiosInstance from "../../utils/axios";
import isEmpty from "../../utils/isEmpty";

// constants
import constants from "../../constants";

import { store } from '../../redux/store';
import { isNativeApp } from "../../utils/commonUtil";
import { setSplashScreenViewFlag } from "../common-persist/common-persist.actions";
import { handleRecentlyLearned } from "../microskill/microskill.actions";

var bridge = require("webview-javascript-bridge-promised");

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const setTokenState = (data) => {
    store.dispatch(setCurrentUser({ companyId: jwtDecode(data?.tokens?.accessToken).companyId }));
    store.dispatch(setToken(data?.tokens));
    store.dispatch(setUser(data));
    localStorage.setItem("accessToken", data?.tokens?.accessToken);
    localStorage.setItem("refreshToken", data?.tokens?.refreshToken);
};

export const checkFirebaseTokenInNative = async (token) => {
    return new Promise((resolve, reject) => {
        if (window.bridge && window.bridge["messageToNative"]) {
            window.bridge.messageToNative(JSON.stringify({ action: "check_fcm_token" }));
        }
        bridge.callHandler("masteroCallback", { action: "check_fcm_token", userStore: '', }, (res) => {
            console.log("callHandler resp:", res);
        });
        bridge.registerHandler("tokenFromNative", async (json_string, responseCallback) => {
            resolve(JSON.parse(json_string)?.deviceToken)
        });
    })
};

export const signIn = async (req) => {
    try {
        const resp = await axiosInstance.post(`${API_URL}/access/sign-in`, req);
        const data = resp?.data?.data;
        if (!isEmpty(data)) { // for MFA
            store.dispatch(setToken(data?.tokens))
        }

        if (resp.status === 200 || resp.status === 201) {
            //set user-type
            let userType = constants.USER_TYPES.USER;
            if (data?.Type) userType = data?.Type;
            // let userType = constants.USER_TYPES.USER;//hardcoded value
            localStorage.setItem("userType", userType);
        }

        if (!isEmpty(data?.tokens?.accessToken)) {
            let isNative = store?.getState()?.common?.isNativeApp;
            // store.dispatch(openToast({ isToastOpen: true, toastMessage: `Login-${isNative}`, toastSeverity: 'success' }));
            setTokenState(data);
            if (isNative) {
                localStorage.setItem("accessToken", data?.tokens?.accessToken);
                localStorage.setItem("refreshToken", data?.tokens?.refreshToken);
                // await saveDeviceToken(data);
            }
        }
        return resp;
    } catch (err) {
        return err.response
    }
};

export const requestOtp = async (req) => {
    try {
        const resp = await axiosInstance.post(`${API_URL}/access/request-otp`, req);
        return resp;
    } catch (err) {
        return err.response
    }
};

export const verifyOtp = async (req) => {

    try {
        const resp = await axiosInstance.post(`${API_URL}/access/verify-otp`, req);
        const data = resp?.data?.data;
        if (resp.status === 200 || resp.status === 201) {
            //set user-type
            let userType = constants.USER_TYPES.USER;
            if (data?.Type) userType = data?.Type;
            // let userType = constants.USER_TYPES.USER;//hardcoded value
            localStorage.setItem("userType", userType);
        }
        if (!isEmpty(data?.tokens?.accessToken)) {
            let isNative = store?.getState()?.common?.isNativeApp;
            // store.dispatch(openToast({ isToastOpen: true, toastMessage: `Login-${isNative}`, toastSeverity: 'success' }));
            if (isNative) {
                await saveDeviceToken(data);
                setTokenState(data);
            } else {
                setTokenState(data);
            }
        }
        return resp;
    } catch (err) {
        return err.response;
    }
};

export const validateThirdPartyUser = async (req) => {
    try {
        const resp = await axiosInstance.post(`${API_URL}/integration/validate-user`, req);
        return resp;
    } catch (err) {
        return err.response
    }
};

export const logout = async () => {
    try {
        //set recently learned to empty on logout
        store.dispatch(handleRecentlyLearned([]));
        store.dispatch(setSplashScreenViewFlag(false));
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("refreshToken");
        window.localStorage.removeItem("userType");
        localStorage.setItem("loggedOut", true);
        // const resp = await axiosInstance.delete(`${API_URL}/access/logout`);
        // return resp;
        if (window.location.pathname !== "/login") window.location.href = "/login";
        return
    } catch (err) {
        return err.response
    }
};

export const saveDeviceToken = async () => {
    if (window.location.pathname === "/login" || window.location.pathname === "/otp-login") return
    if (localStorage.getItem('userType') === constants.USER_TYPES.MANAGER) return

    let deviceToken = '';

    try {
        let { deviceDetails } = store.getState()?.commonPersist;
        let req = {
            deviceOS: !isEmpty(deviceDetails?.os) ? deviceDetails.os : 'WEB',
            deviceToken: !isEmpty(deviceToken) ? deviceToken : 'WEB',
            deviceId: !isEmpty(deviceDetails?.device_id) ? deviceDetails?.device_id : 'WEB',
        }

        if (isEmpty(deviceDetails)) {
            deviceDetails = window.deviceDetails || document.deviceDetails;
        }

        if (!isEmpty(deviceDetails) && (deviceDetails?.os === 'android' || deviceDetails?.os === 'ANDROID')) {
            req['deviceModel'] = deviceDetails.deviceModel
            req['android_sdk_version'] = deviceDetails.android_version;
            req['android_version'] = `${deviceDetails.version}`;
            req['app_code'] = deviceDetails.app_code;
            req['app_version'] = deviceDetails.app_version;
            req['deviceToken'] = !isEmpty(deviceDetails?.deviceId) && deviceDetails.deviceId;
            req['deviceId'] = !isEmpty(deviceDetails?.device_id) && deviceDetails.device_id;
            req['deviceOS'] = !isEmpty(deviceDetails?.os) && deviceDetails.os;
        }
        else if (!isEmpty(deviceDetails) && (deviceDetails?.os === 'ios' || deviceDetails?.os === 'IOS')) {
            req['deviceModel'] = deviceDetails.deviceModel
            req['ios_version'] = `${deviceDetails.ios_version}`;
            req['android_version'] = `${deviceDetails.version}`;
            req['app_code'] = deviceDetails.app_code;
            req['app_version'] = deviceDetails.app_version;
            req['deviceToken'] = !isEmpty(deviceDetails?.deviceId) && deviceDetails.deviceId;
            req['deviceId'] = !isEmpty(deviceDetails?.device_id) && deviceDetails.device_id;
            req['deviceOS'] = !isEmpty(deviceDetails?.os) && deviceDetails.os;
        }

        deviceToken = window.deviceToken || document.deviceToken;
        if (!isEmpty(deviceDetails?.deviceToken) && isEmpty(deviceDetails?.os)) {
            req['deviceToken'] = deviceDetails?.deviceToken;
            req['deviceId'] = 'WEB';
            req['deviceOS'] = 'WEB';
        }
        else if (isEmpty(deviceDetails) && !isEmpty(deviceToken)) {
            req['deviceToken'] = deviceToken;
            req['deviceId'] = 'IOS';
            req['deviceOS'] = 'IOS';
        }
        const resp = await axiosInstance.post(`${API_URL}/access/device-token`, req);
        return resp;
    } catch (err) {
        console.log(err.response)
        return err.response
    }
}


export const updatePrivacyPolicy = (req) => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axiosInstance.post(`${API_URL}/access/user-consent`, req);
            resolve(resp);
            return resp;
        } catch (err) {
            reject(err)
        }
    })
};