import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//  components
import StartScreen from "../common-screens/start-screen";
import GbaGameOverScreen from "../common-screens/game-over-screen";
import GbaWinScreen from "../common-screens/win-screen";
import HowToPlayBuildingBlocks from "../how-to-play/building-blocks_how_to_play";

// redux
import { submitChallengeAttemptData, submitEndChallengeAttempt } from "../../../redux/gba/gba.api";
import { startChallengeAttempt } from "../../../redux/microskill/microskill.api";
import { handleGbaGameState } from "../../../redux/gba/gba.actions";

//  utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";

//constants
import constants from '../../../constants';

//  styles
import useStyle from "./index.styles";
import { useTranslation } from "react-i18next";


//  Building Blocks Images
const imagesData = ["/images/gba/Building_Blocks_road.svg", "/images/gba/Building_Blocks_tower.svg",
    "/images/gba/Building_Blocks_space3.svg", "/images/gba/Building_Blocks_space4.svg",
    "/images/gba/Building_Blocks_space5.svg", "/images/gba/Building_Blocks_space6.svg",
    "/images/gba/Building_Blocks_space7.svg", "/images/gba/Building_Blocks_space8.svg",
    "/images/gba/Building_Blocks_space9.svg"];

const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;

const BuildingBlocks = (props) => {
    const dispatch = useDispatch();
    const { gameData, handleGbaState, isPauseOverlay, defaultGradient, setAddPause } = props;
    const optionData = gameData?.questions.length > 10 ? gameData?.questions.slice(0, 10) : gameData?.questions;
    const { microskillId, challengeId } = useParams();
    const contextData = gameData?.contexts;
    const isMobile = useSelector(state => state?.common?.isMobile);

    let maxLifeGba = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxLife;
    let maxQuestion = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxQuestion;
    let maxLife = commonUtil.getMaxLifeForGba({
        gbaType: gameData.designType,
        questionsInGba: optionData.length,
        maxQuestion: maxQuestion,
        maxLife: maxLifeGba,
    });


    let mxmScore = 120;
    let maxTime = 120;
    let tempTimer = maxTime;
    // let maxLife = 4;
    // let marksPerCorrectAns = 12;
    let marksPerCorrectAns = mxmScore / optionData.length;
    let containerId = 'Building-Blocks-container';

    const [isGamePage, setIsGamePage] = useState(false);
    const [componentKey, setComponentKey] = useState(new Date().getTime());
    const [isHowToPlayScreen, setIsHowToPlayScreen] = useState(false);
    const [isGameComplete, setIsGameComplete] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [lifeRemaining, setLifeRemaining] = useState(maxLife);
    const [attemptData, setAttemptData] = useState('');
    const [gameEndType, setGameEndType] = useState();
    const [imageIndex, setImageIndex] = useState(0);
    const [context, setContext] = useState(null);
    const [incorrectData, setIncorrectData] = useState();

    const classes = useStyle(isMobile);
    const BuildingBlocksRootRef = useRef()
    const totalScore = useRef();
    const qstnBoxRef = useRef(null);
    const attemptRef = useRef();
    const lifeTextRef = useRef(null);
    const progressBarRef = useRef();
    const gradientOverlayRef = useRef([]);
    const intervalRef = useRef();
    const lifeRef = useRef();
    const heartRef = useRef();
    const timerRef = useRef();
    const questionRef = useRef();
    const containerRef = useRef();
    const dropContainerRef = useRef();
    const finalChallengeDataRef = useRef();
    let previousTimeRef = useRef(maxTime);
    const timeRef = useRef();
    const questionNoRef = useRef();
    const timerImgRef = useRef();
    const imageRef = useRef([React.createRef(),
    React.createRef(), React.createRef(),
    React.createRef(), React.createRef(),
    React.createRef(), React.createRef(),
    React.createRef(), React.createRef(),
    React.createRef()]);

    const correctIncorrectTextRef = useRef();

    const correctTextRef = useRef();
    const incorrectTextRef = useRef();

    const salutedTextArray = [
        {
            id: 1,
            text: "CORRECT ANSWER!",
            ref: correctTextRef,
        },
        {
            id: 2,
            text: "WRONG ANSWER!",
            ref: incorrectTextRef,
        }
    ]

    const { t } = useTranslation()

    let bgGradientDefault = defaultGradient;
    let correctAnsGradient = 'linear-gradient(#10AB10, #0B670B)';
    let inCorrectAnsGradient = 'linear-gradient(rgb(114, 104, 104), rgb(246, 59, 52))';

    const totalGBATimespentIntervalRef = useRef();
    const totalGBATimespent = useRef(0);

    const getChallengeDataAsync = async () => {
        const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
        setAttemptData(attemptResp.data.data);
        attemptRef.current = attemptResp.data.data;
    };

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
            clearInterval(totalGBATimespentIntervalRef.current);
        }
    }, [])

    useEffect(() => {
        if (isEmpty(attemptData)) return
        // set initial state
        let currentTime = Date.now();
        console.log("currentTime:", currentTime);
        let tempData = {
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScore.current) ? 0 : Math.ceil(totalScore.current),
            status: constants.GBA_ILA_STATUS.exited,
        }
        dispatch(handleGbaGameState(tempData));
    }, [attemptData])

    const handleAttemptedChallenge = (params) => {
        let { qId, aId, isCorrect, t, skillId, contextId } = params;
        console.log("t:", t);
        let questionObj = {
            questionId: optionData[activeIndex].id,
            contextId: optionData[activeIndex].contextId,
            skillId: optionData[activeIndex].skillId,
            answers: [{
                answerId: aId,
                correct: isCorrect
            }],
            timespent: t
        };
        let req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptRef.current.id,
            questions: [questionObj]
        }

        submitChallengeAttemptData(req);
        handleEndChallengeAttempt(questionObj, req, skillId, contextId)
    }

    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
        let tempQuestionObj = {
            ...questionObj,
            skillId: skillId,
            contextId: contextId,
        }
        let totalTimeSpent = (isEmpty(finalChallengeDataRef.current) ? 0 : finalChallengeDataRef.current.timespent) + tempQuestionObj.timespent;
        if (isEmpty(finalChallengeDataRef.current)) {
            finalChallengeDataRef.current = {
                ...req,
                questions: [tempQuestionObj],
                completion: true,
                timespent: totalTimeSpent,
                scored: 0,
                fallbackSave: true,
            }

        } else {
            finalChallengeDataRef.current.questions.push(tempQuestionObj)
            finalChallengeDataRef.current = {
                ...finalChallengeDataRef.current,
                timespent: totalTimeSpent,
            }
        }
    }

    const startTimer = (time) => {
        //start timer
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);

        totalGBATimespentIntervalRef.current = setInterval(() => {
            setAddPause(true);
            totalGBATimespent.current += 1;
            console.log("totalGBATimespent.current:", totalGBATimespent.current);
        }, 1000);

        intervalRef.current = setInterval(() => {
            if (timerRef.current) {
                timerRef.current.innerHTML = time--;
            }
            if (time < 10) {
                timeRef.current.style.color = "#FF5757";
                timerImgRef.current.src = "/images/gba/timer-red.svg"
                qstnBoxRef.current.style.boxShadow = '0px 0px 7px 4px #c51414bd';
                gradientOverlayRef.current.style.backgroundImage = `${inCorrectAnsGradient}`
                gradientOverlayRef.current.classList.add(classes.incorrectGradientOverlayAnimationInfinite);
                timeRef.current.classList.add(classes.incorrectGradientOverlayAnimationInfinite);
            }
            if (time === -1) {
                // dispatch(saveTimespentNew('1', maxTime - time, '1', 'Y'));
                gradientOverlayRef.current.classList.remove(classes.incorrectGradientOverlayAnimationInfinite);
                clearInterval(totalGBATimespentIntervalRef.current);
                submitEndChallengeAttempt({
                    ...finalChallengeDataRef.current,
                    microskillId: microskillId, attemptId: attemptRef.current.id,
                    challengeId: challengeId, scored: totalScore.current,
                    timespent: totalGBATimespent.current,
                    completion: false,
                    status: constants.GBA_ILA_STATUS.timerLose
                })

                handleGbaState(GBA_SCREEN_STATE[5]);
                BuildingBlocksRootRef.current.style.backgroundImage = `${inCorrectAnsGradient}`
                //let all animation to complete
                setTimeout(() => {
                    setIsGameComplete(true);
                    setGameEndType('timeup');
                }, [2000]);
                setTimeout(() => {
                    clearInterval(intervalRef.current);
                })
            }
        }, 1000);
    }

    useEffect(() => {
        if (isGamePage) {
            startTimer(tempTimer);
            handleGbaState(GBA_SCREEN_STATE[2]);
            BuildingBlocksRootRef.current.style.backgroundImage = `${bgGradientDefault}`
        }
    }, [isGamePage]);

    const [fSize, setFSize] = useState(1);

    useEffect(() => {
        setFSize(window.innerHeight / 961);
    }, []);

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }

    window.addEventListener("resize", resFont);

    useEffect(() => {
        if (isEmpty(attemptData)) getChallengeDataAsync();
    }, [])

    useEffect(() => {
        if (lifeRemaining === 1) {
            gradientOverlayRef.current.classList.add(classes.incorrectGradientOverlayAnimationInfinite);
            lifeTextRef.current.style.color = "#FF5757";
            qstnBoxRef.current.style.boxShadow = '0px 0px 7px 4px #c51414bd';
            heartRef.current.src = "/images/icons/heart-red.png";
            lifeTextRef.current.classList.add(classes.incorrectGradientOverlayAnimationInfinite);
        }
        if (lifeRemaining === 0) {
            setTimeout(async () => {
                handleGbaState(GBA_SCREEN_STATE[4])
                clearInterval(totalGBATimespentIntervalRef.current);
                const submitEndChallengeAttemptData = await submitEndChallengeAttempt({
                    ...finalChallengeDataRef.current,
                    microskillId: microskillId,
                    attemptId: attemptRef.current.id,
                    challengeId: challengeId,
                    scored: totalScore.current,
                    completion: false,
                    status: constants.GBA_ILA_STATUS.lifeLose,
                    timespent: totalGBATimespent.current
                });

                setIncorrectData(submitEndChallengeAttemptData?.data?.data?.consecutiveIncorrectAttempt);

                BuildingBlocksRootRef.current.style.backgroundImage = `${inCorrectAnsGradient}`
                setIsGameComplete(true);
                setGameEndType("loose")
            }, [2000])
        }
    }, [lifeRemaining])

    useEffect(() => {
        if (activeIndex <= 9) {
            if (optionData[activeIndex].contextId !== null) {
                setContext(contextData.filter(item => optionData[activeIndex].contextId !== null && item.id === optionData[activeIndex].contextId))
            }
        }
        if (activeIndex > 0 && lifeRemaining) {
            questionRef.current.style.scale = '1';
        }
    }, [activeIndex])

    useEffect(() => {
        if (isPauseOverlay) {
            handleBackButton();
        } else {
            if (isGamePage) {
                handleResumeClick();
            }
        }
    }, [isPauseOverlay]);

    const handleLifeLoss = () => {
        if (activeIndex < 9) {
            questionRef.current.style.scale = '0';
            setTimeout(() => {
                gradientOverlayRef.current.style.opacity = '0';
            }, [1000]);

            setTimeout(() => {
                if (lifeRemaining - 1 > 0) {
                    containerRef.current.style.pointerEvents = "auto";
                }
            }, [2500])

            setTimeout(() => {
                if (lifeRemaining - 1 > 0) {
                    setActiveIndex(activeIndex + 1);
                }
            }, [1500])
        }
        else {
            setTimeout(() => {
                handleGbaState(GBA_SCREEN_STATE[3])
                clearInterval(totalGBATimespentIntervalRef.current);

                submitEndChallengeAttempt({
                    ...finalChallengeDataRef.current,
                    attemptId: attemptRef.current.id,
                    microskillId: microskillId,
                    challengeId: challengeId,
                    scored: totalScore.current,
                    completion: true,
                    status: constants.GBA_ILA_STATUS.completed,
                    timespent: totalGBATimespent.current
                })
                setIsGameComplete(true);
                setGameEndType("win")
            }, [2000])
        }
        setLifeRemaining(lifeRemaining - 1);
    }

    const handleRightAnswer = (answer) => {
        if (activeIndex < 9) {
            let el = imageRef.current[imageIndex];
            el.className = `${classes.imagesBlocks} ${classes.imagesAnimation}`;
            setImageIndex(imageIndex + 1);
        }
        let currentTime = parseInt(timerRef.current.innerHTML);
        if (lifeRemaining > 1) {
            gradientOverlayRef.current.style.backgroundImage = `${correctAnsGradient}`;
            gradientOverlayRef.current.style.opacity = '1';
        }

        correctIncorrectTextRef.current.style.opacity = '1';
        correctTextRef.current.style.transform = 'scale(1)';
        setTimeout(() => {
            if (!isEmpty(correctIncorrectTextRef.current) && !isEmpty(correctTextRef.current)) {
                correctTextRef.current.style.transform = 'scale(0)';
                correctIncorrectTextRef.current.style.opacity = '0';
            }
        }, [2000])

        // console.log(previousTimeRef.current, "previousTimeRef.current");
        handleAttemptedChallenge({
            qId: optionData[activeIndex].id,
            aId: answer.id,
            isCorrect: answer.isCorrect,
            t: previousTimeRef.current - currentTime,
            skillId: optionData[activeIndex].skillId,
            contextId: optionData[activeIndex].contextId
        });

        if (lifeRemaining > 1) {
            setTimeout(() => {
                gradientOverlayRef.current.style.opacity = '0';
            }, [1000]);
        }

        setTimeout(() => {
            if (!isEmpty(containerRef.current)) containerRef.current.style.pointerEvents = "auto";
        }, [2500]);

        totalScore.current += marksPerCorrectAns;
        previousTimeRef.current = currentTime;
        if (activeIndex < optionData.length - 1 && lifeRemaining > 0) {
            // questionRef.current.style.scale = '0';
            setTimeout(() => {
                setActiveIndex(activeIndex + 1);
            }, [1500])
        }
        else {
            setTimeout(() => {
                handleGbaState(GBA_SCREEN_STATE[3])
                clearInterval(totalGBATimespentIntervalRef.current);

                submitEndChallengeAttempt({
                    ...finalChallengeDataRef.current,
                    attemptId: attemptRef.current.id,
                    microskillId: microskillId,
                    challengeId: challengeId,
                    scored: Math.ceil(totalScore.current),
                    completion: true,
                    status: constants.GBA_ILA_STATUS.completed,
                    timespent: totalGBATimespent.current
                })
                setIsGameComplete(true);
                setGameEndType("win")
            }, [2000])
        }
    }

    const handleWrongAnswer = (answer) => {
        let currentTime = parseInt(timerRef.current.innerHTML);
        gradientOverlayRef.current.style.backgroundImage = `${inCorrectAnsGradient}`;
        gradientOverlayRef.current.style.opacity = '1';

        correctIncorrectTextRef.current.style.opacity = '1';
        incorrectTextRef.current.style.transform = 'scale(1)';

        setTimeout(() => {
            if (!isEmpty(correctIncorrectTextRef.current) && !isEmpty(incorrectTextRef.current)) {
                incorrectTextRef.current.style.transform = 'scale(0)';
                correctIncorrectTextRef.current.style.opacity = '0';
            }
        }, [2000])

        handleAttemptedChallenge({
            qId: optionData[activeIndex].questionId,
            aId: answer.id,
            isCorrect: answer.isCorrect,
            t: previousTimeRef.current - currentTime,
            skillId: optionData[activeIndex].skillId,
            contextId: optionData[activeIndex].contextId
        });


        previousTimeRef.current = currentTime;
        handleLifeLoss();
    }

    const handleOptionButton = (e, id) => {
        containerRef.current.style.pointerEvents = "none";
        let currentTime = parseInt(timerRef.current.innerHTML);

        if (currentTime <= 1) {
            return;
        }

        if (isEmpty(totalScore.current)) {
            totalScore.current = 0;
        }
        const answer = optionData[activeIndex].answers;
        var progressBarWidth = ((activeIndex + 1) / optionData.length) * 100;
        progressBarRef.current.style.width = progressBarWidth + '%';
        questionRef.current.style.scale = '0';
        if (answer[0].id === id) {
            if (answer[0].isCorrect === true) {
                handleRightAnswer(answer[0])
            }
            else {
                handleWrongAnswer(answer[0])
            }
        }
        else {
            if (answer[1].isCorrect === true) {
                handleRightAnswer(answer[1])
            }
            else {
                handleWrongAnswer(answer[1])
            }
        }
    }

    const restartGame = () => {
        setIsGameComplete(false);
        setIsGamePage(true);
        startTimer(tempTimer)
        setActiveIndex(0);
        setLifeRemaining(maxLife);
        setIsHowToPlayScreen(false);
        getChallengeDataAsync();
        setImageIndex(0)
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
        totalGBATimespent.current = 0;
        previousTimeRef.current = maxTime;
        totalScore.current = 0
        finalChallengeDataRef.current = '';
        BuildingBlocksRootRef.current.style.backgroundImage = `${bgGradientDefault}`
    }

    // Pause Screen
    const handleBackButton = () => {
        clearInterval(totalGBATimespentIntervalRef.current);
        let timeSpent = totalGBATimespent.current;
        dispatch(handleGbaGameState({
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScore.current) ? 0 : Math.ceil(totalScore.current),
            timespent: timeSpent
        }))
        clearInterval(intervalRef.current);
    }

    const handleResumeClick = () => {
        setAddPause(true);
        startTimer(parseInt(timerRef.current.innerHTML))
    }

    return (
        <div style={{ backgroundImage: !isGamePage ? bgGradientDefault : bgGradientDefault, fontSize: `${fSize}rem` }}
            className={classes.BuildingBlocksRoot} key={componentKey} ref={BuildingBlocksRootRef}>
            {
                !isGamePage ? (
                    !isHowToPlayScreen ? (
                        <StartScreen
                            setIsGamePage={setIsGamePage}
                            setIsHowToPlayScreen={setIsHowToPlayScreen}
                            gradientBg={bgGradientDefault}
                            bg={"/images/gba/building_blocks.svg"}
                            gameLogo={"/images/gba/Building_Blocks_game_logo.svg"}
                            handleGbaState={handleGbaState}
                        />
                    ) : <HowToPlayBuildingBlocks setIsGamePage={setIsGamePage} />
                ) : (
                    !isGameComplete ? (
                        <>
                            <div className={classes.gradientOverlay} ref={gradientOverlayRef} />
                            <div className={classes.container}>
                                <div className={classes.containerBox} style={{ backgroundImage: "url(/images/gba/building_blocks.svg)", backgroundRepeat: "no-repeat", backgroundPositionY: "bottom", backgroundSize: "contain" }}>
                                    <div className={classes.progressBarContainer}>
                                        <div className={classes.progressBar} ref={progressBarRef} />
                                    </div>

                                    {/* QUESTION BOX */}
                                    <div className={classes.qstnContainer}>
                                        <div className={classes.qstnBox} ref={qstnBoxRef}>
                                            <div className={classes.assetBox}>
                                                <div className={classes.lifeText} ref={lifeTextRef}>
                                                    <div className={classes.life} ref={lifeRef}><b>{lifeRemaining} x</b></div>
                                                    <img src='/images/icons/heart.png' className={classes.icon} ref={heartRef} alt="heart" />
                                                </div>

                                                <div className={classes.questionNo}>Q<span ref={questionNoRef}>{activeIndex + 1}</span>/{optionData.length}</div>

                                                <div className={classes.timerText} ref={timeRef}>
                                                    <img src='/images/icons/stopwatch.png' className={classes.timerIcon} ref={timerImgRef} alt="timer" />
                                                    <div><b><span ref={timerRef}>{tempTimer}</span>s</b></div>
                                                </div>
                                            </div>
                                            <div className={classes.qstn} ref={questionRef}>

                                                {/* ADD CONTEXT */}
                                                <div style={{ color: "yellow", fontSize: "1em", wordBreak: "break-word" }}>
                                                    {activeIndex < optionData.length && context !== null ?
                                                        context[0].context : null}
                                                </div>

                                                <div style={{ fontSize: "1em", wordBreak: "break-word" }}>
                                                    {activeIndex < optionData.length ?
                                                        (optionData[activeIndex].question) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.gamePlayBox} ref={containerRef} id={containerId}>
                                        <div className={classes.correctIncorrectText} ref={correctIncorrectTextRef}>
                                            {
                                                salutedTextArray.map((elem) => (
                                                    <h1 key={elem.id} className={classes.thickText} ref={elem.ref}>{elem.text}</h1>
                                                ))
                                            }
                                        </div>

                                        <div className={classes.dropContainer} ref={dropContainerRef} >
                                            <div className={classes.imagesContainer}>
                                                {imagesData.map((item, i) => (
                                                    <img key={i} ref={(el) => imageRef.current[i] = el} src={item} className={`${classes.imagesBlocks}`} alt="Building_Blocks" />
                                                ))}
                                            </div>
                                        </div>
                                        <div className={classes.optionContainer}>
                                            {activeIndex < 10 && optionData[activeIndex].answers.map(item => (
                                                <button key={item.id} id={item.id}
                                                    style={{ background: "#FFF", color: "#1A3154", fontWeight: "bolder", minHeight: "2em", fontSize: "2em" }}
                                                    onClick={(e) => handleOptionButton(e, item.id)}>{item.answer.toUpperCase() === "TRUE" ? t("True") : t("False")}</button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {
                                gameEndType === 'win' && (
                                    <GbaWinScreen
                                        maxScore={mxmScore}
                                        bg={"/images/gba/building_blocks.svg"}
                                        obtainedScore={totalScore.current} />
                                )
                            }
                            {
                                gameEndType === 'loose' && (
                                    <GbaGameOverScreen
                                        type={"gameover"}
                                        gameType={gameData?.gameType}
                                        gameData={gameData}
                                        incorrectAttempt={incorrectData}
                                        bg={""}
                                        // bg={"/images/gba/building_blocks.svg"}
                                        // bgGradient={defaultGradient}
                                        restartGame={restartGame} />
                                )
                            }
                            {
                                gameEndType === 'timeup' && (
                                    <GbaGameOverScreen
                                        type={"time"}
                                        bg={""}
                                        // bg={"/images/gba/building_blocks.svg"}
                                        // bgGradient={defaultGradient}
                                        restartGame={restartGame} />
                                )
                            }
                        </>
                    )
                )
            }
        </div>
    )
}

export default BuildingBlocks;