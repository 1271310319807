import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


// mui
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import Slide from '@mui/material/Slide';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
// import Hidden from '@mui/material';

// components
import SideDrawer from '../side-drawer';
import ShareDialogBox from '../../share-dialog-Box';


// redux
import { logout } from '../../../redux/auth/auth.api';
import { openToast, setHeaderSearchText, setMicroskillCategories, setSelectedLanguage, setSelectedMicroskillCategories, setSelectedMicroskillCategory, setViewMicroskillByCategory } from '../../../redux/common/common.actions';
import { postUserLanguage } from '../../../redux/user-specific/userSpecific.api';
import { getAllModulesAsync } from '../../../redux/microskill/microskill.api';
import { setIsDarkMode } from '../../../redux/common-persist/common-persist.actions';

// utils
import commonUtil, { getHeaderHeight } from '../../../utils/commonUtil';
import { isNativeApp } from '../../../utils/commonUtil';
import isEmpty from '../../../utils/isEmpty';

// theme
import palette from '../../../theme/palette';

// constants
import constants from '../../../constants';

// Conext
import LearningAidContext from '../../../context/learningAidContext';
import CommonContext from '../../../context/CommonContext';

// i18-next
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

// styles
import useStyles from './index.styles';
import theme from '../../../theme';
import { useContext } from 'react';
import { KeyboardArrowDown, KeyboardBackspace } from '@mui/icons-material';
import { Button, List, ListItemText } from '@mui/material';

// lottie
import lottie from "lottie-web";
import Lottie from 'react-lottie-player';
import christmasGiftAnimationData from '../../../lottie-assets/gift-side-menu.json'

const drawerWidth = 240;
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const SearchBoxWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '5% 90% 5%',
    [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(1),
        width: '80%',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1, 0, 2),
}));
const CloseIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            '&:focus': {
                width: '100%',
            },
        },
    },
}));

const LightDarkModeSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    transform: 'translateX(5px) scale(0.8)',
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {

                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#F4511E',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));
const displayDrawerStyleMobile = {
    sm: 'block',
    md: 'none'
}
const displayDrawerStyleDesktop = {
    xs: 'none',
    sm: 'none',
    md: 'block'
}

function removeDuplicateEntry(arr, field) {
    // array of data, field on which you need to filter
    let filteredArr = [...arr]?.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t[field] === value[field]
        )) && !isEmpty(value)
    )
    return filteredArr
}

const Header = (props) => {
    const { showSearchHeader } = props;
    const queryParams = new URLSearchParams(window.location.search);
    const headerSearchText = useSelector(state => state?.common?.headerSearchText);
    const userDetail = useSelector(state => state?.auth?.user);
    const url = window.location.href;
    let companyId = commonUtil.getCompanyId();

    const dispatch = useDispatch();
    const { isMobile, isTablet, isDocViewing, selectedLanguage, microkillCategories, selectedMicrokillCategories } = useSelector(state => state?.common);
    const { viewChristmasGiftAnimation, isDarkMode } = useSelector(state => state?.commonPersist);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');
    const containerRef = useRef(null);

    const debouncingTimerRef = useRef(null);

    const filterBtnContainerRef = useRef()
    const navigate = useNavigate();
    const [microkillCategoriesList, setMicroskillCategoriesList] = useState([])
    const [toggleCategories, setToggleCategories] = useState(null)
    const [openCategoryFilter, setOpenCategoryFilter] = useState(false)
    const classes = useStyles({ isDarkMode });
    const logos = userDetail?.companyTheme?.logo;

    const {
        mobileOpen, setMobileOpen,
        LanguageChanging, setLanguageChanging,
        anchorElUser, setAnchorElUser
    } = useContext(CommonContext);

    const { t, i18n } = useTranslation();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [showShareDialogBox, setShowShareDialogBox] = useState(false);

    const handleCloseShareDialogBox = () => {
        setShowShareDialogBox(false);
    }

    useEffect(() => {
        if (!isEmpty(selectedLanguage)) {
            handleModuleDataAsync();
        }
    }, [selectedLanguage])

    useEffect(() => {
        setMicroskillCategoriesList(microkillCategories)
    }, [microkillCategories, selectedMicrokillCategories])

    useEffect(() => {
        if (!url.includes("/learn")) {
            setOpenCategoryFilter(false)
        }
    }, [url])

    // this useEffect will decide, to open show more button at filters or not
    useEffect(() => {
        if (!isEmpty(filterBtnContainerRef.current)) {
            let childrenWidth = 0
            let children = filterBtnContainerRef.current.childNodes
            children.forEach(child => {
                let childWidth = child.getBoundingClientRect().width
                childrenWidth += childWidth
            })
            let containerWidth = filterBtnContainerRef.current.getBoundingClientRect().width;
            if (childrenWidth > containerWidth) {
                filterBtnContainerRef.current.style.maxHeight = "3rem";
                setToggleCategories(false)

            } else {
                setToggleCategories(null)
            }
        }

    }, [isSearchVisible, filterBtnContainerRef.current, microkillCategoriesList, openCategoryFilter])

    useEffect(() => {
        dispatch(setHeaderSearchText(''));
        setSearchInputValue('')
    }, [window.location.pathname])

    const handleModuleDataAsync = async () => {
        let queryParam = `?startIndex=0&limit=200&sort=createdOn&sortDirection=desc`;
        queryParam += selectedLanguage !== "Default" ? `&languages=${selectedLanguage}` : ''
        const moduleData = await getAllModulesAsync(queryParam);
        if (moduleData.status === 200 || moduleData.status === 201) {
            let microskillCategories = moduleData.data.data?.microskills?.map(e => {
                if (!isEmpty(e) && !isEmpty(e.Microskill.Category)) return e.Microskill.Category
                else return {};
            }) ?? []

            dispatch(setMicroskillCategories(removeDuplicateEntry(microskillCategories, 'id')))



        } else if (moduleData.status === 500) {
            dispatch(openToast({ isToastOpen: true, toastMessage: 'Internal server error', toastSeverity: 'error' }))
        }
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setLanguageChanging(false)
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleMenuClick = async (data) => {
        setAnchorElUser(null);
        data.func();
        handleCloseUserMenu();
    };
    const handleSearchBtnClick = () => {
        setIsSearchVisible(true)
    };

    const handleCloseSearchClick = () => {
        dispatch(setHeaderSearchText(''));
        setSearchInputValue('')
        setIsSearchVisible(false)
        setToggleCategories(false)
    };

    const handleSearchBarText = (e) => {
        setSearchInputValue(e.target.value);
        clearTimeout(debouncingTimerRef.current);

        debouncingTimerRef.current = setTimeout(() => {
            dispatch(setHeaderSearchText(e.target.value));
        }, 500);
    }

    const CloseNbackIconComp = () => {
        let styling = { color: palette.orange, fontSize: 30, fontWeight: '600' }
        return (
            <ListItem
                className={classes.listBox}

                style={{
                    paddingLeft: 0,
                    paddingTop: isNativeApp() ? constants.HEADER_TOP_PADDING : null,
                    display: LanguageChanging ? "" : 'flex',
                    justifyContent: LanguageChanging ? "" : "space-between",
                    filter: viewChristmasGiftAnimation ? 'blur(5px)' : "" // christmas gift animation
                }}
            >
                <Button
                    variant='text'
                    onClick={LanguageChanging ? () => setLanguageChanging(false) : handleDrawerToggle}
                >
                    {
                        LanguageChanging ? <KeyboardBackspace sx={styling} /> : <CloseIcon sx={styling} />
                    }
                </Button>
            </ListItem>
        )
    }

    const LanguagesListUi = () => {
        return (
            <List sx={{ paddingTop: '0rem' }} className={classes.langListUi}>
                <MenuItem className={`${classes.listBox}`} style={{ paddingLeft: '0' }}>
                    <div style={{ fontSize: '1.2rem' }} onClick={() => setLanguageChanging(false)}>
                        <ChevronLeftRoundedIcon sx={{ color: palette.orange, fontSize: 25 }} />
                    </div>
                    <div style={{ paddingLeft: '0.2rem', marginBottom: '0.3rem', fontSize: '1.2rem' }}>
                        {t("Select App Language")}
                    </div>
                </MenuItem>

                {
                    constants.LANGUAGES_LIST?.map((elem => (
                        // constants.LANGUAGES_LIST?.map((elem => (
                        <MenuItem
                            className={classes.langListBox}
                            key={`${elem.id}-${elem.key}`}
                            onClick={async () => {
                                dispatch(setSelectedLanguage(elem.name))
                                setLanguageChanging(false)
                                i18n.changeLanguage(elem.key);
                                await postUserLanguage({ "language": elem.name })
                            }}
                        >
                            <Typography textAlign="center" className={classes.menuText}>{elem.name}</Typography>
                            {
                                selectedLanguage === elem.name &&
                                <CheckRoundedIcon sx={{ color: palette.orange, fontSize: 22 }} />

                            }
                        </MenuItem>
                    )))
                }
            </List>
        )
    }

    return (
        <>
            <Box className={classes.headerBox}
                style={{
                    height: getHeaderHeight(),
                    boxShadow: isMobile || isTablet ? 'rgba(0, 0, 0, 0.15) 0px 4px 5px 0px' : null,
                    position: 'relative'
                }}
            >
                <AppBar component="nav" className={classes.appBarRoot}>
                    <Toolbar className={classes.toolbar} ref={containerRef}
                        sx={isMobile || isTablet ? {} : {
                            gridTemplateColumns: !isEmpty(logos?.darkHeaderLogo) || !isEmpty(logos?.lightHeaderLogo) ? "20% 50% 30% !important" : "",
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            {(url.includes('gba') || url.includes('scorm')) && (<img src={`/images/icons/mastero_full_logo${isDarkMode ? "" : "_dark"}.svg`} style={{ width: '40%', padding: 'auto' }} alt="Master-O Logo" />)}

                            {(isMobile || isTablet) && (
                                <Box sx={{ mr: 3, display: displayDrawerStyleMobile }}>
                                    {
                                        <img src="/images/icons/hamburger.png"
                                            alt="master-o loading icon"
                                            width={25}
                                            height={25}
                                            onClick={handleDrawerToggle}
                                        />
                                    }
                                </Box>
                            )}
                        </div>

                        {
                            (isMobile || isTablet) && (
                                <div className={`${classes.companyLogoBox} ${classes.centerFlex}`}>
                                    <>
                                        {isDarkMode && !isEmpty(logos?.darkHeaderLogo) && (
                                            <img src={logos.darkHeaderLogo}
                                                alt="logo"
                                                className={classes.companyLogo}
                                            />
                                        )}
                                        {!isDarkMode && !isEmpty(logos?.lightHeaderLogo) && (
                                            <img src={logos.lightHeaderLogo}
                                                alt="logo"
                                                className={classes.companyLogo}
                                            />
                                        )}
                                    </>
                                </div>
                            )
                        }

                        {
                            !(isMobile || isTablet) ? (
                                <SearchBoxWrapper >
                                    <Search
                                        className={`search ${classes.search}`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            visibility: showSearchHeader && !url.includes('/challenge-list') && !url.includes('/performance') && !url.includes('/privacy-policy') && !isDocViewing ? 'visible' : 'hidden',
                                            gridTemplateColumns: openCategoryFilter ? "5% 90% 5%" : "5% 95%",
                                            borderRadius: openCategoryFilter ? `4px 4px 0 0` : "4px",
                                        }}>
                                        <SearchIconWrapper className={classes.centerFlex}>
                                            <SearchIcon color={isDarkMode ? palette.white : palette.orange} style={{ height: '50px', width: '25px' }} />
                                        </SearchIconWrapper>

                                        <StyledInputBase
                                            placeholder={t('Search for Microskills, Documents, Videos etc.')}
                                            inputProps={{ 'aria-label': 'search' }}
                                            onChange={handleSearchBarText}
                                            value={searchInputValue}
                                            onFocus={() => {
                                                if (openCategoryFilter === false) {
                                                    setOpenCategoryFilter(true)
                                                }
                                            }}
                                        />
                                        {
                                            openCategoryFilter && <CloseIconWrapper className={classes.centerFlex}>
                                                <CloseIcon onClick={() => {
                                                    setOpenCategoryFilter(false)
                                                    setSearchInputValue("")
                                                    dispatch(setHeaderSearchText(""));
                                                }} />
                                            </CloseIconWrapper>
                                        }
                                    </Search>
                                    {
                                        !isMobile && openCategoryFilter && url.includes("/learn") && <CategoryFilterComp
                                            openCategoryFilter={openCategoryFilter}
                                            containerRef={containerRef}
                                            toggleCategories={toggleCategories}
                                            setToggleCategories={setToggleCategories}
                                            filterBtnContainerRef={filterBtnContainerRef}
                                            microkillCategoriesList={microkillCategoriesList}
                                            selectedMicrokillCategories={selectedMicrokillCategories}
                                            dispatch={dispatch}
                                        />
                                    }
                                </SearchBoxWrapper>
                            ) : (
                                <>
                                    {
                                        showSearchHeader && (
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                            }}> <img src="/images/icons/search.svg" alt="search" width={25} height={25} onClick={handleSearchBtnClick} /> </Box>
                                        )
                                    }

                                    {
                                        isSearchVisible && (
                                            <Slide direction="left" in={isSearchVisible} container={containerRef.current}>
                                                <div className={classes.searchBox} style={{ top: isNativeApp() ? constants.HEADER_TOP_PADDING : isTablet ? '1%' : '' }}>
                                                    <img src="/images/icons/search.svg" alt="search" width={25} height={25} className={classes.searchIcon} />
                                                    <input onChange={handleSearchBarText} value={searchInputValue} autoFocus={true} />
                                                    <img src="/images/icons/close.svg" alt="search" width={20} className={classes.closeIcon} onClick={handleCloseSearchClick} />
                                                </div>
                                            </Slide>
                                        )
                                    }
                                </>
                            )
                        }

                        {
                            !(isMobile || isTablet) && (
                                <Box sx={{
                                    display: { xs: 'none', sm: 'none', md: 'flex', flexGrow: 0 },
                                    width: !isEmpty(logos?.darkHeaderLogo) || !isEmpty(logos?.lightHeaderLogo) ? '100% !important' : '70%',
                                    height: '100%',
                                    overflow: 'hidden',
                                }}
                                    className={classes.accountDetailBox}
                                >
                                    <>
                                        {isDarkMode && !isEmpty(logos?.darkHeaderLogo) && (
                                            <img src={logos.darkHeaderLogo}
                                                alt="logo"
                                                className={classes.companyLogo}
                                            />
                                        )}
                                        {!isDarkMode && !isEmpty(logos?.lightHeaderLogo) && (
                                            <img src={logos.lightHeaderLogo}
                                                alt="logo"
                                                className={classes.companyLogo}
                                            />
                                        )}
                                    </>
                                    <LightDarkModeSwitch
                                        checked={!isDarkMode}
                                        onChange={() => {
                                            dispatch(setIsDarkMode(!isDarkMode))
                                        }}
                                    />
                                    <div>
                                        <div className={`${classes.avatarBox} ${classes.centerFlex}`} onClick={handleOpenUserMenu} id="userAvatar">
                                            {
                                                isEmpty(commonUtil.getUserName()) ? (
                                                    <PermIdentityIcon style={{ color: "#FFF" }} />
                                                ) : (commonUtil.getUserName()?.charAt(0).toUpperCase())
                                            }
                                        </div>
                                        <Menu
                                            sx={{ mt: '45px' }}
                                            className={classes.menuDropdown}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                        >
                                            {
                                                !LanguageChanging ? (
                                                    constants.HEADER_DROPDOWN.map((item) => {
                                                        return (
                                                            // item.key !== 'logout' && (
                                                            item.key !== 'logout' && item.key !== 'settings' && (
                                                                <MenuItem key={item.id} onClick={() => {
                                                                    if (item.key === 'app_language') {
                                                                        setLanguageChanging(true)
                                                                        return;
                                                                    }
                                                                    if (item.key === 'privacy_policy') {
                                                                        navigate({
                                                                            pathname: "/privacy-policy",
                                                                        });
                                                                        setAnchorElUser(null);
                                                                        handleCloseUserMenu();
                                                                        return;
                                                                    }
                                                                    if (item.key === 'share_mastero') {
                                                                        setShowShareDialogBox(true);
                                                                        handleCloseUserMenu()
                                                                        return;
                                                                    }
                                                                    handleMenuClick(item)
                                                                }}>
                                                                    <img src={item.icon_orange} alt="icon" className={classes.dropDownIcon} width={20} />
                                                                    <Typography textAlign="center" className={classes.menuText}>{`${t(item.name)}${item.key === "app_language" ? ":" : ''}`}</Typography>
                                                                    {
                                                                        item.key === "app_language" && <Typography textAlign="left" className={classes.menuText}>{selectedLanguage}</Typography>
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )
                                                    })
                                                ) : (
                                                    <LanguagesListUi />
                                                )
                                            }
                                        </Menu>
                                    </div>
                                </Box>
                            )
                        }

                        {
                            (isMobile || isTablet) && isSearchVisible && url.includes("/learn") && <CategoryFilterComp
                                openCategoryFilter={isSearchVisible}
                                containerRef={containerRef}
                                toggleCategories={toggleCategories}
                                setToggleCategories={setToggleCategories}
                                filterBtnContainerRef={filterBtnContainerRef}
                                microkillCategoriesList={microkillCategoriesList}
                                selectedMicrokillCategories={selectedMicrokillCategories}
                                dispatch={dispatch}
                            />
                        }
                    </Toolbar>
                </AppBar>

                <Box component="nav">
                    <Drawer
                        // container={container}
                        className={classes.mobileDrawer}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={theme => ({
                            display: displayDrawerStyleMobile,

                            '& .MuiDrawer-paper': { backgroundColor: !isMobile ? `${theme.palette.headerBg} !important` : "", boxSizing: 'border-box', width: drawerWidth },
                        })}
                    >
                        <CloseNbackIconComp />
                        <SideDrawer handleDrawerToggle={handleDrawerToggle} setIsLanguageChanging={setLanguageChanging} isLanguageChanging={LanguageChanging} mobileOpen={mobileOpen} />
                    </Drawer>
                </Box>
            </Box>
            {showShareDialogBox && (
                <ShareDialogBox
                    isOpen={showShareDialogBox}
                    handleClose={handleCloseShareDialogBox}
                />

            )}
        </>
    );
}


export default Header;

const CategoryFilterComp = (props) => {
    const { openCategoryFilter, containerRef, toggleCategories, setToggleCategories, filterBtnContainerRef, microkillCategoriesList, selectedMicrokillCategories, dispatch } = props
    const classes = useStyles();
    const [toggleCategoryContainer, setToggleCategoryContainer] = useState(true)
    const { isMobile } = useSelector(state => state?.common)

    const { t } = useTranslation()
    return (
        <Slide
            direction="down"
            in={openCategoryFilter}
            container={containerRef.current}

        >

            <div
                className={`filter-btn-container ${classes.filterContainer}`}
            >
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: isMobile ? '5% 85% 6%' : '5% 90% 5%',
                }}>
                    <span></span>
                    <h4 className={classes.filterHeading}>
                        {t("Search in these specific categories")}
                    </h4>
                    <Button
                        variant='text'
                        sx={theme => ({
                            padding: '0',
                            width: '100%',
                            textAlign: 'center',
                            minWidth: '0',
                        })}
                        onClick={() => {
                            setToggleCategoryContainer(prev => !prev)
                        }}
                    >
                        <KeyboardArrowDown sx={(theme) => ({
                            width: '1.2em',
                            height: '1.2em',
                            color: theme.palette.fontColor,
                            transformOrigin: 'center center',
                            transition: 'transform 200ms linear',
                            transform: toggleCategoryContainer ? "rotate(180deg)" : "rotate(0deg)"
                        })} />
                    </Button>
                </div>
                {
                    <div
                        style={{
                            overflow: 'hidden',
                            transition: 'height 400ms linear',
                            height: toggleCategoryContainer ? 'auto' : "0"
                        }}
                    >
                        <div
                            className={classes.filterBtnContainer}

                            ref={filterBtnContainerRef}
                            style={{ maxHeight: !toggleCategories ? '3rem' : 'fit-content' }}
                        >
                            {
                                !isEmpty(microkillCategoriesList) && microkillCategoriesList.sort((a, b) => (a.sequence - b.sequence)).map(elem => {
                                    return <Button
                                        key={elem.id}
                                        variant='contained'
                                        className={classes.filterBtn}
                                        disableElevation
                                        sx={{
                                            background: !isEmpty(selectedMicrokillCategories) && selectedMicrokillCategories.includes(elem) ? palette.orange : "#6d0085",
                                            [theme.breakpoints.down('md')]: {
                                                "&:hover": {
                                                    backgroundColor: !isEmpty(selectedMicrokillCategories) && selectedMicrokillCategories.includes(elem) ? palette.orange : "#6d0085",
                                                }
                                            }

                                        }}
                                        size="small"
                                        onClick={() => {
                                            if (!selectedMicrokillCategories.includes(elem)) {
                                                dispatch(setSelectedMicroskillCategories([...selectedMicrokillCategories, elem]));
                                            } else {
                                                let removedCurEntry = [...selectedMicrokillCategories].filter(e => e.id !== elem.id)
                                                dispatch(setSelectedMicroskillCategories(removedCurEntry));
                                            }

                                        }}
                                    >{elem.name}</Button>
                                })

                            }
                            <Button
                                type="contained"
                                size="small"
                                onClick={() => {
                                    if (!isEmpty(selectedMicrokillCategories)) {
                                        dispatch(setSelectedMicroskillCategories([]));
                                    }
                                }}
                            >
                                {t("Clear All")}
                            </Button>
                        </div>
                        <div style={{
                            textAlign: 'center',
                        }}>
                            {
                                !isEmpty(toggleCategories) && <Button
                                    type="text"
                                    size="small"
                                    onClick={() => {
                                        setToggleCategories(prev => !prev)
                                    }}
                                >
                                    {
                                        !toggleCategories ? t("Show More") : t("Show Less")
                                    }
                                </Button>
                            }
                        </div>
                    </div>
                }
            </div>
        </Slide>
    )
}