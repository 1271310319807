import { commonPersistActionTypes } from "./common-persist.types";

export const setChristmasGiftAnimation = (data) => ({
    type: commonPersistActionTypes.VIEW_CHRISTMAS_GIFT_ANIMATION,
    payload: data
});

export const setIsDarkMode = (data) => ({
    type: commonPersistActionTypes.SET_IS_DARK_MODE,
    payload: data
});

export const setSplashScreenViewFlag = (data) => ({
    type: commonPersistActionTypes.SET_SPLASH_SCREEN_VIEW_FLAG,
    payload: data
});

export const setDeviceDetails = (data) => ({
    type: commonPersistActionTypes.SET_DEVICE_DETAILS,
    payload: data
});

export const setPrivacyPolicyDetails = (data) => ({
    type: commonPersistActionTypes.SET_PRIVACY_POLICY_DETAILS,
    payload: data
});