import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";
import { post } from "@okta/okta-auth-js";

const useStyles = makeStyles((theme) => ({
  centerFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  certificateRoot: {
    padding: "3em",
    userSelect: "none",
    // fontSize: isHDScreen ? '11px' : (devicePixelRatio <= 1.25 ? '16px' : devicePixelRatio <= 1.8 ? '11px' : '9px'),
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      position: 'fixed',
      width: '100vw'
    },
  },
  certificateBox: {
    height: "100%",
    backgroundColor: "#fff",
    borderRadius: "0.5em",
    padding: "1.5em 4em",
    display: "grid",
    gridTemplateRows: "12% 80% 8%",
    [theme.breakpoints.down("sm")]: {
      padding: "1em",
      alignItems: "center",
      borderRadius: "0",
      width: '100%'
    },
  },
  certificateText: {
    fontWeight: 700,
    color: palette.greyText4,
    fontSize: "2.5em",
    // marginBottom: '2em',
    display: "flex",
    alignItems: "center",
    // justifyContent: 'space-between',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2em",
    },
  },
  pdfRoot: {
    display: "grid",

    // gridTemplateColumns: '25% 75%',
    gridTemplateColumns: "auto",
    boxShadow: "rgba(0, 0, 0, 0.37) 0px 0px 59px",
    borderRadius: "0.5em",
    overflow: "hidden",
    margin: "auto",
    // transform: 'scale(0.9)'
  },
  marginBox: {
    backgroundImage: "linear-gradient(rgb(8, 0, 55), rgb(79, 0, 96))",
    padding: "1.2em",
    "& img": {
      width: "100%",
    },
  },
  contentBox: {
    // display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // gap: '0.7em',
    display: "grid",
    gridTemplateRows: "13% 8% 10% 8% 20% 8% 20% 13%",
    height: "98%",
    justifyItems: "center",
    [theme.breakpoints.down("sm")]: {
      // gap: '0.55em',
    },
  },
  date: {
    color: palette.greyText4,
    fontSize: "1.2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7em",
    },
  },
  heading: {
    color: palette.orange,
    fontWeight: 700,
    fontSize: "2em",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9em",
    },
  },
  awardedTo: {
    fontSize: "1.2em",
    fontWeight: 700,
    color: palette.greyText2,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9em",
    },
  },
  heading2: {
    color: palette.orange,
    fontWeight: 700,
    fontSize: "2.1em",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
  completionText: {
    color: palette.greyText1,
    fontWeight: 800,
    fontSize: "1.1em",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.55em",
    },
  },
  // pdfBox: {}
  headerImage: {
    textAlign: "center",
    height: "100%",
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
  },
  footerImage: {
    textAlign: "center",
    height: "100%",
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
  },
  backButton: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    boxShadow: "0px 0px 9px -2px #00000082",
    cursor: "pointer",
    marginRight: "2em",
  },
  absliRoot: {
    position: "relative",
    // backgroundImage: 'url(/images/certificates/certificate-absli.png)',
    // width: '100%',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover'
  },
  absliCoverImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  absliUserName: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: "46%",
    color: "#000",
    fontWeight: "800",
    fontSizet: "1.1em",
  },
  absliMicroskillName: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: "60%",
    color: "#000",
    fontWeight: "700",
    fontSize: "0.9em",
  },

  genericRoot: {
    position: "relative",
    "& .image-wrapper-pdfview": {
      overflow: "hidden",
    },
  },
  genericCoverImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    userDrag: "none",
    userSelect: "none",
    "-moz-user-select": "none",
    "-webkit-user-drag": "none",
    "-webkit-user-select": "none",
    "-ms-user-select": "none",
  },
  genericCertifiedDate: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: "20%",
    color: "#000",
    fontWeight: "500",
    // fontSize: '2.1em',
    fontSize: "1rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.5rem",
    },
  },
  genericUserName: {
    width: "100%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    // top: '46%',
    top: "47.5%",
    color: "#000",
    fontWeight: "800",
    // fontSize: '2.1em',
    fontSize: "1.5rem",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  genericMicroskillName: {
    width: "100%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: "65%",
    color: "#000",
    fontWeight: "700",
    fontSize: "1rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem",
    },
  },
  downloadBtnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
}));

export default useStyles;