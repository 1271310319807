import { microskillActionTypes } from "./microskill.types";

const INITIAL_STATE = {
    MODULE_LIST: null,
    recentlyLearnedIds: []
}
const microskillReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case microskillActionTypes.GET_ALL_MODULES:
            return {
                ...state,
                MODULE_LIST: action.payload
            }
        case microskillActionTypes.RECENTLY_LEARNED:
            return {
                ...state,
                recentlyLearnedIds: action.payload
            }
        default:
            return state;
    }
}

export default microskillReducer