import { createSearchParams } from "react-router-dom";
import { getChallengeLists } from "../redux/microskill/microskill.api";
import isEmpty from "./isEmpty";


const getChallengeListData = async (
    microskillId,
    challengeId,
    setNextChallengeData,
    setShowNextChallengeBtn,
    setShowCertificate,
) => {
    try {
        // Fetch challenge list data
        const moduleData = await getChallengeLists(microskillId);

        // Check if the request was not successful
        if (moduleData.status !== 200 && moduleData.status !== 201) {
            setShowNextChallengeBtn(false);
            setShowCertificate(false);
            return;
        }

        const challenges = moduleData?.data?.data?.challenges || [];
        const sortedChallenges = challenges.sort((a, b) => a.sequence - b.sequence);

        // Find the current challenge
        const currentChallenge = sortedChallenges.find(challenge => challenge.challengeId === challengeId);
        if (isEmpty(currentChallenge)) {
            console.error("Challenge not found");
            setShowNextChallengeBtn(false);
            setShowCertificate(false);
            return;
        }

        const currentSequence = currentChallenge.sequence;
        const totalChallenges = sortedChallenges.length;
        const isCompletion = moduleData?.data?.data?.microskill?.completion || false;

        // Determine the next steps based on the current sequence
        if (currentSequence === totalChallenges) {
            setShowCertificate(true);

        } else if (currentSequence < totalChallenges) {
            const nextChallenge = sortedChallenges[currentSequence];
            setNextChallengeData(nextChallenge);
            setShowNextChallengeBtn(true);
        } else {
            setShowNextChallengeBtn(false);
            if (isCompletion) {
                setShowCertificate(true);
            }
        }
    } catch (error) {
        console.error("Error in getChallengeListData:", error);
        setShowNextChallengeBtn(false);
        setShowCertificate(false);
    }
};


const handleChallengeListCardClick = (card, navigate, microskillId) => {

    console.log("card : ", card);

    const challenge = card?.Challenge;
    const challengeType = challenge?.type?.toUpperCase();
    const challengeId = card?.challengeId;
    console.log("challengeType: ", challengeType)

    console.log(
        "challenge", challenge
    )

    // if (!challenge || isEmpty(challengeType) || card?.status?.toUpperCase() === 'LOCKED') return;

    // Helper function to construct the pathname
    const buildPathname = (type, extraPath = '') => `/challenge-list/${microskillId}/${challengeId}/${type}${extraPath}`;

    console.log('buildPathname', buildPathname)
    // Construct query parameters
    const queryParams = `name=${challenge.name}`;

    console.log('query params', queryParams);
    // Map challenge types to their respective paths
    const typeToPath = {
        'GBA': buildPathname('gba'),
        'LA': buildPathname('learning-aid'),
        'SCORM': buildPathname('scorm', `/${challenge?.setting?.folderName}`),
        'SL': buildPathname('spotlight')
    };




    console.log("typeToPath: " + typeToPath)

    const pathname = typeToPath[challengeType];

    console.log("pathname : ", pathname);

    if (pathname) {
        navigate({
            pathname,
            search: `?${createSearchParams(queryParams)}`,
            replace: true
        });
    }
};



const utilityFunctions = {
    getChallengeListData,
    handleChallengeListCardClick,
}

export default utilityFunctions;