import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    formWinContainer: {
        padding: '1rem',
        height: '100%',
        display: 'grid',
        gridTemplateRows: '20% 70% 10%'
    },

    formInfoContainer: {
        display: 'grid',
        gridTemplateRows: '25% 75%'
    },

    closeContainer: {
        justifyContent: 'flex-end'
    },

    closeIcon: {
        height: '100%'
    },

    formName: {
        fontSize: '1.2rem',
        fontWeight: '1000',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem'
        },
    },

    userInfoContainer: {
        display: 'grid',
        gridTemplateRows: '30% 70%'
    },

    tickContainer: {
        display: 'flex',
        justifyContent: 'center'
    },

    userInfo: {
        paddingTop: '0.5rem',
        marginBottom: '1rem'
    },

    thankTimeupText: {
        fontSize: '1.5rem',
        letterSpacing: '0.3rem',
        textAlign: 'center',
        fontWeight: '800'
    },

    userName: {
        textAlign: "center",
        fontWeight: "900",
        fontSize: "1rem",
        marginTop: '0.5rem',
    },

    divideLine: {
        margin: '0.5rem 0 !important',
        borderBottomWidth: "5px !important",
    },

    additionalText: {
        margin: '0.5rem auto',
        fontSize: '1rem',
        fontWeight: '600',
        width: '90%',
        textAlign: 'center'
    },

    dateDisplay: {
        fontSize: '1rem',
        fontWeight: '800',
        textAlign: 'center'
    },

    totalScoreText: {
        marginTop: '1rem',
        marginBottom: '0.5rem',
        fontSize: '1.5rem',
        fontWeight: '1000',
        textAlign: 'center'
    },

    scoreText: {
        fontSize: '2rem',
        fontWeight: '1000',
        textAlign: 'center'
    },

    exitButtonContainer: {
        padding: '1rem',
        // boxShadow: '0 0 10px grey'
    },

    exitButton: {
        color: '#FFF !important',
        background: '#F4511E !important',
        width: '100%',
        fontSize: '1.3rem !important',
        fontWeight: 'bold !important',
    }
}));

export default useStyles;