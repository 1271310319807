import constants from "../constants";
import { store } from '../redux/store';
// import jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import isEmpty from "./isEmpty";
import { setBrowserTabFocus } from "../redux/common/common.actions";

const countryList = constants.COUNTRIES_LIST;
const BLUR_EVENT = 'blur';
const VISIBILTY_CHANGE = 'visibilitychange';

const getCountryCodeByDialCode = (dialCode) => {
    return countryList.find(d => d.dial_code === dialCode)
};

const getStarValue = (maxScore, scored, maxStar = constants.MAX_STAR_GBA, minimumScore = constants.MINIMUM_SCORE_GBA) => {
    if (scored <= minimumScore) {
        return 0;
    }
    if (scored > maxScore) return 3
    let starValue = 0;
    const ratio = (((scored - minimumScore) * 100) / (maxScore - minimumScore));
    if (ratio >= 66) {
        starValue = 3;
    } else if (ratio >= 34 && ratio < 66) {
        starValue = 2;
    } else if (ratio > 0 && ratio < 34) {
        starValue = 1;
    } else {
        starValue = 0;
    }
    return starValue;
};

const getMinutesFromSeconds = (time) => {
    let timeValue = time > 0 ? time : -1 * time;
    let sec = timeValue % 60;
    let min = Math.floor(timeValue / 60);

    let minValue = min.toString().length !== 1 ? min.toString() : `${min}`;
    let secValue = sec.toString().length !== 1 ? sec.toString() : `0${sec}`;
    return minValue + ':' + secValue;
};

const shuffleArray = (array) => {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
};

const getSlicedArray = (n, start, array) => {
    let tempArray = [];
    for (let k = start; k < start + n; k++) {
        let temp = array[k];
        tempArray.push(temp);
    }
    return tempArray
};

const randomNo = (min, max, excludedNoArray = []) => {
    let num;
    do {
        num = Math.floor(Math.random() * (max - min + 1) + min);
    } while (excludedNoArray.includes(num));
    console.log({ num })
    return num;
};

const remToPx = (rem) => {
    const remValue = parseFloat(rem.includes('rem') ? rem.split('rem')[0] : rem)
    const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const px = remValue * fontSize;
    return rem.includes('rem') ? `${px}px` : px;
};

const getUserName = () => {
    const tokens = store.getState()?.auth?.tokens;
    if (isEmpty(tokens)) return null

    let decoded = jwtDecode(tokens.accessToken);
    return decoded.firstName ?? decoded.username
};

const getLoginUserName = () => {
    const tokens = store.getState()?.auth?.tokens;
    if (isEmpty(tokens)) return null;

    let decoded = jwtDecode(tokens.accessToken);
    return decoded.username;
}

const getUserId = () => {
    const tokens = store.getState()?.auth?.tokens;
    if (isEmpty(tokens)) return null

    let decoded = jwtDecode(tokens.accessToken);
    return decoded.userId
};

const getCompanyId = () => {
    const tokens = store.getState()?.auth?.tokens;
    if (isEmpty(tokens)) return null

    let decoded = jwtDecode(tokens.accessToken);
    return decoded.companyId
};

const getImageDimensions = (imgUrl) => {
    return new Promise((resolve, reject) => {
        let imageWidth = null;
        let imageHeight = null;
        const img = new Image();
        img.onload = function () {
            imageWidth = this.width;
            imageHeight = this.height;
            resolve({ width: imageWidth, height: imageHeight })
        };
        img.onerror = function () {
            reject({ width: 0, height: 0 })
        };
        img.src = imgUrl;
    });
}

const getScreenResolution = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const resolution = screenWidth + 'x' + screenHeight;
    let abbreviation;

    switch (resolution) {
        case '640x480':
            abbreviation = 'VGA';
            break;
        case '800x600':
            abbreviation = 'SVGA';
            break;
        case '1024x768':
            abbreviation = 'XGA';
            break;
        case '1280x720':
            abbreviation = 'HD';
            break;
        case '1366x768':
            abbreviation = 'HD';
            break;
        case '1600x900':
            abbreviation = 'HD+';
            break;
        case '1920x1080':
            abbreviation = 'FHD';
            break;
        case '2560x1440':
            abbreviation = 'QHD';
            break;
        case '3840x2160':
            abbreviation = '4K';
            break;
        case '5120x2880':
            abbreviation = '5K';
            break;
        case '7680x4320':
            abbreviation = '8K';
            break;
        default:
            // Check for resolutions falling in between categories
            if (screenWidth >= 2560 && screenWidth < 3840 && screenHeight >= 1440 && screenHeight < 2160) {
                abbreviation = '2.5K';
            } else if (screenWidth >= 3840 && screenWidth < 5120 && screenHeight >= 2160 && screenHeight < 2880) {
                abbreviation = '5K';
            } else if (screenWidth >= 1080 && screenWidth <= 1280 && screenHeight >= 618 && screenHeight < 720) {
                // console.log("HD screen")
                abbreviation = 'HD';
            } else {
                abbreviation = 'Unknown';
            }
    }
    return abbreviation;
};

const getGreatestClosetNumberFromAnArray = (array, target) => {
    let closest = Infinity;

    for (let i = 0; i < array.length; i++) {
        let num = array[i];
        // if (num === target) continue;  // Skip if the number is equal to the target
        if (num >= target && num < closest) closest = num;
    }
    return closest === Infinity ? null : closest;
};

const getRandomAlphabet = () => {
    // ASCII code range for uppercase letters: 65 (A) to 90 (Z)
    const minCharCode = 65;
    const maxCharCode = 90;

    // Generate a random ASCII code within the range
    const randomCharCode = Math.floor(Math.random() * (maxCharCode - minCharCode + 1)) + minCharCode;

    // Convert the ASCII code to its corresponding character (alphabet letter)
    const randomAlphabet = String.fromCharCode(randomCharCode);

    return randomAlphabet;
}

const getMaxLifeForGba = ({ gbaType, maxQuestion, questionsInGba, maxLife }) => {
    let life = 4;
    if (gbaType === 'TF_CrazyCars' || gbaType === 'TF_BuildingBlocks'
        || gbaType === 'TF_TastyTruth' || gbaType === 'TF_SoundRight'
        || gbaType === 'MCQ_TikiMonkey' || gbaType === 'MCQ_MasterBlaster' || gbaType === 'MCQ_Football') {
        life = Math.floor(maxLife * (questionsInGba / maxQuestion))

    } else if (gbaType === 'MA_BalloonPop' || gbaType === 'MA_SpaceRocks'
        || gbaType === 'MTRR_PopcornMatch' || gbaType === 'MTRR_TrashTrivia' || gbaType === 'MTRR_CrackerMania'
        || gbaType === 'MA_RevealThePicture') {
        life = Math.round(maxLife * (questionsInGba / maxQuestion))
    }
    return life
}

const getQuestionAndLifeForGba = ({ gbaType }) => {
    let data = { maxLife: 4, maxQuestion: 10 }
    if (gbaType === 'TF_CrazyCars' || gbaType === 'TF_BuildingBlocks'
        || gbaType === 'TF_TastyTruth' || gbaType === 'TF_SoundRight'
        || gbaType === 'MCQ_TikiMonkey') {
        data = { maxLife: 4, maxQuestion: 10 }
    } else if (gbaType === 'MTRR_PopcornMatch' || gbaType === 'MTRR_TrashTrivia' || gbaType === 'MTRR_CrackerMania') {
        data = { maxLife: 5, maxQuestion: 15 }
    } else if (gbaType === 'MA_BalloonPop' || gbaType === 'MA_SpaceRocks' || gbaType === 'MA_RevealThePicture') {
        data = { maxLife: 4, maxQuestion: 12 }
    }
    return data
}

export const isNativeApp = () => {
    return store?.getState()?.common?.isNativeApp
};

export const getHeaderHeight = () => {
    let headerHeight = store?.getState()?.common?.isNativeApp ? constants.HEADER_HEIGHT_MOBILE : constants.HEADER_HEIGHT;
    return headerHeight
};

export const browserZoom = () => {
    return Math.round(window.devicePixelRatio * 100);
}

const getFileType = (fileName) => {
    const tempArr = fileName.split('.')
    const fileType = tempArr[tempArr.length - 1].toLowerCase();
    if (constants.AUDIO_EXTENSIONS_TYPE.includes(fileType)) return (`audio/${fileType}`)
    if (constants.VIDEO_EXTENSIONS_TYPE.includes(fileType)) return (`video/${fileType}`)
    if (constants.IMAGE_EXTENSIONS_TYPE.includes(fileType)) return (`image/${fileType}`)
    if (constants.PDF_EXTENSIONS_TYPE.includes(fileType)) return (`application/${fileType}`)
    if (constants.DOC_EXTENSIONS_TYPE.includes(fileType)) {
        if (fileType.includes('xls')) return (`application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`)
        if (fileType.includes('doc')) return (`application/vnd.openxmlformats-officedocument.wordprocessingml.document`)
        if (fileType.includes('ppt')) return (`application/vnd.openxmlformats-officedocument.presentationml.presentation`)
    }
    return null
}

const getFileTypeExtension = (fileType) => {
    if (fileType.includes('webm')) return (`webm`)
    else if (fileType.includes('mp4')) return (`mp4`)
    else if (fileType.includes('mpeg')) return (`mpeg`)
    else if (fileType.includes('mp3')) return (`mp3`)
    else if (fileType.includes('ogg')) return (`ogg`)
    else if (fileType.includes('wav')) return (`wav`)
    return fileType.includes('audio') ? 'mp3' : 'mp4'
}

const handleVisibilityChange = () => {
    if (document.hidden || document.webkitHidden) store?.dispatch(setBrowserTabFocus(false));
    else store?.dispatch(setBrowserTabFocus(true));
}

const addVisibilityEventListeners = () => {
    document.addEventListener(VISIBILTY_CHANGE, handleVisibilityChange);
    window.addEventListener(BLUR_EVENT, handleVisibilityChange);
}
const removeVisibilityEventListeners = () => {
    document.removeEventListener(VISIBILTY_CHANGE, handleVisibilityChange);
    window.removeEventListener(BLUR_EVENT, handleVisibilityChange);
}

const getDeviceDetails = (selectors, data) => {
    const details = {
        platformDetails: {
            brands: [
                {
                    brand: selectors.browserName,
                    version: selectors.browserVersion
                }
            ],
            mobile: selectors.isMobile,
            platform: selectors.osName,
            version: selectors.osVersion
        },
        data
    }
    return details;

}


const validToken = (tkn) => {
    const currentTimestamp = new Date().getTime() / 1000;
    if (isEmpty(tkn?.accessToken)) return false
    let decoded = jwtDecode(tkn.accessToken);
    if (decoded.exp < currentTimestamp) return false;
    else return true;
}

const parseJSONFromFlutter = (str) => {
    let data = str.split(', ');
    data[0] = data[0].slice(1);
    data[data.length - 1] = data[data.length - 1].slice(0, -1);
    data = data.reduce((acc, item) => {
        const [key, value] = item.split(': ');
        acc[key] = value;
        return acc;
    }, {});

    return JSON.parse(JSON.stringify(data));
};

function objectify(records, mapping_key) {
    let o = {};

    if (!Array.isArray(records)) return o;

    if (records.length === 0) return o;

    for (let record of records) {
        let key = record[mapping_key];
        o[key] = record;
    }

    return o;
}

function truncateString(str, maxLength = 30) {
    if (isEmpty(str)) return "";
    if (str.length <= maxLength) return str;
    else return str.slice(0, maxLength).trim() + '...';
}

const getSelectLabel = ({ type, defaultText, selected, dataObject }) => {
    let newLabel = defaultText || '';
    if (type === 'single') {
        if (selected.length) newLabel = truncateString(dataObject[selected[0]]?.name, 18);
        return newLabel
    }
    if (type === 'multiple') {
        if (selected.length) {
            newLabel = (selected.length > 1) ?
                `${truncateString(dataObject[selected[0]]?.name, 14)} & ${selected.length - 1} more` :
                truncateString(dataObject[selected[0]]?.name, 22)
        }
        return newLabel
    }
    return newLabel
}

const fancyTimeFormat = (d) => {
    // Hours, minutes and seconds
    const hrs = Math.floor(d / 3600);
    const mins = Math.floor(d % 3600 / 60);
    const secs = Math.floor(d % 3600 % 60);

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";
    if (hrs > 0) ret += "" + (hrs < 10 ? `${hrs}` : hrs) + ":";
    ret += "" + (mins < 10 ? `0${mins}` : mins) + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

const commonUtil = {
    getCountryCodeByDialCode,
    getStarValue,
    getMinutesFromSeconds,
    shuffleArray,
    getSlicedArray,
    randomNo,
    remToPx,
    getUserName,
    getUserId,
    getCompanyId,
    getImageDimensions,
    getScreenResolution,
    getGreatestClosetNumberFromAnArray,
    browserZoom,
    getRandomAlphabet,
    getMaxLifeForGba,
    getQuestionAndLifeForGba,
    getLoginUserName,
    getFileType,
    getFileTypeExtension,
    handleVisibilityChange,
    addVisibilityEventListeners,
    removeVisibilityEventListeners,
    getDeviceDetails,
    validToken,
    truncateString,
    getSelectLabel,
    objectify,
    fancyTimeFormat,
    parseJSONFromFlutter
}

export default commonUtil