import { makeStyles } from "@mui/styles";
import commonUtil from "../../../utils/commonUtil";

const isHDScreen = commonUtil.getScreenResolution() === 'HD';

const useStyle = makeStyles((theme) => ({
    BuildingBlocksRoot: {
        color: "#fff",
        boxSizing: "border-box",
        position: "relative",
        //transition: "0.7s",
        backgroundSize: '600% 100%',
        animation: '$animateGameLandingPage 5s ease infinite',
        height: '100%',
    },
    startPage: {
        height: "100%",

    },
    container: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: "100%",
        overflow: 'hidden',
        touchAction: 'none',
        opacity: '1',
        zIndex: '2',
        position: 'relative'
    },
    "@keyframes animateGameLandingPage": {
        '0%': { backgroundPosition: '0%' },
        '100%': { backgroundPosition: '100%' }
    },
    containerBox: {
        display: 'grid',
        height: '100%',
        gridAutoRows: '1.5% 25% 73.5%',
    },
    progressBarContainer: {
        position: "relative",
        height: "9px",
        top: "-8%",
        backgroundColor: '#fff',
        zIndex: "2",
        width: (isMobile) => {
            return `calc(100% - ${isMobile ? '0px' : '0px'})`
        },
        borderRadius: (isMobile) => {
            return `${isMobile ? '0px' : '0px'}`
        },
        // margin: (isMobile) => { return `${isMobile ? "" : "0.3rem auto"}` },
        overflow: 'hidden',
    },
    progressBar: {
        position: "absolute",
        top: "0",
        left: "0",
        height: "9px",
        backgroundColor: "#ffd042",
        width: '0',
        transition: '.4s',
    },
    // questionBox container
    qstnContainer: {
        cursor: 'default',
        height: "100%",
        maxHeight: "130%",
        // padding: '0.5rem 0.5rem 0 0.5rem',
        transform: "translateY(-200px)",
        animation: "$translateQstnContainer 1s ease 0s 1 normal forwards running",
        [theme.breakpoints.down("sm")]: {
            // padding: '0.5rem 0.5rem 0 0.5rem',
        },
        opacity: '0.8',
    },
    "@keyframes translateQstnContainer": {
        "0%": { transform: "translateY(-200px)" },
        "100%": { transform: "translateY(0)" }
    },
    qstnBox: {
        // borderRadius: "10px",
        height: "100%",
        fontSize: "1.2em",
        // boxShadow: '0px 10px 20px #0000004d',
        background: '#1D1D1D 0% 0% no-repeat padding-box',
        display: 'grid',
        gridAutoRows: '20% 80%',
    },
    assetBox: {
        display: "grid",
        // gridTemplateColumns: "1fr 1fr",
        gridTemplateColumns: "1fr 1fr 1fr",
        padding: "12px",
        alignItems: 'center'
    },
    lifeText: {
        display: "flex",
        alignItems: "center",
        // padding: "0 15px",
        // fontSize: '1em',
        [theme.breakpoints.down("sm")]: {
            fontSize: 'clamp(1rem, 1.2vw, 1.4rem)',
        },
    },
    timerText: {
        display: "flex",
        alignItems: "center",
        // padding: "0 15px",
        // width: '45%',
        //justifyContent: "right",
        justifyContent: "end",
        fontSize: '1em',
        [theme.breakpoints.down("sm")]: {
            fontSize: 'clamp(1rem, 1.2vw, 1.4rem)',
        },
    },
    icon: {
        width: "1em",
        height: "1em",
        margin: "0 5px",
        [theme.breakpoints.down("sm")]: {
            width: "12px",
            height: "12px",
        },
    },
    timerIcon: {
        width: "1em",
        height: "1em",
        margin: "0 5px",
        [theme.breakpoints.down("sm")]: {
            width: "14px",
            height: "14px",
        },
    },
    qstn: {
        transition: '.9s',
        padding: "2%",
        textAlign: "center",
        flexDirection: "column",
        scale: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Open Sans',
        wordBreak: "break-word",
        // fontFamily: 'Montserrat',
        // fontWeight: 700,
        fontSize: isHDScreen ? '0.6em' : '1em',
        opacity: '1',
        transition: '.8s',
        fontWeight: 'bold',
        [theme.breakpoints.down("sm")]: {
            fontSize: isHDScreen ? '0.75rem' : '1rem',
            // fontSize: 'clamp(1.2rem, 1.2vw, 1.5rem)',
        },
    },
    gamePlayBox: {
        display: 'grid',
        gridAutoRows: '80% 20%',
        // padding: '1.5rem',
        width: "100%",
        position: 'relative',
    },
    dropContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: "column",
        justifyContent: "flex-end",
        overflow: 'hidden',
        zIndex: '1',
        height: '100%',
        padding: '1rem',
        // width: '25cqi',
        containerType: 'inline-size',
        // '& div:nth-child(odd)': {
        //     animation: '$rotateClockWise 30s linear infinite',
        //     animationDelay: '1.2s'
        // },
        // '& div:nth-child(even)': {
        //     animation: '$rotateAntiClockWise 30s linear infinite',
        //     animationDelay: '1.2s'
        // },
        // "& div:last-child": {
        //     // border: '3px dashed #ffffff',
        //     animation: 'auto',
        //     position: 'relative'
        // },
        "& > *": {
            // position: 'absolute',
            // top: '50%',
            // left: '50%',
            // height: '200px',
            // width: '200px',
            // border: '1.5px dashed #ffffff',
            // borderRadius: '50%',
            // transform: 'translate(-50%, -50%)',
            // // boxShadow: '0 0 60px -40px #000000',
            // backgroundColor: '#fcf804',
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center',
            // transition: '.8s'
        },
    },
    lifeCircle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        height: '200px',
        width: '200px',
        border: '1.5px dashed #ffffff',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        // boxShadow: '0 0 60px -40px #000000',
        backgroundColor: '#fcf804',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '.8s'
    },
    bottomContainer: {
        width: '100%'
    },
    "@keyframes rotateClockWise": {
        "0%": { transform: "translate(-50%, -50%) rotate(0deg)", },
        "100%": { transform: "translate(-50%, -50%) rotate(360deg)" },
    },
    "@keyframes rotateAntiClockWise": {
        "0%": { transform: "translate(-50%, -50%) rotate(360deg)", },
        "100%": { transform: "translate(-50%, -50%) rotate(0deg)" },
    },
    playPauseBtn: {
        width: '35%'
    },
    optionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        opacity: '1',
        transition: '.8s',
        position: 'relative',
        zIndex: '5',
        '& > *': {
            fontSize: '0.8em',
            padding: '0.2rem 0.6rem',
            border: 'none',
            background: '#D6619F',
            margin: '0 1rem',
            color: '#fff',
            borderRadius: '5px',
            width: '40%',
            // fontSize: 'clamp(0.875rem, 1vw, 1.5rem)',
            minHeight: '40px',
            transition: '.5s',
            webkitTransition: '.5s',
        },
        '& > button': {
            cursor: 'pointer',
        }
    },
    dropHereText: {
        userSelect: 'none',
        fontFamily: 'Montserrat',
        fontSize: '1.1em',
        border: 'none !important',
        transform: 'scale(1)',
        transition: '.5s',
        animation: 'auto !important',
        textAlign: 'center',
        // textShadow: '0px 0px 3px #000000',
        width: '100%',
        wordBreak: 'break-word',
        padding: '0rem 0.8rem',
        color: '#f4511e'
    },
    animateClockWiseRotation: {
        animation: '$rotateClockWise 0.8s linear infinite'
    },
    checkMark: {
        transition: '.6s',
        opacity: '0',
        width: '60%',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    crossMark: {
        transition: '.6s',
        opacity: '0',
        width: '50%',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    gradientOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        opacity: '0',
        transition: '.5s ease',
        zIndex: '1',
        backgroundImage: 'linear-gradient(#800e315e, #f63b348c)',
    },
    incorrectGradientOverlayAnimation: {
        animation: '$incorrectGradientOverlayAnimation 2s ease-in-out 0.1s 1 normal forwards running'
    },
    '@keyframes incorrectGradientOverlayAnimation': {
        '0%': { opacity: '0' },
        '10%': { opacity: '1' },
        '80%': { opacity: '1' },
        '100%': { opacity: '0' },
    },
    incorrectGradientOverlayAnimationInfinite: {
        animation: '$incorrectGradientOverlayAnimationInfinite 1.5s ease-in-out 0s infinite normal forwards running'
    },
    '@keyframes incorrectGradientOverlayAnimationInfinite': {
        '0%': { opacity: "0" },
        "20%": { opacity: "1" },
        "50%": { opacity: "1" },
        "75%": { opacity: "1" },
        "100%": { opacity: '0' }
    },
    mainText: {
        animation: "$zoomInOut 1s ease 0s infinite normal forwards running",
    },
    "@keyframes zoomInOut": {
        "0%": { transform: "scale(1)" },
        "50%": { transform: "scale(0.95)" },
        "100%": { transform: "scale(1)" },
    },
    loadingText: {
        color: '#cb3ef3',
    },
    pauseOverlay: {
        height: 'inherit',
        width: 'inherit'
    },

    imagesBlocks: {
        width: "100%",
        position: "absolute",
        left: '0%',
        top: '-40%',
        opacity: "0",

        // [theme.breakpoints.down('sm')]: {
        //     width: '88vi',   
        // }

    },
    imagesAnimation: {
        opacity: '1',
        transition: "1s",
        top: '0%',
        [theme.breakpoints.down('md')]: {
            top: '1%'
        },
        [theme.breakpoints.down('sm')]: {
            top: '0%',
        }
        // [theme.breakpoints.down('sm')]: {
        //     top: '26vh'
        // }
    },
    imagesContainer: {
        backgroundImage: "url(/images/gba/Building_Blocks_base_station.svg)",
        height: '60cqi',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'center',
        position: 'relative',
        // [theme.breakpoints.down('md')]: {
        //     height: '57%',
        // },
        [theme.breakpoints.down('sm')]: {
            height: '60cqi'
        },
    },
    questionNo: {
        textAlign: 'center',
        fontWeight: 600,
        color: '#F5CF38',
        letterSpacing: '0.2rem'
    },
    correctIncorrectText: {
        fontFamily: 'MotionControl !important',
        position: 'absolute',
        // fontSize: '2.5rem',
        textAlign: 'center',
        width: '100%',
        zIndex: 2,
        transition: '0.5s',
        opacity: '0',
        width: '90%',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '2rem',
        display: 'flex',
        justifyContent: 'center',
    },
    thickText: {
        fontFamily: 'MotionControl',
        fontSize: '3em',
        fontWeight: 900,
        transform: 'scale(0)',
        transformOrigin: 'center center',
        transition: 'transform 0.5s linear',
        position: 'absolute',
        color: theme.palette.white
    }
}));

export default useStyle;
