import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams, useSearchParams, createSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Bar } from 'react-chartjs-2';
import StarRatingComponent from 'react-star-rating-controlled-component';
import Lottie from "react-lottie-player";

// mui
import { Button } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// components
import SpotlightResultLoader from "../../components/spotlight/spotlight-result-loader";
import CustomReactPlayer from "../../components/custom-react-player";
import StatusAccordion from "../../components/status-accordion";
import DialogAlert from "../../components/dialog-alert";
import RecommendedVideoPreview from "../../components/spotlight/recomended-video-preview";

// redux
import { getSpotlightFinalResponse, submitSpotlight } from "../../redux/spotlight/spotlight.api";
import { getChallengeData, getChallengeLists } from "../../redux/microskill/microskill.api";
import { openToast, setLearnerJourneyEnded } from "../../redux/common/common.actions";
import { getLearnerJourneyStatus, submitEndChallengeAttempt } from "../../redux/gba/gba.api";
import { handleGbaScreenState } from "../../redux/gba/gba.actions";

// utils
import isEmpty from "../../utils/isEmpty";
import commonUtil from "../../utils/commonUtil";
import eventBus from "../../utils/eventBus";
import utilityFunctions from "../../utils/utilityFunctions";

// constants
import constants from "../../constants";

// styles
import useStyles from "./index.styles";
import palette from "../../theme/palette";
import { useTranslation } from "react-i18next";

// lotties
import clarityLottie from '../../lottie-assets/clarity.json'
import toneLottie from '../../lottie-assets/tone.json';
import CORRECT_TICK_LOTTIE from '../../lottie-assets/Form_Submission_lottie.json';



const isKeyExist = (object, key) => {
    if (isEmpty(object)) return false;
    let bool = Object?.keys(object)?.includes(key);
    return bool
}
const STAR_WHITE = "/images/icons/star_white_2.png";
const STAR_HIGHLIGHTED = "/images/icons/star_orange.png";
const HEIGHT_MARGIN = 4;
const KEY_WORD_MATCH_TAB_COUNT = 6;
const Y_MAX = 91;
const BAR_RADIUS = 12;

const DEFAULT_OPTIONS = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        labels: {
            display: false
        },
        title: {
            display: false,
        },
    },
    tooltips: {
        mode: 'index',
        intersect: false
    },
    legend: {
        display: false,
    },
};
const SPEECH_RATE_OPTIONS = {
    ...DEFAULT_OPTIONS,
    scales: {
        x: {
            display: false,
        },
        y: {
            display: false,
            reverse: false,
            max: Y_MAX,
        }
    },
    tooltips: {
        mode: 'index',
        intersect: false
    },
    legend: {
        display: false,
    },
};
const TONE_OPTIONS = {
    ...DEFAULT_OPTIONS,
    scales: {
        x: {
            stacked: true,
            display: false,
        },
        y: {
            stacked: true,
            display: false,
            // max: 350,
        },
    },
    legend: {
        display: false,
    },
};
const CLARITY_OPTIONS = {
    ...DEFAULT_OPTIONS,
    scales: {
        x: {
            display: false,
        },
        y: {
            display: false,
            reverse: false,
            max: Y_MAX,
        }
    },
};
const CHART_DATA_SETTINGS = {
    borderRadius: BAR_RADIUS,
    borderSkipped: false,
};
const keywordMatchDataLabels = [
    {
        id: '1',
        name: 'EXACT MATCH',
        count: '2',
        backgroundColor: '#2A9A1A',
        value: "exact-match"
    },
    {
        id: '2',
        name: 'SIMILAR MATCH',
        count: '2',
        backgroundColor: "#93e600",
        value: "similar-match"
    },
    {
        id: '3',
        name: 'NO MATCH',
        count: '2',
        backgroundColor: '#FF0000',
        value: "no-match"
    },
];
const BACK_BTN_EVENT = constants.HEADER_BACK_BUTTON_CLICK_EVENT;
const SCREEN_STATE = constants.SPOTLIGHT_SCREEN_STATE;

const SpotlightResult2 = (props) => {
    // const { isMobile } = props;
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyles(isMobile);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { state } = location;
    console.log({ state });
    const { totalAttempts } = state;
    let searchParam = isEmpty(location.search.split('?')[1]) ? '' : `&${location.search.split('?')[1]}`

    let [searchParams, setSearchParams] = useSearchParams();
    let attemptId = searchParams.get('attemptId');
    let journeyId = searchParams.get('journeyId');
    const { microskillId, challengeId, id, jobId } = useParams();
    const [spotlightResultData, setSpotlightResultData] = useState();
    const [graphData, setGraphData] = useState();
    const [criteria, setCriteria] = useState(state?.challengeData.criteria);
    const [keywordMatchTabCount, setKeywordMatchTabCount] = useState(KEY_WORD_MATCH_TAB_COUNT);
    const [speechRateGraphData, setSpeechRateGraphData] = useState({});
    const [toneGraphData, setToneGraphData] = useState({});
    const [clarityGraphData, setClarityGraphData] = useState({});
    const [userSpotlightResponseId, setUserSpotlightResponseId] = useState();
    const [isDialogueOpen, setDialogueOpen] = useState(false);
    const dialogueContentDefault = "You have successfully submitted the Roleplay!";
    const [dialogueContent, setDialogueContent] = useState(dialogueContentDefault);
    const [noSpeechDetected, setNoSpeechDetected] = useState(false);
    const [fSize, setFSize] = useState(1);
    const [isPreviewVideo, setIsPreviewVideo] = useState(false);
    const [nextChallengeData, setNextChallengeData] = useState({});
    const [showNextChallengeBtn, setShowNextChallengeBtn] = useState(false);
    const [showCertificate, setShowCertificate] = useState(false);
    const { handleChallengeListCardClick } = utilityFunctions;
    const [isSpotlightLastAttempt, setIsSpotlightLastAttempt] = useState(false);
    const [challengeData, setChallengeData] = useState(state?.challengeData);
    const lastAttemptDialogText = 'This is your last attempt! You will not be allowed to record again after this attempt!'

    console.log('challenge Data : : : : : : : : : : ', challengeData);
    console.log('totalAttemtps : : : :: : : : : ', totalAttempts);

    const activeTopVideo = useRef({});
    const { t } = useTranslation();

    const sleep = (ms) => new Promise(resolve => setTimeout(() => resolve(), ms));

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }
    window.addEventListener("resize", resFont);

    const backBtnListnerFunction = (data) => {
        navigate(-1)
    };

    useEffect(() => {
        eventBus.on(BACK_BTN_EVENT, backBtnListnerFunction);
        return () => {
            eventBus.remove(BACK_BTN_EVENT, backBtnListnerFunction);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getChallengeDataAsync = async () => {
        let respData = await getChallengeData(microskillId, challengeId);
        setCriteria(respData?.data?.data?.SpotLight?.criteria);
    };

    const getServeResult = async () => {
        const respData = await getSpotlightFinalResponse(id);
        if (respData?.status === 200 || respData?.status === 201) {
            let apiResponseData = respData?.data?.data
            if (apiResponseData.userSpotLightResponse.jobStatus.toUpperCase() === 'PROCESSING') {
                setTimeout(() => {
                    getServeResult();
                }, 2000);
                return
            } else if (apiResponseData.userSpotLightResponse.jobStatus.toUpperCase() === 'ERRORED') {
                setDialogueContent("No speech detected.");
                setDialogueOpen(true);
                setNoSpeechDetected(true);
                return
            }

            setSpotlightResultData(apiResponseData);
            setGraphData(apiResponseData?.userSpotLightResponse?.response);
            setUserSpotlightResponseId(apiResponseData?.userSpotLightResponse.id);

            // // code to get video path is not exists
            if (isEmpty(apiResponseData?.userSpotLightResponse?.videoPath)) {
                setTimeout(() => {
                    getServeResult();
                }, 2000);
                return
            }

            if (isCriteriaContains(criteria, 'tone') || isCriteriaContains(criteria, 'clarity')) {
                if (apiResponseData?.userSpotLightResponse?.jobStatus?.toUpperCase() === 'PARTIAL_COMPLETED') {
                    setTimeout(() => {
                        getServeResult();
                    }, 3000)
                }
                return
            }

        }
    };

    useEffect(() => {
        (async function () {
            resFont();
            if (isEmpty(criteria)) getChallengeDataAsync();
            getServeResult();
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isEmpty(graphData)) return;

        if (isKeyExist(graphData, "speechRate")) {
            if (isEmpty(graphData.speechRate.data)) return;
            if (isEmpty(graphData.speechRate.data)) return;
            SPEECH_RATE_OPTIONS.scales.y.max = Math.max.apply(Math, graphData?.speechRate?.data) * 1.2;
            setSpeechRateGraphData({
                labels: [...graphData.speechRate.data],
                datasets: [
                    {
                        data: [...graphData.speechRate.data],
                        backgroundColor: palette.voilet3,
                        ...CHART_DATA_SETTINGS
                    },
                ],
            })
        }

        if (isKeyExist(graphData, "tone")) {
            setToneGraphData(
                {
                    labels: Array.from(Array(Object.keys(graphData?.tone?.data).length), (e, i) => ('')),
                    // labels: ['', '', '', '', '', '', ''],
                    datasets: [
                        {
                            label: 'Positive',
                            data: graphData?.tone.data.map(item => (Math.round(item.positive * 100))),
                            backgroundColor: graphData?.tone?.labels.find((item) => item.value === 'positive')?.backgroundColor,
                            // borderRadius: {
                            //   bottomLeft: BAR_RADIUS,
                            //   bottomRight: BAR_RADIUS,
                            // },
                            borderSkipped: false
                        },
                        {
                            label: 'Neutral',
                            data: graphData?.tone.data.map(item => (Math.round(item.neutral * 100))),
                            backgroundColor: graphData?.tone?.labels.find((item) => item.value === 'neutral')?.backgroundColor,
                            // borderRadius: BAR_RADIUS,
                            // borderSkipped: false
                        },
                        {
                            label: 'Negative',
                            data: graphData?.tone.data.map(item => (Math.round(item.negative * 100))),
                            backgroundColor: graphData?.tone?.labels.find((item) => item.value === 'negative')?.backgroundColor,
                            // borderRadius: {
                            //   topLeft: BAR_RADIUS,
                            //   topRight: BAR_RADIUS,
                            // },
                            borderSkipped: false
                        },
                    ],
                }
            )
        }

        if (isKeyExist(graphData, "clarity")) {
            CLARITY_OPTIONS.scales.y.max = Math.max.apply(Math, graphData?.clarity?.data) * 1.2;
            setClarityGraphData(
                {
                    labels: [...graphData.clarity.data],
                    datasets: [
                        {
                            data: [...graphData.clarity.data],
                            ...CHART_DATA_SETTINGS,
                            backgroundColor: [...graphData.clarity.data].map((value) => value < 0 ? '#FF0000' : '#2A9A1A'),
                        },
                    ],
                }
            )
        }

    }, [graphData]);

    const handleKeywordShowMoreClick = (type) => {
        if (type === 'show-more') {
            setKeywordMatchTabCount(graphData?.keywordMatch.data.length);
        } else {
            setKeywordMatchTabCount(KEY_WORD_MATCH_TAB_COUNT);
        }
    };

    const getDurationStyle = () => {
        const durationData = graphData?.duration;
        const actualDuration = parseInt(durationData.actualDuration);
        const durationTaken = parseInt(durationData.durationTaken);

        let recommendedWidthPer = 85;
        let ratio = (durationTaken / actualDuration);
        let actualPer = (recommendedWidthPer * ratio);
        if (actualDuration >= durationTaken) {
            return {
                recommendedWidth: `${recommendedWidthPer}%`,
                recommendedTextDisplayLeft: ratio > 0.5 ? '0' : 'auto',
                recommendedTextDisplayRight: ratio > 0.5 ? 'auto' : '15%',
                actualDurationLeft: `${actualPer}%`,
                textTransform: ratio > 0.2 ? 'translateX(-50%)' : 'none'
            }
        }
        return {}
    };

    // const handleSubmitClick = async (btnText) => {

    //     setDialogueOpen(true);
    //     const respData = await submitSpotlight(userSpotlightResponseId);
    //     await sleep(3000);

    //     submitEndChallengeAttempt({
    //         completion: true,
    //         status: "COMPLETED",
    //         journeyId,
    //         microskillId,
    //         challengeId,
    //         attemptId,
    //         timespent: graphData?.duration.durationTaken,
    //     });

    //     if (!isEmpty(journeyId) && journeyId !== "null") {
    //         let reqData = {
    //             microskillId,
    //             challengeId,
    //             journeyId,
    //         }

    //         getLearnerJourneyStatus(reqData).then(res => {
    //             dispatch(setLearnerJourneyEnded(res?.data?.data?.journeyEnded));
    //         });
    //     }

    //     if (respData.status === 200 || respData.status === 201) {

    //         /**
    //          * Here btnText shows which button should be clicked either 'Submit & Next Challenge' or 
    //          * 'Submit & Show Certificate'.
    //          */

    //         if (btnText === 'next challenge') {
    //             handleNextBtnClick();
    //         } else {
    //             handleCertificateClick();
    //         }

    //         setDialogueOpen(false);
    //         return;

    //     } else {
    //         dispatch(openToast({ isToastOpen: true, toastMessage: 'Something went wrong while submitting Spotlight!', toastSeverity: 'error' }));
    //     }
    // };


    const handleSubmitClick = async (btnText) => {

        try {
            setDialogueOpen(true);

            // Submit the spotlight response and wait for it to complete
            const respData = await submitSpotlight(userSpotlightResponseId);

            // Consider if this sleep is necessary for your use case
            await sleep(3000);

            // Complete the challenge attempt
            await submitEndChallengeAttempt({
                completion: true,
                status: "COMPLETED",
                journeyId,
                microskillId,
                challengeId,
                attemptId,
                timespent: graphData?.duration?.durationTaken,
            });

            // Fetch learner journey status if journeyId is valid
            if (journeyId && journeyId !== "null") {
                const reqData = { microskillId, challengeId, journeyId };
                const journeyStatus = await getLearnerJourneyStatus(reqData);
                dispatch(setLearnerJourneyEnded(journeyStatus?.data?.data?.journeyEnded));
            }

            // Handle the response from submitting spotlight
            if ([200, 201].includes(respData.status)) {
                btnText === 'next challenge' ? handleNextBtnClick() : handleCertificateClick();
            } else {
                dispatch(openToast({
                    isToastOpen: true,
                    toastMessage: 'Something went wrong while submitting Spotlight!',
                    toastSeverity: 'error',
                }));
            }
        } catch (error) {
            console.error("Error in handleSubmitClick:", error);
            dispatch(openToast({
                isToastOpen: true,
                toastMessage: 'An unexpected error occurred!',
                toastSeverity: 'error',
            }));
        } finally {
            setDialogueOpen(false);
        }
    };


    const handleConfirmBtnClick = () => {
        setDialogueOpen(false);
        // return noSpeechDetected ? navigate(-1) : navigate(`/challenge-list/${microskillId}`);
    };

    const handleBackButtonClick = () => {
        navigate(-1);
    };

    const starRatings = commonUtil.getStarValue(spotlightResultData?.maxScore, graphData?.userScore, 3, 0);

    const isCriteriaContains = (criteria, key) => {
        if (isEmpty(criteria)) return false;
        let findObj = criteria.find((item) => (item.name?.toUpperCase() === key?.toUpperCase()));
        let isContains = !isEmpty(findObj) && (findObj?.check === 1);
        return isContains;
    }


    const getChallengeListData = async () => {

        const moduleData = await getChallengeLists(microskillId);

        if (moduleData.status === 200 || moduleData.status === 201) {

            let data = moduleData?.data?.data;
            let sortedData = data?.challenges.sort((a, b) => a.sequence - b.sequence);
            const currentChallenge = sortedData.filter((challenge) => challenge.challengeId === challengeId)[0]
            const currentSequence = currentChallenge.sequence;
            const isCompletion = moduleData?.data?.data?.microskill?.completion;

            if (parseInt(currentSequence) === sortedData.length) {
                setShowNextChallengeBtn(false);
                return;
            }

            if (parseInt(currentSequence) < sortedData?.length) {

                const nextChallenge = sortedData[currentSequence];
                setNextChallengeData(nextChallenge);
                setShowNextChallengeBtn(true);
            } else {

                if (!isCompletion && parseInt(currentSequence) >= sortedData?.length) {
                    setShowNextChallengeBtn(false);
                    return;
                }
            }
        } else {
            setShowNextChallengeBtn(false);
        }
    }

    useEffect(() => {
        getChallengeListData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [microskillId]);

    const handleNextBtnClick = () => {
        dispatch(handleGbaScreenState(constants.GBA_SCREEN_STATE[0]));
        handleChallengeListCardClick(nextChallengeData, navigate, microskillId);
    };

    const handleCertificateClick = () => {
        if (showNextChallengeBtn) return;
        navigate(`/challenge-list/${microskillId}/certificate`);
    };

    const handleRecordAgainClick = () => {

        const maxAttempts = challengeData?.maxAttempts;
        const remainingAttempts = maxAttempts - totalAttempts;

        if (remainingAttempts === 1) {
            setIsSpotlightLastAttempt(true);
        } else {
            navigate(`/challenge-list/${microskillId}/${challengeId}/spotlight?name=${spotlightResultData.name}`, { state: { spotlightScreenState: SCREEN_STATE[3] } });
        }
    }

    return (
        <div className={classes.spotlightResultRoot} style={constants.FULL_HEIGHT_CENTER}>
            {
                !isMobile && jobId === 'undefined' && (
                    <div className={`${classes.backBtnBox} ${classes.centerFlex}`} onClick={handleBackButtonClick}>
                        <KeyboardBackspaceIcon sx={theme => ({ color: theme.palette.white })} />
                    </div>
                )
            }
            <div className={`${classes.SpotlightResultBox} scrollbar`}
                style={{
                    height: `calc(${constants.FULL_HEIGHT_VALUE} - ${isMobile ? '0' : HEIGHT_MARGIN}rem)`,
                    fontSize: `${fSize}rem`
                    // marginTop: `${HEIGHT_MARGIN / 2}rem`
                }}>
                <div className={`${classes.SpotlightResultContainer}`}>
                    {
                        isEmpty(graphData) ? (
                            !isEmpty(criteria) && (
                                <SpotlightResultLoader data={criteria} />
                            )
                        ) : (
                            <div className={`${classes.spotlightResult}`}>
                                <div className={`${classes.centerFlex} ${classes.RolePlayTextWrapper}`}>
                                    <div className={classes.RolePlayText}>{t("Roleplay Analysis")}</div>
                                </div>
                                <div className={`${classes.videoBox} ${classes.centerFlex}`}>
                                    {
                                        !isEmpty(spotlightResultData?.userSpotLightResponse?.videoPath) ? (
                                            <CustomReactPlayer mediaUrl={spotlightResultData.userSpotLightResponse.videoPath} height={'100%'} />
                                        ) : (
                                            <audio controls src={`${spotlightResultData?.userSpotLightResponse?.audioPath}`} >
                                                <source src={`${spotlightResultData?.userSpotLightResponse?.audioPath}`} type="audio/ogg" />
                                                <source src={`${spotlightResultData?.userSpotLightResponse?.audioPath}`} type="audio/mpeg" />
                                                <source src={`${spotlightResultData?.userSpotLightResponse?.audioPath}`} type={`audio/${spotlightResultData?.userSpotLightResponse?.audioPath?.split(".")?.at(-1)}`} />  {/* extract audio file format from path */}
                                                Your browser does not support the audio tag.
                                            </audio>
                                        )
                                    }
                                </div>

                                <div className={classes.cardContainer}>
                                    {/* DURATION */}
                                    {
                                        graphData?.duration && (
                                            <div className={`${classes.card} scrollbar`}>
                                                <div className={classes.cardHeader}>
                                                    <div className={classes.cardHeaderInner}>
                                                        <div className={classes.cardName}>{t('DURATION')}</div>
                                                        <div className={classes.valueBox}>
                                                            <div className={`${classes.valueText} ${classes.timeText}`}>
                                                                {commonUtil.getMinutesFromSeconds(graphData?.duration.durationTaken)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <StatusAccordion
                                                        score={graphData.duration.score}
                                                        bgColor={graphData?.duration?.labels?.find(d => d.name.toUpperCase() === graphData?.duration?.qualitativeFeedback.toUpperCase())?.backgroundColor}
                                                        qualitativeFeedback={graphData.duration.qualitativeFeedback}
                                                        type
                                                    />
                                                </div>
                                                <div className={classes.cardDetailBox}>
                                                    <div className={`${classes.durationBox}`}>
                                                        <div className={classes.maxDuration}>
                                                            <div className={`${classes.recommendedDuration} ${classes.centerFlex}`}
                                                                style={{ width: getDurationStyle().recommendedWidth }}
                                                            />
                                                            <div className={`${classes.actualDuration}`}
                                                                style={{ left: getDurationStyle().actualDurationLeft }}
                                                            >
                                                                <span className={`${classes.yourDurationText}`}
                                                                    style={{ transform: getDurationStyle().textTransform }}
                                                                >
                                                                    Your Duration
                                                                </span>
                                                            </div>

                                                            <div className={`${classes.recommendedDurationText} ${classes.centerFlex}`}
                                                                style={{
                                                                    left: getDurationStyle().recommendedTextDisplayLeft,
                                                                    right: getDurationStyle().recommendedTextDisplayRight,
                                                                }}
                                                            >
                                                                {`Recommended ${commonUtil.getMinutesFromSeconds(graphData?.duration.actualDuration)}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {/* DURATION */}


                                    {/* SPEECH RATE */}
                                    {
                                        graphData?.speechRate && (
                                            <div className={`${classes.card} scrollbar`}>
                                                <div className={classes.cardHeader}>
                                                    <div className={classes.cardHeaderInner}>
                                                        <div className={classes.cardName}>{t('SPEECH RATE')}</div>
                                                        <div className={classes.valueBox}>
                                                            <div className={classes.valueText}>{graphData.speechRate?.value}</div>
                                                            <div className={classes.valueSubText}>{'Words per minute'}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <StatusAccordion
                                                            score={graphData.speechRate.score}
                                                            bgColor={graphData?.speechRate?.labels?.find(d => d.name.toUpperCase() === graphData?.speechRate?.qualitativeFeedback.toUpperCase())?.backgroundColor}
                                                            qualitativeFeedback={graphData.speechRate.qualitativeFeedback}
                                                            type
                                                        />
                                                    </div>
                                                </div>

                                                <div className={classes.cardDetailBox}>
                                                    {
                                                        !isEmpty(speechRateGraphData) && (
                                                            <Bar options={SPEECH_RATE_OPTIONS} data={speechRateGraphData} height={80} />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                    {/* SPEECH RATE */}


                                    {/* KEYWORD MATCH */}
                                    {
                                        graphData?.keywordMatch && (
                                            <div className={`${classes.card} scrollbar`}>
                                                <div className={classes.cardHeader}>
                                                    <div className={classes.cardHeaderInner}>
                                                        <div className={classes.cardName}>{t('KEYWORD MATCH')}</div>
                                                        <div className={classes.valueBox}>
                                                            <div className={classes.valueText}>{`${graphData.keywordMatch?.value}%`}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <StatusAccordion
                                                            score={graphData.keywordMatch.score}
                                                            bgColor={graphData?.keywordMatch?.labels?.find(d => d.name.toUpperCase() === graphData?.keywordMatch?.qualitativeFeedback.toUpperCase())?.backgroundColor}
                                                            qualitativeFeedback={graphData.keywordMatch.qualitativeFeedback}
                                                            type
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classes.labelDetailBox}>
                                                    {
                                                        keywordMatchDataLabels?.map((item, index) => (
                                                            <div className={`${classes.centerFlex} ${classes.labelBox}`} key={`keyword-${index}`}>
                                                                <span className={classes.labelName}>{t(item.name)}</span>
                                                                <span className={classes.labelColor}
                                                                    style={{ backgroundColor: item.backgroundColor === '#93E600' ? '#2A9A1A' : item.backgroundColor }}>
                                                                </span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                                                <div className={classes.cardDetailBox}>
                                                    <>
                                                        <div className={classes.keywordTabContainer}>
                                                            {
                                                                graphData?.keywordMatch?.data?.map((item, index) => (
                                                                    keywordMatchTabCount > index ? (
                                                                        <div className={classes.keywordTabBox} key={`keyword-data-${item.id}`}>
                                                                            <div className={classes.keywordTab}
                                                                                style={{ backgroundColor: graphData?.keywordMatch?.labels?.find(d => d.value === item.type).backgroundColor }}
                                                                            // style={{ backgroundColor: labels[item.type].backgroundColor }}
                                                                            >
                                                                                {item.name}&nbsp;
                                                                                {!isEmpty(item.count) && item.type !== 'similar-match' && item.count >= 2 && (
                                                                                    <span style={{ marginLeft: '8px' }}>({item.count})</span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ) : (null)
                                                                ))
                                                            }
                                                        </div>
                                                        {
                                                            graphData?.keywordMatch.data.length > keywordMatchTabCount ? (
                                                                <div className={`${classes.centerFlex} ${classes.showMore}`} onClick={() => handleKeywordShowMoreClick('show-more')}>
                                                                    {t("Show more")} <ExpandMoreIcon sx={{ color: '#F4511E' }} />
                                                                </div>
                                                            ) : (
                                                                graphData?.keywordMatch.data.length === keywordMatchTabCount && (
                                                                    <div className={`${classes.centerFlex} ${classes.showMore}`} onClick={() => handleKeywordShowMoreClick('show-less')}>
                                                                        {t("Show less")} <ExpandLessIcon sx={{ color: '#F4511E' }} />
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </>
                                                </div>

                                                {
                                                    !isEmpty(graphData?.keywordMatch?.data?.filter(elem => elem?.product === true)) && <div className={classes.cardHeader} style={{ marginTop: '2em' }}>
                                                        <div className={classes.cardHeaderInner}>
                                                            <div className={classes.cardName}>{t('PRODUCT MENTION')}</div>
                                                            <div className={classes.valueBox}>
                                                                <div className={classes.valueText}>{`${graphData.keywordMatch.data?.filter(elem => elem.product === true)?.reduce((accumulator, currentValue) => { return accumulator + currentValue.count }, 0)}`}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className={classes.cardDetailBox}>
                                                    <>
                                                        <div className={classes.keywordTabContainer}>
                                                            {
                                                                graphData?.keywordMatch?.data?.filter(elem => elem?.product === true)?.map((item, index) => (
                                                                    <div className={classes.keywordTabBox} key={`keyword-data-${item.id}`}>
                                                                        <div className={classes.keywordTab}
                                                                            style={{ backgroundColor: item.count > 0 ? "#2A9A1A" : "#FF0000" }}
                                                                        >
                                                                            {item.name}
                                                                            {item.count <= 0 ? ('') : (` (${item.count})`)}
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {/* KEYWORD MATCH */}


                                    {/* TRANSCRIPT MATCH */}
                                    {
                                        graphData?.transcriptMatch && (
                                            <div className={`${classes.card} ${classes.transcriptMatchCard} scrollbar`}>
                                                <div className={`${classes.cardHeader} ${classes.transcriptMatchCardHeader}`}>
                                                    <div className={classes.cardHeaderInner}>
                                                        <div className={classes.cardName}>{t('TRANSCRIPT MATCH')}</div>
                                                        <div className={classes.valueBox}>
                                                            <div className={`${classes.valueText}`}>
                                                                {`${graphData.transcriptMatch?.value}%`}
                                                                {/* {`${isEmpty(graphData?.transcriptMatch.transcriptMatchPercentage) ? graphData.transcriptMatch?.value : graphData.transcriptMatch?.transcriptMatchPercentage}%`} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <StatusAccordion
                                                            score={graphData.transcriptMatch.score}
                                                            bgColor={graphData?.transcriptMatch?.labels?.find(d => d.name.toUpperCase() === graphData?.transcriptMatch?.qualitativeFeedback.toUpperCase())?.backgroundColor}
                                                            qualitativeFeedback={graphData.transcriptMatch.qualitativeFeedback}
                                                            type
                                                        />
                                                    </div>
                                                </div>

                                                <div className={`${classes.labelDetailBox} ${classes.transcriptLableDetailBox}`}>
                                                    {/* <div className={`${classes.centerFlex} ${classes.labelBox}`}>
                                                        <span className={classes.labelName}>{t('MISMATCH')}</span>
                                                        <span className={`${classes.labelColor} `}
                                                            style={{ backgroundColor: '#ff0000' }}>
                                                        </span>
                                                    </div> */}
                                                    {/* {
                                                        graphData?.transcriptMatch.labels.map((item, index) => (
                                                            <div className={`${classes.centerFlex} ${classes.labelBox}`} key={`transcript-${index}`}>
                                                                <span className={classes.labelName}>{item.name}</span>
                                                                <span className={`${classes.labelColor} ${classes.transcriptCircle}`}
                                                                    style={{ backgroundColor: item.backgroundColor === '#93E600' ? '#2A9A1A' : item.backgroundColor }}>
                                                                </span>
                                                            </div>
                                                        ))
                                                    } */}
                                                </div>

                                                <div className={`${classes.cardDetailBox} ${classes.transcriptCardDetailBox} scrollbar`}>
                                                    <div className={classes.formattedHtml} dangerouslySetInnerHTML={{ __html: graphData?.transcriptMatch?.transcript }} ></div>
                                                    {/* <div className={classes.formattedHtml} dangerouslySetInnerHTML={{ __html: graphData?.transcriptMatch?.formattedLearnerTranscript }} ></div> */}
                                                </div>
                                            </div>
                                        )
                                    }
                                    {/* TRANSCRIPT MATCH */}

                                    {/* PRODUCT MENTION */}
                                    {
                                        // graphData?.keywordMatch && (
                                        //     <div className={`${classes.card} scrollbar`}>

                                        //     </div>
                                        // )
                                    }
                                    {/* PRODUCT MENTION */}


                                    {/* TONE */}
                                    {
                                        isCriteriaContains(criteria, 'tone') && (
                                            <>
                                                {
                                                    !isKeyExist(graphData, "tone") ? (
                                                        <div className={`${classes.lotieCard} scrollbar`}>
                                                            <Lottie loop animationData={toneLottie} play />
                                                        </div>
                                                    ) : (

                                                        <div className={`${classes.card} scrollbar`}>
                                                            <div className={classes.cardHeader}>
                                                                <div className={classes.cardHeaderInner}>
                                                                    <div className={classes.cardName}>{t('TONE')}</div>
                                                                    <div className={classes.valueBox}>
                                                                        <div className={`${classes.valueText} ${classes.valueString}`}>
                                                                            {`${graphData.tone?.value}`}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <StatusAccordion
                                                                        score={graphData.tone.score}
                                                                        bgColor={graphData?.tone?.labels?.find(d => d.name.toUpperCase() === graphData?.tone?.qualitativeFeedback.toUpperCase())?.backgroundColor}
                                                                        qualitativeFeedback={graphData.tone.qualitativeFeedback}
                                                                        type
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className={classes.cardDetailBox}>
                                                                {
                                                                    !isEmpty(toneGraphData) && (
                                                                        <Bar options={TONE_OPTIONS} data={toneGraphData} height={100} />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                    {/* TONE */}

                                    {/* CLARITY*/}
                                    {
                                        isCriteriaContains(criteria, 'clarity') && (
                                            <>
                                                {
                                                    !isKeyExist(graphData, "clarity") ? (
                                                        <div className={`${classes.lotieCard} scrollbar`}>
                                                            <Lottie loop animationData={clarityLottie} play />
                                                        </div>
                                                    ) : (
                                                        <div className={`${classes.card} scrollbar`}>
                                                            <div className={classes.cardHeader}>
                                                                <div className={classes.cardHeaderInner}>
                                                                    <div className={classes.cardName}>{t('CLARITY')}</div>
                                                                    <div className={classes.valueBox}>
                                                                        <div className={`${classes.valueText}`}>
                                                                            {`${graphData.clarity?.value}%`}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <StatusAccordion
                                                                        score={graphData.clarity.score}
                                                                        bgColor={graphData?.clarity?.labels?.find(d => d.name.toUpperCase() === graphData?.clarity?.qualitativeFeedback.toUpperCase())?.backgroundColor}
                                                                        qualitativeFeedback={graphData.clarity?.qualitativeFeedback}
                                                                        type
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className={classes.cardDetailBox}>
                                                                {
                                                                    !isEmpty(clarityGraphData) && (
                                                                        <Bar options={CLARITY_OPTIONS} data={clarityGraphData} height={80} />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                    {/* CLARITY*/}
                                </div>

                                {
                                    // (!isKeyExist(graphData, 'tone') && !isKeyExist(graphData, 'clarity')) ? (
                                    //     <div className={`${classes.scoreCard} ${classes.centerFlex}`}>
                                    //         <CircularProgress size={50} />
                                    //     </div>
                                    // ) : (
                                    <>
                                        <div className={classes.scoreBox}>
                                            <div className={classes.scoreText}>{t("YOUR SCORE")}</div>
                                            <StarRatingComponent
                                                name={'ss'}
                                                starCount={3}
                                                // value={getRatingFromScore(graphData?.userScore)}
                                                value={starRatings}
                                                renderStarIcon={(index, value) => {
                                                    return (
                                                        <span>
                                                            {
                                                                index > value ?
                                                                    (<img src={STAR_WHITE} alt="star" width={25} />)
                                                                    :
                                                                    (<img src={STAR_HIGHLIGHTED} alt="star" width={25} />)
                                                            }
                                                        </span>
                                                    )
                                                }}
                                                starColor={'#FFD042'}
                                                emptyStarColor={'#FFF'}
                                                editing={false}
                                            />
                                            <div className={`${classes.valueText}`}>
                                                {`${graphData.userScore.toFixed(0)} / ${spotlightResultData.maxScore}`}
                                            </div>
                                        </div>
                                    </>
                                    // )
                                }

                                {jobId !== 'undefined' && (
                                    <>
                                        {showNextChallengeBtn && <Button variant="contained"
                                            fullWidth
                                            size="large"
                                            disableElevation
                                            className={`p-0 fixed_ratio_20_3`}
                                            onClick={() => { handleSubmitClick('next challenge') }}
                                        >
                                            <b>{t("Submit & Next")}</b>
                                        </Button>

                                        }

                                        {!showNextChallengeBtn && <Button variant="contained"
                                            fullWidth
                                            size="large"
                                            disableElevation
                                            className={`p-0 fixed_ratio_20_3`}
                                            onClick={() => { handleSubmitClick('show certificate') }}
                                        >
                                            <b>{t("Submit & Show Certificate")}</b>
                                        </Button>}

                                        <Button variant="text"
                                            sx={theme => ({
                                                color: theme.palette.greyText4
                                            })}
                                            size="medium"
                                            fullWidth
                                            disableElevation
                                            className={`p-0 fixed_ratio_20_3`}
                                            // onClick={(e) => {
                                            //     navigate(`/challenge-list/${microskillId}/${challengeId}/spotlight?name=${spotlightResultData.name}`, { state: { spotlightScreenState: SCREEN_STATE[3] } });
                                            // }}

                                            onClick={handleRecordAgainClick}
                                        >
                                            <u><b>{t("Record Again")}</b></u>
                                        </Button></>
                                )}
                            </div>
                        )
                    }

                </div>
                { /* RECOMMENDED VIDEOS */}
                {
                    isPreviewVideo && (
                        <div className={classes.modalDiv} style={{
                            height: `calc(${constants.FULL_HEIGHT_VALUE} - ${isMobile ? '0' : HEIGHT_MARGIN}rem)`
                        }}>
                            <RecommendedVideoPreview videoData={activeTopVideo.current} closeBtnFunc={setIsPreviewVideo} />
                        </div>
                    )
                }
                { /* RECOMMENDED VIDEOS */}
            </div >

            {
                isDialogueOpen && (
                    <DialogAlert
                        isOpen={isDialogueOpen}
                        content={dialogueContent}
                        confirmBtnName={'Redirecting...'}
                        handleConfirmBtnClick={handleConfirmBtnClick}
                        lottieFile={CORRECT_TICK_LOTTIE}
                    />
                )
            }

            {console.log("isSpotlightLastAttempt : : :", isSpotlightLastAttempt)}

            {
                isSpotlightLastAttempt && (
                    <DialogAlert
                        isOpen={isSpotlightLastAttempt}
                        content={lastAttemptDialogText}
                        confirmBtnName={'Start Recording'}
                        handleConfirmBtnClick={() => {
                            navigate(`/challenge-list/${microskillId}/${challengeId}/spotlight?name=${spotlightResultData.name}`, { state: { spotlightScreenState: SCREEN_STATE[3] } });

                        }}
                        discardBtnName={'Cancel'}
                        handleDiscardBtnClick={() => { setIsSpotlightLastAttempt(false) }}
                    />
                )
            }

        </div >
    )
}

export default SpotlightResult2;