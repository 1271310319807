import { signIn, validateThirdPartyUser } from "../redux/auth/auth.api";
import { setThirdPartyIntegrationFlag } from "../redux/auth/auth.actions";
var bridge = require("webview-javascript-bridge-promised");

export const thirdPartyAppIntegrationWithMastero = (dispatch, useSelector, openDialogue) => {
    // window.location.href = "/loading"
    bridge.registerHandler("masteroCallback", (params, responseCallback) => {
        responseCallback(`responseCallback from JS! ${params}`);
        // dispatch(openDialogue({
        //     isDialogueOpen: true,
        //     dialogueTitle: '',
        //     dialogueContent: JSON.stringify(params)
        // }))
        // dispatch(setThirdPartyIntegrationFlag(true));
    });

    bridge.registerHandler("messageFromNative", async (json_string, responseCallback) => {
        // const isThirdPartyIntegration = useSelector(state => state.auth.isThirdPartyIntegration);
        // console.log("integration:", isThirdPartyIntegration)
        if (window.location.pathname !== '/loading') dispatch(setThirdPartyIntegrationFlag(true));

        let json_obj = JSON.parse(json_string);
        const resp = await validateThirdPartyUser({ encryptedText: json_obj.uname_enc, companyCode: json_obj.company });
        const req = {
            username: resp.data.data.username,
            password: '',
        }
        const signInResp = await signIn(req)
        if (signInResp.status === 200 || signInResp.status === 201) {
            dispatch(setThirdPartyIntegrationFlag(false));
            window.location.href = '/learn';
            // setShowLoadingMicroskill(false);
        } else if (signInResp.status === 400) {
            dispatch(openDialogue({
                isDialogueOpen: true,
                dialogueTitle: '',
                dialogueContent: 'User ID does not exist, please contact your company administrator.'
            }));
        }

        // const decrypted = AES.decrypt(json_obj.uname_enc, "7061737323313233");
        // let mobile = decrypted.toString(Utf8);
        // json_obj.uname = mobile.replace(/['"]+/g, "");
        // dispatch(openDialogue({
        //     isDialogueOpen: true,
        //     dialogueTitle: 'FROM NATIVE 1',
        //     dialogueContent: JSON.stringify(signInResp.data.data)
        // }));
    });
}