// TODO : start form attempt enable

import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';

// styles
import useStyles from './form.styles';

// mui
import { useTheme } from '@mui/styles';
import { Button, CircularProgress, Dialog } from '@mui/material';

// mui-icons
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

// components
import FormAnswers from "./components/formAnswers";
import FormWinScreen from './components/formsWinScreen';
import FormLooseScreen from './components/formsLooseScreen';
import DialogAlert from '../dialog-alert';


// sample data

// redux-api
import { fetchFormData, startFormAttempt, submitEndFormAttempt, submitFormAttemptData } from '../../redux/form/form.api';

// utils
import isEmpty from '../../utils/isEmpty';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Form = (props) => {

    const { formId = '', openForm, setOpenForm } = props;

    const theme = useTheme();

    const [initialHeight, setInitialHeight] = useState(window.innerHeight);
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
    const isMobile = useSelector(state => state?.common?.isMobile);


    const [data, setData] = useState('');
    const [loading, setLoading] = useState(true);
    const [formName, setFormName] = useState('');
    const [formDescription, setFormDescription] = useState('');
    const [isFormSubmit, setFormSubmit] = useState(false);
    const [isAssessment, setIsAssessment] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [totalMandatory, setTotalMandatory] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [timeup, setTimeup] = useState(false);
    const [attemptMandatory, setAttemptMandatory] = useState([]);
    const [maxScore, setMaxScore] = useState(0);

    const [minscore, setMinScore] = useState(0);
    const [showLooseScreen, setShowLooseScreen] = useState(false);

    const [attemptId, setAttemptId] = useState('');
    const [attemptData, setAttemptData] = useState([]);

    const [questionAttemptData, setQuestionAttemptData] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false); // dialog alert
    const [dialogContent, setDialogContent] = useState(false); // dialog alert
    const [dialogConfirmBtn, setDialogConfirmBtn] = useState(false); // dialog alert
    const [finalScoreState, setFinalScoreState] = useState(0); // form win screen

    const formStartTimeRef = useRef(moment(new Date()));
    const intervalRef = useRef(null);
    const timerRef = useRef(null);
    const timerValueRef = useRef(null);
    // const finalScoreRef = useRef(0);

    const { t, i18n } = useTranslation()
    const styles = useStyles(i18n);

    const fetchFormDataFromId = async () => {
        const resp = await fetchFormData(formId);
        if (resp.status === 200 || resp.status === 201) {
            const respData = resp.data.data;
            setData(respData);
            setIsAssessment(respData.assessment);
            if (respData.assessment) {
                setMinScore(respData.minScore);
            }
            setQuestions(respData.Questions);
            setFormName(respData.name);
            setFormDescription(respData.description);
            const formQuestion = respData.Questions;
            const mandatoryQuestion = formQuestion.filter((temp) => temp.mandatory === true);
            setTotalMandatory(mandatoryQuestion.length);
            setSubmitButtonDisabled(mandatoryQuestion.length > 0);
            timerValueRef.current = respData.timeLimit;
            setShowScore(respData.showScore);
            setMaxScore(respData.maxScore);
            setMinScore(respData.settings.minScore || 0);
            startAttemptForm();
        }
    }

    const startAttemptForm = async () => {
        console.log('it is called for form attempt');
        const resp = await startFormAttempt({ formId });
        if (resp.status === 200 || resp.status === 201) {
            const respData = resp.data.data;
            setAttemptId(respData.id);
            setQuestionAttemptData({ formId: formId, attemptId: respData.id, questions: [] });
            formStartTimeRef.current = moment(new Date());
        }

    }

    const startTimer = (time) => {
        intervalRef.current = setInterval(() => {
            if (timerRef.current && time > 0) {
                timerRef.current.innerHTML = time--;
            }
            else if (time === 0) {
                setTimeup(true);
                handleSubmitForm();
            }
        }, 1000);
    };

    const handleSubmitForm = async () => {
        var score = 0;
        console.log("attemptData :", attemptData);
        for (const question of attemptData) {
            if (question.type === 'SINGLE_CHOICE_QUESTION') {
                const attemptAnswers = question.answers;
                for (const ans of attemptAnswers) {
                    // console.log(question.type, ans)
                    score += ans.isCorrect ? question.scorePerResponse : 0;
                }
            } else if (question.type === 'MULTI_CHOICE_QUESTION') {
                const attemptAnswers = question.answers;
                for (const ans of attemptAnswers) {
                    // console.log(question.type, ans)
                    score += ans.isCorrect ? question.scorePerResponse : 0;
                }
            } else if (question.type === 'OPEN_ENDED') {
                // console.log(question.type, question.answers.length)
                score += question.answers.length * question.scorePerResponse;
            } else if (question.type === 'STAR_RATING') {
                // console.log(question.type, question.answers.length)
                score += question.answers.length * question.scorePerResponse;
            } else if (question.type === 'NUMBER_RATING') {
                // console.log(question.type, question.answers.length)
                score += question.answers.length * question.scorePerResponse;
            } else if (question.type === 'DATE') {
                // console.log(question.type, question.answers.length)
                score += question.answers.length * question.scorePerResponse;
            } else if (question.type === 'CUSTOM') {
                score += question.answers.length * question.scorePerResponse;
            }
        }

        const endTime = moment(new Date());



        let tempObj = {
            formId: formId,
            attemptId: attemptId,
            status: "COMPLETED",
            completion: true,
            scored: score,
            timespent: endTime.diff(formStartTimeRef.current, 'second'),
            questions: [...attemptData]
        };

        if (isAssessment && score < minscore) {
            tempObj.status = "FAILED";
            tempObj.completion = false;
            setShowLooseScreen(true);
        }

        clearInterval(intervalRef.current);
        setLoading(true);

        const response = await submitEndFormAttempt(tempObj);
        if (response.status === 200 || response.status === 201) {
            setLoading(false);
            setFormSubmit(true);
            setFinalScoreState(score);
            if (isAssessment && score < minscore) {
                setShowLooseScreen(true);
            }
        } else {
            setLoading(false);
            setIsDialogOpen(true);
            setDialogContent('Error in submitting form, Please try again!');
            setDialogConfirmBtn('Try Again!')
        }
    }

    const formTryAgainClick = () => {
        setShowLooseScreen(false);
        setLoading(true);
        setFormSubmit(false);
        setQuestions([]);
        setAttemptData([]);
        setAttemptMandatory([]);
        setQuestionAttemptData({});
        fetchFormDataFromId();
    }

    const makeAttemptData = () => {
        const tempQuestion = [];
        questions.map((ques) => {
            const t = {
                questionId: ques.id,
                question: ques.question,
                type: ques.type,
                sequence: ques.sequence,
                answers: [],
                scorePerResponse: ques.scorePerResponse,
            };

            tempQuestion.push(t);
        });

        setAttemptData(tempQuestion);
    }

    const handleFormClose = () => {
        setOpenForm(false);
    }

    useEffect(() => {
        (async function () {
            if (!isEmpty(questionAttemptData.questions)) {
                const temp = { formId, attemptId: attemptId, questions: questionAttemptData.questions }
                const resp = await submitFormAttemptData(temp);
                if (!isEmpty(resp) && (resp.status === 200 || resp.status === 201)) {
                    setQuestionAttemptData({ ...questionAttemptData, questions: [] });
                } else {
                    setLoading(true);
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                    setQuestions([])
                    setDialogConfirmBtn('Okay')
                    setIsDialogOpen(true);
                    setDialogContent('Something went wrong!');
                }
            }
        })();
    }, [questionAttemptData]);

    useEffect(() => {
        if (attemptMandatory.length === totalMandatory) {
            setSubmitButtonDisabled(false);
        }
        else if (totalMandatory !== 0) {
            setSubmitButtonDisabled(true)
        }
    }, [attemptMandatory]);

    useEffect(() => {
        if (!isEmpty(formId)) {
            fetchFormDataFromId();
        }
    }, [formId]);

    useEffect(() => {
        if (!isEmpty(questions)) {
            makeAttemptData();
        }
    }, [questions]);

    useEffect(() => {
        if (openForm && attemptId !== '' && !isEmpty(questions)) {
            if (isAssessment && !isEmpty(timerValueRef.current)) {
                startTimer(timerValueRef.current);
            }
            setLoading(false);
        }
    }, [questions, attemptId, timerValueRef.current]);

    useEffect(() => {
        const handleResize = () => {
            const currentHeight = window.innerHeight;
            setIsKeyboardOpen(currentHeight < initialHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [initialHeight]);


    // dialog alert close handle
    const handleDialogAlertClose = () => {
        setIsDialogOpen(false);
        if (dialogConfirmBtn === 'Okay') {
            if (!isEmpty(formId)) {
                fetchFormDataFromId();
                startAttemptForm();
            }
        } else if (dialogConfirmBtn === 'Try Again!') {
            handleSubmitForm();
        }
        setDialogContent('');
    }

    return (
        <Dialog
            open={openForm}
            onClose={(_, reason) => {
                if (reason !== 'backdropClick') {

                }
            }}

            sx={{
                backdropFilter: "blur(10px)",
                '& .MuiDialogContent-root': {
                    padding: "2rem",
                },
                '& .MuiDialogActions-root': {
                    padding: "1rem",
                },
            }}

            PaperProps={{
                className: styles.paperProps
            }}
        >

            {
                loading ?
                    <div className={`${styles.centerFlex} ${styles.loadingForm}`}>
                        <CircularProgress />
                    </div>
                    : (
                        !isFormSubmit ? (
                            <div className={styles.formContainer} style={{ height: isMobile && isKeyboardOpen ? '100vh' : '100%' }}>
                                <div className={` ${styles.formInfoContainer}`} >
                                    <div className={`${styles.centerFlex} ${styles.IconContainer}`}>
                                        {!isAssessment && (
                                            // <CloseIcon style={{ color: '#F4511E', cursor: 'pointer' }} onClick={() => handleFormClose()} />
                                            <img src='/images/icons/form-close-icon.svg' alt="form-close" className={styles.closeIcon} onClick={() => handleFormClose()} />
                                        )}
                                        {isAssessment && (
                                            <div className={`${styles.timerContainer} ${styles.centerFlex}`}>
                                                <div className={styles.timerDiv}>
                                                    <span style={{ fontSize: '1.2rem' }} ref={timerRef}>{timerValueRef.current}</span>
                                                    <span style={{ fontSize: '1.2rem' }}>s</span>
                                                </div>
                                                <AccessAlarmIcon style={{ color: '#F4511E', margin: 'auto' }} />
                                            </div>
                                        )}
                                    </div>

                                    <div className={`${styles.centerFlex} ${styles.formNameDescriptionContainer}`}>
                                        <div className={styles.formName}>{formName}</div>

                                        <div className={styles.formDescription}>{formDescription}</div>
                                    </div>
                                </div>

                                <div className={`${styles.formQuestionConatiner}`}>
                                    {
                                        questions.map((ques, idx) => {
                                            const answers = ques.answers;
                                            const type = ques.type;
                                            const options = ques.options;

                                            return (
                                                <div key={ques.id} className={styles.questionContainer}>
                                                    <div className={`${styles.centerFlex} ${styles.formQuestion}`}>
                                                        <div className={styles.questionSerialNumber}>Q{idx + 1}. </div>
                                                        <div className={styles.questionTxt}>{ques.question} {ques.mandatory && <span className={styles.mandatorySign}>*</span>}</div>
                                                    </div>
                                                    <div className={styles.formAnswersContainer}>
                                                        <FormAnswers answers={answers} questionSequence={ques.sequence} questionType={type} questionId={ques.id} setAttemptData={setAttemptData} attemptData={attemptData} options={options} mandatory={ques.mandatory} setAttemptMandatory={setAttemptMandatory} attemptMandatory={attemptMandatory} questionAttemptData={questionAttemptData} setQuestionAttemptData={setQuestionAttemptData} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className={`${styles.centerFlex} ${styles.formSubmitContainer}`}>
                                        <Button
                                            className={styles.formSubmitButton}
                                            disabled={submitButtonDisabled}
                                            style={{
                                                color: '#FFF',
                                                background: submitButtonDisabled ? '#787878' : '#F4511E'
                                            }}
                                            onClick={() => {
                                                if (!submitButtonDisabled) {
                                                    handleSubmitForm();
                                                }
                                            }}
                                        >
                                            {t("Submit")}
                                        </Button>
                                    </div>
                                </div>


                            </div>

                        ) : (
                            !isAssessment ?
                                <FormWinScreen formName={formName} openForm={openForm} setOpenForm={setOpenForm} showScore={showScore} timeup={timeup} isAssessment={isAssessment} score={finalScoreState} maxScore={maxScore} />
                                :
                                showLooseScreen ?
                                    <FormLooseScreen formName={formName} openForm={openForm} setOpenForm={setOpenForm} showScore={showScore} score={finalScoreState} maxScore={maxScore} formTryAgainClick={formTryAgainClick} />
                                    :
                                    <FormWinScreen formName={formName} openForm={openForm} setOpenForm={setOpenForm} showScore={showScore} timeup={timeup} isAssessment={isAssessment} score={finalScoreState} maxScore={maxScore} />
                        )
                    )
            }


            {
                isDialogOpen && (
                    <DialogAlert
                        isOpen={isDialogOpen}
                        content={dialogContent}
                        confirmBtnName={dialogConfirmBtn}
                        handleConfirmBtnClick={handleDialogAlertClose}
                    />
                )
            }

        </Dialog >
    )
}

export default Form;