import { eventsActionTypes } from "./events.types";

const INITIAL_STATE = {
    userName: null,
    screen: 'LOGIN PAGE',
    device: null,
    details: {},
}

const loginEventReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case eventsActionTypes.LOGIN_PAGE:
            return {
                ...state
            }
        case eventsActionTypes.LOGIN_USERNAME:
            return {
                ...state,
                ...action.payload
            }
        case eventsActionTypes.LOGIN_PASSWORD:
            return {
                ...state,
                ...action.payload
            }
        case eventsActionTypes.LOGIN_OTP:
            return {
                ...state,
                ...action.payload
            }
        case eventsActionTypes.LOGIN_ERROR:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

export default loginEventReducer;