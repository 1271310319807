import React, { useEffect } from "react";

// mui
import { Button } from "@mui/material";

// redux
import { useSelector, useDispatch } from "react-redux";
import { setChristmasGiftAnimation, setIsDarkMode } from "../../redux/common-persist/common-persist.actions";

// styles
import useStyles from './index.styles';

// lottie
import Lottie from 'react-lottie-player';
import giftAnnouncement from '../../lottie-assets/gift-announcement.json'


const ChristmasGiftAnimation = (props) => {
    const { wrapperPosition, containerWidth } = props
    const dispatch = useDispatch();
    const classes = useStyles();
    const { isMobile } = useSelector(state => state.common);
    const { isDarkMode, viewChristmasGiftAnimation } = useSelector(state => state.commonPersist);

    useEffect(() => {
        if (isMobile) return;
        if (viewChristmasGiftAnimation) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [viewChristmasGiftAnimation, isMobile])

    return (
        <div className={classes.giftAnimeWrapper} style={{
            visibility: viewChristmasGiftAnimation ? 'visible' : 'hidden',
            transform: viewChristmasGiftAnimation ? 'translateY(0)' : 'translateY(-100vh)',
            position: wrapperPosition,
            overflow: 'hidden',
        }}>
            <div className={classes.giftAnimeWrapperOverlay}></div>
            <ul className={classes.snowContainer}>
                {
                    Array.from(Array(100)).map((_, index) => {
                        let width = `${Math.floor(Math.random() * 15)}px`
                        return (<li key={index} className={classes.snow}
                            style={{
                                left: `${Math.floor(Math.random() * 100)}%`,
                                width: width,
                                height: width,
                                animationDelay: `${Math.floor(Math.random() * 10)}s`,
                                animationDuration: `${Math.floor(Math.random() * 50) + 10}s`,
                                animationTimingFunction: `cubic-bezier(${Math.random()}, ${Math.random()}, ${Math.random()}, ${Math.random()})`,
                                filter: `blur(${Math.floor(Math.random() * 5)}px)`,
                            }}
                        ></li>)
                    })
                }
            </ul>
            <div className={classes.giftAnimeContainer} style={{ width: containerWidth }}>
                <div className={classes.lottieContainer}>
                    <Lottie
                        animationData={giftAnnouncement}
                        loop={true}
                        play={true}
                    />
                </div>
                <h2 className={classes.giftAnimeTitle}>
                    Happy Holidays from Master-O!
                </h2>
                <h6 className={classes.giftAnimeBody}>
                    We've got a new light mode to brighten up your festive mood!
                </h6>
                <Button
                    variant="contained"
                    // fullWidth
                    color="primary"
                    sx={theme => ({
                        borderRadius: '4px',
                        textTransform: 'uppercase',
                        fontSize: '1rem',
                    })}
                    onClick={() => {
                        console.log("first")
                        dispatch(setChristmasGiftAnimation(false))
                        dispatch(setIsDarkMode(false))
                    }}
                >Switch to light mode </Button>
            </div>
        </div>
    )
}

export default ChristmasGiftAnimation