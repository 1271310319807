export const commonActionTypes = {
    OPEN_TOAST: 'OPEN_TOAST',
    OPEN_DIALOGUE: 'OPEN_DIALOGUE',
    SET_IS_MOBILE: 'SET_IS_MOBILE',
    SET_IS_TABLET: 'SET_IS_TABLET',
    TOGGLE_SIDE_DRAWER: 'TOGGLE_SIDE_DRAWER',
    SET_IS_NATIVE_APP: 'SET_IS_NATIVE_APP',
    SET_HEADER_SEARCH_TEXT: 'SET_HEADER_SEARCH_TEXT',
    VIEW_MICROSKILL_BY_CATEGORY: 'VIEW_MICROSKILL_BY_CATEGORY',
    SET_BROWSER_TAB_FOCUS: 'SET_BROWSER_TAB_FOCUS',
    ROTATE_MOBILE: 'ROTATE_MOBILE',
    IS_DOC_VIEWING: 'IS_DOC_VIEWING',
    SET_SELECTED_LANGUAGE: 'SET_SELECTED_LANGUAGE',
    SET_MICROSKILL_CATEGORIES: 'SET_MICROSKILL_CATEGORIES',
    SET_SELECTED_MICROSKILL_CATEGORIES: 'SET_SELECTED_MICROSKILL_CATEGORIES',
    VIEW_LA_ONBOARDING_SCREEN: 'VIEW_LA_ONBOARDING_SCREEN',
    SET_LEARNER_JOURNEY_ENDED: 'SET_LEARNER_JOURNEY_ENDED'
}