/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// mui
import { styled, alpha } from '@mui/material/styles';
import { Badge, Box, CircularProgress, InputBase, Rating, Slide, Typography } from "@mui/material";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

// MUI Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
// import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
// import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

import DescriptionIcon from '@mui/icons-material/Description';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';


// components
import HeaderBackButton from "../layout-elements/header-back-button";
import DocumentModal from "../la-cards/document-modal";
import ProductComparison from "../product-comparison";
// import EmiCalculator from "../emi-calculator";
// import TcoCalculator from "../tco-calculator";
import TcoCalculator2 from "../tco-calculator-2";
import SavingsCalculator from "../savings-calculator";

// redux
import { useDispatch, useSelector } from "react-redux";
import { submitUserActivityData } from "../../redux/event-tracking/event.api";
import { SalesEnablementPage } from "../../redux/event-tracking/event.action";

// utils
import isEmpty from "../../utils/isEmpty";
import { getHeaderHeight, isNativeApp } from '../../utils/commonUtil';

// theme
import palette from "../../theme/palette";

// context
import LearningAidContext from "../../context/learningAidContext";

// styles
import useStyles, { BannerHeight, NavigationContainerHeight, } from "./index.styles";



// constants
import constants from "../../constants";
import { salesEnablementDataMahindra } from "../../constants/sales-enablement-data-mahindra";
import { salesEnablementDataCSB } from '../../constants/sales-enablement-data-csb'
import LaVoiceOverAudioPlayer from "../la-voiceover-audio-player";
import { Elderly } from "@mui/icons-material";
import Carousel from "react-multi-carousel";
const responsive = constants.SCREEN_RESPONSIVENESS_DOCUMENT;


const height = '180px';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: '3%',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


// search bar in faq comps
const SearchBoxWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
}));
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '5% 90% 5%',
    [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(1),
        width: '80%',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1, 0, 2),
}));
const CloseIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            '&:focus': {
                width: '100%',
            },
        },
    },
}));


const {
    IMAGE_EXTENSIONS,
    VIDEO_EXTENSIONS,
    AUDIO_EXTENSIONS,
    PDF_EXTENSIONS,
    BOTTOM_NAV_HEIGHT,
    HEADER_TOP_PADDING,

} = constants;


const ProductDetails = (props) => {
    let { productId } = props;
    productId = parseInt(productId);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);

    const {
        PRODUCT_DETAILS_NAVIGATION_STATUS,
        PRODUCT_DETAIL_LIST,
        PRODUCT_FILES_LIST,
        RIVAL_PRODUCT_DETAILS
    } = productId === 1 ? salesEnablementDataMahindra : salesEnablementDataCSB;

    const { addFiles, files, isFileViewerOpen, handleDocumentBtnClick } = useContext(LearningAidContext);
    const isMobile = useSelector(state => state?.common?.isMobile);
    const isDarkMode = useSelector(state => state?.commonPersist?.isDarkMode);
    const userActivityData = useSelector(state => state?.event);

    const ProductDetailBoxRef = useRef();
    const ProductDetailHeaderRef = useRef();
    const ProductDetailBgRef = useRef();
    const ProductDetailMobileHeaderRef = useRef();
    const containerRef = useRef(null);
    const debounceSearchRef = useRef(null);
    const proDetailNavBarRef = useRef(null);
    // const showInitScrollAnime = useRef(true);

    const [showProductComparison, setShowProductComparison] = useState(false);
    const [productData, setProductData] = useState({});
    const [activeCardStatus, setActiveCardStatus] = useState(PRODUCT_DETAILS_NAVIGATION_STATUS[0].toUpperCase());
    const [expanded, setExpanded] = useState(false);
    const [openFloatingMenu, setOpenFloatingMenu] = useState(true);
    const [showSavingsCalculator, setShowSavingsCalculator] = useState(false);
    const [showEmiCalc, setShowEmiCalc] = useState(false);
    const [innerBoxHeaderText, setInnerBoxHeaderText] = useState('');
    const [selectedPersona, setSelectedPersona] = useState(null);
    const [summaryData, setSummaryData] = useState([])
    const [faqData, setFaqData] = useState([])

    const [isSearchVisible, setIsSearchVisible] = useState(true); // make search bar visible by default
    const [searchInputValue, setSearchInputValue] = useState('');
    const [showSearchHeader, setShowSearchHeader] = useState(false);

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const { t, i18n } = useTranslation()

    const classes = useStyles();

    useEffect(() => {
        if (!isEmpty(activeCardStatus)) {
            let dispatchingObj = {
                ...userActivityData,
                oldScreen: userActivityData.currentScreen,
                currentScreen: "SALES ENABLEMENT PRODUCT DETAILS",
                nextSlide: '',
                activityData: {
                    screen: `Sales Enablement ${activeCardStatus.toLowerCase()} page screen for ${PRODUCT_DETAIL_LIST[productId - 1].name}`,
                }
            }
            if (showProductComparison) dispatchingObj.activityData['additionalScreen'] = `Product Comparison of ${innerBoxHeaderText} `
            if (isFileViewerOpen) dispatchingObj.activityData['additionalScreen'] = `Document viewer - "${files?.name}" opened`
            if (showEmiCalc) dispatchingObj.activityData['additionalScreen'] = 'TCO Calculator page screen'
            if (showSavingsCalculator) dispatchingObj.activityData['additionalScreen'] = 'Saving Calculator page screen'
            dispatch(SalesEnablementPage(dispatchingObj))
            submitUserActivityData(dispatchingObj);
        }
    }, [activeCardStatus, showProductComparison, showEmiCalc, showSavingsCalculator, isFileViewerOpen, files, innerBoxHeaderText])

    useEffect(() => {
        console.log("PRODUCT_DETAIL_LIST[productId - 1]", PRODUCT_DETAIL_LIST[productId - 1])
        setProductData(PRODUCT_DETAIL_LIST[productId - 1])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId])

    const handleBackBtnClick = () => {
        // navigate(-1)
        navigate({
            pathname: '/content-repository',
            search: `?name=Library`
        });
        // navigate('/learn')
    }

    const handleBackBtnCompClick = () => {
        setShowProductComparison(false)
        setShowEmiCalc(false);
        setShowSavingsCalculator(false);
        setInnerBoxHeaderText("")
    }

    useEffect(() => {
        const activeUrlParams = urlParams.get('active')
        const personaUrlParams = urlParams.get('persona')

        if (!isEmpty(personaUrlParams)) setSelectedPersona(personaUrlParams)
        if (isEmpty(activeUrlParams)) navigate(`?active=${PRODUCT_DETAILS_NAVIGATION_STATUS[0].toLowerCase()}${isEmpty(personaUrlParams) ? "" : `&persona=${personaUrlParams}`}`) // in case of empty url params

        setActiveCardStatus(activeUrlParams?.toUpperCase())
    }, [urlParams])

    useEffect(() => {
        // console.log(isEmpty(selectedPersona) ? 'def' : selectedPersona)
        // console.log(productData)
        if (isEmpty(productData)) return
        setSummaryData(productData?.summary['def'])
        console.log("productData?.summary['def'] ::::::", productData?.summary['def'])
        // setSummaryData(productData?.summary[isEmpty(selectedPersona) ? 'def' : selectedPersona] || [])
        console.log("productData: " + productData)
        console.log("productData: " + productData?.faqs['def']);
        setFaqData(productData?.faqs['def'])
        // setFaqData(productData?.faqs[isEmpty(selectedPersona) ? 'def' : selectedPersona] || [])

    }, [selectedPersona, productData])

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleFileClick = (item) => {
        console.log('handleFileClick event = ', item);

        if (openFloatingMenu) setOpenFloatingMenu(false)
        handleDocumentBtnClick()
        addFiles({
            ...item,
            name: i18n.language === 'hi' ? item.name.hi : item.name.en,
            path: i18n.language === 'hi' ? item.path.hi : item.path.en,
        })
    }

    const getImageUrl = (type, extension) => {
        let baseImgUrl = "/images/documents";
        if (type.toLowerCase() === 'folder') {
            return `${baseImgUrl}/folder.png`;
        } else {
            if (IMAGE_EXTENSIONS.includes(extension)) return `${baseImgUrl}/image_orange_thumb.svg`;
            else if (AUDIO_EXTENSIONS.includes(extension)) return `${baseImgUrl}/audio_orange_thumb.svg`;
            else if (VIDEO_EXTENSIONS.includes(extension)) return `${baseImgUrl}/video_orange_thumb.svg`;
            else if (PDF_EXTENSIONS.includes(extension)) return `${baseImgUrl}/pdf_orange_thumb.svg`;
            // else if (extension === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') return `${baseImgUrl}/default_orange_thumb.svg`;
            return `${baseImgUrl}/default_orange_thumb.svg`;
        }
    }

    const handleSearchBtnClick = () => {
        setIsSearchVisible(true)
        setShowSearchHeader(false)
    };

    const handleCloseSearchClick = () => {
        setSearchInputValue('')
        setIsSearchVisible(false)
        setShowSearchHeader(true)
        setFaqData(productData?.faqs['def'])
    };

    const handleSearchBarText = (e) => {
        e.preventDefault();
        clearTimeout(debounceSearchRef.current);

        let delay = 500;
        let { value } = e.target;
        setSearchInputValue(value)

        if (isEmpty(value)) delay = 0;
        debounceSearchRef.current = setTimeout(() => {
            setFaqData(productData?.faqs['def'].filter(item => ({ ...item, question: i18n.language === 'hi' ? item.question.hi : item.question.en }).question.toLowerCase().includes(value.toLowerCase())))
            clearTimeout(debounceSearchRef.current);
        }, delay)
    }


    const scrollAnime = async (element, top, left) => {
        element.scrollTo({
            top: top,
            left: left,
            behavior: 'smooth'
        })
    }

    const handleScrollAnime = async (el) => {
        console.log('scrolling', el)

        await sleep(1000 * 1.5)
        scrollAnime(el, 0, 200)

        await sleep(1000 * 3)
        scrollAnime(el, 0, 0)
    }

    // effect to create scroll animation at navigation bar
    useEffect(() => {
        if (!isMobile) return;
        if (isEmpty(proDetailNavBarRef.current)) return



        // if active card is faq then scroll to rigth
        handleScrollAnime(proDetailNavBarRef.current)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, proDetailNavBarRef.current])

    useEffect(() => {

        if (!isMobile) return;
        if (isEmpty(proDetailNavBarRef.current)) return;

        if (activeCardStatus === PRODUCT_DETAILS_NAVIGATION_STATUS[0].toUpperCase()) {
            scrollAnime(proDetailNavBarRef.current, 0, -200);
            return;
        }

        if (activeCardStatus === PRODUCT_DETAILS_NAVIGATION_STATUS[1].toUpperCase()) {
            scrollAnime(proDetailNavBarRef.current, 0, -200);
            return;
        }

        if (activeCardStatus === PRODUCT_DETAILS_NAVIGATION_STATUS[2].toUpperCase()) {
            scrollAnime(proDetailNavBarRef.current, 0, 400);
            return;
        }

        if (activeCardStatus === PRODUCT_DETAILS_NAVIGATION_STATUS[3].toUpperCase()) {
            scrollAnime(proDetailNavBarRef.current, 0, 200)
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, proDetailNavBarRef.current, activeCardStatus])


    return (
        <>
            {
                !showProductComparison && !showEmiCalc && !showSavingsCalculator && (
                    <>
                        <div className={classes.productDetailBox}
                            ref={ProductDetailBoxRef}
                        >
                            {
                                <>
                                    <div className={classes.productDetailBg} ref={ProductDetailBgRef}
                                    // style={{ backgroundImage: `url(${isEmpty(productData.orgCoverImage) ? '/images/sample-image/master_o_brand_icon.png' : productData.orgCoverImage}) ${isMobile ? ', radial-gradient(transparent, black)' : ''}`, backgroundColor: '#fff' }}
                                    >
                                        {isMobile && (
                                            <div className={classes.productDetailMobileHeader}
                                                ref={ProductDetailMobileHeaderRef}
                                                // style={{ height: getHeaderHeight() }}
                                                style={{ height: getHeaderHeight(), paddingTop: isNativeApp() ? constants.HEADER_TOP_PADDING : '' }}
                                            >
                                                <div className={`${classes.centerFlex} ${classes.backButton}`}
                                                    onClick={handleBackBtnClick}>
                                                    <HeaderBackButton />
                                                </div>
                                                <div className={`${classes.centerFlex} ${classes.headerTitle}`}>
                                                    {/* {headerText} */}
                                                    {productData?.name}
                                                </div>
                                                <div className={`${classes.centerFlex} ${classes.headerIcons}`}>

                                                    {
                                                        activeCardStatus === PRODUCT_DETAILS_NAVIGATION_STATUS[0].toUpperCase() && !isEmpty(productData) && productData.isVoiceOverAvailable && (
                                                            <LaVoiceOverAudioPlayer
                                                                voiceOverTrack={productData?.summaryVoiceOverTrack[i18n.language === 'hi' ? 'hi' : 'en']}
                                                                // isAutoPlay={false}
                                                                autoPlay={true}
                                                            />
                                                        )
                                                    }
                                                    {/* <SearchBarForFaq /> */}
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                    <div className={classes.productDetailWrapper}>
                                        {/* navigation  */}
                                        <div className={`${classes.productDetailNavigationContainer}`} ref={proDetailNavBarRef}>

                                            <ul className={classes.productDetailNavigation}>
                                                {

                                                    PRODUCT_DETAILS_NAVIGATION_STATUS.map((item, index) => (
                                                        <li
                                                            key={`${item}-${index}`}
                                                            className={`${classes.productDetailNavigationItem}`}
                                                        >
                                                            <a
                                                                className={`${classes.productDetailNavigationLink} ${item.toUpperCase() === activeCardStatus ? classes.activeCard : ''}`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigate(`?active=${item.toLowerCase()}${isEmpty(selectedPersona) ? "" : `&persona=${selectedPersona}`}`)
                                                                    // setActiveCardStatus(item)
                                                                }}
                                                            >
                                                                {t(item)}
                                                            </a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>

                                        {/* content  */}
                                        <div className={`${classes.productDetailContentWrapper} scrollbar-hidden`}
                                            style={{
                                                maxHeight: isMobile ? `calc(100dvh - ${BOTTOM_NAV_HEIGHT} - ${BannerHeight} - ${NavigationContainerHeight})` : '100%', // 3rem is height of
                                                padding: activeCardStatus === PRODUCT_DETAILS_NAVIGATION_STATUS[3].toUpperCase() ? '0% 3% 0%' : ''
                                            }}
                                        >
                                            <div className={classes.productDetailContentContainer}>


                                                {

                                                    activeCardStatus === PRODUCT_DETAILS_NAVIGATION_STATUS[0].toUpperCase() && (
                                                        <div className={classes.summaryCarouselContainer}>
                                                            <Carousel
                                                                autoPlaySpeed={3000}
                                                                centerMode={false}
                                                                className={classes.carouselContainer}
                                                                containerClass="container-with-dots"
                                                                dotListClass=""
                                                                draggable={true} // Ensures that swiping is enabled
                                                                focusOnSelect={false}
                                                                infinite={false}
                                                                itemClass=""
                                                                keyBoardControl
                                                                minimumTouchDrag={90}
                                                                pauseOnHover
                                                                renderArrowsWhenDisabled={false}
                                                                renderButtonGroupOutside={false}
                                                                renderDotsOutside={false}
                                                                responsive={responsive}
                                                            >
                                                                {!isEmpty(summaryData) && summaryData.map((item, index) => (
                                                                    <div
                                                                        key={index} // Unique key for each item
                                                                        className={classes.carouselItem}
                                                                    >
                                                                        <img
                                                                            src={item.summaryImage} // Assuming each item has an image property
                                                                            alt={`Carousel item ${index}`}
                                                                            className={classes.carouselImage}
                                                                        />
                                                                        <div
                                                                            className={classes.summaryText}
                                                                            dangerouslySetInnerHTML={{ __html: i18n.language === 'hi' ? item.hi : item.en }}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </Carousel>
                                                        </div>
                                                    )
                                                }





                                                {
                                                    // product comparison box
                                                    activeCardStatus === PRODUCT_DETAILS_NAVIGATION_STATUS[1].toUpperCase() && (
                                                        <div className={classes.comparisonBox}>
                                                            <div className={classes.comparisonList}>
                                                                {
                                                                    RIVAL_PRODUCT_DETAILS[productId - 1].competitorData.map((item, index) => (
                                                                        // RIVAL_PRODUCT_DETAILS.map((item, index) => (
                                                                        // PRODUCT_DETAIL_LIST[productId - 1]?.name !== item?.name &&
                                                                        (
                                                                            <div
                                                                                className={classes.comparisonItem}
                                                                                onClick={() => {
                                                                                    setShowProductComparison(true)
                                                                                    setInnerBoxHeaderText(`${PRODUCT_DETAIL_LIST[productId - 1].name} VS ${item.name}`)
                                                                                }}
                                                                            >
                                                                                <div className={classes.comparisonItemLeft}>
                                                                                    <img src={PRODUCT_DETAIL_LIST[productId - 1].productImage} className={classes.comparisonItemImg} alt="product" />
                                                                                    <div className={classes.comparisonItemTitle}>{PRODUCT_DETAIL_LIST[productId - 1].name}</div>
                                                                                </div>
                                                                                <div className={classes.comparisonItemRight}>
                                                                                    <img src={item.productImage} className={classes.comparisonItemImg} alt="product"
                                                                                    // style={{ filter: 'blur(10px)' }}

                                                                                    />
                                                                                    <div className={classes.comparisonItemTitle}>{item.name}</div>
                                                                                </div>
                                                                                <div className={`${classes.centerFlex} ${classes.comparisonItemMid}`}>VS</div>
                                                                            </div>
                                                                        ))
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    // documents viewer
                                                    activeCardStatus === PRODUCT_DETAILS_NAVIGATION_STATUS[2].toUpperCase() && (
                                                        <div className={classes.documentsBox}>
                                                            <div className={`${classes.documentFolder} scrollbar-hidden`}>
                                                                {
                                                                    PRODUCT_FILES_LIST[productId - 1].documentsData.map(item => {
                                                                        // PRODUCT_FILES_LIST.map(item => {
                                                                        return (
                                                                            <div className={classes.folderBox} key={item.id}
                                                                                onClick={() => handleFileClick(item)}
                                                                            >
                                                                                <img src={getImageUrl(item.type, item.mimeType)} width={80} style={{ filter: isDarkMode ? item.type.toLowerCase() === 'folder' ? 'none' : 'brightness(0) invert(1)' : 'none' }} />
                                                                                <div className={classes.folderName}>{i18n.language === 'hi' ? item.name.hi : item.name.en}</div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>


                                                        </div>
                                                    )
                                                }
                                                {
                                                    // product faqs box
                                                    activeCardStatus === PRODUCT_DETAILS_NAVIGATION_STATUS[3].toUpperCase() && (
                                                        !isEmpty(faqData) ? (
                                                            <div className={classes.faqsBox}>
                                                                <div className={`${classes.searchBoxWrapper} ${classes.centerFlex}`}>
                                                                    {/* <SearchBarForFaq /> */}


                                                                    {/* {
                                                                    showSearchHeader && (
                                                                        <Box> <img src="/images/icons/search.svg" alt="search" width={20} onClick={handleSearchBtnClick} /> </Box>
                                                                    )
                                                                } */}

                                                                    {
                                                                        isSearchVisible && (
                                                                            <Slide direction="left" in={isSearchVisible} container={containerRef.current}>
                                                                                <div className={classes.searchBox} style={{ top: isNativeApp() ? constants.HEADER_TOP_PADDING : '' }}>
                                                                                    <img src="/images/icons/search.svg" alt="search" width={18} className={classes.searchIcon} />
                                                                                    <input onChange={handleSearchBarText} value={searchInputValue} />
                                                                                    {/* <img src="/images/icons/close.svg" alt="search" width={20} className={classes.closeIcon} onClick={handleCloseSearchClick} /> */}
                                                                                </div>
                                                                            </Slide>
                                                                        )
                                                                    }
                                                                </div>
                                                                {
                                                                    !isEmpty(faqData) && faqData?.map((item, index) => (
                                                                        <Accordion
                                                                            key={item.id}
                                                                            sx={theme => ({
                                                                                backgroundColor: theme.palette.voilet,
                                                                                // marginBottom: '1rem',
                                                                                borderColor: 'transparent'
                                                                            })}
                                                                            disableGutters
                                                                            square
                                                                            expanded={expanded === `panel${index + 1}`}
                                                                            onChange={handleAccordionChange(`panel${index + 1}`)}
                                                                        >
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon sx={theme => ({ color: theme.palette.orange })} />}
                                                                                aria-controls={`panel${index + 1}bh-content`}
                                                                                id={`panel${index + 1}bh-header`}
                                                                            >
                                                                                <Typography sx={theme => ({
                                                                                    color: theme.palette.fontColor,
                                                                                    fontSize: '1rem',
                                                                                    fontWeight: 600,
                                                                                })}>
                                                                                    <div dangerouslySetInnerHTML={{ __html: i18n.language === 'hi' ? item.question.hi : item.question.en }}></div>
                                                                                </Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <Typography sx={theme => ({
                                                                                    color: theme.palette.fontColor,
                                                                                    fontSize: '1rem',
                                                                                    "& b": {
                                                                                        fontWeight: 800,
                                                                                        color: theme.palette.orange,
                                                                                    }
                                                                                })}>
                                                                                    <div dangerouslySetInnerHTML={{ __html: i18n.language === 'hi' ? item.answer.hi : item.answer.en }}></div>
                                                                                </Typography>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    ))

                                                                }
                                                            </div>
                                                        ) : (
                                                            <div className={classes.noDataBox}>
                                                                Coming soon
                                                            </div>
                                                        ))
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </>
                                // )
                            }
                        </div >
                        <div className={classes.FloatingMenuContainer}>
                            <div className={classes.FloatingMenu}>
                                <div className={`${classes.centerFlex} ${classes.FloatingMenuIcon}`} onClick={() => setOpenFloatingMenu(prev => !prev)}>
                                    <AddRoundedIcon sx={{
                                        fontSize: '2rem',
                                        color: '#FFF',
                                        transform: `${openFloatingMenu ? 'rotate(45deg)' : 'rotate(0deg)'}`,
                                        transformOrigin: 'center center',
                                        transition: 'all 0.3s linear'
                                    }} />
                                </div>
                                <ul className={classes.FloatingMenuList}
                                    style={{
                                        transform: openFloatingMenu ? 'scale(1)' : 'scale(0)'
                                    }}
                                >
                                    {
                                        productId < 2 && <li className={classes.FloatingMenuItem} onClick={() => {
                                            setShowSavingsCalculator(true)
                                            setOpenFloatingMenu(false);
                                            setInnerBoxHeaderText("Savings Calculator");
                                        }}>
                                            <Badge color="info" badgeContent={'NEW'} anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        top: '-10px',
                                                        left: '15px',
                                                    }
                                                }}>
                                                Savings Calculator
                                            </Badge>
                                        </li>
                                    }
                                    {
                                        productId < 2 && <li className={classes.FloatingMenuItem} onClick={() => {
                                            setShowEmiCalc(true)
                                            setOpenFloatingMenu(false);
                                            setInnerBoxHeaderText("TCO Calculator")
                                        }}>
                                            <Badge color="info" badgeContent={'NEW'} anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        top: '-10px',
                                                        left: '15px',
                                                    }
                                                }}>
                                                TCO Calculator
                                            </Badge>
                                        </li>
                                    }

                                    <li
                                        className={classes.FloatingMenuItem}
                                        onClick={() => {
                                            handleFileClick(PRODUCT_FILES_LIST[productId - 1].documentsData.filter(item => item.findingName === 'one_pager')[0])
                                        }}
                                    >One-pager</li>
                                    <li
                                        className={classes.FloatingMenuItem}
                                        onClick={() => {
                                            handleFileClick(PRODUCT_FILES_LIST[productId - 1].documentsData.filter(item => item.findingName === 'brochure')[0])
                                        }}
                                    >Brochure</li>
                                    <li
                                        className={classes.FloatingMenuItem}
                                        onClick={() => {
                                            handleFileClick(PRODUCT_FILES_LIST[productId - 1].documentsData.filter(item => item.findingName === 'video')[0])
                                        }}
                                    >Video</li>

                                    {/* <li className={classes.FloatingMenuItem}>Blog</li> */}
                                    {/* <li className={classes.FloatingMenuItem}>Create Presentation</li> */}
                                </ul>
                            </div>
                        </div>
                    </>
                )
            }
            {
                showProductComparison && (
                    <ProductComparison handleBackBtnCompClick={handleBackBtnCompClick} headerText={innerBoxHeaderText} productId={productId} />
                )
            }
            {
                showSavingsCalculator && (
                    <SavingsCalculator handleBackBtnCompClick={handleBackBtnCompClick} headerText={innerBoxHeaderText} />
                )
            }
            {
                showEmiCalc && (
                    <TcoCalculator2 handleBackBtnCompClick={handleBackBtnCompClick} headerText={innerBoxHeaderText} />
                )
            }
            {
                isFileViewerOpen && (
                    <DocumentModal rootGridRows="8% 92% 0%" openingFrom="SALES_ENABLEMENT" />
                )
            }
        </>
    )
}

export default ProductDetails;






// {
//     // product summary box
//     activeCardStatus === PRODUCT_DETAILS_NAVIGATION_STATUS[0].toUpperCase() && (

//         <ul className={classes.summaryBox}>
//             {
//                 // productData?.summary['default']?.map((item, index) => (
//                 !isEmpty(summaryData) && summaryData.map((item, index) => (
//                     <li key={index}>
//                         <span></span> {/* custom bullet */}
//                         <div className={classes.summaryText}
//                             dangerouslySetInnerHTML={{ __html: i18n.language === 'hi' ? item.hi : item.en }}
//                         ></div>
//                     </li>
//                 ))
//             }
//         </ul>
//     )
// }