import { gbaActionTypes } from "./gba.types";

export const handleGbaScreenState = (data) => ({
    type: gbaActionTypes.GBA_SCREENSTATE,
    payload: data
});

export const handleGbaGameState = (data) => ({
    type: gbaActionTypes.GBA_GAMESTATE,
    payload: data
})