import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import commonReducer from "./common/common.reducer";
import authReducer from "./auth/auth-reducer";
import spotlightReducer from './spotlight/spotlight.reducer';
import gbaReducer from './gba/gba.reducer';
import microskillReducer from './microskill/microskill.reducer';
import eventReducer from './event-tracking/event.reducer';
import commonPersistReducer from './common-persist/common-persist.reducer';
import loginEventReducer from './events/events.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'microskill', 'commonPersist']
}

const rootReducer = combineReducers({
    common: commonReducer,
    auth: authReducer,
    spotlight: spotlightReducer,
    gba: gbaReducer,
    microskill: microskillReducer,
    event: eventReducer,
    commonPersist: commonPersistReducer,
    loginEvent: loginEventReducer
})

export default persistReducer(persistConfig, rootReducer);