import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// components
import HeaderBackButton from '../layout-elements/header-back-button';

// mui
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { styled } from "@mui/styles";

// utils
import isEmpty from '../../utils/isEmpty';
import { getHeaderHeight, isNativeApp } from '../../utils/commonUtil';

// styles
import useStyles from "./index.styles";

// react i18next
import { useTranslation } from 'react-i18next';

// constants
import constants from '../../constants';
import { salesEnablementDataMahindra } from '../../constants/sales-enablement-data-mahindra';
import { salesEnablementDataCSB } from '../../constants/sales-enablement-data-csb';

const ProductComparison = (props) => {
    const { handleBackBtnCompClick, headerText, productId } = props;
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyles();
    const { HEADER_HEIGHT, HEADER_TOP_PADDING } = constants
    const { PRODUCT_DETAIL_LIST, RIVAL_PRODUCT_DETAILS } = productId === 1 ? salesEnablementDataMahindra : salesEnablementDataCSB
    const [productOneName, productTwoName] = headerText.split(" VS ");
    const productOne = PRODUCT_DETAIL_LIST.filter((item) => item.name === productOneName)[0];
    const productTwo = RIVAL_PRODUCT_DETAILS[productId - 1].competitorData.filter((item) => item.name === productTwoName)[0];

    const { t, i18n } = useTranslation();
    console.log(RIVAL_PRODUCT_DETAILS, PRODUCT_DETAIL_LIST, productOne, productTwo, productOneName, productTwoName, headerText)

    const styleTableBodyCell = {
        color: (theme) => theme.palette.fontColor,
        font: 'normal normal normal 0.9rem Open Sans',
        borderBottom: theme => `1px solid ${theme.palette.fontColor}`,
        maxWidth: window.innerWidth / 3,
    }

    const styleTableHeadCell = {
        color: (theme) => theme.palette.fontColor,
        font: 'normal normal bold 0.9rem Open Sans',
        borderBottom: theme => `1px solid ${theme.palette.fontColor}`
    }

    return (
        <div className={classes.productComparisonRoot}>
            <div
                className={classes.productComparisonHeader}
                style={{ height: getHeaderHeight(), paddingTop: isNativeApp() ? constants.HEADER_TOP_PADDING : '' }}
            >
                <div
                    className={`${classes.centerFlex} ${classes.backButton}`}
                    // style={{ marginTop: isNativeApp() ? HEADER_TOP_PADDING : null }}
                    onClick={() => handleBackBtnCompClick(false)}
                >
                    <HeaderBackButton />
                </div>
                <div className={`${classes.centerFlex} ${classes.headerTitle}`}>
                    {headerText}
                </div>
            </div>
            <div
                className={`${classes.centerFlex} ${classes.productComparisonBody}`}
                style={{
                    height: `calc(100% - ${getHeaderHeight()})`,
                }}
            >
                <div className={`${classes.container}`}>
                    {
                        !isEmpty(productOne.specsStatements[productTwo.findingName]) && (
                            productOne.specsStatements[productTwo.findingName].map(item => (<div className={classes.statementBox}>
                                {/* <div className={classes.statementText}
                                    dangerouslySetInnerHTML={{ __html: i18n.language === 'hi' ? item.statement.hi : item.statement.en }}
                                >
                                </div> */}
                            </div>
                            ))
                        )
                    }
                    <TableContainer component={Paper} sx={(theme) => ({
                        backgroundColor: theme.palette.darkVoilet,
                        boxShadow: '0px 0px 0px 0px transparent',
                        overflowX: 'auto',
                        [theme.breakpoints.down('md')]: {
                            backgroundColor: theme.palette.mobilePerfPageCardsBg,
                        }
                    })}>
                        <Table sx={{
                            minWidth: 300
                        }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={(theme) => ({
                                    backgroundColor: theme.palette.darkVoilet,
                                    [theme.breakpoints.down('md')]: {
                                        backgroundColor: theme.palette.mobilePerfPageCardsBg,
                                    }
                                })}>
                                    <TableCell align="center" sx={styleTableHeadCell}>{t("Description")}</TableCell>
                                    <TableCell align="center" sx={styleTableHeadCell}>{productOneName}</TableCell>
                                    <TableCell align="center" sx={styleTableHeadCell}>{productTwoName}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>


                                <div style={{ display: 'none' }}>
                                    <TableRow key={'abc'} sx={{}}>
                                        <TableCell align="center" sx={styleTableBodyCell}>{t('Image')}</TableCell>
                                        <TableCell align="center" sx={styleTableBodyCell}>
                                            <img src={productOne.productImage}
                                                alt={productOne.name}
                                                // height="110px"
                                                width={'100%'}
                                                style={{
                                                    // aspectRatio: '16/9'
                                                }} />
                                        </TableCell>
                                        <TableCell align="center" sx={styleTableBodyCell}>
                                            <img src={productTwo.productImage}
                                                alt={productTwo.name}
                                                // height="110px"
                                                width={'100%'}
                                                style={{
                                                    // aspectRatio: '16/9'
                                                    filter: 'blur(10px)'
                                                }} />
                                            {/* {productTwo.specifications[row.id - 1].value} */}
                                        </TableCell>
                                    </TableRow>
                                </div>





                                {
                                    productOne.specifications[productTwo.findingName].map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={theme => ({
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                '&:nth-of-type(odd)': {
                                                    backgroundColor: theme.palette.darkVoilet2,
                                                },

                                                '&:nth-of-type(even)': {
                                                    backgroundColor: theme.palette.darkVoilet,
                                                },

                                                fontWeight: 'normal',
                                                borderTop: '0px',
                                                borderBottom: '0px',
                                                height: '3.99rem',
                                                [theme.breakpoints.down('md')]: {
                                                    backgroundColor: theme.palette.mobilePerfPageCardsBg,
                                                }

                                            })}
                                        >

                                            <TableCell align="center" sx={styleTableBodyCell}>{i18n.language === 'hi' ? row.title.hi : row.title.en}</TableCell>
                                            <TableCell align="center" sx={styleTableBodyCell}>
                                                <div dangerouslySetInnerHTML={{ __html: i18n.language === 'hi' ? row.value.hi : row.value.en }}></div>
                                            </TableCell>
                                            <TableCell align="center" sx={styleTableBodyCell}>
                                                <div dangerouslySetInnerHTML={{ __html: i18n.language === 'hi' ? productTwo.specifications[row.id - 1].value.hi : productTwo.specifications[row.id - 1].value.en }}></div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div >
        </div >
    )
}

export default ProductComparison