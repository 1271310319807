import { makeStyles } from "@mui/styles";
import commonUtil from "../../../utils/commonUtil";

const isFHDScreen = commonUtil.getScreenResolution() === "FHD";

const useStyle = makeStyles((theme) => ({
    learnoCasinoRoot: {
        color: "#fff",
        boxSizing: "border-box",
        //background: "linear-gradient(#f7591f, #fcb340)",
        position: "relative",
        transition: "0.7s",
        userSelect: 'none',
        transition: 'background 1s',
        height: '100%',
        background: 'linear-gradient(rgb(24, 91, 11), rgb(24, 91, 11))',
        backgroundSize: 'cover'
    },
    startPage: {
        height: "100%",
        backgroundImage: "url(/popcornMatch/popcorn_match_overlay.png)"
    },
    centerFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    bgImgBox: {
        textAlign: "center",
        height: "40%"
    },
    mainText: {
        animation: "$zoomInOut 1.2s ease 0s infinite normal forwards running",
    },
    titleImage: {
        width: "85%"
    },
    btnBox: {
        textAlign: "center",
        height: "60%"
    },
    btnContainer: {
        width: "100%",
    },
    howToPlay: {
        width: "85%",
        fontFamily: "MotionControl",
        fontSize: "1.8em",
        border: "none",
        padding: "0.6rem",
        cursor: "pointer",
        borderRadius: "5px",
        background: "none",
        marginBottom: "30px",
        color: "#fff",
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '2.5rem',
        },
    },
    startBtn: {
        width: "85%",
        fontFamily: "MotionControl",
        fontSize: "2em",
        background: "#ffe66e",
        border: "none",
        padding: "0.6rem",
        cursor: "pointer",
        borderRadius: "5px",
        boxShadow: '0 2px 7px -1px #000',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '3rem',
        },
    },

    "@keyframes animateOpacity": {
        "0%": { opacity: "0" },
        "100%": { opacity: "1" },
    },
    "@keyframes shakeObj": {
        "0%": { transform: "rotate(3deg)" },
        "10%": { transform: "rotate(-3deg)" },
        "20%": { transform: "rotate(3deg)" },
        "30%": { transform: "rotate(-3deg)" },
        "40%": { transform: "rotate(3deg)" },
        "50%": { transform: "rotate(-3deg)" },
        "60%": { transform: "rotate(3deg)" },
        "70%": { transform: "rotate(-3deg)" },
        "80%": { transform: "rotate(3deg)" },
        "90%": { transform: "rotate(-3deg)" },
        "95%": { transform: "rotate(3deg)" },
        "100%": { transform: "rotate(0deg)" },
    },
    "@keyframes slideFromBottom": {
        "0%": { transform: "translateY(250%)" },
        "100%": { transform: "translateY(0)" },
    },
    "@keyframes zoomInOut": {
        "0%": { transform: "scale(1)" },
        "50%": { transform: "scale(0.95)" },
        "100%": { transform: "scale(1)" },
    },


    // questionBox container
    qstnContainer: {
        height: "100%",
        // margin: "0.5rem 0.5rem 0 0.5rem",
        transform: "translateY(-200px)",
        animation: "$translateQstnContainer 1s ease 0s 1 normal forwards running",
        zIndex: '2',
        position: 'relative',
        opacity: '0.8',
    },
    "@keyframes translateQstnContainer": {
        "0%": { transform: "translateY(-200px)" },
        "100%": { transform: "translateY(0)" }
    },
    qstnBox: {
        backgroundColor: "#000000",
        // borderRadius: "10px",
        height: "100%",
        fontSize: "1.2em",
        // boxShadow: "0px 10px 20px #0000004D",
        display: 'grid',
        gridTemplateRows: '30% 70%'
    },
    assetBox: {
        display: "grid",
        // gridTemplateColumns: "1fr 1fr",
        gridTemplateColumns: "1fr 1fr 1fr",
        padding: "12px",
        alignItems: 'center'
    },
    lifeText: {
        display: "flex",
        alignItems: "center",
    },
    timerText: {
        display: "flex",
        alignItems: "center",
        justifySelf: "end",
        // padding: "0 15px",
        //justifyContent: "right",
        // minWidth: '60px'
    },
    icon: {
        width: "1em",
        height: "1emfdf",
        margin: "0 5px"
    },
    life: {
        fontWeight: '600',
        fontSize: '1em',
    },
    time: {
        fontWeight: '600',
        width: '30px',
        textAlign: 'center',
        fontSize: '1em'
    },
    timerIcon: {
        width: "1.1em",
        height: "1.1em",
        margin: "0 5px"
    },
    qstn: {
        textAlign: "center",
        transition: '.9s',
        fontFamily: 'Open Sans',
        // fontWeight: '500',
        fontSize: '0.9em',
        padding: '0 1rem',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1rem',
        },
    },
    questionText: {
        fontWeight: 'normal',
        fontSize: '0.88em',
    },

    //game play
    container: {
        display: "grid",
        height: "100%",
        gridAutoRows: "25% 75%",
        overflow: 'hidden',
        touchAction: 'none',
        opacity: '0',
        background: 'url(/images/gba/learno_casino_bg.png)',
        backgroundSize: 'cover',
        animation: '$animateGameLandingPage 2s ease 0s 1 normal forwards running '
    },
    "@keyframes animateGameLandingPage": {
        "0%": { opacity: '0' },
        "100%": { opacity: '1' },
    },
    questionBox: {
        height: "60%",
        padding: "0.7rem"
    },
    qBox: {
        backgroundColor: "#000",
        height: "100%",
        padding: "1rem",
        borderRadius: "5px",
    },
    dragCardContainer: {
        display: "grid",
        height: "100%",
        gridAutoRows: "50% 50%",
        transition: '.5s',
        position: 'relative'
    },
    answerContainer: {
        display: "flex",
        padding: "1.5rem 0",
        flexWrap: "wrap",
        justifyContent: 'center',
        "& > :nth-child(1)": {
            paddingLeft: '1rem'
        },
        "& > :nth-child(3)": {
            paddingRight: '1rem'
        }
    },
    answer: {
        flex: "0 1 33%",
        display: "flex",
        alignItems: 'flex-start',
        justifyContent: 'center',
        transition: '.4s',
        padding: '0 1rem',
        cursor: 'pointer'
    },
    topCard: {
        position: 'relative',
        height: '60%',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        transition: '0.2s'
        // transition: '0.4s',

    },
    bottomCard: {
        position: 'relative',
        height: '60%',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        // transition: '0.4s',

    },
    answerText: {
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '0.9em',
        textAlign: 'center',
        width: '115%',
        // width: '13ch',
        fontWeight: 'bold',
        // whiteSpace: 'normal',
        // wordBreak: 'break-word',
        fontFamily: 'Montserrat',
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.9rem',
        },
    },
    answerImg: {
        height: '60px',
    },

    option: {
        flex: "1 1 33%",
        display: "flex",
        padding: '0 1.2rem',
        alignItems: 'center',
        justifyContent: 'center',
    },
    qstnImgBox: {
        position: 'relative',
        height: '60%',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down("sm")]: {
            // mobile
            height: '45%',
            width: '60%'
        },
    },
    qstnText: {
        position: 'absolute',
        top: '108%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '1.1em',
        textAlign: 'center',
        width: '14ch',
        // whiteSpace: 'normal',
        // wordBreak: 'break-word',
        fontFamily: 'Montserrat',
    },
    qstnImg: {
        height: '60px',
    },
    progressBarContainer: {
        position: "relative",
        height: "7px",
        zIndex: "2",
        width: (isMobile) => {
            return `calc(100% - ${isMobile ? '0px' : '0px'})`
        },
        borderRadius: (isMobile) => {
            return `${isMobile ? '0px' : '0px'}`
        },
        // margin: (isMobile) => { return `${isMobile ? "" : "0.3rem auto"}` },
        overflow: 'hidden',
        backgroundColor: theme.palette.white,
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.progressBarBg,
        },
    },
    progressBar: {
        position: "absolute",
        top: "0",
        left: "0",
        height: "7px",
        backgroundColor: "#ffd042",
        width: '0',
        transition: '.4s',
    },
    boxShadowBreathingAnimation: {
        transition: '2s',
        animation: '$boxShadowBreathingAnimation 1s ease 0s infinite normal forwards running '
    },
    dragItemAction: {
        fontFamily: 'MotionControl',
        fontSize: '1.6em',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.8rem',
        },
    },
    '@keyframes boxShadowBreathingAnimation': {
        '0%': { boxShadow: '0 0 15px -2px red' },
        '10%': { boxShadow: '0 0 16px -2px red' },
        '20%': { boxShadow: '0 0 17px -1px red' },
        '30%': { boxShadow: '0 0 18px -1px red' },
        '40%': { boxShadow: '0 0 19px 0px red' },
        '50%': { boxShadow: '0 0 20px 0px red' },
        '60%': { boxShadow: '0 0 19px -1px red' },
        '70%': { boxShadow: '0 0 18px -1px red' },
        '80%': { boxShadow: '0 0 17px -2px red' },
        '90%': { boxShadow: '0 0 16px -2px red' },
        '100%': { boxShadow: '0 0 15px -2px red' },
    },
    gradientBox: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'linear-gradient(rgb(218 120 120), rgb(123 0 0))',
        opacity: 0,
        zIndex: 0
    },
    opacityAnimation: {
        animation: '$opacityAnimation 1.2s ease 0s infinite normal forwards running '
    },
    '@keyframes opacityAnimation': {
        // '0%': { opacity: '1' },
        // '60%': { opacity: '1' },
        // '90%': { opacity: '0' },
        // '100%': { opacity: '0.1' },
        '0%': { opacity: '0' },
        '10%': { opacity: '1' },
        '60%': { opacity: '1' },
        '100%': { opacity: '0' },
    },
    questionNo: {
        textAlign: 'center',
        fontWeight: 600,
        color: '#F5CF38',
        letterSpacing: '0.2rem'
    },


    // gamePlay
    gameContanier: {
        display: 'grid',
        gridTemplateRows: '80% 20%',
        position: 'relative',
        zIndex: 2,
    },
    circleContainer: {
        position: 'relative'
    },
    circleBox: {
        background: '#B97846',
        margin: 'auto',
        borderRadius: '50%',
        position: 'relative',
        boxShadow: '0px 2px 9px -1px rgb(0 0 0 / 60%)'
    },
    centerCircle: {
        position: 'absolute',
        width: '45%',
        height: '45%',
        borderRadius: '50%',
        backgroundColor: '#ceb308',
        // background: 'linear-gradient(#621ff7, #ed40fc)',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '0.75em',
        fontWeight: 600,
        padding: '0.5rem',
        textAlign: 'center'
    },
    optionContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        position: 'relative',
        zIndex: '2'
    },
    optionText: {
        border: '1px solid rgb(255 255 255 / 50%)',
        fontSize: '0.8em',
        fontWeight: 600,
        textAlign: 'center',
        cursor: 'pointer'
    },
    optionBox: {
        position: 'relative',
        display: 'grid',
        gridTemplateRows: '40% 60%',
        '&:nth-child(even)': {
            '&:nth-child(even)': {
                backgroundColor: '#ff000070'
            }
        },
        '&:nth-child(odd)': {
            '&:nth-child(odd)': {
                backgroundColor: '#00000070'
            }
        }
    },
    chipBox: {
        border: '1px solid rgb(255 255 255 / 50%)',
        fontFamily: "MotionControl",
        fontSize: '1.5em',
        position: 'relative'
    },

    rollerContainer: {
        position: 'absolute',
        width: '80%',
        height: '80%',
        borderRadius: '50%',
        border: '3px solid #ffe016',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    roller: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        transition: '0.5s'
    },
    firstRoller: {
        width: 'calc(100% - 3px)'
    },
    secondRoller: {
        width: '80%'
    },
    chipContainer: {
        position: 'absolute',
        inset: '0 0 0 0',
    },
    chip: {
        position: 'absolute',
        transition: '0.5s',
        // filter: 'drop-shadow(0px 0px 4px #fff)'
    },
    chip1: {
        top: '-40px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    chip2: {
        top: '50%',
        right: '-40px',
        transform: 'translateY(-50%)',
    },
    chip3: {
        bottom: '-40px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    chip4: {
        left: '-40px',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    ballContainer: {
        position: 'absolute',
        inset: '0 0 0 0 '
    },
    ball: {
        position: 'absolute',
        // transition: '0.5s',
        // filter: 'drop-shadow(0px 0px 4px #fff)',
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        background: 'radial-gradient(circle at 5px 5px, #e79c64, #89410b)',
        zIndex: 2,
        boxShadow: '0px 0px 2px 0px rgb(0 0 0 / 56%)'
    },
    ball1: {
        top: '7px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    ball2: {
        top: '50%',
        right: '7px',
        transform: 'translateY(-50%)',
    },
    ball3: {
        bottom: '7px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    ball4: {
        left: '7px',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    doubleDownTextBox: {
        position: 'absolute',
        inset: '0 0 0 0',
        background: '#fff',
        color: '#000',
        fontSize: '1.3em',
        // fontWeight: 600,
        fontFamily: 'MotionControl',
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        padding: '0.8rem 0.2rem',
        cursor: 'pointer'
    },
    overlayBox: {
        position: 'absolute',
        inset: '0 0 0 0',
        background: 'transparent'
    },
    doubleDownText1: {
        textAlign: 'center'
    },
    doubleDownText2: {
        textAlign: 'center'
    },
    doubleDownCount: {
        fontSize: '2em',
        color: '#a4a4a4'
    },
    rotateFirstRoller: {
        // animation: '$rotateFirstRollerAnimation 2s ease 0s infinite normal forwards running ',
        animation: '$rotateFirstRollerAnimation 2.5s infinite linear',
        transition: 'animation-duration 0.8s ease'
    },
    '@keyframes rotateFirstRollerAnimation': {
        'from': {
            'transform': 'translate(-50% , -50%) rotate(0deg)',
        },
        'to': {
            'transform': 'translate(-50% , -50%) rotate(360deg)',
        }
    },
    rotateSecondRoller: {
        // animation: '$rotateFirstRollerAnimation 2s ease 0s infinite normal forwards running ',
        animation: '$rotateSecondRollerAnimation 2.5s infinite linear ',
    },
    '@keyframes rotateSecondRollerAnimation': {
        'from': {
            'transform': 'translate(-50% , -50%) rotate(360deg)',
        },
        'to': {
            'transform': 'translate(-50% , -50%) rotate(0deg)',
        }
    },
    spiralRotation: {
        // animation: '$spiralRotationAnimation 8s infinite linear ',
        top: '50% !important',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    '@keyframes spiralRotationAnimation': {
        '0%': {
            'transform': 'translate(-50%, -50%) rotate(0deg) translateX(100px) translateY(0) ',
        },
        '80%': {
            'transform': 'translate(-50%, -50%) rotate(360deg) translateX(50px) translateY(0)',
        },
        '100%': {
            'transform': 'translate(-50%, -50%) rotate(360deg) translateX(10px) translateY(0)',
        }
    },
    ballImage: {
        // opacity: '0',
        position: 'absolute',
        top: '2000px'
    },
    checkMark: {
        transition: '.6s',
        opacity: '0',
        width: '60%',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    incorrectGradientOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        // backgroundImage: 'linear-gradient(#800e315e, #f63b348c)',
        opacity: '0',
        transition: '.5s ease',
    },
    wrongAnswerAnimation: {
        animation: '$wrongAnswerAnim 1s ease-in-out 0s infinite normal forwards running'
    },
    "@keyframes wrongAnswerAnim": {
        "0%": {
            background: 'linear-gradient(rgb(24, 91, 11), rgb(24, 91, 11))',
        },
        "100%": {
            background: 'linear-gradient(rgb(91, 11, 11), rgb(91, 11, 11))',
        },
    },
    blinkAnimationEffect: {
        animation: '$blinkAnimation 1.5s ease-in-out 0s infinite normal forwards running',
    },
    '@keyframes blinkAnimation': {
        '0%': { opacity: 0 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0 }
    },
    dealerImage: {
        width: '20em',
        position: 'absolute',
        bottom: '22%',
        zIndex: '3',
    },
    answerType: {
        display: 'none',
        position: 'absolute',
        inset: '0 0 0 0',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.2em'
    },
    childChipBox: {
        width: '100%',
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    showMessageRoot: {
        position: 'absolute',
        inset: '0 0 0 0',
        fontFamily: "MotionControl",
        fontSize: '3.5em'
    },
    centerQText: {
        fontSize: '1.15em',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    redBorderBlink: {
        animation: '$redBorderBlinkAnimation 1.5s ease-in-out 0s infinite normal forwards running',
    },
    '@keyframes redBorderBlinkAnimation': {
        '0%': { boxShadow: '0px 0px 15px 6px #0000004D' },
        '100%': { boxShadow: '0px 0px 15px 6px #b8000099' }
    },

    // gamePlay
}));

export default useStyle;
