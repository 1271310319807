import { gbaActionTypes } from "./gba.types";
import constants from "../../constants";

const INITIAL_STATE = {
    screenState: constants.GBA_SCREEN_STATE[0],
    gameState: {}
}

const gbaReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case gbaActionTypes.GBA_SCREENSTATE:
            return {
                ...state,
                screenState: action.payload
            }

        case gbaActionTypes.GBA_GAMESTATE:
            return {
                ...state,
                gameState: action.payload
            }
        default:
            return state;
    }
}

export default gbaReducer