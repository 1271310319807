import { authActionTypes } from "./auth.types";

const INITIAL_STATE = {
    error: null,
    tokens: null,
    companyId: null,
    isThirdPartyIntegration: false,
    msalRequestToken: null,
    oktaRequestToken: null,
    userProfileData: {},
    user: {}
}
const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case authActionTypes.SET_CURRENT_USER:
            return {
                ...state,
                companyId: action.payload.companyId
            }
        case authActionTypes.SET_TOKEN:
            return {
                ...state,
                tokens: action.payload
            }
        case authActionTypes.IS_THIRD_PARTY_INTERGRATION:
            return {
                ...state,
                isThirdPartyIntegration: action.payload
            }
        case authActionTypes.SET_MSAL_REQUEST_TOKEN:
            return {
                ...state,
                msalRequestToken: action.payload
            }
        case authActionTypes.SET_OKTA_REQUEST_TOKEN:
            return {
                ...state,
                oktaRequestToken: action.payload
            }
        case authActionTypes.SET_USER_PROFILE_DATA:
            return {
                ...state,
                userProfileData: action.payload
            }
        case authActionTypes.SET_USER:
            return {
                ...state,
                user: action.payload
            }
        default:
            return state;
    }
}

export default authReducer