import React, { useState, useEffect } from 'react';

// utils
import isEmpty from "../../utils/isEmpty";

// styles
import useStyles from './index.styles';

const OtpInput = (props) => {
    const {
        otpLength = 4,
        setOtp,
        type = "text",
        otp
    } = props;

    const classes = useStyles();
    const [otpValue, setOtpValue] = useState(otp);

    const handleChange = (e) => {
        if (String(e.target.value).length < 7) {
            setOtpValue(e.target.value)
        }
    }

    useEffect(() => {
        // console.log("otpValue:", otpValue);
        setOtp(otpValue)
    }, [otpValue])

    const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
            if (next < otpLength) {
                elmnt.target.form.elements[next].focus()
            }
        }
    }

    return (
        <>
            {/* <div className={classes.otpBoxDesktop}> */}
            {
                <input
                    style={{ width: '100%', fontSize: '1rem', textAlign: 'left' }}
                    type='number'
                    autoComplete='off'
                    placeholder='Enter OTP'
                    onChange={e => handleChange(e)}
                    value={isEmpty(otpValue) ? '' : otpValue}
                />
            }
            {/* </div> */}
        </>

    );
}

export default OtpInput;