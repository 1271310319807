import { commonActionTypes } from "./common.types";

const INITIAL_STATE = {
    isToastOpen: false,
    toastMessage: '',
    toastDuration: 3000,
    toastSeverity: '',
    isDialogueOpen: false,
    dialogueTitle: '',
    dialogueContent: '',
    isMobile: false,
    isTablet: false,
    isSideDrawerCollapsed: true,
    isNativeApp: false,
    headerSearchText: '',
    viewMicroskillByCategory: true,
    isBrowserTabFocused: true,
    isRotatedMobile: false,
    isDocViewing: false,
    selectedLanguage: null,
    microkillCategories: [],
    selectedMicrokillCategories: [],
    viewLaOnboardingScreen: true,
    learnerJourneyEnded: false,
}

const commonReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case commonActionTypes.SET_IS_MOBILE:
            return {
                ...state,
                isMobile: action.payload
            }

        case commonActionTypes.SET_IS_TABLET:
            return {
                ...state,
                isTablet: action.payload
            }

        case commonActionTypes.OPEN_TOAST:
            return {
                ...state,
                isToastOpen: action.payload.isToastOpen,
                toastMessage: action.payload.toastMessage,
                toastDuration: action.payload.toastDuration,
                toastSeverity: action.payload.toastSeverity
            }

        case commonActionTypes.OPEN_DIALOGUE:
            return {
                ...state,
                isDialogueOpen: action.payload.isDialogueOpen,
                dialogueTitle: action.payload.dialogueTitle,
                dialogueContent: action.payload.dialogueContent,
            }
        case commonActionTypes.TOGGLE_SIDE_DRAWER:
            return {
                ...state,
                isSideDrawerCollapsed: action.payload,
            }
        case commonActionTypes.SET_IS_NATIVE_APP:
            return {
                ...state,
                isNativeApp: action.payload,
            }
        case commonActionTypes.SET_HEADER_SEARCH_TEXT:
            return {
                ...state,
                headerSearchText: action.payload,
            }
        case commonActionTypes.VIEW_MICROSKILL_BY_CATEGORY:
            return {
                ...state,
                viewMicroskillByCategory: action.payload,
            }
        case commonActionTypes.SET_BROWSER_TAB_FOCUS:
            return {
                ...state,
                isBrowserTabFocused: action.payload,
            }
        case commonActionTypes.ROTATE_MOBILE:
            return {
                ...state,
                isRotatedMobile: action.payload
            }
        case commonActionTypes.IS_DOC_VIEWING:
            return {
                ...state,
                isDocViewing: action.payload
            }
        case commonActionTypes.SET_SELECTED_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload
            }

        case commonActionTypes.SET_MICROSKILL_CATEGORIES:
            return {
                ...state,
                microkillCategories: action.payload
            }

        case commonActionTypes.SET_SELECTED_MICROSKILL_CATEGORIES:
            return {
                ...state,
                selectedMicrokillCategories: action.payload
            }
        case commonActionTypes.VIEW_LA_ONBOARDING_SCREEN:
            return {
                ...state,
                viewLaOnboardingScreen: action.payload,
            }
        case commonActionTypes.SET_LEARNER_JOURNEY_ENDED:
            return {
                ...state,
                learnerJourneyEnded: action.payload,
            }

        default:
            return state;
    }
}

export default commonReducer