import constants from "../../constants";
import axiosInstance from "../../utils/axios";
import isEmpty from "../../utils/isEmpty";
import { jwtDecode } from "jwt-decode";
// import { getAllModules } from "./learn.actions";

export const submitUserActivityData = async (payload = {}, authObj={}) => {
    try {
        if(localStorage.getItem('userType') === constants.USER_TYPES.MANAGER) return //disabling event tracking incase of manager
        if(!isEmpty(authObj)){ // code added for generic doc viewer
            payload.companyId = authObj.companyId;
            payload.userId = authObj.userId;
            payload.tokenId = authObj.tokenId;
            // console.log("submitUserActivityData:", payload);
            // return {}
        } else 
        if (localStorage.getItem('accessToken') !== null || localStorage.getItem('accessToken') !== undefined || localStorage.getItem('requestToken') !== null || localStorage.getItem('requestToken') !== undefined) {
            const tempData = localStorage.getItem('accessToken') === null ? jwtDecode(localStorage.getItem('requestToken')) : jwtDecode(localStorage.getItem('accessToken'));

            if (localStorage.getItem('accessToken') !== null || localStorage.getItem('accessToken') !== undefined) {
                payload.companyId = tempData.companyId;
                payload.userId = tempData.userId;
                payload.tokenId = tempData.tokenId;
            }
            else if (localStorage.getItem('requestToken') !== null || localStorage.getItem('requestToken') !== undefined) {
                payload.companyId = tempData.companyId;
                payload.userId = tempData.userId;
                payload.tokenId = tempData.tokenId;
            }
        }
        const respData = await axiosInstance.post(`/access/user-activity`, payload);
        return respData;
    } catch (err) {
        // console.log("submitUserActivityData:", err);
    }
}
