import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import RecordRTC, { MediaStreamRecorder } from "recordrtc";
import Recorder from 'recorder-js';
import getBlobDuration from 'get-blob-duration';
import * as SDK from "microsoft-cognitiveservices-speech-sdk";
// import * as facemesh from "@tensorflow-models/face-landmarks-detection";
// import Webcam from "react-webcam";
// import { drawMesh } from "../../../utils/faceMesh";
// import * as tf from "@tensorflow/tfjs";

import { useTranslation } from "react-i18next";

// mui
import { Button, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// components
import DialogAlert from "../../dialog-alert";

// redux
import { openDialogue } from "../../../redux/common/common.actions";
import { handleSpotlightScreenState } from "../../../redux/spotlight/spotlight.actions";

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";
import speechToText from "../../../utils/speechToTextUtils";

// constants
import constants from "../../../constants";

// theme
import palette from "../../../theme/palette";

// styles
import useStyles from "./index.styles";
import { getTranscriptMatch } from "../../../redux/spotlight/spotlight.api";
import { get } from "@okta/okta-auth-js";
import webViewJavaScriptBridge from "webview-javascript-bridge";

// const MAX_TIME = 120;
const WAITING_TIME = 3;
// const BUFFER_TIME_FOR_AUDIO_TO_TEXT_FOR_LAST_WORDS = 2;
// const minSoundThreshold = 0.01;
// const minSoundVolumeReq = 0
const minSoundVolumeReq = -27

// transcript match
// speech rate
// duration
// keyword match
// product mention
// tone (from backend)
// clarity (from backend)

const DIALOG_ALERT = {
    "MEDIA_PERMISSION": "Media Permission",
    "PERMISSION_DENIED": "No Camera Permission Granted"
}
const CAMERA_PERMISSION_ERROR_MESSAGE_NATIVE = `Please grant camera permissions in your system settings and restart the app.`
const CAMERA_PERMISSION_ERROR_MESSAGE = 'You have denied camera permission. To access to spotlight feature please restart the application and give camera permission.';
const CAMERA_IN_USE_ERROR_MESSAGE = 'Another applicaiton might be using your camera. Please close the application and try again.';
const NO_SPEECH_ALERT_TIME = 5 * 1000

const RecordVideo2 = (props) => {
    const {
        screenStateList,
        setVideoFile,
        setVideoBlob,
        setAudioFile,
        setAudioBlob,
        setDuration,
        spotlightData,
        totalAttempts,
        setSpotlightAnalytics,
        setIsEmptySpeech,
        startChallengeAsync,
        remainingAttempts
    } = props;

    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { microskillId, challengeId } = useParams();
    const [isCameraPermission, setIsCameraPermission] = useState(false);
    const [isCameraFeed, setIsCameraFeed] = useState(false);
    const [isWaiting, setIsWaiting] = useState(true);
    const cameraStreamRef = useRef(null);
    const videoStreamRef = useRef(null);
    const audioStreamRef = useRef();
    const rectBound = useRef();
    const intervalRef = useRef();
    const timerRef = useRef();
    const waitingTimeRef = useRef();
    const progressBarRef = useRef();
    const wrongGradientBoxRef = useRef();
    const canvasRef = useRef(null);
    const recognizerRef = useRef(null);
    const textRecognizingRef = useRef(false);
    const [sentencesSentimentAnalyses, setsentencesSentimentAnalyses] = useState([]);
    const [speechAnalysisData, setSpeechAnalysisData] = useState(null);
    const [transcriptTextMatch, setTranscriptTextMatch] = useState(null);
    const [dialogMessage, setDialogMessage] = useState(CAMERA_PERMISSION_ERROR_MESSAGE_NATIVE);
    const [dialogTitle, setDialogTitle] = useState(DIALOG_ALERT.PERMISSION_DENIED);
    const firstRenderRef = useRef(false);

    const recognizedTextRef = useRef('');
    const [recognizedText, setRecognizedText] = useState('');
    const lastRecognizedTextRef = useRef('');

    const isNativeApp = localStorage.getItem('isNative');

    const [confirmBtnName, setConfirmBtnName] = useState(isNativeApp === 'true' ? 'Change Settings' : 'Okay');

    const recordingStoppedRef = useRef(false);
    const [isRecordingStopping, setIsRecordingStopping] = useState(false);

    const audioConfigRef = useRef(null);
    const speechConfigRef = useRef(null);
    const noSpeechDetectDebounceRef = useRef(null);
    const errorBoxRef = useRef(null);
    const lastSoundTimeRef = useRef(Date.now())
    const audioSourceRef = useRef(null);
    const audioAnalyzerRef = useRef(null);
    const audioVolumeInDbRef = useRef(0);

    const { t } = useTranslation();

    const MAX_TIME = spotlightData.timeLimit;
    const MAX_ATTEMPT = spotlightData.maxAttempts;

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    useEffect(() => {
        if (isEmpty(isNativeApp)) {
            setDialogTitle(DIALOG_ALERT.MEDIA_PERMISSION);
            setDialogMessage(CAMERA_PERMISSION_ERROR_MESSAGE)
        }
    }, [])

    useEffect(() => {
        // eslint-disable-next-line no-use-before-define
        window.onbeforeunload = function () {
            return t('You have unsaved data. do you really want to reload?');
        }
        return () => {
            window.onbeforeunload = "";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // start challenge attempt on every recording starts
        startChallengeAsync();
        return () => {
            // handleStop();
            stopAllProcesses();
        }
    }, []);

    const stopAllProcesses = () => {
        clearInterval(intervalRef.current)
        if (cameraStreamRef.current && cameraStreamRef.current[0]) {
            cameraStreamRef.current[0].stop();
        }
        if (cameraStreamRef.current && cameraStreamRef.current[1]) {
            cameraStreamRef.current[1].stop();
        }
        if (audioStreamRef.current && audioStreamRef.current.stream) {
            audioStreamRef.current.stream.stop();
        }
        if (!isEmpty(audioSourceRef.current)) audioSourceRef.current.disconnect();
        if (!isEmpty(audioAnalyzerRef.current)) audioAnalyzerRef.current.disconnect();
        if (!isEmpty(noSpeechDetectDebounceRef.current)) clearTimeout(noSpeechDetectDebounceRef.current)
    }

    // const runFacemesh = async () => {
    //     // OLD MODEL
    //     // const net = await facemesh.load({
    //     //   inputResolution: { width: 640, height: 480 },
    //     //   scale: 0.8,
    //     // });
    //     // NEW MODEL
    //     const net = await facemesh.load(facemesh.SupportedPackages.mediapipeFacemesh);
    //     setInterval(() => {
    //         detect(net);
    //     }, 100);
    // };

    // useEffect(() => {
    //     if (!isCameraFeed) return
    //     runFacemesh();
    // }, [isCameraFeed]);

    // const detect = async (net) => {
    //     if (
    //         typeof webcamRef.current !== "undefined" &&
    //         webcamRef.current !== null &&
    //         webcamRef.current.video.readyState === 4
    //     ) {
    //         // const video = document.querySelector("video");
    //         // const videoWidth = document.querySelector("video").videoWidth;
    //         // const videoHeight = document.querySelector("video").videoHeight;

    //         if (
    //             typeof webcamRef.current !== "undefined" &&
    //             webcamRef.current !== null &&
    //             webcamRef.current.video.readyState === 4
    //         ) {
    //             // Get Video Properties
    //             const video = webcamRef.current.video;
    //             const videoWidth = webcamRef.current.video.videoWidth;
    //             const videoHeight = webcamRef.current.video.videoHeight;

    //             // Set video width
    //             // const video = webcamRef.current.video;
    //             // const videoWidth = webcamRef.current.video.videoWidth;
    //             // const videoHeight = webcamRef.current.video.videoHeight;
    //             webcamRef.current.video.width = videoWidth;
    //             webcamRef.current.video.height = videoHeight;

    //             // Set canvas width
    //             canvasRef.current.width = videoWidth;
    //             canvasRef.current.height = videoHeight;

    //             // Make Detections
    //             // OLD MODEL
    //             //   const face = await net.estimateFaces(video);
    //             // NEW MODEL

    //             const face = await net.estimateFaces({ input: video });

    //             // Get canvas context
    //             const ctx = canvasRef.current.getContext("2d");
    //             requestAnimationFrame(() => { drawMesh(face, ctx) });
    //         };
    //     }
    // };

    const handlePermission = async () => {
        // video
        try {
            setIsCameraFeed(false);
            rectBound.current = document.getElementById('recordVideoRootId').getBoundingClientRect();
            // let videoConfig = {
            //     mandatory: {
            //         minWidth: rectBound.current.width,
            //         minHeight: 600,
            //         maxWidth: rectBound.current.width,
            //         maxHeight: rectBound.current.height,
            //     }
            // }

            let tempCameraStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            // let tempCameraStream = await navigator.mediaDevices.getUserMedia({ video: videoConfig, audio: true });
            // setCameraStream(tempCameraStream);

            const rtcConfig = {
                type: 'video',
                recorderType: MediaStreamRecorder,
                frameRate: 30,
                mimeType: 'video/webm;codecs=vp9',
                // canvas: { width: 480, height: 640 },
            };
            videoStreamRef.current = new RecordRTC(tempCameraStream, rtcConfig);
            cameraStreamRef.current = tempCameraStream.getTracks();

            videoStreamRef.current.startRecording();

            let tempVideo = document.querySelector("#camera_preview_video");
            tempVideo.srcObject = tempCameraStream;

            // } catch (err) {
        } catch ({ message }) {
            if (message.includes('in use')) {
                setDialogMessage(CAMERA_IN_USE_ERROR_MESSAGE);
                if (isNativeApp === 'true') {
                    setConfirmBtnName('Okay');
                }
            }
            setIsCameraPermission(true);
            return
        }
        // video


        // audio
        try {
            var AudioContext = window.AudioContext || window.webkitAudioContext;
            var audioContext = new AudioContext({
                sampleRate: 8000,
            });
            let tempAudioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            audioStreamRef.current = new Recorder(audioContext, { numChannels: 1 });
            audioStreamRef.current.init(tempAudioStream);
            // setAudioStream(tempAudioStream);
            setIsCameraFeed(true); // camera stream flag
            // recognizerRef.current.startContinuousRecognitionAsync();
            handleRecording();


            // // // to detect audio
            const source = audioContext.createMediaStreamSource(tempAudioStream);
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 2048;

            audioSourceRef.current = source;
            audioAnalyzerRef.current = analyser;

            source.connect(analyser);

            const dataArray = new Uint8Array(analyser.fftSize);

            function detectVoice() {
                analyser.getByteTimeDomainData(dataArray);

                // Calculate the average volume
                let sum = 0;
                for (let i = 0; i < dataArray.length; i++) {
                    const amplitude = (dataArray[i] - 128) / 128; // Normalize to -1 to 1
                    sum += amplitude * amplitude;
                }
                const volume = Math.sqrt(sum / dataArray.length);
                const decibels = 20 * Math.log10(volume);
                audioVolumeInDbRef.current = decibels;

                // Detect voice activity
                // if (volume > minSoundThreshold) { // Adjust threshold based on your needs
                if (decibels > minSoundVolumeReq) { // Adjust threshold based on your needs
                    lastSoundTimeRef.current = Date.now();

                    if (!isEmpty(errorBoxRef.current)) {
                        errorBoxRef.current.style.opacity = 0;
                        errorBoxRef.current.style.visibility = 'hidden'
                    }
                }

                requestAnimationFrame(detectVoice);
            }
            if (!isEmpty(dataArray)) {
                detectVoice();
                checkSoundTimeout();
            }
        } catch (error) {
            console.log("audio error:", error);
        }
        // audio
    };

    function checkSoundTimeout() {

        if (Date.now() - lastSoundTimeRef.current > NO_SPEECH_ALERT_TIME) {
            if (!isEmpty(errorBoxRef.current) && textRecognizingRef.current === false) {
                errorBoxRef.current.style.opacity = 1;
                errorBoxRef.current.style.visibility = 'visible'

                // let errorMsgElem = document.querySelector("#error_msg_tag");
                // if(audioVolumeInDbRef.current < -25 && audioVolumeInDbRef.current > -35){
                //     errorMsgElem.innerText = 'Please speak louder!';
                // } else if(audioVolumeInDbRef.current < -35){
                //     errorMsgElem.innerHtml = `No speech detected <br />Please speak loudly!';
                // }


            }
        }
        noSpeechDetectDebounceRef.current = setTimeout(checkSoundTimeout, 1000);
    }

    const parseTime = (nano) => {
        let hour = Math.floor(nano / 36000000000);
        const temp = nano % 36000000000;
        let minute = Math.floor(temp / 600000000);
        const temp2 = temp % 600000000;
        let second = Math.floor(temp2 / 10000000);
        let mil = temp2 % 10000000;
        hour = hour.toString();
        minute = minute.toString();
        second = second.toString();
        mil = mil.toString().slice(0, 3); //cuts off insignificant digits
        return `${hour}:${minute}:${second}.${mil}`;
    };

    const initiateRecognizer = async () => {
        const recognizer = new SDK.SpeechRecognizer(speechConfigRef.current, audioConfigRef.current);

        recognizerRef.current = recognizer
        recognizer.recognized = (s, e) => {
            // if(isWaiting) return
            const { result } = e;
            const { text, offset, duration, properties } = result;
            const resultConfidence = result.reason === SDK.ResultReason.RecognizedSpeech ? 1 : 0;

            if (typeof result.text !== 'undefined') {

                textRecognizingRef.current = true;
                if (result.text !== lastRecognizedTextRef.current) {

                    setRecognizedText(prevText => {
                        return `${prevText} ${result.text}`;
                    });
                    recognizedTextRef.current += ` ${result.text}`;
                    lastRecognizedTextRef.current = result.text;


                    // ////////////  // /////////////// // /////////////
                    const words = result.text.split(' ');
                    const wordConfidences = words.map(() => resultConfidence);

                    let wordsMap = [];
                    words.map((i, index) => ({ word: i, confidence: wordConfidences[index] }))

                    // const offset = result.offset / 10000000; // Convert offset to seconds
                    // const duration = result.duration / 10000000; // Convert duration to seconds

                    let temp = {
                        sessionId: e.sessionId,
                        text,
                        offset: parseTime(offset),
                        duration,
                        words
                    };
                    setsentencesSentimentAnalyses(prev => {
                        return [...prev, temp];
                    })
                }
            }

            // setConfidence(prev => resultConfidence);

            // Approximate confidence level word by word (assuming all words have same confidence level)
            // if (result.text !== lastRecognizedTextRef.current) {


            // }

            textRecognizingRef.current = false;
        };

        recognizer.startContinuousRecognitionAsync()
    }

    const initSpeechRecognition = async (language) => {
        // const audioConfig = SDK.AudioConfig.fromDefaultMicrophoneInput();
        audioConfigRef.current = SDK.AudioConfig.fromDefaultMicrophoneInput();
        // const speechConfig = SDK.SpeechConfig.fromSubscription("9693480d2fd348d4a254252b0fdcecc0", "centralindia");
        speechConfigRef.current = SDK.SpeechConfig.fromSubscription("9693480d2fd348d4a254252b0fdcecc0", "centralindia");
        speechConfigRef.current.speechRecognitionLanguage = language || "en-US";
        // const phraseList = SDK.PhraseListGrammar.fromRecognizer(recognizer);
        // phraseList.addPhrase("hello");
        // recognizer.
        // let sessionId = "session1";
        // let offset = 0;
        // let duration = 0;
        // let words = [];

        // recognizer.recognizing = (s, e) => {
        //     const { result } = e;
        //     const { offset, duration, properties } = result;
        //     const wordData = result.properties.getProperty(SDK.PropertyId.SpeechServiceResponse_JsonResult).NBest[0];
        //     const word = wordData.Display;
        //     const confidence = wordData.Confidence;

        //     offset = offset / 10000000; // Convert offset to seconds
        //     duration = duration / 10000000; // Convert duration to seconds
        //     words.push({ word, confidence });
        // };

        // recognizer.recognized = (s, e) => {
        //     setSpeechAnalysisData({
        //         sessionId,
        //         text: e.result.text,
        //         offset,
        //         duration,
        //         words
        //     });
        // };

        // recognizer.startContinuousRecognitionAsync();

        // const sentiments = await new Promise((resolve, reject) => {
        //     recognizer.recognized = (_, event) => {
        //         if (event.result.reason !== SDK.ResultReason.NoMatch) {
        //             const sessionId = event.sessionId;
        //             const { text, offset, duration, properties } = event.result;
        //             let sentenceAnalysis = {
        //                 sessionId, text,
        //                 offset: parseTime(offset), duration,
        //                 // words: JSON.parse(properties['privValues'][0])['NBest'][0]['Words'],
        //             };
        //             sentences.push(text);
        //             sentencesAnalyses.push(sentenceAnalysis);
        //         }
        //     }
        // });
        // const sentencesSentimentAnalyses = { sentencesAnalyses, sentiments };

        // recognizer.recognizing = (s, e) => {
        //     const sessionId = e.sessionId;
        //     const { text, offset, duration, properties } = e.result;

        //     try {
        //         if (isEmpty(speechTextRef.current)) speechTextRef.current = e?.result?.text;
        //         else {
        //             let split1 = text.trim().split(speechTextRef.current.trim());
        //             // if (!isEmpty(split1[1]))
        //         }

        //         // sentences.push(text);
        //         // sentencesAnalyses.push({
        //         //     sessionId,
        //         //     text,
        //         //     offset: parseTime(offset),
        //         //     duration,
        //         //     // words: JSON.parse(properties['privValues'][0])['NBest'][0]['Words'],
        //         // });
        //     } catch (err) {
        //         console.log("err:", err);
        //     }
        // };

        // recognizerRef.current = recognizer;
    };

    const getWordConfidences = (result) => {
        const wordConfidences = [];
        const totalConfidence = result.confidence;
        const words = result.text.split(' ');
        const averageWordConfidence = totalConfidence / words.length;
        for (const word of words) {
            wordConfidences.push({ word, confidence: averageWordConfidence });
        }
        return wordConfidences;
    };

    useEffect(() => {
        (async function () {
            if (!firstRenderRef.current) {
                firstRenderRef.current = true;
                return
            }
            const speechToTextResp = await speechToText(
                {
                    sentencesAnalyses: sentencesSentimentAnalyses,
                    transcript: recognizedText
                },
                {
                    ...spotlightData,
                    timespent: !isEmpty(timerRef?.current?.innerHTML) ? MAX_TIME - parseInt(timerRef?.current?.innerHTML) : 0
                }
            );
            setSpeechAnalysisData(speechToTextResp);
        })();
    }, [sentencesSentimentAnalyses]);


    // this useEffect is used to match the transcript with the proepr recognized (to resolve the issue of last line not capturing)
    useEffect(() => {
        (async function () {
            if (!isCameraFeed) return
            // startTimerForNoSpeechAlert()

            if (recordingStoppedRef.current && !textRecognizingRef.current) {
                if (!isEmpty(textRecognizingRef.current)) clearTimeout(noSpeechDetectDebounceRef.current);
                await sleep(1000)
                await matchTranscript();
                await sleep(500);

                setIsRecordingStopping(false);
                dispatch(handleSpotlightScreenState(screenStateList[4]));
            }
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recognizedText, textRecognizingRef.current, recordingStoppedRef.current]);

    useEffect(() => {
        if (isEmpty(speechAnalysisData)) return;
        setSpotlightAnalytics(() => {
            let returningObj = {
                transcript: transcriptTextMatch?.transcript,
                // transcript: recognizedText.trim().replaceAll(" +", " "),
            }
            if (isCriteriaContains(spotlightData.criteria, 'transcriptMatch')) {
                returningObj['transcriptMatch'] = transcriptTextMatch
            }
            if (isCriteriaContains(spotlightData.criteria, 'duration')) {
                returningObj['duration'] = speechAnalysisData?.duration
            }
            if (isCriteriaContains(spotlightData.criteria, 'speechRate')) {
                returningObj['speechRate'] = speechAnalysisData?.speechRate
            }
            if (isCriteriaContains(spotlightData.criteria, 'keyMatch')) {
                returningObj['keywordMatch'] = speechAnalysisData?.keywordMatch
            }

            return returningObj
        })
    }, [spotlightData, speechAnalysisData, transcriptTextMatch]);

    const matchTranscript = async () => {
        // if (isEmpty(recognizedText)) {
        //     setIsEmptySpeech(true)
        //     return;
        // };

        const getLargerString = (str1, str2) => {
            if (isEmpty(str1) && isEmpty(str2)) return str1;
            return str1?.length > str2?.length ? str1 : str2;
        }

        const cleanUpString = (str) => {
            if (isEmpty(str)) return str;
            return str?.trim()?.replaceAll(" +", " ");
        }

        const TranscriptMatchResp = await getTranscriptMatch({
            creatorText: spotlightData.transcript,
            transcript: getLargerString(cleanUpString(recognizedText), cleanUpString(recognizedTextRef.current)),
            criteria: spotlightData.criteria,
            // microskillId,
            // challengeId
        })
        let transcriptMatch = TranscriptMatchResp?.data?.data?.transcriptMatch;
        if (isEmpty(transcriptMatch?.transcript)) {
            setIsEmptySpeech(true)
        }
        setTranscriptTextMatch(TranscriptMatchResp?.data?.data?.transcriptMatch);
    }

    useEffect(() => {
        handlePermission();
        initSpeechRecognition(spotlightData?.language);
        // avatar video play
        // document.querySelector("#avatar_video")?.play()
    }, [spotlightData]);

    const startCountDown = (time, ref, prefix, suffix, type) => {
        if (!isEmpty(intervalRef.current)) clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            if (!isEmpty(ref?.current?.innerText)) {
                let currentTime = ref.current.innerText
                ref.current.innerText = ` ${parseInt(currentTime) - 1}`;
                // ref.current.innerHTML = ` ${tempTime - 1}`;

                if (type === 'RECORDING_TIME') {
                    let progressPercentage = (ref.current.innerHTML / time) * 100;
                    if (!isEmpty(progressBarRef.current)) {
                        progressBarRef.current.style.width = `${progressPercentage}%`;

                        // handle progressbar color
                        if (progressPercentage <= 50 && progressPercentage > 0) progressBarRef.current.style.backgroundColor = '#ffb300';
                        // if (progressPercentage <= 50 && progressPercentage > 25) progressBarRef.current.style.backgroundColor = '#ffb300';
                        else if (progressPercentage <= 0) {
                            // else if (progressPercentage <= 25) {
                            progressBarRef.current.style.backgroundColor = '#f63b34';
                            wrongGradientBoxRef.current.style.display = 'block';
                        }
                    }
                }
            }
        }, 1000);
    }

    const handleRecording = async () => {
        // setIsRecording(true);

        // Count down
        startCountDown(WAITING_TIME, waitingTimeRef, "", "", 'WAITING_TIME');
        await sleep(3000);
        initiateRecognizer();
        lastSoundTimeRef.current = Date.now();
        // startTimerForNoSpeechAlert()
        setIsWaiting(false);
        clearInterval(intervalRef.current)
        await sleep(200);

        // start recording timer
        startCountDown(MAX_TIME, timerRef, "", "", 'RECORDING_TIME');
        // setStream(cameraStream);

        // video
        // const videoConfig = {
        //     type: 'video',
        //     mimeType: 'video/webm;codecs=vp8',
        //     //   canvas: { width: 480, height: 640 },
        //     frameRate: 25,
        // };
        // videoStreamRef.current = new RecordRTC(cameraStream, videoConfig);

        // audio
        audioStreamRef.current.start()

        // audioStreamRef.current = new StereoAudioRecorder(audioStream, {
        //     desiredSampRate: 16 * 1000,
        // });
        // audioStreamRef.current.record();
    };

    const handleStopRecording = async () => {

        // handle stop recording
        setIsRecordingStopping(true);
        videoStreamRef.current.stopRecording(async function () {
            const blob = this.getBlob();
            // const blob = videoStreamRef.current.getBlob();
            console.log("video blob: ", blob);
            if (isEmpty(blob) && isEmpty(blob.size)) {
                dispatch(openDialogue({
                    isDialogueOpen: true,
                    dialogueTitle: 'Video Issue',
                    dialogueContent: t('Encountered issue while recording the video. Though audio will be saved for analyzation.')
                }));
                return
            }
            const duration = await getBlobDuration(blob);
            setDuration(duration);
            let fileExtension = commonUtil.getFileTypeExtension(blob.type);
            const file = new File([blob], `spotlight-video.${fileExtension}`, { lastModified: new Date().getTime(), type: blob.type });
            // const file = new File([blob], "spotlight-video", { lastModified: new Date().getTime(), type: blob.type });
            setVideoFile(file);
            setVideoBlob(URL.createObjectURL(blob));
            videoStreamRef.current.destroy();

        });

        let DURATION;
        audioStreamRef.current.stop().then(async ({ blob, buffer }) => {
            const duration = await getBlobDuration(blob);
            DURATION = duration;

            setDuration(duration);
            let fileExtension = commonUtil.getFileTypeExtension(blob.type);
            const file = new File([blob], `spotlight-audio.${fileExtension}`, { lastModified: new Date().getTime(), type: blob.type });
            // const file = new File([blob], "spotlight-audio", { lastModified: new Date().getTime(), type: blob.type });
            setAudioBlob(URL.createObjectURL(blob));
            setAudioFile(file);

            if (recognizerRef.current) {
                recognizerRef.current.stopContinuousRecognitionAsync();
                recognizerRef.current.close();
            }

            recordingStoppedRef.current = true;

            // setIsRecordingStopping(false);
            // dispatch(handleSpotlightScreenState(screenStateList[4]));
        });
        if (!isEmpty(audioSourceRef.current)) audioSourceRef.current.disconnect();
        if (!isEmpty(audioAnalyzerRef.current)) audioAnalyzerRef.current.disconnect();

        stopAllProcesses();
    };

    const handlePermissionDialogueClose = () => {
        if (isNativeApp === 'true' && CAMERA_PERMISSION_ERROR_MESSAGE_NATIVE === dialogMessage) {
            webViewJavaScriptBridge.sendMessage({ action: 'OpenSettingsChannel', params: true });
            window.location.replace(`/challenge-list/${microskillId}`);
        } else {
            navigate(-1);
        }
        setIsCameraPermission(false);

    };

    const isCriteriaContains = (criteria, key) => {
        if (isEmpty(criteria)) return false;
        let findObj = criteria.find((item) => (item.name?.toUpperCase() === key?.toUpperCase()));
        let isContains = !isEmpty(findObj) && (findObj.check == 1);
        return isContains
    }

    // const startTimerForNoSpeechAlert = () => {
    //     if (!isEmpty(noSpeechDetectDebounceRef.current)) {
    //         clearTimeout(noSpeechDetectDebounceRef.current)
    //         noSpeechDetectDebounceRef.current = null
    //     }
    //     if (isEmpty(errorBoxRef.current)) return

    //     noSpeechDetectDebounceRef.current = setTimeout(() => {
    //         errorBoxRef.current.style.visibility = 'visible';
    //         errorBoxRef.current.style.opacity = '1';

    //         setTimeout(() => {
    //             errorBoxRef.current.style.visibility = 'hidden';
    //             errorBoxRef.current.style.opacity = '0';

    //         }, 1500);
    //     }, NO_SPEECH_ALERT_TIME)
    // }

    return (
        <div className={classes.recordVideoRoot} id="recordVideoRootId">
            {
                !isCameraFeed && (
                    <div style={constants.FULL_HEIGHT_CENTER}>
                        <CircularProgress />
                    </div>
                )
            }

            <div className={classes.videoBox}>
                <video
                    style={{
                        display: isCameraFeed ? 'block' : 'none',
                        // height: rectBound?.current?.height,
                        // width: rectBound?.current?.width,
                        height: "100%",
                        width: "100%",
                    }}
                    width={480}
                    height={640}
                    muted
                    // ref={refVideo}
                    id="camera_preview_video"
                    className={classes.videoTag}
                    playsInline
                    autoPlay={true}
                    controls={false}
                // preload="auto"
                />

                {/* <Webcam
                    ref={webcamRef}
                    style={{
                        position: "absolute",
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        right: 0,
                        textAlign: "center",
                        zindex: 9,
                        // width: 640,
                        // height: 480,
                    }}
                /> */}
                <canvas
                    ref={canvasRef}
                    // tyle={{ display: isCameraFeed ? 'block' : 'none',position: "absolute", height: rectBound?.current?.height, width: rectBound?.current?.width }}
                    // height={621}
                    // height={621}
                    // width={346}
                    style={{
                        position: "absolute",
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        // inset: '0 0 0 0',
                        left: '0',
                        top: '0',
                        textAlign: "center",
                        zindex: 9,

                        // height: 621,
                        // width: 346
                    }}
                />
                <RecordingOverlay
                    spotlightData={spotlightData}
                    classes={classes}
                    isCameraFeed={isCameraFeed}
                    isWaiting={isWaiting}
                    waitingTimeRef={waitingTimeRef}
                    WAITING_TIME={WAITING_TIME}
                    progressBarRef={progressBarRef}
                    MAX_ATTEMPT={MAX_ATTEMPT}
                    totalAttempts={totalAttempts}
                    t={t}
                    wrongGradientBoxRef={wrongGradientBoxRef}
                    MAX_TIME={MAX_TIME}
                    sleep={sleep}
                    timerRef={timerRef}
                    handleStopRecording={handleStopRecording}
                    isRecordingStopping={isRecordingStopping}
                    errorBoxRef={errorBoxRef}
                />
            </div>

            {
                isCameraPermission && (
                    <DialogAlert
                        isOpen={isCameraPermission}
                        title={dialogTitle}
                        content={t(dialogMessage)}
                        confirmBtnName={confirmBtnName}
                        handleConfirmBtnClick={handlePermissionDialogueClose}
                    />
                )
            }
        </div>
    )
}

export default RecordVideo2;


const RecordingOverlay = (props) => {
    const {
        spotlightData,
        classes,
        isCameraFeed,
        isWaiting,
        waitingTimeRef,
        WAITING_TIME,
        progressBarRef,
        MAX_ATTEMPT,
        totalAttempts,
        t,
        wrongGradientBoxRef,
        MAX_TIME,
        sleep,
        timerRef,
        handleStopRecording,
        isRecordingStopping,
        errorBoxRef
    } = props
    const [showAssistBtn, setShowAssistBtn] = useState(false);
    const [showTranscriptText, setShowTranscriptText] = useState(false);
    // const [transcriptText, setTranscriptText] = useState("")
    const [textAnimeLoading, setTextAnimeLoading] = useState(false);
    const [textAnimeComplete, setTextAnimeComplete] = useState(false);
    const [buttonsVisible, setButtonsVisible] = useState(true)

    async function typeWriter(id, txt, speed) {
        let i = 0, element = document.getElementById(id), parentElement = document.querySelector("#transcript_text_body");

        setTextAnimeLoading(true);
        if (!isEmpty(element)) {
            while (i < txt.length) {
                element.innerHTML += txt.charAt(i);
                if (i % 14 === 0 && !isEmpty(parentElement)) {
                    parentElement.scrollTo({
                        top: i,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
                i++;
                await sleep(speed);
            }
        }
        setTextAnimeComplete(true)
        setTextAnimeLoading(false)

        // scroll to bottom when typewriter is complete
        if (element.innerText.length >= txt.length) {
            parentElement.scrollTo({
                top: parentElement.scrollHeight,
                left: 0,
                behavior: 'smooth'

            })
        }
    }

    useEffect(() => {
        if (!isWaiting && spotlightData.isTranscript) {
            if (textAnimeComplete === true || textAnimeLoading === true) return;
            typeWriter("transcript_text", spotlightData.transcript, 50)
        }
    }, [spotlightData, isWaiting, textAnimeComplete, textAnimeLoading]);
    // }, [showTranscriptText, textAnimeComplete, textAnimeLoading])
    return (
        isCameraFeed && (
            isWaiting ? (
                <div className={`${classes.recordingOverlayRoot} ${classes.centerFlex}`}>
                    <span ref={waitingTimeRef} className={classes.waitingTimeText}>{WAITING_TIME}</span>
                </div>
            ) : (
                <div className={classes.recordingOverlayRoot}>
                    <div className={classes.progressBarBox}>
                        <div className={classes.progressBar} ref={progressBarRef}></div>
                    </div>
                    <div className={classes.overlayBox}>
                        <div className={classes.notificationBox}>
                            <div className={`${classes.text}`}>
                                <img src="/images/icons/heart.png" width={13} />
                                <span> x </span>
                                <span>{MAX_ATTEMPT - totalAttempts}</span>
                            </div>
                            <div className={`${classes.text}`}>
                                <img src="/images/icons/stopwatch.png" width={13} />
                                <span ref={timerRef}> {MAX_TIME}</span>
                                <span>s</span>
                            </div>
                        </div>
                        <div className={classes.assistBoxContainer}>
                            <div className={classes.assistBox}>

                                {
                                    buttonsVisible && spotlightData.isTranscript && spotlightData.transcript.length > 0 && (
                                        <div className={`${classes.transcriptBtn} ${classes.centerFlex}`}
                                            onClick={() => {
                                                setShowTranscriptText(true)
                                                setButtonsVisible(false)
                                            }}
                                        >
                                            <ArrowBackIosNewIcon sx={{ fontSize: '0.8rem' }} />
                                            <div className={classes.transcriptBtnText}>{t("Transcript")}</div>
                                        </div>
                                    )
                                }
                                {
                                    spotlightData.assistance && buttonsVisible && !showAssistBtn && spotlightData.keywords.length > 0 && (
                                        <div className={`${classes.assistBtn} ${classes.centerFlex}`}
                                            onClick={() => {
                                                setShowAssistBtn(true)
                                                setButtonsVisible(false)
                                            }}
                                        >
                                            <ArrowBackIosNewIcon sx={{ fontSize: '0.8rem' }} />
                                            <div className={classes.assistText}>{t("Assist")}</div>
                                        </div>
                                    )
                                }
                                {
                                    showAssistBtn && (
                                        <div className={classes.assistTextContainer}>
                                            <div className={classes.assistCloseIcon}>
                                                <CloseIcon sx={{ color: palette.orange }} onClick={() => {
                                                    setShowAssistBtn(false)
                                                    setButtonsVisible(true)
                                                }} />
                                            </div>
                                            <div className={classes.transcriptBtnContainer}>
                                            </div>
                                            <div className={`${classes.assistTextBtns} scrollbar`}>
                                                {
                                                    spotlightData.keywords.map(item => (
                                                        item.keywords.map((item2, index) => (
                                                            <div key={`${item2.id}-${index}`} className={`${classes.textBtn} ${classes.centerFlex}`}>
                                                                {item2.name}
                                                            </div>
                                                        ))
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    )
                                }


                            </div>

                            {
                                true && <div className={`${classes.errorContainer} ${classes.centerFlex}`} ref={errorBoxRef}>
                                    <h3 id="error_msg_tag">No speech detected <br />Please speak loudly!</h3>
                                    {/* <h3>No speech detected in last {NO_SPEECH_ALERT_TIME / 1000} seconds</h3> */}
                                </div>
                            }


                            {/* avatar box  ----- code to be used in future */}
                            {
                                false && <div className={classes.avatarContainer}>
                                    <video
                                        src='/sample-documents/Avatar_video_1.mp4'
                                        playsInline
                                        id="avatar_video"
                                        // autoPlay
                                        controls={false}
                                        muted
                                        loop
                                        width="100%"
                                        height="100%"
                                        style={{
                                            objectFit: 'cover',
                                            borderRadius: '10px 0 0 10px'
                                        }}
                                        onLoadedMetadata={(event) => {
                                            event.target.play()
                                        }}
                                    />
                                </div>
                            }
                            {/* avatar box */}
                        </div>

                        {/* // transcript text */}
                        <div className={`${classes.transcriptTextContainer}`} style={{
                            visibility: showTranscriptText ? 'visible' : 'hidden'
                        }}>
                            <div className={classes.transcriptTextHeader}>
                                <h4>Transcript</h4>
                                <div><CloseIcon sx={{ color: palette.orange }} onClick={() => {
                                    setShowTranscriptText(false)
                                    setButtonsVisible(true)
                                }} /></div>
                            </div>
                            <div className={`${classes.transcriptTextBody} scrollbar`} id="transcript_text_body" >
                                <p className={classes.transcriptText} id="transcript_text"></p>
                            </div>
                        </div>
                        {/* // transcript text */}
                        <div className={classes.buttonBox}>
                            <Button variant="contained"
                                size="large"
                                className={`fixed_ratio_20_3 ${classes.stopBtn}`}
                                fullWidth
                                onClick={handleStopRecording}
                                disabled={isRecordingStopping}
                            >
                                {
                                    isRecordingStopping ? <CircularProgress size={24} /> : t('Stop Recording')
                                }
                            </Button>
                        </div>
                    </div>

                    <div className={classes.wrongGradientBox} ref={wrongGradientBoxRef} />
                </div>
            )
        )
    )
}