import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
    },

    formAnswerContainer: {
        padding: '0.5rem',
        paddingLeft: '2.2rem',
        margin: '1rem 0',
        marginTop: '0rem',
        width: '90%',
        [theme.breakpoints.down('md')]: {
            marginLeft: '0rem'
        }
    },

    centerFlexLaptop: {
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start'
        }
    },

    openEnded: {
        padding: '0.7rem 0.5rem',
        fontSize: '1rem',
        width: '100%',
        border: 'none',
        border: '1px solid rgba(0,0,0,0.2)',
        // borderBottom: '1px solid rgba(244, 81, 30, 0.7)',
        outline: 'none',
        fontFamily: "'Montserrat', sans-serif",
        // marginLeft: "2rem",
        [theme.breakpoints.up('md')]: {
            // marginLeft: "2rem",

        }
    },

    sliderComponent: {
        // marginBottom: '1rem',
        height: '4rem',
        marginLeft: "0.5rem",
        marginBottom: '-1rem',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
            // justifyContent: 'center',
            width: '100%',
            marginLeft: '0rem'
        }
    },

    numberRating: {
        width: 'calc(100% - 0.5rem) !important',
        // [theme.breakpoints.down('md')]: {
        //     width: '86% !important'
        // }
    },

    datePicker: {
        padding: '0.5rem',
        marginLeft: "0rem",
        [theme.breakpoints.down('md')]: {
            width: '100% !important',
            marginLeft: "2rem",
        }
    },

    starRating: {
        fontSize: '2.5rem !important',
        marginLeft: '0rem !important',
        [theme.breakpoints.down('md')]: {
            marginLeft: '0rem !important'
        }
    },

    uploadFileButton: {
        marginLeft: '2rem !important'
    }
}));

export default useStyles;