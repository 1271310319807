import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    loadingForm: {
        height: '100%',
        width: '100%'
    },

    paperProps: {
        width: '95vw',
        height: '100vh',
        overflow: 'hidden',
        margin: '0 !important',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '40% !important',
            margin: 'auto !important'
        }
    },

    formContainer: {
        height: '100%',
        display: 'grid',
        // gridTemplateRows: '25% 75%',
        fontSize: (i18n) => {
            let langObj = constants.LANGUAGES_LIST.filter(e => e.key === i18n.language)[0]
            return langObj.font
        },
    },

    formInfoContainer: {
        display: 'grid',
        // gridTemplateRows: '20% 80%',
        padding: '1rem 1rem 0.5rem',
        width: '100%',
        boxShadow: '0 0 5px grey',
        [theme.breakpoints.down('md')]: {
            rowGap: '0.5rem'
        }
    },

    IconContainer: {
        justifyContent: 'flex-end',
        width: '100%',
        height: '100%'
    },

    closeIcon: {
        height: '24px',
        width: '24px',
        float: 'right',
        cursor: 'pointer'
    },

    timerContainer: {
        columnGap: '0.5rem'
    },

    timerDiv: {
        textAlign: 'center',
        width: '80%'
    },

    formNameDescriptionContainer: {
        width: '100%',
        flexDirection: 'column',
        // height: '75%',
        justifyContent: 'space-evenly'
    },

    formName: {
        fontSize: '1.1rem',
        fontWeight: '1000',
        textAlign: 'left',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem'
        },
    },

    formDescription: {
        width: '100%',
        textAlign: 'left',
        fontSize: '0.9rem',
        fontWeight: '500',
        marginTop: '0.5rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem'
        },
    },

    formQuestionConatiner: {
        padding: '1rem',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '8px',
            background: "#cdcdcd",
            borderRadius: '5px',

            [theme.breakpoints.down('sm')]: {
                width: '5px',
            }
            // display: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f5411e',
            borderRadius: '5px'
        }
    },

    questionContainer: {
        marginBottom: '1rem',
    },

    formQuestion: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        columnGap: '0.5rem',
        fontWeight: '800'
    },

    questionSerialNumber: {
        fontSize: '1rem'
    },

    questionTxt: {
        fontSize: '1rem'
    },

    mandatorySign: {
        color: 'red'
    },

    formSubmitContainer: {
        padding: '0rem 1rem',
        // boxShadow: '0 0 10px grey'
    },

    formSubmitButton: {
        width: '100%',
        fontSize: '1.3rem !important',
        fontWeight: 'bold !important',
    }
}));

export default useStyles;