import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import lottie from "lottie-web";

// mui
import CircularProgress from '@mui/material/CircularProgress';

// components
import StartScreen from "../common-screens/start-screen";
import HowToPlay from "../how-to-play/mcq-how-to-play";
import GbaGameOverScreen from "../common-screens/game-over-screen";
import GbaWinScreen from "../common-screens/win-screen";

// redux
import { submitChallengeAttemptData, submitEndChallengeAttempt } from "../../../redux/gba/gba.api";
import { startChallengeAttempt } from "../../../redux/microskill/microskill.api";
import { handleGbaGameState } from "../../../redux/gba/gba.actions";

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";

// constant
import constants from "../../../constants";

// lottie
import asteroidBurst from '../../../lottie-assets/asteroid_burst.json';
import wrongAnswer from '../../../lottie-assets/wrong_answer_2.json';
import missedAnswer from '../../../lottie-assets/missed_answer.json';

// Style
import useStyle from "./index.styles";

let questions = [];
let answers = [];
let finalQuestionId = null;
let questionsPerContext = 4;
const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;

const SpaceRocks = (props) => {
    const { gameData, handleGbaState, isPauseOverlay, defaultGradient, setAddPause } = props;
    const isMobile = useSelector(state => state?.common?.isMobile);
    const gbaGameData = gameData.questions;
    const mcqDataLength = gameData.answers.length;
    const { microskillId, challengeId } = useParams();

    let maxLifeGba = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxLife;
    let maxQuestion = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxQuestion;
    let maxLife = commonUtil.getMaxLifeForGba({
        gbaType: gameData.designType,
        questionsInGba: gbaGameData.length * questionsPerContext,
        maxQuestion: maxQuestion,
        maxLife: maxLifeGba,
    });

    let mxmScore = 120;
    let marksPerCorrectAns = mxmScore / (gbaGameData.length * questionsPerContext);

    let minmScore = 90;
    let containerHeight = 0;
    let viewedEntries = 0;
    let iObserver = null;
    let rockFlowSpeed1 = 70;
    let rockFlowSpeed2 = rockFlowSpeed1 / 2;
    let rockFlowSpeed3 = rockFlowSpeed1 / 5;

    const classes = useStyle(isMobile);
    const dispatch = useDispatch();
    const [isGamePage, setIsGamePage] = useState(false);
    const progressBarRef = useRef();
    const questionRef = useRef([]);
    const gameplayBoxRef = useRef(null);
    const gameplayContainerRef = useRef(null);
    const rockImageBoxRef = useRef([]);
    const speedBoxRef = useRef(null);
    const BalloonPopRootRef = useRef(null);
    const lifeTextRef = useRef(null);
    const qstnBoxRef = useRef(null);
    const wrongLottieRef = useRef(null);
    const speedBarRectBoxRef = useRef(null);
    const firstUpdate = useRef(true);
    const isGamePausedRef = useRef(false);
    const incorrectGradientOverlayRef = useRef();
    const currentTimeRef = useRef(Date.now());
    const finalChallengeDataRef = useRef();
    const totalScoreRef = useRef(0);
    const attemptDataRef = useRef();
    const isDataSubmitted = useRef(false);
    const questionNoRef = useRef();
    const viewedEntriesRef = useRef(0);

    const totalGameTimeRefWithoutPauseRef = useRef();
    const totalGameTimeRef = useRef(0);
    const timePerQuestionRef = useRef(0);

    const lifeRef = useRef();
    const heartRef = useRef();
    const scrollIntervalRef = useRef();
    const intervalRef = useRef();
    const [activeIndex, setActiveIndex] = useState(0);
    const [isHowToPlayScreen, setIsHowToPlayScreen] = useState(false);
    const [isGameComplete, setIsGameComplete] = useState(false);
    const [attemptData, setAttemptData] = useState('');
    const [gameEndType, setGameEndType] = useState('win');
    const [life, setLife] = useState(maxLife);
    const speedBarLength = 16;
    const [speedLevel, setSpeedLevel] = useState(2);
    const [isClickAllowed, setIsClickAllowed] = useState(true);
    const [totalViwedEntries, setTotalViwedEntries] = useState(0);
    const [incorrectData, setIncorrectData] = useState();
    const [resettingGame, setResettingGame] = useState(false);

    const activeIndexRef = useRef(activeIndex);

    const isHDScreen = commonUtil.getScreenResolution() === 'HD';

    let bgGradientDefault = defaultGradient;
    let bgGradientLoss = 'linear-gradient(#800E31, #F63B34)';
    let speedBarGradient = 'transparent linear-gradient(1deg, #F1DA12 0%, #FF7104 100%)';
    let headerBg = '#4F0060';

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    const [fSize, setFSize] = useState(1);

    useEffect(() => {
        if (isEmpty(attemptData)) getChallengeDataAsync();
        // eslint-disable-next-line no-use-before-define
    }, [])

    useEffect(() => {
        setFSize(window.innerHeight / 961);
    }, []);

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    };

    window.addEventListener("resize", resFont);

    useEffect(() => {
        if (isPauseOverlay) {
            isGamePausedRef.current = true;
            if (!firstUpdate.current) {
                handleBackButtonClick();
            }
        } else {
            if (firstUpdate.current) {
                firstUpdate.current = false;
                return
            }
            handleResumeClick();
        }
        // eslint-disable-next-line no-use-before-define
    }, [isPauseOverlay]);

    useEffect(() => {

        if (isGamePage) {
            handleGbaState(GBA_SCREEN_STATE[2]);
            let scrollElement = document.querySelector('#gameplayBoxRef');
            if (isEmpty(scrollElement)) return;
            scrollElement.addEventListener('wheel', handleAction, { passive: false });
            scrollElement.addEventListener('touchstart', handleAction, { passive: false });
            scrollElement.addEventListener('touchend', handleAction, { passive: false });
            scrollElement.addEventListener('touchmove', handleAction, { passive: false });
            scrollElement.addEventListener('mousedown', handleAction, { passive: false });
            scrollElement.addEventListener('mousemove', handleAction, { passive: false });
            scrollElement.addEventListener('mouseup', handleAction, { passive: false });
            gameplayBoxRef.current.style.opacity = '0';
            setInitialParameters();

            // for speedbar
            let scrollElement2 = speedBoxRef.current;
            scrollElement2.addEventListener('wheel', handleSpeedBarAction, { passive: false });
            scrollElement2.addEventListener('touchstart', handleSpeedBarAction, { passive: false });
            scrollElement2.addEventListener('touchend', handleSpeedBarAction, { passive: false });
            scrollElement2.addEventListener('touchmove', handleSpeedBarAction, { passive: false });
            scrollElement2.addEventListener('mousedown', handleSpeedBarAction, { passive: false });
            scrollElement2.addEventListener('mousemove', handleSpeedBarAction, { passive: false });
            scrollElement2.addEventListener('mouseup', handleSpeedBarAction, { passive: false });
            gameplayBoxRef.current.style.opacity = '0';

            // reset values
            setTotalViwedEntries(0);
            setSpeedLevel(2);
            setLife(maxLife);
            setActiveIndex(0);
            // setTotalScore(0);
            totalScoreRef.current = 0;
            currentTimeRef.current = Date.now();

            setTimeout(() => {
                setIsClickAllowed(true);
            }, 100);
        }
        // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
    }, [isGamePage]);

    useEffect(() => {
        if (activeIndex > 0) {
            setInitialParameters();
            if (!isEmpty(questionRef.current)) questionRef.current.style.scale = '1';
        }
        // eslint-disable-next-line no-use-before-define
    }, [activeIndex]);

    useEffect(() => {
        if (life < maxLife) {
            if (!isEmpty(lifeRef.current)) lifeRef.current.innerHTML = life + ' x';
            if (life <= 1) {
                // last life animation
                animateIncorrectAnsBg(false);
                lifeRef.current.style.color = '#F63B34';
                lifeTextRef.current.classList.add(`${classes.incorrectGradientOverlayAnimationInfinite}`);
                heartRef.current.src = "/images/icons/heart-red.png";
                if (life === 0) {
                    questions.push({
                        answers,
                        question_id: finalQuestionId,
                    });
                    handleLifeZero();
                }
            } else {
                animateIncorrectAnsBg(true);
            }
        }
        // eslint-disable-next-line no-use-before-define
    }, [life]);

    useEffect(() => {
        // clean up on unmount
        questions = [];
        answers = [];
        finalQuestionId = null;
        return () => {
            removeListener();
            clearInterval(scrollIntervalRef.current);
        };
    }, []);

    useEffect(() => {
        let progressBarWidth = (totalViwedEntries / mcqDataLength) * 100;
        if (!isEmpty(progressBarRef.current)) {
            progressBarRef.current.style.width = progressBarWidth + "%";
        }
    }, [totalViwedEntries])

    const getChallengeDataAsync = async () => {
        const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
        setAttemptData(attemptResp.data.data);
        attemptDataRef.current = attemptResp.data.data;
    };

    useEffect(() => {
        if (isEmpty(attemptData)) return
        // set initial state
        let tempData = {
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            status: constants.GBA_ILA_STATUS.exited,
        }
        dispatch(handleGbaGameState(tempData));
    }, [attemptData]);

    const handleLifeZero = () => {
        if (!isEmpty(iObserver)) iObserver.disconnect();
        clearInterval(scrollIntervalRef.current);
        clearInterval(totalGameTimeRefWithoutPauseRef.current);
        setIsClickAllowed(false);
        setTimeout(async () => {
            handleGbaState(GBA_SCREEN_STATE[4]);
            const submitEndChallengeAttemptData = await submitEndChallengeAttempt({
                ...finalChallengeDataRef.current,
                completion: false,
                attemptId: attemptDataRef.current.id,
                scored: Math.ceil(totalScoreRef.current),
                status: constants.GBA_ILA_STATUS.lifeLose,
                microskillId: attemptDataRef.current.microskillId,
                challengeId: attemptDataRef.current.challengeId,
                timespent: totalGameTimeRef.current,
            });

            setIncorrectData(submitEndChallengeAttemptData?.data?.data?.consecutiveIncorrectAttempt);
            setGameEndType('loose');
            setIsGameComplete(true);
        }, 2000);
    }

    const removeListener = () => {
        if (isEmpty(document.getElementById('gameContainer'))) return;
        let old_element = document.getElementById('gameContainer');
        let new_element = old_element.cloneNode(true);
        old_element.parentNode.replaceChild(new_element, old_element);
    }

    const setInitialParameters = () => {
        console.log("setInitialParameters called")
        viewedEntries = 0;
        // viewedEntriesRef.current = 0;
        containerHeight = gameplayBoxRef.current ? gameplayBoxRef.current.clientHeight : 100;
        let scrollValue = containerHeight;
        if (!isEmpty(gameplayBoxRef.current)) gameplayBoxRef.current.style.transform = `translateY(${scrollValue}px)`;

        setTimeout(() => {
            observeEntries();
            // observeEntriesForCount();
            if (speedLevel === 1) {
                startScrolling(scrollValue, rockFlowSpeed1);
                setSpeed(rockFlowSpeed1, 'one');
            } else if (speedLevel === 2) {
                startScrolling(scrollValue, rockFlowSpeed2);
                setSpeed(rockFlowSpeed2, 'half');
            } else if (speedLevel === 3) {
                startScrolling(scrollValue, rockFlowSpeed3);
                setSpeed(rockFlowSpeed3, 'all');
            }
            gameplayBoxRef.current.style.opacity = '1';
        }, 1000);
    }

    const startScrolling = (scrollValue, interval) => {
        clearInterval(totalGameTimeRefWithoutPauseRef.current)
        totalGameTimeRefWithoutPauseRef.current = setInterval(() => {
            setAddPause(true);
            totalGameTimeRef.current += 1;
            // console.log(totalGameTimeRef.current, "totalGameTimeRef.current")
        }, 1000);
        scrollIntervalRef.current = setInterval(() => {
            scrollValue -= 1;
            if (!isEmpty(gameplayBoxRef.current)) gameplayBoxRef.current.style.transform = `translateY(${scrollValue}px)`;
        }, interval);
    }

    useEffect(() => {
        handleScore()
    }, [life])

    const handleScore = () => {
        let tempValue = totalScoreRef.current - marksPerCorrectAns;
        totalScoreRef.current = (tempValue) < 0 ? 0 : tempValue;
    }

    const handleAction = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isClickAllowed) return;
        let elId = e.target?.id;
        let answerText = null;
        let lottieBox = null;
        let asset = null;
        let setComplete = false;
        let lottieAsset = asteroidBurst;
        if (!isEmpty(elId) && (e.type === 'touchend' || e.type === 'mousedown') && (elId.includes('game'))) {
            let children = e.target?.parentElement?.children;
            let currentIndex = activeIndexRef.current;
            console.log("activeIndexRef.current", activeIndexRef.current)
            let answerArray = gbaGameData[activeIndexRef.current].answers;
            console.log("answerArray", answerArray);
            setActiveIndex(preState => {
                // answerArray = gbaGameData[preState].answers;
                // currentContextId = gbaGameData[preState].id;
                currentIndex = preState >= activeIndexRef.current ? preState : activeIndexRef.current;
                let index = parseInt(elId.split('-')[2]);
                if (index + 1 === gbaGameData[preState].answers.length) {
                    setComplete = true;
                    handleChangeQuestion(preState);
                }
                return preState;
            });
            if (!setComplete) {
                setTotalViwedEntries(prevState => prevState + 1);
            }

            let answerId = elId.split('-')[1];
            let answerObj = answerArray.find(d => d.id === answerId)
            let currentTime = Date.now();

            let tempReq = {
                qId: gbaGameData[currentIndex].id,
                aId: answerId,
                isCorrect: true,
                t: Math.round(timePerQuestionRef.current),
                skillId: gbaGameData[currentIndex].skillId,
                contextId: gbaGameData[currentIndex].contextId,
            }
            timePerQuestionRef.current = 0;
            if (answerObj.isCorrect) {
                handleChallengeAttemptData(tempReq);
                currentTimeRef.current = currentTime;
                totalScoreRef.current += marksPerCorrectAns;
            } else {
                tempReq.isCorrect = false;
                handleChallengeAttemptData(tempReq);
                currentTimeRef.current = currentTime;

                lottieAsset = wrongAnswer;
                setLife(prevState => {
                    let newState = prevState === 0 ? prevState : prevState - 1;
                    return (newState);
                });

            }
            for (var i = 0; i < children.length; i++) {
                var tableChild = children[i];
                if (!isEmpty(tableChild.id) && tableChild.id.includes('gamelottie')) {
                    lottieBox = tableChild;
                    lottieBox.style.zIndex = "3";
                }
                if (!isEmpty(tableChild.id) && tableChild.id.includes('gameimg')) {
                    asset = tableChild;
                }
                if (!isEmpty(tableChild.id) && tableChild.id.includes('gametext')) {
                    answerText = tableChild;
                }
            }
            asset.style.opacity = '0';
            answerText.style.opacity = '0';
            const lottieAnimation = lottie.loadAnimation({
                container: lottieBox,
                renderer: "svg",
                loop: false,
                autoplay: false,
                animationData: lottieAsset,
                speed: '1'
            });
            lottieAnimation.play()
        }
        return false;
    }

    const animateIncorrectAnsBg = (stop) => {
        if (isEmpty(incorrectGradientOverlayRef.current)) return
        incorrectGradientOverlayRef.current.style.opacity = '1';
        qstnBoxRef.current.classList.add(classes.qstnBoxBoxShadow);
        if (life === 1) {
            incorrectGradientOverlayRef.current.classList.add(`${classes.incorrectGradientOverlayAnimationInfinite}`)
        }
        if (stop) {
            setTimeout(() => {
                incorrectGradientOverlayRef.current.style.opacity = '0';
                qstnBoxRef.current.classList.remove(classes.qstnBoxBoxShadow);
            }, 1000);
        }
    }

    const observeEntries = () => {
        console.log("observeEntries called")
        let bottomY = gameplayContainerRef.current.clientHeight;
        let topY = gameplayBoxRef.current.children[0].clientHeight;
        iObserver = new IntersectionObserver(async (entries, iObserver) => {
            console.log("IntersectionObserver called: ", entries)

            let entryObj = entries.length > 1 ? entries[1] : entries[0]

            console.log("entryObj: ", entryObj)
            if (entryObj.isIntersecting) {
                viewedEntries++;

                let answerArray = gbaGameData[activeIndex].answers;
                let answerId = entryObj.target.id.split('-')[1];
                let index = parseInt(entryObj.target.id.split('-')[2]);
                let assetTextId = `gametext-${answerId}-${index}`;
                let assetTextIdEl = document.getElementById(assetTextId);

                if (assetTextIdEl.style.opacity != '0') {
                    setTotalViwedEntries(prevState => prevState + 1);
                }
                let answerObj = answerArray.find(d => d.id === answerId);
                if (answerObj.isCorrect && assetTextIdEl.style.opacity != '0') {
                    let missedLottie = lottie.loadAnimation({
                        container: wrongLottieRef.current,
                        renderer: "svg",
                        loop: false,
                        autoplay: false,
                        animationData: missedAnswer,
                        speed: '1'
                    });
                    missedLottie.play();
                    let currentTime = Date.now();
                    handleChallengeAttemptData({
                        qId: gbaGameData[activeIndex].id,
                        aId: answerId,
                        isCorrect: false,
                        t: Math.round(timePerQuestionRef.current),
                        skillId: gbaGameData[activeIndex].skillId,
                        contextId: gbaGameData[activeIndex].contextId,
                    });
                    await sleep(500);
                    missedLottie.destroy();
                    setLife(prevState => {
                        if (viewedEntries === gbaGameData[activeIndex].answers.length && prevState - 1 !== 0) {
                            handleChangeQuestion(activeIndex);
                        }
                        return (prevState === 0 ? prevState : prevState - 1)
                    });
                } else {
                    if (viewedEntries === gbaGameData[activeIndex].answers.length) {
                        handleChangeQuestion(activeIndex);
                    }
                }
            }
        }, { root: gameplayContainerRef.current, rootMargin: `${topY}px 0px -${bottomY}px 0px`, threshold: 0.98, });

        let intersectItem = document.querySelectorAll('.rockImageContainer');
        for (let i = 0; i < intersectItem.length; ++i) {
            iObserver.observe(intersectItem[i]);
        }
    }

    const handleChangeQuestion = async (i) => {
        let currentLifes = life; // to avoid life zero when changing question
        questionRef.current.style.scale = '0';
        // questionNoRef.current.innerHTML = activeIndex + 1;
        setTotalViwedEntries((i + 1) * gbaGameData[i].answers.length);

        await sleep(1000);
        clearInterval(scrollIntervalRef.current);
        iObserver.disconnect();
        gameplayBoxRef.current.style.opacity = '0';
        await sleep(500);
        if (i < gbaGameData.length - 1) {
            setActiveIndex(i + 1);
            activeIndexRef.current = i + 1;
            // let width = 100 / (gbaGameData.length - i);
            // progressBarRef.current.style.width = width + "%";
        } else {
            setIsClickAllowed(false);
            await sleep(500);
            setLife(preState => {
                currentLifes = preState;
                if (preState === 0) {
                    handleLifeZero();
                }
                return preState;
            })
            // await sleep(100);
            if (!isDataSubmitted.current && currentLifes > 0) { //
                // is data submitted is false and lifes are greater than 0
                gameCompleteAction();
                isDataSubmitted.current = true;
            }
        }
    }

    const gameCompleteAction = () => {
        if (totalScoreRef.current < minmScore) {
            handleLifeZero();
        } else {
            handleGbaState(GBA_SCREEN_STATE[3]);
            clearInterval(totalGameTimeRefWithoutPauseRef.current);
            submitEndChallengeAttempt({
                ...finalChallengeDataRef.current,
                attemptId: attemptDataRef.current.id,
                scored: Math.ceil(totalScoreRef.current),
                status: constants.GBA_ILA_STATUS.completed,
                microskillId: attemptDataRef.current.microskillId,
                challengeId: attemptDataRef.current.challengeId,
                completion: true,
                timespent: totalGameTimeRef.current
            })
            setGameEndType('win');
            setIsGameComplete(true);
            timePerQuestionRef.current = 0;
            totalGameTimeRef.current = 0;
        }
        return
    }

    const restartGame = () => {
        // debugger
        setResettingGame(true);
        questions = [];
        answers = [];
        finalQuestionId = null;
        isDataSubmitted.current = false;
        setActiveIndex(0);
        setSpeedLevel(2);
        setIsClickAllowed(true)
        setIsGamePage(false);
        setIsGameComplete(false);
        totalScoreRef.current = 0;
        viewedEntriesRef.current = 0;
        currentTimeRef.current = Date.now() // to avoid negative time sending in payload
        clearInterval(scrollIntervalRef.current);
        clearInterval(intervalRef.current);
        activeIndexRef.current = 0;
        timePerQuestionRef.current = 0;
        totalGameTimeRef.current = 0;
        clearInterval(totalGameTimeRefWithoutPauseRef.current);
        getChallengeDataAsync()
        setTimeout(() => {
            setIsGamePage(true);
            setResettingGame(false);
        }, 100);
    }

    const handleBackButtonClick = () => {
        // gbaPauseOverlayRef.current.style.display = 'flex';
        let currentTime = Date.now();
        clearInterval(totalGameTimeRefWithoutPauseRef.current);


        dispatch(handleGbaGameState({
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: Math.ceil(totalScoreRef.current),
            timespent: Math.round(totalGameTimeRef.current)
        }))
        clearInterval(scrollIntervalRef.current);
    }

    const handleResumeClick = () => {
        // gbaPauseOverlayRef.current.style.display = 'none';
        if (isEmpty(gameplayBoxRef.current)) return;
        setAddPause(true);
        let currentY = parseInt(gameplayBoxRef.current.style.transform.split('(')[1].split(')')[0].split('px')[0]);
        if (speedLevel === 1) {
            startScrolling(currentY, rockFlowSpeed1);
        } else {
            startScrolling(currentY, rockFlowSpeed2);
        }
    }

    const setSpeedBar = (elArray, upto, gradient) => {
        for (let i = 0; i < elArray.length; i++) {
            if (i < upto) {
                elArray[i].style.background = gradient;
            } else {
                elArray[i].style.background = "#fff";
            }
        }
    }

    const handleSpeedBarAction = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // let speedBarChildren = speedBarRectBoxRef.current.children;

        if (e?.target?.parentElement?.id === 'speedDecrement' && (e.type === 'touchend' || e.type === 'mousedown')) {
            setSpeedLevel(prevState => {
                if (prevState === 1) return (prevState)
                if (prevState === 2) {
                    setSpeed(rockFlowSpeed1, 'one');
                    return (1);
                } else {
                    setSpeed(rockFlowSpeed2, 'half');
                    return (2);
                }
            })
        } else if (e?.target?.parentElement?.id === 'speedIncrement' && (e.type === 'touchend' || e.type === 'mousedown')) {
            setSpeedLevel(prevState => {
                if (prevState === 1) {
                    setSpeed(rockFlowSpeed2, 'half');
                    return (2);
                } else {
                    setSpeed(rockFlowSpeed3, 'all');
                    return (3);
                }
            })
        }
    }

    const setSpeed = (speedValue, barLengthRatio) => {
        let speedBarChildren = speedBarRectBoxRef.current.children;
        let ratio = 1;
        if (barLengthRatio === 'one') {
            ratio = 1;
        } else if (barLengthRatio === 'half') {
            ratio = speedBarChildren.length / 2;
        } else if (barLengthRatio === 'all') {
            ratio = speedBarChildren.length;
        }
        let currentY = parseInt(gameplayBoxRef.current.style.transform.split('(')[1].split(')')[0].split('px')[0]);
        clearInterval(scrollIntervalRef.current);
        startScrolling(currentY, speedValue);
        setSpeedBar(speedBarChildren, ratio, speedBarGradient);
    }

    const handleChallengeAttemptData = (params) => {
        let { qId, aId, isCorrect, t, skillId, contextId } = params;
        let questionObj = {
            questionId: qId,
            skillId: skillId,
            contextId: contextId,
            answers: [{
                answerId: aId,
                correct: isCorrect
            }],
            timespent: t
        };
        let req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptDataRef.current.id,
            questions: [questionObj]
        }
        submitChallengeAttemptData(req);
        handleEndChallengeAttempt(questionObj, req, skillId, contextId)
    }

    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
        let tempQuestionObj = {
            ...questionObj,
            skillId: skillId,
            contextId: contextId,
        }
        let totalTimeSpent = (isEmpty(finalChallengeDataRef.current) ? 0 : finalChallengeDataRef.current.timespent) + tempQuestionObj.timespent;
        if (isEmpty(finalChallengeDataRef.current)) {
            finalChallengeDataRef.current = {
                ...req,
                questions: [tempQuestionObj],
                completion: true,
                timespent: totalTimeSpent,
                scored: 0,
                fallbackSave: true,
            }
        } else {
            finalChallengeDataRef.current.questions.push(tempQuestionObj)
            finalChallengeDataRef.current = {
                ...finalChallengeDataRef.current,
                timespent: totalTimeSpent,
            }
        }
    }

    return (
        <div style={{
            backgroundImage: !isGamePage ? bgGradientDefault : bgGradientDefault,
            fontSize: `${fSize}rem`
        }}
            className={classes.balloonPopRoot} ref={BalloonPopRootRef}>

            {
                isEmpty(attemptData) || resettingGame ? (
                    <div style={constants.FULL_HEIGHT_CENTER}>
                        <CircularProgress
                            sx={theme => ({
                                color: resettingGame ? theme.palette.white : theme.palette.orange
                            })}
                        />
                    </div>
                ) : (
                    !isGamePage ? (
                        !isHowToPlayScreen ? (
                            <div className={classes.startPage}>
                                <StartScreen
                                    setIsGamePage={setIsGamePage}
                                    setIsHowToPlayScreen={setIsHowToPlayScreen}
                                    bg={"/images/gba/space_rocks_bg.png"}
                                    gameLogo="/images/gba/Space_Rocks_Logo.png"
                                    handleGbaState={handleGbaState}
                                />
                            </div>
                        ) : (
                            <HowToPlay
                                headerBg={headerBg}
                                qstnBoxBg={'#000000'}
                                asset1Img={'/images/gba/rock.png'}
                                asset2Img={'/images/gba/rock.png'}
                                headerColor='#a34735'
                                setIsGamePage={setIsGamePage}
                                gameType='spaceRocks' />
                        )
                    ) : (
                        !isGameComplete ? (
                            <>
                                <div className={classes.incorrectGradientOverlay} ref={incorrectGradientOverlayRef} />
                                <div className={classes.gameContainer} id="gameContainer">

                                    <div className={classes.progressBarContainer}>
                                        <div className={classes.progressBar} ref={progressBarRef} />
                                    </div>

                                    {/* QUESTION BOX */}
                                    <div className={classes.qstnContainer}>
                                        <div className={classes.qstnBox} ref={qstnBoxRef}>
                                            <div className={classes.assetBox}>
                                                <div className={classes.lifeText} ref={lifeTextRef}>
                                                    <div className={classes.life} ref={lifeRef}>{life} x </div>
                                                    <img src='/images/icons/heart.png' className={classes.icon} ref={heartRef} alt="heart" />
                                                </div>
                                                <div className={classes.questionNo}>Q<span ref={questionNoRef}>{activeIndex + 1}</span>/{gbaGameData.length}</div>
                                                <div className={classes.timerText}>
                                                    {/* <img src='/popcornMatch/stopwatch.png' className={classes.timerIcon} alt="timer" />
                                                    <div><b><span ref={timerRef}>{tempTimer}</span>s</b></div> */}
                                                </div>
                                            </div>
                                            <div className={classes.qstn} ref={questionRef}> {gbaGameData[activeIndex].question} </div>
                                        </div>
                                    </div>

                                    <div className={classes.gameplayContainer} ref={gameplayContainerRef}>
                                        <div className={classes.scrollBox} >
                                            <div ref={wrongLottieRef} className={classes.wrongLottie}></div>
                                            <div className={classes.gameplayBox} ref={gameplayBoxRef} id="gameplayBoxRef">
                                                {
                                                    gbaGameData[activeIndex].answers.map((option, index1) => (
                                                        <div className={`${classes.rockImageBox}`} key={option.id}
                                                            ref={(ref) => rockImageBoxRef.current.push(ref)}>
                                                            <div className={`${classes.rockBox} rockImageContainer`} id={`rockImageContainer-${option.id}-${index1}`}>
                                                                <img src="/images/gba/rock.png" className={classes.rockImage} id={`gameimg-${option.id}`} alt="rock" />
                                                                <div className={classes.answerTxt} id={`gametext-${option.id}-${index1}`}>
                                                                    {option.answer}
                                                                    {/* {`-${option.isCorrect}`} */}
                                                                </div>
                                                                <div id={`gamelottie-${option.id}`} className={classes.correctLottie} />
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className={classes.speedBarBox} ref={speedBoxRef}>
                                            <div className={classes.speedDecrement} id='speedDecrement'>
                                                <img src="/images/icons/left_arrow.png" alt="left_arrow" className={classes.speedBarArrow} />
                                                <img src="/images/icons/left_arrow.png" alt="left_arrow" className={classes.speedBarArrow} />
                                            </div>
                                            <div className={classes.speedBarRectBox} ref={speedBarRectBoxRef}>
                                                {
                                                    Array.from(Array(speedBarLength), (e, i) => (
                                                        <div key={i} style={{ background: i < speedBarLength / 2 ? speedBarGradient : 'auto' }} />
                                                    ))
                                                }
                                            </div>
                                            <div className={classes.speedIncrement} id='speedIncrement'>
                                                <img src="/images/icons/right_arrow.png" alt="left_arrow" className={classes.speedBarArrow} />
                                                <img src="/images/icons/right_arrow.png" alt="left_arrow" className={classes.speedBarArrow} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {
                                    gameEndType === 'win' && (
                                        <GbaWinScreen
                                            maxScore={mxmScore}
                                            bg={""}
                                            obtainedScore={Math.ceil(totalScoreRef.current)} />
                                    )
                                }
                                {
                                    gameEndType === 'loose' && (
                                        <GbaGameOverScreen
                                            type={"gameover"}
                                            gameType={gameData?.gameType}
                                            gameData={gameData}
                                            incorrectAttempt={incorrectData}
                                            bg={""}
                                            bgGradient={bgGradientLoss}
                                            restartGame={restartGame} />
                                    )
                                }
                            </>
                        )
                    )
                )
            }
        </div>
    )
};
export default SpaceRocks;
