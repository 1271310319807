import React from "react";
import StarRatingComponent from 'react-star-rating-controlled-component';


// mui
import Rating from '@mui/material/Rating';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import Stepper from '@mui/material/Stepper';

// utils
import commonUtil from "../../utils/commonUtil";

// styles
import useStyles from "./index.styles";

const starWhite = "/images/icons/star_white_updated.svg";
const starHighlighted = "/images/icons/filled_star_updated.svg";

const ChallengeListCard = (props) => {
    const {
        bgIcon = "/images/challenge-list/card.svg",
        bgColor = "#5487eb",
        star = 3,
        data,
        handleChallengeListCardClick,
        index,
        isBlur,
    } = props;
    const classes = useStyles();
    const starValue = commonUtil.getStarValue(data?.Challenge?.maxScore, data.scored, 3, data?.Challenge?.type === 'SL' ? 0 : null);
    const getCardBg = (type) => {
        if (type === 'SL') return "/images/challenge-list/spotlight.svg"
        else if (type === 'LA') return "/images/challenge-list/card.svg"
        else if (type === 'GBA') return "/images/challenge-list/game.svg"
        else if (type === 'SCORM') return "/images/challenge-list/scorm.svg"
    }

    const getCardBgColor = (i) => {
        if (i === 0 || i % 4 === 0) return "#5487EB";
        else if (i === 1 || i % 5 === 0) return "#43A047";
        else if (i === 2 || i % 6 === 0) return "#FFB300";
        else if (i === 3 || i % 7 === 0) return "#F63B34";
    }

    return (
        <div className={classes.challengeListCardRoot}
            style={{
                backgroundImage: `url(${getCardBg(data?.Challenge?.type)})`,
                backgroundColor: getCardBgColor(index),
                filter: isBlur ? 'blur(4px)' : null
            }}
            onClick={handleChallengeListCardClick}
        >
            <div className={classes.cardName}>{data?.Challenge?.name}</div>
            {data?.Challenge?.maxScore > 0 && <div className={classes.score}>{`${data.scored} / ${data?.Challenge?.maxScore}`}</div>}
            <div className={classes.starBox}>
                {
                    data?.Challenge?.type !== 'LA' && data?.Challenge?.type !== 'SCORM' && (
                        <StarRatingComponent
                            name={'ss'}
                            starCount={3}
                            value={starValue}
                            renderStarIcon={(index, value) => {
                                return (
                                    <span>
                                        {/* {index > value ? (<StarBorderIcon />) : (<StarIcon />)} */}
                                        {index > value ? (<img src={starWhite} alt="star" />) : (<img src={starHighlighted} alt="star" />)}
                                    </span>
                                )
                            }}
                            starColor={'#FFD042'}
                            emptyStarColor={'#FFF'}
                            editing={false}
                        />
                    )
                }
            </div>
        </div>
    )
};

export default ChallengeListCard