import React, { useEffect, useState } from 'react';

import useStyles from './index.styles';

// mui
import { Button, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Rating, Slider, TextField, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useTheme } from '@mui/styles';

// mui-icons
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import StarIcon from '@mui/icons-material/Star';

// moment
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const FormAnswers = (props) => {
    const { answers, questionType, questionSequence, questionId, setAttemptData, attemptData, options, mandatory, attemptMandatory, setAttemptMandatory, questionAttemptData, setQuestionAttemptData } = props;

    const styles = useStyles();

    const theme = useTheme();

    const { t } = useTranslation();

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleMultiChoice = (e, ans) => {

        const tempAttemptQuestion = attemptData.filter((temp) => temp.questionId === questionId);
        var tempAttemptData = attemptData.filter((temp) => temp.questionId !== questionId);
        const newAttemptAnswers = tempAttemptQuestion[0].answers;

        const isAnswerPresent = newAttemptAnswers.filter((tans) => tans.id === ans.id);

        var newAttempt = tempAttemptQuestion[0];
        if (isAnswerPresent.length > 0) {
            newAttempt.answers = newAttemptAnswers.filter((tans) => tans.id !== ans.id);
            if (newAttempt.answers.length === 0 && mandatory) {
                setAttemptMandatory(attemptMandatory.filter((temp) => temp !== questionId));
            }
        }
        else {
            newAttemptAnswers.push(ans);
            newAttempt.answers = newAttemptAnswers;
            const isPresent = attemptMandatory.find((temp) => temp === questionId);
            if (!isPresent && mandatory) {
                setAttemptMandatory([...attemptMandatory, questionId]);
            }

            const attemptQuestion = attemptData.filter((temp) => temp.questionId === questionId)[0];


            const questions = [
                {
                    id: questionId,
                    question: attemptQuestion.question,
                    options: attemptQuestion.options,
                    type: attemptQuestion.type,
                    answers: newAttemptAnswers
                }
            ]

            setQuestionAttemptData({ ...questionAttemptData, questions: questions })
        }

        tempAttemptData.push(newAttempt);

        tempAttemptData = tempAttemptData.sort((a, b) => a.sequence - b.sequence);

        setAttemptData(tempAttemptData);
    }

    const handleSingleChoice = (e, ans) => {
        const answer = JSON.parse(ans);

        const tempAttemptQuestion = attemptData.filter((temp) => temp.questionId === questionId);
        var tempAttemptData = attemptData.filter((temp) => temp.questionId !== questionId);

        const isPresent = attemptMandatory.find((temp) => temp === questionId);
        if (!isPresent && mandatory) {
            setAttemptMandatory([...attemptMandatory, questionId]);
        }


        var newAttempt = tempAttemptQuestion[0];
        newAttempt.answers = [answer];

        const attemptQuestion = attemptData.filter((temp) => temp.questionId === questionId)[0];


        const questions = [
            {
                id: questionId,
                question: attemptQuestion.question,
                options: attemptQuestion.options,
                type: attemptQuestion.type,
                answers: newAttempt.answers
            }
        ]

        setQuestionAttemptData({ ...questionAttemptData, questions: questions })

        tempAttemptData.push(newAttempt);

        tempAttemptData = tempAttemptData.sort((a, b) => a.sequence - b.sequence);


        setAttemptData(tempAttemptData);

    }

    const handleOpenEnded = (e, questionId) => {
        const tempAns = [{
            answer: e.target.value
        }];

        const isPresent = attemptMandatory.find((temp) => temp === questionId);
        if (isPresent === undefined && mandatory && e.target.value !== '') {
            setAttemptMandatory([...attemptMandatory, questionId]);
        }
        else if (isPresent && mandatory && e.target.value === '') {
            setAttemptMandatory(attemptMandatory.filter((temp) => temp !== questionId));
        }


        const tempAttemptedQuestion = attemptData.filter((temp) => temp.questionId === questionId);
        var tempAttemptData = attemptData.filter((temp) => temp.questionId !== questionId);

        var newAttempt = tempAttemptedQuestion[0];
        newAttempt.answers = tempAns;

        const attemptQuestion = attemptData.filter((temp) => temp.questionId === questionId)[0];


        const questions = [
            {
                id: questionId,
                question: attemptQuestion.question,
                options: attemptQuestion.options,
                type: attemptQuestion.type,
                answers: tempAns
            }
        ]

        setQuestionAttemptData({ ...questionAttemptData, questions: questions })

        tempAttemptData.push(newAttempt);
        tempAttemptData = tempAttemptData.sort((a, b) => a.sequence - b.sequence);


        setAttemptData(tempAttemptData);
    }

    const handleSliderChange = (e, sliderValue) => {
        const tempAns = [{
            answer: sliderValue.toString()
        }];

        const isPresent = attemptMandatory.find((temp) => temp === questionId);
        if (!isPresent && mandatory) {
            setAttemptMandatory([...attemptMandatory, questionId]);
        }

        const tempAttemptedQuestion = attemptData.filter((temp) => temp.questionId === questionId);
        var tempAttemptData = attemptData.filter((temp) => temp.questionId !== questionId);

        var newAttempt = tempAttemptedQuestion[0];
        newAttempt.answers = tempAns;

        const attemptQuestion = attemptData.filter((temp) => temp.questionId === questionId)[0];


        const questions = [
            {
                id: questionId,
                question: attemptQuestion.question,
                options: attemptQuestion.options,
                type: attemptQuestion.type,
                answers: tempAns
            }
        ]

        setQuestionAttemptData({ ...questionAttemptData, questions: questions })

        tempAttemptData.push(newAttempt);
        tempAttemptData = tempAttemptData.sort((a, b) => a.sequence - b.sequence);

        console.log(tempAttemptData);
        setAttemptData(tempAttemptData);
    }

    const handleDateChange = (e, dateValue) => {
        const tempAns = [{
            answer: moment(e.$d).format('DD-MM-YYYY')
        }];

        const isPresent = attemptMandatory.find((temp) => temp === questionId);
        if (!isPresent && mandatory) {
            setAttemptMandatory([...attemptMandatory, questionId]);
        }

        const tempAttemptedQuestion = attemptData.filter((temp) => temp.questionId === questionId);
        var tempAttemptData = attemptData.filter((temp) => temp.questionId !== questionId);

        var newAttempt = tempAttemptedQuestion[0];
        newAttempt.answers = tempAns;

        const attemptQuestion = attemptData.filter((temp) => temp.questionId === questionId)[0];


        const questions = [
            {
                id: questionId,
                question: attemptQuestion.question,
                options: attemptQuestion.options,
                type: attemptQuestion.type,
                answers: tempAns
            }
        ]

        setQuestionAttemptData({ ...questionAttemptData, questions: questions })

        tempAttemptData.push(newAttempt);
        tempAttemptData = tempAttemptData.sort((a, b) => a.sequence - b.sequence);


        setAttemptData(tempAttemptData);
    }

    const handleStarChange = (e, value) => {

        const tempAns = [{
            answer: value?.toString()
        }];

        const isPresent = attemptMandatory.find((temp) => temp === questionId);
        if (!isPresent && mandatory) {
            setAttemptMandatory([...attemptMandatory, questionId]);
        }

        const tempAttemptedQuestion = attemptData.filter((temp) => temp.questionId === questionId);
        var tempAttemptData = attemptData.filter((temp) => temp.questionId !== questionId);

        var newAttempt = tempAttemptedQuestion[0];
        newAttempt.answers = tempAns;

        const attemptQuestion = attemptData.filter((temp) => temp.questionId === questionId)[0];


        const questions = [
            {
                id: questionId,
                question: attemptQuestion.question,
                options: attemptQuestion.options,
                type: attemptQuestion.type,
                answers: tempAns
            }
        ]

        setQuestionAttemptData({ ...questionAttemptData, questions: questions })

        tempAttemptData.push(newAttempt);
        tempAttemptData = tempAttemptData.sort((a, b) => a.sequence - b.sequence);


        setAttemptData(tempAttemptData);
    }


    const handleFileUpload = (e) => {
        console.log(e.target.files[0])
    }

    const marks = [
        {
            value: options.minLimit,
            label: options.minLabel === '' ? options.minLimit : options.minLabel
        },

        {
            value: options.maxLimit,
            label: options.maxLabel === '' ? options.maxLimit : options.maxLabel
        }
    ]

    const CustomButtonStyle = {
        width: '100%',
        textAlign: 'left',
        paddingLeft: '0',
        "&:hover": {
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
        "& .MuiTouchRipple-child": {
            backgroundColor: 'rgba(0,0,0,0.2)'
        }, "& .MuiTouchRipple-rippleVisible": {
            opacity: '0.5',
            animation: '$enterKeyframe 2s ease-in-out',
        },
        "@keyframes enterKeyframe": {
            "0%": {
                transform: 'scale(0)',
                opacity: '0.1'
            },
            "100%": {
                transform: 'scale(1)',
                opacity: '0.5'
            }
        }
    }

    return (
        <div className={styles.formAnswerContainer}>
            {(questionType === 'SINGLE_CHOICE_QUESTION' || questionType === 'MULTI_CHOICE_QUESTION') &&
                <FormControl sx={{
                    width: '100%'
                }}>

                    {questionType === 'MULTI_CHOICE_QUESTION' && answers.map((ans, idx) => {
                        return <FormControlLabel
                            key={ans.id}
                            label={ans.answer}
                            control={<Checkbox
                                style={{ padding: '0.3rem 0.5rem 0.3rem 0rem' }}
                            />}
                            onChange={(e) => handleMultiChoice(e, ans)}
                            sx={{
                                alignItems: 'flex-start',
                                marginLeft: '0rem ',
                                marginBottom: '0.5rem',
                                marginRight: '0rem',
                                width: '100%',
                                '.MuiFormControlLabel-label': {
                                    // border: '1px solid rgba(0,0,0,0.2)',
                                    width: '100%',
                                    padding: '0.3rem 0.5rem'
                                }
                            }}
                        />
                    })}

                    {questionType === 'SINGLE_CHOICE_QUESTION' &&
                        <RadioGroup onChange={(e, value) => handleSingleChoice(e, value)}>
                            {
                                answers.map((ans, idx) => {
                                    const ansId = ans.id;
                                    return <FormControlLabel
                                        key={ansId}
                                        value={JSON.stringify(ans)}
                                        label={ans.answer}
                                        control={<Radio
                                            style={{ padding: '0.3rem 0.5rem 0.3rem 0rem' }}
                                        />}
                                        sx={{
                                            alignItems: 'flex-start',
                                            marginLeft: '0rem',
                                            marginBottom: '0.5rem',
                                            marginRight: '0rem',
                                            width: '100%',
                                            '.MuiFormControlLabel-label': {
                                                // border: '1px solid rgba(0,0,0,0.2)',
                                                width: '100%',
                                                padding: '0.3rem 0.5rem'
                                            }
                                        }}
                                    />
                                })
                            }
                        </RadioGroup>
                    }

                </FormControl>
            }

            {
                questionType === 'OPEN_ENDED' &&
                <div className={`${styles.centerFlex} ${styles.centerFlexLaptop}`}>
                    <textarea rows={1} className={styles.openEnded} placeholder={t('Enter your Answer')} onChange={(e) => handleOpenEnded(e, questionId)} />
                </div>
            }

            {
                questionType === 'NUMBER_RATING' &&
                <div className={`${styles.centerFlex} ${styles.centerFlexLaptop} ${styles.sliderComponent}`}>
                    <Slider size='medium' className={styles.numberRating} defaultValue={options.minLimit}
                        step={1}
                        min={options.minLimit}
                        max={options.maxLimit}
                        valueLabelDisplay='auto'
                        marks={marks}
                        onChange={(e, value) => handleSliderChange(e, value)}
                        sx={{
                            ".MuiSlider-markLabel": {
                                width: '40%',
                                textAlign: 'center',
                                whiteSpace: 'wrap',
                                textOverflow: 'wrap',
                                // marginBottom: '1rem'
                            },
                            [theme.breakpoints.up('md')]: {
                                ".MuiSlider-markLabel": {
                                    width: '40%',
                                }
                            }
                        }}
                    />
                </div>
            }

            {
                questionType === 'DATE' &&
                <div className={`${styles.centerFlex} ${styles.centerFlexLaptop}`}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}

                    >
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker className={styles.datePicker}
                                sx={{
                                    marginLeft: '2rem',
                                    [theme.breakpoints.down('md')]: {
                                        marginLeft: '2rem',
                                        '.MuiInputBase-root > input': {
                                            padding: '0.5rem',
                                            width: '100%'
                                        }
                                    }
                                }}
                                onChange={(e, value) => handleDateChange(e, value)}
                                views={['year', 'month', 'day']}
                            />
                        </DemoContainer>
                    </LocalizationProvider></div>
            }

            {questionType === 'STAR_RATING' &&
                <Rating
                    className={styles.starRating}
                    size='large'
                    onChange={(e, value) => handleStarChange(e, value)}
                    emptyIcon={
                        <StarIcon fontSize="inherit" sx={{
                            borderRadius: '5px',
                            marginRight: '0.3rem',
                        }} />
                    }
                />
            }

            {
                questionType === 'CUSTOM' &&
                <Button className={styles.uploadFileButton} component="label" variant="contained" disableElevation startIcon={<CloudUploadIcon />} onChange={(e) => handleFileUpload(e)}>
                    {t("Upload file")}
                    <VisuallyHiddenInput type="file" />
                </Button>
            }
        </div >
    )
}

export default FormAnswers;