import { makeStyles } from "@mui/styles";
import constants from "../../constants";
import { isNativeApp } from "../../utils/commonUtil";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    gbaRootWrapper: {
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
            position: 'fixed',
            overflow: 'hidden',
            width: '100vw',
        }
    },
    gbaRoot: {
        userSelect: 'none',
        position: 'relative',
        backgroundColor: '#000',
        color: theme.palette.white,
        width: '100%',
        fontSize: ({ i18n }) => {
            let langObj = constants.LANGUAGES_LIST.filter(e => e.key === i18n.language)[0]
            return langObj.font
        },
        padding: (isMobile) => {
            return isMobile ? '0' : '1rem'
        },
        // cursor: 'pointer'
        // height: 'calc(844px - 4rem) !important'
        // height: 'calc(694px - 4rem) !important'
    },
    gbaBox: {
        position: 'relative',
        height: '100%',
        borderRadius: ({ isMobile }) => {
            return isMobile ? 'inherit' : '8px'
        },
        border: ({ isMobile }) => {
            return isMobile ? 'none' : '2px solid #FFFFFF'
        },
        aspectRatio: ({ isMobile }) => {
            return isMobile && isNativeApp() ? '9/16' : '9/16'
        },
        width: ({ isMobile }) => {
            return !isMobile ? 'auto' : '100%'
        },
        margin: 'auto',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 'none'
        }
        // backdropFilter: 'blur(7px)',
        // backgroundColor: '#ffc0cb30',
    },
    backBtnBox: {
        position: 'absolute',
        left: '2rem',
        top: '2rem',
        height: '2rem',
        width: '2rem',
        border: '2px solid #FFF',
        // aspectRatio: '1/1',
        borderRadius: '50%',
        cursor: 'pointer'
    },
    gbaHeader: {
        height: isNativeApp() ? constants.HEADER_HEIGHT_MOBILE : constants.HEADER_HEIGHT,
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'flex-start',
        padding: '0.5rem 1rem',
        paddingTop: isNativeApp() ? constants.HEADER_TOP_PADDING : null
    },
    headerName: {
        fontWeight: 600,
        paddingLeft: '0.5rem'
    }
}))

export default useStyles