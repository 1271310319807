import { makeStyles } from "@mui/styles";
import commonUtil from "../../../utils/commonUtil";
// import palette from "../../theme/palette";

// const COLORS = constants.COLORS;
const isHDScreen = commonUtil.getScreenResolution() === 'FHD';
const screen = commonUtil.getScreenResolution();
const zoom = commonUtil.browserZoom();

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    cricketRoot: {
        height: '100%',
        backgroundImage: "url(/images/gba/master-blaster/master_blaster_cloud_2.png)",
        backgroundPositionY: 'top',
        backgroundPositionX: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    //game play
    gameContainer: {
        display: "grid",
        height: "100%",
        overflow: 'hidden',
        touchAction: 'none',
        opacity: '1',
        animation: '$animateGameLandingPage 2s ease 0s 1 normal forwards running, $animateOverlay 3s ease 1s 1 normal forwards running',
        // backgroundImage: "linear-gradient(#00000000, #00000000), url(/images/gba/cricket_bg_2.png)",
        backgroundRepeat: 'repeat-x',
        backgroundSize: 'cover',
        transition: '1s',
        gridTemplateRows: !isHDScreen ? "25% 75%" : "25% 75%",
        // animation: '$animateOverlay 1s ease 0s 1 normal forwards running'
    },
    blinkAnimationEffect: {
        animation: '$blinkAnimation 1.5s ease-in-out 0s infinite normal forwards running',
    },
    '@keyframes blinkAnimation': {
        '0%': { opacity: 0 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0 }
    },
    "@keyframes animateGameLandingPage": {
        "0%": { opacity: '0' },
        "100%": { opacity: '1' }
    },
    "@keyframes animateOverlay": {
        // "to": { backgroundImage: 'linear-gradient(#0000007a, #00000069), url(/images/gba/cricket_bg_2.png)' },
    },
    progressBarContainer: {
        position: "relative",
        height: "9px",
        width: '100%',
        zIndex: "2",
        // width: (isMobile) => {
        //     return `calc(100% - ${isMobile ? '0px' : '0px'})`
        // },
        borderRadius: (isMobile) => {
            return `${isMobile ? '0px' : '0px'}`
        },
        // margin: (isMobile) => {
        //     return isMobile ? '' : "auto"
        // },
        overflow: 'hidden',

        backgroundColor: theme.palette.white,
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.progressBarBg,
        },
    },
    progressBar: {
        position: "absolute",
        top: "0",
        left: "0",
        height: "9px",
        backgroundColor: "#ffd042",
        // width: '0',
        transition: '.4s',
    },
    // questionBox container
    qstnContainer: {
        height: "90%",
        transform: "translateY(-200px)",
        animation: "$translateQstnContainer 1s ease 0s 1 normal forwards running",
        zIndex: '2',
        position: 'relative',
        opacity: '0.8',
        // padding: !isHDScreen ? "0.5rem 0 0 0" : "0.5rem 0 0 0",
    },
    "@keyframes translateQstnContainer": {
        "0%": { transform: "translateY(-200px)" },
        "100%": { transform: "translateY(0)" }
    },
    qstnBox: {
        display: 'grid',
        gridTemplateRows: '35% 65%',
        backgroundColor: 'rgb(0 0 0 / 56%)',
        fontSize: "1.2em",
        height: '100%'
    },
    qstnBoxBoxShadow: {
        // animation: '$wrongAnsAnimation 1.5s ease 0s 1 normal forwards running'
    },
    "@keyframes wrongAnsAnimation": {
        "0%": { boxShadow: "0px 10px 20px #0000004D" },
        "100%": { boxShadow: "0px 0px 20px -1px #F63B34E8" },
        // "100%": { boxShadow: "0px 10px 20px #0000004D"}
    },
    assetBox: {
        display: "grid",
        // gridTemplateColumns: "1fr 1fr",
        gridTemplateColumns: "1fr 1fr 1fr",
        padding: "12px",
        alignItems: 'center'
    },
    lifeText: {
        display: "flex",
        alignItems: "center",
    },
    timerText: {
        display: "flex",
        alignItems: "center",
        // padding: "0 15px",
        width: '100%',
        justifyContent: "end",
    },
    time: {
        fontWeight: '600',
        fontSize: '1em',
        width: '25%',
        textAlign: 'center'
    },
    icon: {
        width: "1em",
        height: "1em",
        margin: "0 5px"
    },
    life: {
        fontWeight: '600',
        fontSize: '1em',
    },
    timerIcon: {
        width: "1em",
        height: "1em",
        margin: "0 5px",
    },
    qstn: {
        textAlign: "center",
        transition: '.5s',
        fontFamily: 'Open Sans',
        fontSize: '1.2em',
        padding: '0rem 0.9rem',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.2em ',
        },
    },
    gameplayContainer: {
        position: 'relative',
        height: '100%',
        // paddingTop: '3rem',
    },
    gameplayContainerPseudo: {
        '&::before': {
            content: '""',
            position: 'absolute',
            // inset: '0 0 0 0',
            // backgroundColor: '#000000b0',
            zIndex: 2,
            backdropFilter: 'blur(6px)',
            width: '100%',
            height: '100%',
            transition: '.6s',
            animation: '$pseudoDivAnimation .4s ease 0s 1 normal forwards running'
        }
    },
    "@keyframes pseudoDivAnimation": {
        "0%": { opacity: "0" },
        "100%": { opacity: "1" },
    },
    gameplayContainerPseudoHide: {
        '&::before': {
            content: '""',
            position: 'absolute',
            // inset: '0 0 0 0',
            // backgroundColor: '#000000b0',
            zIndex: 2,
            backdropFilter: 'blur(6px)',
            width: '100%',
            height: '100%',
            transition: '.6s',
            animation: '$pseudoDivAnimationHide .4s ease 0s 1 normal forwards running'
        }
    },
    "@keyframes pseudoDivAnimationHide": {
        "0%": { opacity: "1" },
        "100%": { opacity: "0" },
    },
    gameContainerBox: {
        position: 'relative',
        height: '100%'
    },
    option: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        width: '85%',
        fontSize: '1.2em',
        fontFamily: 'Roboto, sans-serif'
    },
    questionNo: {
        textAlign: 'center',
        fontWeight: 600,
        color: '#F5CF38',
        letterSpacing: '0.2rem'
    },
    optionBtn: {
        padding: '0.5rem 1rem !important',
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'flex-start !important',
        // background: 'none !important',
        background: '#565656bf !important',
        width: '100% !important',
        borderRadius: '6rem !important',
        fontWeight: '500 !important',
        gap: '1rem',
        color: '#fff !important',
        minHeight: '3.2rem !important',
        minHeight: '4rem',
        transition: 'all 0.15s linear !important',
    },
    ballImage: {
        width: '2rem'
    },
    optionBox: {
        display: 'grid',
        alignItems: 'center',
        position: 'absolute',
        left: '0',
        gap: '0.6rem',
        height: '100%',
        // width: '10%',
        overflow: 'hidden',
        transition: '.4s',
        zIndex: 2,
        padding: '3rem 1rem 0 1rem',
        // opacity: 0,
        // animation: "$translateOptionContainer 1s ease 0s 1 normal forwards running",
    },
    "@keyframes translateOptionContainer": {
        "0%": { transform: "translateX(-400px)" },
        "100%": { transform: "translateY(0)" }
    },
    animationTypeLottieBox: {
        position: 'relative',
        zIndex: 1,
        // height: '100%'
    },
    lottieBox: {
        position: 'absolute',
        opacity: '1',
        top: '1000px'
        // inset: '0 0 0 0',
        // display: 'flex',
        // alignItems: 'flex-end'
    },
    lottieLoader: {
        position: 'absolute',
        inset: '0 0 0 0',
        zIndex: 10,
    },
    assestsBox: {
        position: 'relative',
        opacity: '0',
        height: '100%',
        display: 'grid',
        alignContent: 'flex-end'
    },
    assestsLayoutBox: {
        position: 'relative',
        // inset: '0 0 0 0',
        top: '57%',
        display: 'grid',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        }
    },
    tapToPlayBox: {
        position: 'absolute',
        zIndex: 2,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0 1rem 0 0',
        top: '0',
        left: '-10px',
        cursor: 'pointer'
        // display: 'block'
    },
    tapToPlayIconBox: {
        width: '3rem',
        height: '3rem',
        borderRadius: '0 50% 50% 0',
        backgroundColor: '#0000007d',
        // transform: 'rotate(180deg)'
    },
    tapToPlayText: {
        fontFamily: 'MotionControl',
        // fontWeight: 500,
        fontSize: '2rem',
        paddingLeft: '1rem'
    },
    defaultImage: {
        position: 'absolute',
        bottom: '0'
    },
    wrongAnswerAnimation: {
        animation: '$wrongAnswerAnim 1s ease-in-out 0s infinite normal forwards running'
    },
    "@keyframes wrongAnswerAnim": {
        "0%": {
            boxShadow: "inset 0px 0px 10px 4px red",
            background: "#ff000063",
            zIndex: '2',
            opacity: 0,
        },
        "100%": {
            boxShadow: "inset 0px 0px 10px 1px red",
            background: "#ff000029",
            zIndex: '2',
            opacity: 1,
        },
    },
    rightAnswerAnimation: {
        animation: '$rightAnswerAnim 1s ease-in-out 0s 3 normal forwards running'
    },
    "@keyframes rightAnswerAnim": {
        "0%": {
            boxShadow: "inset 0px 0px 10px 4px #0cc43d",
            // background: "#1b870082",
            zIndex: '2',
            opacity: 0,
        },
        "100%": {
            boxShadow: "inset 0px 0px 10px 1px #0cc43d",
            // background: "#1b870057",
            zIndex: '2',
            opacity: 1,
        },
    },
    incorrectGradientOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        // backgroundImage: 'linear-gradient(#800e315e, #f63b348c)',
        opacity: '0',
        transition: '.5s ease',
    },
    optionText: {
        fontSize: '1em',
        textAlign: 'left',
        position: 'absolute',
        top: '50%',
        left: '4rem',
        transform: ' translateY(-50%)',
        fontWeight: 600,
        paddingRight: '0.6rem',
        cursor: 'pointer'
    },
    greenBtn: {
        backgroundColor: '#06ab06bd !important',
        transition: '0.8s !important',
    },
    redBtn: {
        backgroundColor: '#ff00009c !important',
        transition: '0.8s !important',
    },
    blinkAnimation: {
        animation: "$blinkAnim 1s ease 0s infinite normal forwards running",
    },
    "@keyframes blinkAnim": {
        "0%": { opacity: "1" },
        "50%": { opacity: "0" },
        "100%": { opacity: "1" },
    },
    btnMainContainer: {
        position: 'relative',
    },
    correctIncorrectText: {
        fontFamily: 'MotionControl !important',
        position: 'absolute',
        // fontSize: '2.5rem',
        textAlign: 'center',
        width: '100%',
        zIndex: 2,
        transition: '0.5s',
        opacity: '0',
        width: '90%',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '0.6rem',
        display: 'flex',
        justifyContent: 'center',
    },
    correctIncorrectTextLottie: {
        position: 'absolute',
    },
    muteSwitch: {
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: 2,
        cursor: 'pointer',
        padding: '0.4rem'
    },
    thickText: {
        fontFamily: 'MotionControl',
        fontSize: '3em',
        fontWeight: 900,
        transform: 'scale(0)',
        transformOrigin: 'center center',
        transition: 'transform 0.5s linear',
        position: 'absolute',
        color: theme.palette.greyText4
    },

    muteSwitch: {
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: 2,
        cursor: 'pointer',
        padding: '0.4rem'
    },
}))

export default useStyles