import { eventActionTypes } from "./event.types";
// import constants from "../../constants";

const INITIAL_STATE = {
    oldScreen: "",
    currentScreen: "LOGIN",
    nextScreen: "",
    activityType: "",
    activityData: {}
}

const eventReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case eventActionTypes.LOGIN_PAGE:
            return {
                ...state,
                ...action.payload,
                activityType: eventActionTypes.LOGIN_PAGE
            }
        case eventActionTypes.LOGIN_BUTTON_CLICK:
            return {
                ...state,
                ...action.payload,
                activityType: eventActionTypes.LOGIN_BUTTON_CLICK
            }
        case eventActionTypes.LOGIN_OTP:
            return {
                ...state,
                ...action.payload,
                activityType: eventActionTypes.LOGIN_OTP
            }
        case eventActionTypes.SCREEN_CHANGE:
            return {
                ...state,
                ...action.payload,
                activityType: eventActionTypes.SCREEN_CHANGE
            }
        case eventActionTypes.LEARN_PAGE:
            return {
                ...state,
                ...action.payload,
                activityType: eventActionTypes.LEARN_PAGE
            }
        case eventActionTypes.LOGGED_IN:
            return {
                ...state,
                ...action.payload,
                activityType: eventActionTypes.LOGGED_IN
            }
        case eventActionTypes.MICROSKILLS_LOADED:
            return {
                ...state,
                ...action.payload,
                activityType: eventActionTypes.MICROSKILLS_LOADED
            }
        case eventActionTypes.DOCUMENTS_PAGE:
            return {
                ...state,
                ...action.payload,
                activityType: eventActionTypes.DOCUMENTS_PAGE
            }
        case eventActionTypes.DOCUMENTS_LOADED:
            return {
                ...state,
                ...action.payload,
                activityType: eventActionTypes.DOCUMENTS_LOADED
            }
        case eventActionTypes.PERFORMANCE_PAGE:
            return {
                ...state,
                ...action.payload,
                activityType: eventActionTypes.PERFORMANCE_PAGE
            }
        case eventActionTypes.SALES_ENABLEMENT_PAGE:
            return {
                ...state,
                ...action.payload,
                activityType: eventActionTypes.SALES_ENABLEMENT_PAGE
            }
        default:
            return state;
    }
}

export default eventReducer