import React from 'react';

// styles
import useStyles from './index.styles';

// mui-icons
import CloseIcon from '@mui/icons-material/Close';

// mui
import { Button, Divider } from '@mui/material';

// components
import Lottie from 'react-lottie-player';

// lottie-assests
import formSubmissionLottieData from "../../../../lottie-assets/Form_Submission_lottie.json";
import timeupFormSubmissionLottieData from "../../../../lottie-assets/timeout_forms.json";


// utils
import commonUtil from '../../../../utils/commonUtil';
import moment from 'moment';
import { useTranslation } from 'react-i18next';


const FormWinScreen = (props) => {

    const { formName, isAssessment, timeup, openForm, setOpenForm, showScore, score, maxScore } = props;

    const formCloseOrExit = () => {
        window.location.href = '/learn';
        setOpenForm(false);
    }

    const styles = useStyles();

    const { t } = useTranslation()
    return (
        <div className={styles.formWinContainer}>
            <div className={styles.formInfoContainer}>
                <div className={`${styles.centerFlex} ${styles.closeContainer}`}>
                    <img src='/images/icons/form-close-icon.svg' className={styles.closeIcon} onClick={() => formCloseOrExit()} />

                </div>
                <div className={`${styles.centerFlex} ${styles.info}`}>
                    <div className={styles.formName}>{formName}</div>
                </div>
            </div>

            <div className={styles.userInfoContainer}>
                <div className={`${styles.tickContainer}`}>
                    <Lottie
                        animationData={timeup ? timeupFormSubmissionLottieData : formSubmissionLottieData}
                        loop={false}
                        play={true}
                        style={{ width: '50%' }}
                    />
                </div>
                <div className={styles.userInfo}>
                    <div className={styles.thankTimeupText} style={{
                        color: isAssessment && timeup ? 'red' : 'black'
                    }}>
                        {isAssessment && timeup ? t("TIME'S UP") : t('THANK YOU')}
                    </div>
                    <div className={styles.userName}>
                        {commonUtil.getUserName() === null || commonUtil.getUserName() === undefined || commonUtil.getUserName() === '' ? commonUtil.getLoginUserName() : `${commonUtil.getUserName()} (${commonUtil.getLoginUserName()})`}
                    </div>

                    <Divider className={styles.divideLine} sx={{
                        borderBottomColor: isAssessment && timeup ? "red !important" : "green !important",
                    }} />

                    <div className={styles.additionalText}>
                        {isAssessment && timeup ? t("Your form is submitted automatically on") : t("You have successfully submitted this form on")}
                    </div>

                    <div className={styles.dateDisplay}>
                        {moment().format('DD MMMM YYYY')} at {moment().format('hh:mm A')}
                    </div>

                    {showScore && (
                        <>
                            <div className={styles.totalScoreText}>
                                {t("TOTAL SCORE")}:
                            </div>

                            <div className={styles.scoreText}>
                                {score} / {maxScore}
                            </div>
                        </>
                    )}
                </div>

            </div>

            <div className={`${styles.centerFlex} ${styles.exitButtonContainer}`}>
                <Button className={styles.exitButton} onClick={() => formCloseOrExit()}>
                    {t("Exit")}
                </Button>
            </div>
        </div>
    )
}

export default FormWinScreen;