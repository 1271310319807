import { commonPersistActionTypes } from "./common-persist.types";

const INITIAL_STATE = {
    viewChristmasGiftAnimation: false,
    isDarkMode: false,
    isSplashScreenViewed: false,
    deviceDetails: {},
    privacyPolicyDetails: {
        lastDateToAcceptPolicy: null,
        policyAccepted: true,
        policyAcceptedOn: null,
        remainingDaysToAccept: 0,
        isDeclined: false,
    },
}

const commonPersistReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case commonPersistActionTypes.VIEW_CHRISTMAS_GIFT_ANIMATION:
            return {
                ...state,
                viewChristmasGiftAnimation: action.payload,
            }

        case commonPersistActionTypes.SET_IS_DARK_MODE:
            return {
                ...state,
                isDarkMode: action.payload
            }

        case commonPersistActionTypes.SET_SPLASH_SCREEN_VIEW_FLAG:
            return {
                ...state,
                isSplashScreenViewed: action.payload
            }

        case commonPersistActionTypes.SET_DEVICE_DETAILS:
            return {
                ...state,
                deviceDetails: action.payload,
            }

        case commonPersistActionTypes.SET_PRIVACY_POLICY_DETAILS:
            return {
                ...state,
                privacyPolicyDetails: action.payload
            }

        default:
            return state;
    }
}

export default commonPersistReducer