import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// components
import StartScreen from "../common-screens/start-screen";
import AudioPlayer from './audio-player';
import HowToPlay from "../how-to-play/sounds-right";
import GbaGameOverScreen from "../common-screens/game-over-screen";
import GbaWinScreen from "../common-screens/win-screen";

// redux
import { submitChallengeAttemptData, submitEndChallengeAttempt } from "../../../redux/gba/gba.api";
import { startChallengeAttempt } from "../../../redux/microskill/microskill.api";
import { handleGbaGameState } from "../../../redux/gba/gba.actions";

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";

// constant
import constants from "../../../constants";

// Style
import useStyle from "./index.styles";

// images
const pause_btn = '/images/gba/pause_btn.svg';
const play_btn = '/images/gba/play_btn.svg';

const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;
let questions = [];

const SoundsRight = (props) => {
    const { gameData, handleGbaState, isPauseOverlay, defaultGradient, setAddPause } = props;
    const isMobile = useSelector(state => state?.common?.isMobile);
    const optionData = gameData?.questions;
    const contextData = gameData?.Context;
    const sessionStartTime = '';
    const { microskillId, challengeId } = useParams();
    const dispatch = useDispatch();

    let maxLifeGba = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxLife;
    let maxQuestion = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxQuestion;
    let questionsInGba = optionData.length;
    let maxLife = commonUtil.getMaxLifeForGba({
        gbaType: gameData.designType,
        questionsInGba: questionsInGba,
        maxQuestion: maxQuestion,
        maxLife: maxLifeGba,
    });

    let mxmScore = 120;
    let marksPerCorrectAns = mxmScore / questionsInGba;

    let maxTime = 120;
    let contextStartTime = 0;
    let tempTimer = maxTime;
    let lifeRemaining = maxLife;
    let noOfCircles = lifeRemaining;
    let obtainedScore = 0;
    let currentContextId = null;

    const classes = useStyle(isMobile);
    const qstnBoxRef = useRef(null);
    const lifeTextRef = useRef(null);
    const timerRef = useRef();
    const lifeRef = useRef();
    const questionRef = useRef();
    const dropContainerRef = useRef();
    const progressBarRef = useRef();
    const containerRef = useRef(null);
    const soundsRightRef = useRef(null);
    const heartRef = useRef();
    const audioPlayerRef = useRef();
    const dropTextRef = useRef();
    const gradientOverlayRef = useRef([]);
    const checkMarkRef = useRef();
    const crossMarkRef = useRef();
    const optionContainerRef = useRef();
    const intervalRef = useRef();
    const audioLoadIntervalRef = useRef();
    const audioPlayerBoxRef = useRef();
    const prevAnsTimeRef = useRef(maxTime);
    const finalChallengeDataRef = useRef();
    const attemptDataRef = useRef();
    const totalScoreRef = useRef();
    const timerTextRef = useRef();
    const clockRef = useRef();
    const questionNoRef = useRef();
    const backgroundSoundRef = useRef();
    const gamePlaySoundRef = useRef();

    const [attemptData, setAttemptData] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const [totalScore, setTotalScore] = useState(0);
    const [isHowToPlayScreen, setIsHowToPlayScreen] = useState(false);
    const [isGameComplete, setIsGameComplete] = useState(false);
    const [gameEndType, setGameEndType] = useState('');
    const [isGamePage, setIsGamePage] = useState(false);
    const [isDropContainerVisible, setIsDropContainerVisible] = useState(false);
    const [isAudioPlayerVisible, setIsAudioPlayerVisible] = useState(true);
    const [isAudioLoaded, setIsAudioLoaded] = useState(false);
    const [dropContainerWidth, setDropContainerWidth] = useState(100);
    const [activeIndex, setActiveIndex] = useState(0);
    const [trackDuration, setTrackDuration] = useState(0);
    const [componentKey, setComponentKey] = useState(new Date().getTime());
    const [incorrectData, setIncorrectData] = useState();
    const [isAudioPlaying, setIsAudioPlaying] = useState(true);
    const isHDScreen = commonUtil.getScreenResolution() === 'HD';

    let filePath = !isEmpty(contextData.context) ? contextData.context : "/sample-documents/mp3-1.mp3";
    const audioRef = useRef(new Audio(filePath));

    const totalGBATimespentIntervalRef = useRef();
    const totalGBATimespent = useRef(0);

    let active = false;
    let isDragActive = true;
    let currentX;
    let currentY;
    let initialX;
    let initialY;
    let xOffset = 0;
    let yOffset = 0;
    let dragItem = null;
    let container = null;
    let runningIndex = 0;
    let circleOffset = 100;
    let circleFadeDurationMs = 1500;
    let correctAnswersCount = 0;
    let containerId = 'sounds-right-container';
    let sampleStatement = 'Listen to the sound carefully';
    let bgGradientDefault = defaultGradient;
    let correctAnsGradient = 'linear-gradient(#10AB10, #0B670B)';
    let inCorrectAnsGradient = 'linear-gradient(#D51313, #721010)';
    let hslColorPer = 54;
    let headerBg = '#4F0060';
    let gradientBg = "linear-gradient(#5B0F8E, #E63A35)";
    let colorRed = "#F63B34";
    // let dropCircleOpacityArray = ["0.3", "0.4", "0.5", "1"];
    let correctCircleBgArr = [`hsl(120deg 68% ${hslColorPer}%)`];
    let inCorrectCircleBgArr = [`hsl(0deg 81% ${hslColorPer}%)`];
    let circleBgArray = ['#bf7f47', '#e1c022', '#f3e60e', '#fbf804'];

    // add color to the array
    for (let k = 1; k <= maxLife; k++) {
        correctCircleBgArr.push(`hsl(120deg 68% ${hslColorPer - (k * 5)}%)`);
        inCorrectCircleBgArr.push(`hsl(0deg 81% ${hslColorPer - (k * 5)}%)`);
    }
    correctCircleBgArr = correctCircleBgArr.reverse();
    inCorrectCircleBgArr = inCorrectCircleBgArr.reverse();

    useEffect(() => {
        if (isEmpty(attemptData)) getChallengeDataAsync();
    }, [])

    useEffect(() => {
        if (typeof document.hidden !== "undefined") {
            // Listen for visibilitychange event
            document.addEventListener("visibilitychange", handleVisibilityChange);
        }

        return () => {
            removeListener();
            clearInterval(intervalRef.current);
            clearInterval(totalGBATimespentIntervalRef.current);
        }
    }, []);

    const handleVisibilityChange = () => {
        if (document.hidden) {
            // User has minimized the browser
            handlePlayBtnClick(false);
        }
    }

    useEffect(() => {
        if (isPauseOverlay) {
            if (!isEmpty(backgroundSoundRef.current)) backgroundSoundRef.current.pause();
            handleBackButtonClick();
            if (isAudioPlayerVisible) {
                handlePlayBtnClick(false);
            }
        } else {
            if (!isEmpty(backgroundSoundRef.current)) backgroundSoundRef.current.play();
            if (!isAudioPlayerVisible) {
                handleResumeClick();
            }
        }
    }, [isPauseOverlay]);

    useEffect(() => {
        // setGameEndType('win');
        // setIsGameComplete(true);
        if (isGamePage) {
            totalScoreRef.current = 0;
            handleGbaState(GBA_SCREEN_STATE[2]);
        }
        if (!isAudioLoaded && isGamePage) {
            audioLoadIntervalRef.current = setInterval(() => {
                if (isNaN(audioRef.current.duration)) return
                clearInterval(audioLoadIntervalRef.current);
                audioLoadIntervalRef.current = null;
                getTrackDuration();
                setIsAudioLoaded(true);
            }, 1000);
        }

        let containerWidth = dropContainerRef.current ? dropContainerRef.current.clientHeight : dropContainerWidth;
        // if (containerWidth <= 330) setsXsScreen(true);
        setDropContainerWidth(containerWidth);
        setIsDropContainerVisible(true);

        // clean up
    }, [isGamePage]);

    const getChallengeDataAsync = async () => {
        const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
        setAttemptData(attemptResp.data.data);
        attemptDataRef.current = attemptResp.data.data
    };

    useEffect(() => {
        if (isEmpty(attemptData)) return
        // set initial state
        let tempData = {
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            status: constants.GBA_ILA_STATUS.exited,
        }
        dispatch(handleGbaGameState(tempData));
    }, [attemptData])

    const getTrackDuration = () => {
        clearInterval(audioLoadIntervalRef.current);
        // console.log("audioRef:",audioLoadIntervalRef.current);
        let trackDuration = Math.round(audioRef.current.duration);
        let timeInSeconds = trackDuration % 60 > 9 ? (trackDuration % 60) : '0' + (trackDuration % 60);
        let timeInMinutes = parseInt(trackDuration / 60);
        setTrackDuration(`${timeInMinutes}:${timeInSeconds}`);
        setIsAudioPlayerVisible(true);
    }

    const startTimer = (time) => {
        //start timer
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
        questions = [];
        totalGBATimespentIntervalRef.current = setInterval(() => {
            if (isEmpty(timerRef.current)) return;
            totalGBATimespent.current += 1;
            console.log("totalGBATimespent.current:", totalGBATimespent.current);
            setAddPause(true);
        }, 1000);

        intervalRef.current = setInterval(() => {
            if (timerRef.current) timerRef.current.innerHTML = time--;
            if (time === -1) {
                // Time zero
                gameLooseChallengeAnswers();
                submitEndChallengeAttempt({
                    ...finalChallengeDataRef.current,
                    microskillId: attemptDataRef.current.microskillId,
                    attemptId: attemptDataRef.current.id,
                    challengeId: attemptDataRef.current.challengeId,
                    scored: Math.ceil(totalScoreRef.current),
                    completion: false,
                    status: constants.GBA_ILA_STATUS.timerLose,
                    timespent: totalGBATimespent.current
                });
                handleGbaState(GBA_SCREEN_STATE[5]);
                setGameEndType('timeup');
                setTimeout(() => {
                    setIsGameComplete(true);
                }, 1500);
                setTimeout(() => {
                    clearInterval(intervalRef.current);
                    clearInterval(totalGBATimespentIntervalRef.current);
                })
            } if (time < 10) {
                timerTextRef.current.style.color = colorRed;
                clockRef.current.src = "/images/icons/clock-orange.png";
            }
        }, 1000);
    }

    const removeListener = () => {
        if (isEmpty(document.getElementById(containerId))) return;
        let old_element = document.getElementById(containerId);
        let new_element = old_element.cloneNode(true);
        old_element.parentNode.replaceChild(new_element, old_element);
    }

    const addListner = async () => {
        startTimer(tempTimer);
        const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
        await sleep(10);

        container = document.getElementById(containerId);
        if (container === null) return;

        container.addEventListener("touchstart", dragStart, false);
        container.addEventListener("touchend", dragEnd, false);
        container.addEventListener("touchmove", drag, false);

        container.addEventListener("mousedown", dragStart, false);
        container.addEventListener("mouseup", dragEnd, false);
        container.addEventListener("mousemove", drag, false);
    }

    const restartGame = () => {
        setIsAudioPlayerVisible(true);
        setIsGameComplete(false);
        setIsGamePage(true);
        getChallengeDataAsync();
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
        prevAnsTimeRef.current = maxTime;

        // addListner();
        setActiveIndex(0);
        totalScoreRef.current = 0;
        totalGBATimespent.current = 0;
    }

    const handleBackButtonClick = () => {
        let timespent = totalGBATimespent.current;
        dispatch(handleGbaGameState({
            ...finalChallengeDataRef.current,
            microskillId: attemptDataRef.current.microskillId,
            challengeId: attemptDataRef.current.challengeId,
            attemptId: attemptDataRef.current.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            timespent: timespent
        }))
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
    }

    const handleResumeClick = () => {
        setAddPause(true);
        startTimer(isEmpty(timerRef.current) ? 0 : parseInt(timerRef.current.innerHTML));
        // gbaPauseOverlayRef.current.style.display = 'none';
    }

    const handlePlayBtnClick = (state) => {
        setIsPlaying(state);
        if (!isEmpty(audioPlayerRef.current)) audioPlayerRef.current.handlePlayPause(state);
    }

    const hanldeTrackEnd = () => {
        let timer = new Date();
        contextStartTime = timer.getTime() / 1000;
        addListner();
        setIsAudioPlayerVisible(false);
        audioPlayerBoxRef.current.style.display = 'none';
    }

    const dragStart = (e) => {
        dragItem = document.getElementById(e?.target?.id);
        if (!isDragActive) return
        if (isEmpty(dragItem)) return;
        if (dragItem.getAttribute('drag') !== 'true') return;
        dragItem.style.transition = "0s";
        dragItem.style.boxShadow = '0px 2px 4px -2px #000';

        if (e.type === "touchstart") {
            initialX = e.touches[0].clientX - xOffset;
            initialY = e.touches[0].clientY - yOffset;
        } else {
            initialX = e.clientX - xOffset;
            initialY = e.clientY - yOffset;
        }
        active = true;
    }

    const drag = (e) => {
        if (!isDragActive) return
        if (dragItem == null) return;
        if (!active) return;
        if (dragItem.getAttribute('drag') !== 'true') return;

        e.preventDefault();
        if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;
        } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
        }
        xOffset = currentX;
        yOffset = currentY;
        setTranslate(currentX, currentY, dragItem, true);

        let dropEl = document.getElementById('drop-box');
        if (!isEmpty(dropEl)) {
            // check option is dragged into the center
            if (isOverlapping(dragItem, dropEl)) {
                if (isEmpty(dropTextRef.current)) return;
                dropTextRef.current.style.transform = 'scale(0)';
            } else {
                dropTextRef.current.style.transform = 'scale(1)';
            }
        }
    }

    const dragEnd = async (event) => {
        if (dragItem === null) return;
        if (dragItem.getAttribute('drag') !== 'true') return;
        // if ( !isEmpty(gameEndType) ) return;
        isDragActive = false;
        dragItem.style.boxShadow = 'none';

        const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
        xOffset = yOffset = 0;
        active = false;
        dragItem.style.transition = "all .6s";
        let dropEl = document.getElementById('drop-box');
        if (isEmpty(dropTextRef.current)) return

        // dropTextRef.current.style.transform = 'scale(0)';
        if (!isEmpty(dropEl)) {
            // check option is dragged into the center
            if (isOverlapping(dragItem, dropEl)) {
                // scale down the dropped answer
                dragItem.style.transform = "translate3d(" + currentX + "px, " + currentY + "px, 0) scale(0)";

                // Progress Bar
                correctAnswersCount += 1;
                let progressBarWidth = (correctAnswersCount / optionData.length) * 100;
                if (!isEmpty(progressBarRef.current)) {
                    progressBarRef.current.style.width = progressBarWidth + "%";
                }
                // CHECK CORRECT OR INCORRECT ANSWER
                let optionId = dragItem.id.split('-')[1];
                optionContainerRef.current.style.opacity = '0';
                let correctAnswerObj = optionData[runningIndex].answers.find(d => d.isCorrect);
                let currentTime = parseInt(timerRef.current.innerHTML);

                let tempReq = {
                    qId: optionData[runningIndex].questionId,
                    aId: correctAnswerObj.answerId,
                    isCorrect: true,
                    t: prevAnsTimeRef.current - currentTime,
                    skillId: optionData[runningIndex].skillId,
                    contextId: optionData[runningIndex].contextId,
                };

                if (optionId == correctAnswerObj.answerId) {
                    gamePlaySoundRef.current.src = '/audio/soundsRight/SRcorrect.mp3';
                    gamePlaySoundRef.current.play();
                    handleChallengeAttemptData(tempReq);
                    prevAnsTimeRef.current = currentTime;
                    currentContextId = optionData[runningIndex].question;

                    // CORRECT ANSWER
                    obtainedScore += marksPerCorrectAns;
                    totalScoreRef.current += marksPerCorrectAns;
                    addColor(dropContainerRef.current?.childNodes, 'correct');
                    if (!isEmpty(gradientOverlayRef.current)) {
                        gradientOverlayRef.current.style.backgroundImage = `${correctAnsGradient}`;
                        gradientOverlayRef.current.style.opacity = '1';
                    }
                    setTimeout(() => {
                        checkMarkRef.current.style.opacity = `1`;
                    }, circleFadeDurationMs / 2);
                } else {
                    // INCORRECT ANSWER
                    gamePlaySoundRef.current.src = '/audio/soundsRight/SRwrong.mp3';
                    gamePlaySoundRef.current.play();
                    currentContextId = optionData[runningIndex].question;
                    lifeRemaining--;
                    lifeRef.current.innerHTML = lifeRemaining + ' x';
                    lifeTextRef.current.classList.add(`${classes.mainText}`);
                    qstnBoxRef.current.style.boxShadow = '0 0 15px -2px red';
                    tempReq.isCorrect = false;
                    handleChallengeAttemptData(tempReq);
                    if (lifeRemaining === 1) {
                        lifeRef.current.style.color = colorRed;
                        heartRef.current.src = "/images/icons/heart-red.png";
                    } else {
                        setTimeout(() => {
                            lifeTextRef.current.classList.remove(`${classes.mainText}`);
                        }, 2000);
                    }

                    addColor(dropContainerRef.current?.childNodes, 'incorrect');
                    if (!isEmpty(gradientOverlayRef.current)) {
                        gradientOverlayRef.current.style.opacity = '1';
                        gradientOverlayRef.current.style.backgroundImage = `${inCorrectAnsGradient}`;
                    }
                    setTimeout(() => {
                        crossMarkRef.current.style.opacity = `1`;
                        crossMarkRef.current.classList.add(classes.animateClockWiseRotation);
                    }, circleFadeDurationMs / 2);

                    let lifeCircleId = `life-${maxLife - lifeRemaining}`;
                    let lifeCircleEl = document.getElementById(lifeCircleId);

                    // Animate circle on wrong answer
                    if (!isEmpty(lifeCircleEl)) {
                        lifeCircleEl.style.transition = `${circleFadeDurationMs / 1000}s`;
                        lifeCircleEl.style.opacity = `0`;
                        await sleep(circleFadeDurationMs / 2);
                        lifeCircleEl.style.display = 'none';
                    }
                }
                //  NEW questions appears
                await sleep(circleFadeDurationMs);
                addColor(dropContainerRef.current?.childNodes, 'default');
                if (!isEmpty(gradientOverlayRef.current)) {
                    gradientOverlayRef.current.style.opacity = '0';
                    gradientOverlayRef.current.style.backgroundImage = ``;
                }
                if (lifeRemaining > 1) {
                    qstnBoxRef.current.style.boxShadow = 'none';
                }
                questionRef.current.style.scale = '0';
                crossMarkRef.current.classList.remove(classes.animateClockWiseRotation);
                await sleep(circleFadeDurationMs - 500);
                if (!isEmpty(gradientOverlayRef.current) && lifeRemaining > 1) {
                    gradientOverlayRef.current.style.backgroundImage = `${bgGradientDefault}`;
                }
                else {
                    lifeTextRef.current.classList.add(`${classes.incorrectGradientOverlayAnimationInfinite}`);
                    gradientOverlayRef.current.classList.add(`${classes.incorrectGradientOverlayAnimationInfinite}`)
                }
                crossMarkRef.current.style.opacity = `0`;
                checkMarkRef.current.style.opacity = `0`;
                setActiveIndex(prevState => {
                    runningIndex = prevState + 1;
                    // Check if all the question are complete
                    if (runningIndex > optionData.length - 1) {
                        setWinScreenData();
                        return prevState
                    }
                    else return (runningIndex)
                });
                optionContainerRef.current.style.opacity = '1';
                questionRef.current.style.scale = '1';
                dropTextRef.current.style.transform = 'scale(1)';

                if (lifeRemaining === 0) {
                    gameLooseChallengeAnswers();
                    await sleep(1500);
                    handleGbaState(GBA_SCREEN_STATE[4]);

                    clearInterval(intervalRef.current);
                    clearInterval(totalGBATimespentIntervalRef.current);
                    const submitEndChallengeAttemptData = await submitEndChallengeAttempt({
                        ...finalChallengeDataRef.current,
                        attemptId: attemptDataRef.current.id,
                        scored: Math.ceil(totalScoreRef.current),
                        completion: false,
                        status: constants.GBA_ILA_STATUS.lifeLose,
                        microskillId: attemptDataRef.current.microskillId,
                        challengeId: attemptDataRef.current.challengeId,
                        timespent: totalGBATimespent.current
                    })

                    setIncorrectData(submitEndChallengeAttemptData?.data?.data?.consecutiveIncorrectAttempt);
                    removeListener();
                    setGameEndType('loose');
                    setIsGameComplete(true);
                } else isDragActive = true;
            }
        }
        setTranslate(0, 0, dragItem, false);
    }

    const gameLooseChallengeAnswers = () => {
        let obtainedScore = 0;
        let sessionEndDateTime = new Date();
        let sessionEndTime = sessionEndDateTime.getTime() / 1000;
        let totalTimeSpent = sessionEndTime - sessionStartTime;
    }

    const setTranslate = (xPos, yPos, el, isIntitalTranslate) => {
        if (el === null) return
        if (!isIntitalTranslate) {
            el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0) scale(1)";
        } else {
            el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0) scale(1)";
        }
        isDragActive = true;
    }

    const isOverlapping = (e1, e2) => {
        if (e1.length && e1.length > 1) {
            e1 = e1[0];
        }
        if (e2.length && e2.length > 1) {
            e2 = e2[0];
        }
        const rect1 = e1 instanceof Element ? e1.getBoundingClientRect() : false;
        const rect2 = e2 instanceof Element ? e2.getBoundingClientRect() : false;

        let overlap = false;
        if (rect1 && rect2) {
            let ratio = 1.1;
            overlap = !(
                (rect1.right / ratio) < rect2.left ||
                rect1.left * ratio > rect2.right ||
                rect1.bottom / ratio < rect2.top ||
                rect1.top * ratio > rect2.bottom
            );
            return overlap;
        }
        console.warn('Please provide valid HTMLElement object');
        return overlap;
    }

    const addColor = (arr, isCorrect) => {
        if (isEmpty(arr)) return
        arr.forEach((el, i) => {
            el.style.opacity = 1;
            if (isCorrect === 'correct') {
                el.style.backgroundColor = correctCircleBgArr[i];
            } else if (isCorrect === 'incorrect') {
                el.style.backgroundColor = inCorrectCircleBgArr[i];
            } else {
                el.style.backgroundColor = circleBgArray[i];
                // el.style.opacity = dropCircleOpacityArray[i];
            }
        })
    }

    const setWinScreenData = () => {
        setTotalScore(obtainedScore);
        totalScoreRef.current = obtainedScore;
        handleGbaState(GBA_SCREEN_STATE[3]);
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
        submitEndChallengeAttempt({
            ...finalChallengeDataRef.current,
            attemptId: attemptDataRef.current.id,
            scored: Math.ceil(totalScoreRef.current),
            completion: true,
            status: constants.GBA_ILA_STATUS.completed,
            microskillId: attemptDataRef.current.microskillId,
            challengeId: attemptDataRef.current.challengeId,
            timespent: totalGBATimespent.current
        })
        removeListener();
        setGameEndType('win');
        setIsGameComplete(true);
    }

    const handleChallengeAttemptData = (params) => {
        let { qId, aId, isCorrect, t, skillId, contextId } = params;
        let questionObj = {
            questionId: qId,
            skillId: skillId,
            contextId: contextId,
            answers: [{
                answerId: aId,
                correct: isCorrect
            }],
            timespent: t
        };
        let req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptData.id,
            questions: [questionObj]
        }
        submitChallengeAttemptData(req);
        handleEndChallengeAttempt(questionObj, req, skillId, contextId)
    }

    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
        let tempQuestionObj = {
            ...questionObj,
            skillId: skillId,
            contextId: contextId,
        }
        let totalTimeSpent = (isEmpty(finalChallengeDataRef.current) ? 0 : finalChallengeDataRef.current.timespent) + tempQuestionObj.timespent;
        if (isEmpty(finalChallengeDataRef.current)) {
            finalChallengeDataRef.current = {
                ...req,
                questions: [tempQuestionObj],
                completion: true,
                timespent: totalTimeSpent,
                scored: 0,
                fallbackSave: true,
            }
        } else {
            finalChallengeDataRef.current.questions.push(tempQuestionObj)
            finalChallengeDataRef.current = {
                ...finalChallengeDataRef.current,
                timespent: totalTimeSpent,
            }
        }
    }

    const [fSize, setFSize] = useState(1);
    useEffect(() => {
        setFSize(window.innerHeight / 961);
    }, []);

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }
    window.addEventListener("resize", resFont);

    const handleAudioLoad = (e) => {
        backgroundSoundRef.current.play();
    }

    const handleAudioMutelick = () => {
        setIsAudioPlaying(prev => {
            if (prev && !isEmpty(backgroundSoundRef.current)) {
                backgroundSoundRef.current.muted = true;
                gamePlaySoundRef.current.muted = true;
            }
            else {
                backgroundSoundRef.current.muted = false;
                gamePlaySoundRef.current.muted = false;
            }
            return !prev
        })
    }


    return (
        <div style={{ backgroundImage: !isGamePage ? bgGradientDefault : bgGradientDefault, fontSize: `${fSize}rem` }}
            className={classes.soundsRightRoot} ref={soundsRightRef} key={componentKey}>
            {
                !isGamePage ? (
                    !isHowToPlayScreen ? (
                        <StartScreen
                            setIsGamePage={setIsGamePage}
                            setIsHowToPlayScreen={setIsHowToPlayScreen}
                            bg={"/images/gba/sounds_right_bg.png"}
                            logoWidth={'55%'}
                            gameLogo="/images/gba/sounds_right_logo.svg"
                            handleGbaState={handleGbaState}
                        />
                    ) : (
                        <HowToPlay
                            qstnBoxBg={'#000000'}
                            setIsGamePage={setIsGamePage} />
                    )
                ) : (
                    !isGameComplete ? (
                        <>
                            <div className={classes.gradientOverlay} ref={gradientOverlayRef} />
                            <div className={classes.container} >
                                <div className={classes.containerBox}>

                                    <div className={classes.progressBarContainer}>
                                        <div className={classes.progressBar} ref={progressBarRef} />
                                    </div>

                                    {/* QUESTION BOX */}
                                    <div className={classes.qstnContainer}>
                                        <div className={classes.qstnBox} ref={qstnBoxRef}>
                                            <div className={classes.assetBox}>
                                                <div className={classes.lifeText} ref={lifeTextRef}>
                                                    <div className={classes.life}><b ref={lifeRef}>{lifeRemaining} x</b></div>
                                                    <img src='/images/icons/heart.png' className={classes.icon} ref={heartRef} alt="heart" />
                                                </div>
                                                <div className={classes.questionNo}>Q<span ref={questionNoRef}>{activeIndex + 1}</span>/{optionData.length}</div>
                                                <div className={classes.timerText} ref={timerTextRef}>
                                                    <img src='/images/icons/stopwatch.png' className={classes.timerIcon} ref={clockRef} alt="timer" />
                                                    <div><b><span ref={timerRef}>{tempTimer}</span>s</b></div>
                                                </div>
                                            </div>
                                            <div className={classes.qstn} ref={questionRef}>
                                                {isAudioPlayerVisible ? sampleStatement : optionData[activeIndex].question}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.gamePlayBox}
                                        ref={containerRef}
                                        id={containerId}
                                        style={{ padding: isHDScreen ? '0.6rem' : null }}
                                    >
                                        {
                                            !isAudioPlayerVisible ?
                                                <div className={classes.muteSwitch} onClick={handleAudioMutelick}>
                                                    {isAudioPlaying ? (
                                                        <img src="/images/gba/learno_casino_sound.png" alt="icon" width={30} />
                                                    ) : (
                                                        <img src="/images/gba/learno_casino_mute.png" alt="icon" width={30} />
                                                    )}
                                                </div>
                                                : null
                                        }

                                        <div className={classes.dropContainer} ref={dropContainerRef} >
                                            {
                                                isDropContainerVisible && (
                                                    Array.from(Array(noOfCircles), (e, i) => {
                                                        let circleWidth = dropContainerWidth - (i * 50) - (isHDScreen ? circleOffset - 40 : circleOffset);
                                                        return (
                                                            <div key={i}
                                                                id={i === noOfCircles - 1 ? 'drop-box' : `life-${i + 1}`}
                                                                className={classes.lifeCircle}
                                                                style={{
                                                                    width: `${circleWidth}px`,
                                                                    height: `${circleWidth}px`,
                                                                    backgroundColor: `${circleBgArray[i]}`,
                                                                    // display: `${displayState}`,
                                                                    // opacity: `${dropCircleOpacityArray[i]}`,
                                                                }}
                                                            >
                                                                {
                                                                    (i === noOfCircles - 1) && (
                                                                        isAudioLoaded ? (
                                                                            isAudioPlayerVisible ? (
                                                                                <img src={!isPlaying ? play_btn : pause_btn} alt="play pause"
                                                                                    className={classes.playPauseBtn} style={{ width: `${circleWidth / 3.5}px` }}
                                                                                    onClick={() => { handlePlayBtnClick(!isPlaying) }} />
                                                                            ) : (
                                                                                <>
                                                                                    <div className={classes.dropHereText} ref={dropTextRef}>Drag & Drop Your Answer</div>
                                                                                    <img src="/images/gba/check.png" alt="check" className={classes.checkMark} ref={checkMarkRef} />
                                                                                    <img src="/images/gba/cross.png" alt="cross" className={classes.crossMark} ref={crossMarkRef} />
                                                                                </>
                                                                            )
                                                                        ) : (
                                                                            <span className={classes.loadingText}>Loading...</span>
                                                                        )
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                )
                                            }
                                        </div>

                                        <div className={classes.bottomContainer} >
                                            <div ref={audioPlayerBoxRef}
                                                style={{ opacity: isAudioLoaded && isAudioPlayerVisible ? '1' : '0' }}>
                                                {
                                                    isAudioPlayerVisible && (
                                                        <AudioPlayer
                                                            filePath={filePath}
                                                            totalTrackDuration={trackDuration}
                                                            handlePlayBtnClick={handlePlayBtnClick}
                                                            hanldeTrackEnd={hanldeTrackEnd}
                                                            ref={audioPlayerRef} />
                                                    )
                                                }
                                            </div>

                                            {
                                                isAudioLoaded && !isAudioPlayerVisible && (
                                                    <div className={classes.optionContainer} ref={optionContainerRef}>
                                                        {
                                                            optionData[activeIndex].answers.map((item, index) => (
                                                                <button key={item.answerId} drag="true"
                                                                    id={`option-${item.answerId}`}>
                                                                    {item.answer}
                                                                </button>
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            }
                                            {
                                                !isAudioPlayerVisible ?
                                                    <>
                                                        <audio
                                                            ref={backgroundSoundRef}
                                                            src="/audio/soundsRight/SRbackground.mp3"
                                                            preload="metadata"
                                                            onLoadedMetadata={handleAudioLoad}
                                                            onEnded={(e) => {
                                                                e.target.play();
                                                            }}
                                                        ></audio>
                                                        <audio
                                                            ref={gamePlaySoundRef}
                                                            preload="metadata"
                                                        ></audio>
                                                    </>
                                                    : null
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {
                                gameEndType === 'win' && (
                                    <GbaWinScreen
                                        maxScore={mxmScore}
                                        bg={""}
                                        obtainedScore={Math.ceil(totalScore)} />
                                )
                            }
                            {
                                gameEndType === 'loose' && (
                                    <GbaGameOverScreen
                                        type={"gameover"}
                                        gameType={gameData?.gameType}
                                        gameData={gameData}
                                        incorrectAttempt={incorrectData}
                                        bg={""}
                                        bgGradient={gradientBg}
                                        restartGame={restartGame} />
                                )
                            }
                            {
                                gameEndType === 'timeup' && (
                                    <GbaGameOverScreen
                                        type={"time"}
                                        bg={""}
                                        bgGradient={gradientBg}
                                        restartGame={restartGame} />
                                )
                            }
                        </>
                    )
                )
            }
        </div>
    )
};

export default SoundsRight;