import { authActionTypes } from "./auth.types";

export const setCurrentUser = (data) => ({
    type: authActionTypes.SET_CURRENT_USER,
    payload: data
})

export const setToken = (data) => ({
    type: authActionTypes.SET_TOKEN,
    payload: data
})

export const setThirdPartyIntegrationFlag = (data) => ({
    type: authActionTypes.IS_THIRD_PARTY_INTERGRATION,
    payload: data
})

export const setMsalRequestToken = (data) => ({
    type: authActionTypes.SET_MSAL_REQUEST_TOKEN,
    payload: data
});

export const setOktaRequestToken = (data) => ({
    type: authActionTypes.SET_OKTA_REQUEST_TOKEN,
    payload: data
});

export const setUserProfileData = (data) => ({
    type: authActionTypes.SET_USER_PROFILE_DATA,
    payload: data
});

export const setUser = (data) => ({
    type: authActionTypes.SET_USER,
    payload: data
});