import React, { useEffect, useState, useRef } from "react";
import ProductsCardContainer from "../products-card-container";
import ProductCard from "../product-card";
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import { SalesEnablementPage } from "../../redux/event-tracking/event.action";
import { submitUserActivityData } from "../../redux/event-tracking/event.api";

// mui
import { Box } from "@mui/material";

// constants
import { salesEnablementDataMahindra } from "../../constants/sales-enablement-data-mahindra";
import { salesEnablementDataCSB } from "../../constants/sales-enablement-data-csb";

// utils
import isEmpty from "../../utils/isEmpty";

// theme

// styles

const SalesEnablementMobileView = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userActivityData = useSelector(state => state?.event);
    const [openPersonaList] = useState(false);
    const [selectedPersona] = useState(null);

    const queryParams = new URLSearchParams(window.location.search);
    const productId = queryParams.get('productId');

    const firstRenderRef = useRef(true)


    const { PRODUCT_LIST } = productId === 1 ? salesEnablementDataMahindra : salesEnablementDataCSB;

    // const ChangePersonaView = (bool, str) => {
    //     setOpenPersonaList(bool);
    //     setSelectedPersona(str);

    // }

    useEffect(() => {
        if (firstRenderRef.current && openPersonaList) {
            let activityData = {
                ...userActivityData,
                oldScreen: userActivityData.currentScreen,
                currentScreen: "SALES ENABLEMENT",
                nextSlide: '',
                activityData: { screen: "Persona vise product list screen" }
            }
            dispatch(SalesEnablementPage(activityData));
            submitUserActivityData(activityData);
            firstRenderRef.current = !firstRenderRef.current
        }
    }, [openPersonaList, firstRenderRef.current])

    const handleProductCardClick = (d) => {
        navigate(`/product/${d.id}?active=summary${isEmpty(selectedPersona) ? "" : `&persona=${selectedPersona}`}`);
    }

    return (
        <>
            {
                !openPersonaList && <>
                    <ProductsCardContainer data={PRODUCT_LIST} name="" checked={false} />
                    {/* <PersonaCardContainer data={PERSONA_LIST} name="" checked={false} ChangePersonaView={ChangePersonaView} /> */}
                </>
            }{
                openPersonaList && <>
                    <Box
                        className={"scrollbar-hidden"}
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(1, 1fr)',
                            columnGap: '0.5rem',
                            rowGap: '0.5rem',
                            height: '100%',
                            overflowY: 'auto',
                        }}>
                        {
                            PRODUCT_LIST.map(item => (
                                <React.Fragment key={`unique-${item.id}`}>
                                    <ProductCard cardData={item} handleMicroskillCardClick={() => handleProductCardClick(item)} ratio="16/9" />
                                </React.Fragment>
                            ))
                        }
                    </Box>
                </>
            }
        </>
    )
}

export default SalesEnablementMobileView;